import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes'
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, stopAsyncValidation, formValueSelector } from 'redux-form'
import UserInfoForm, { validate } from '../components/User/UserInfoForm'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'
import { UserData } from 'dataObjects/user'
import { storeUser } from 'actions/userAction'
import { ValidationError } from 'errors/RequestValidationError'
import { addressSearchByPostalCode, PostalCodeSearchResult } from 'utils/postalCodeSearch'


interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

const CN_FORM_NAME = 'UserInfoForm'
export default function UserInfoFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  const fvSelector = formValueSelector(CN_FORM_NAME)

  const autoAddressLoad = (fnChange) => {
    const postal_code = fvSelector(state, 'contact_postal_code')
    // 住所検索
    addressSearchByPostalCode(postal_code, (result) => {
      let addressType: PostalCodeSearchResult = null
      if (Array.isArray(result.results)) {
        addressType = result.results[0]
      } else {
        addressType = result.results
      }
      fnChange("contact_address_pref", addressType.address1);
      fnChange("contact_address_city", addressType.address2);
      fnChange("contact_address_street", addressType.address3);
    })
  }

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {  
      setIsDisplay(false)
      
      const serverUserData = state.userData;
      console.log({serverUserData})
      const userData: UserData = {
        user_id: serverUserData.user_id,
        user_name: values['user_name'],
        user_email: serverUserData.user_email,
        contact_family_name: values['contact_family_name'],
        contact_family_name_kana: values['contact_family_name_kana'] ,
        contact_given_name: values['contact_given_name'],
        contact_given_name_kana: values['contact_given_name_kana'],
        contact_email: values['contact_email'],
        contact_email_confirmation: values['contact_email_confirmation'],
        contact_postal_code: values['contact_postal_code'],
        contact_address_pref: values['contact_address_pref'],
        contact_address_city: values['contact_address_city'],
        contact_address_street: values['contact_address_street'],
        contact_address_room: values['contact_address_room'],
        contact_tel: values['contact_tel'],
        use_times: serverUserData.use_times
      }
      ownDispatch(storeUser(userData))
      
    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if (isDisplay === false) {
      console.log({ errors })
           
      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)

      ownDispatch(stopAsyncValidation(CN_FORM_NAME, errors))
      setIsDisplay(true)
    }
  } 

  const _props = {
    onSubmit: submit,
    initialValues: convertFormInitialValues<UserData>(state.userData),
    validationResult: state.ss422ValidationErrorResult,
    ssValidation: showValidationError,
    autoAddressLoad: autoAddressLoad
  }

  return <UserInfoForm {..._props} />

}