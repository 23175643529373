import {
  FETCH_CLOSEDAY_LIST_START,
  FETCH_CLOSEDAY_LIST_SUCCESS,
  FETCH_CLOSEDAY_LIST_FAIL,
  STORE_CLOSEDAY_LOCAL,
  STORE_CLOSEDAY_SUCCESS
} from '../actions/spaceCloseDayAction';

import { ActionTypes } from '../actions/actionTypes';
import { CloseDay } from 'dataObjects/closeday';

export interface CloseDayState {
  list: CloseDay[]
}

const initialCloseDayState: CloseDayState = {
  list: []
}


const closeDay = (
  state: CloseDayState = initialCloseDayState,
  action: ActionTypes,
): CloseDayState => {
  switch (action.type) {
    // 休業日
    case FETCH_CLOSEDAY_LIST_START:
      return { ...state };
    case FETCH_CLOSEDAY_LIST_SUCCESS:
      return {
        ...state,
        list: [
          ...action.payload
        ],
      }
    case STORE_CLOSEDAY_LOCAL:
      return {
        ...state,
        list: [
          ...action.payload
        ],
      }
    case STORE_CLOSEDAY_SUCCESS:
      return {
        ...state,
        list: [
          ...action.payload
        ],
      }
    case FETCH_CLOSEDAY_LIST_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default closeDay;
