import { Normal, ValidationError422 } from '../types/appApiTypes';
import { NotFoundError } from '../errors/NotFoundError';
import { InternalServerError } from '../errors/InternalServerError';
import { AppResponseError } from '../errors/AppResponseError';
import { AuthenticationError } from '../errors/AuthenticationError';
import { RequestValidationError } from '../errors/RequestValidationError'
import { AxiosResponse } from 'axios';

/**
 * checking response from application and throw error if it's necessary
 */
export async function checkAppResponse(response: AxiosResponse<any>) {
  
    //const errRes: ErrorRes = await response.data;
    if(response.status >= 200 && response.status <= 299) {
      return
    }

    if(response.status === 401 || response.status === 403){
      // 認証認可エラー
      let message = ''
      switch(response.statusText) {
        case 'Unauthorized':
          message = 'メールアドレスまたはパスワードが一致しません'
          break;
        default:
          message = response.statusText
      }              
      throw new AuthenticationError(message);
    }

    if (response.status === 404) {
      throw new NotFoundError(response.statusText);
    }

    if (response.status === 422) {
      // Laravel API Request Validation Error
      throw new RequestValidationError(await response.data)
    }

    if (response.status >= 500) {
      throw new InternalServerError(response.statusText);
    }

    throw new AppResponseError(response.statusText, response);
  
}
