import { PATH_NAME_CHANGE } from '../actions/locationChangeAction';
import { ActionTypes } from '../actions/actionTypes';
import { ViewState } from 'dataObjects/behavior';
import { PAGE_SHOWN } from 'actions/pagingAction';
import { FETCH_SETTINGS_START, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAIL } from 'actions/settingsAction';
import {
  FETCH_TAX_RATE_MASTER_START,
  FETCH_TAX_RATE_MASTER_SUCCESS,
  FETCH_TAX_RATE_MASTER_FAIL,
  STORE_TAX_RATE_MASTER_START,
  STORE_TAX_RATE_MASTER_SUCCESS,
  STORE_TAX_RATE_MASTER_INVALID,
  STORE_TAX_RATE_MASTER_FAIL,
  FETCH_FACILITIES_MASTER_START,
  FETCH_FACILITIES_MASTER_SUCCESS,
  FETCH_FACILITIES_MASTER_FAIL,
  STORE_FACILITIES_MASTER_START,
  STORE_FACILITIES_MASTER_SUCCESS,
  STORE_FACILITIES_MASTER_INVALID,
  STORE_FACILITIES_MASTER_FAIL,
  FETCH_USAGE_MASTER_START,
  FETCH_USAGE_MASTER_SUCCESS,
  FETCH_USAGE_MASTER_FAIL,
  STORE_USAGE_MASTER_START,
  STORE_USAGE_MASTER_SUCCESS,
  STORE_USAGE_MASTER_INVALID,
  STORE_USAGE_MASTER_FAIL,
  FETCH_TERMS_OF_SERVICE_MASTER_START,
  FETCH_TERMS_OF_SERVICE_MASTER_SUCCESS,
  FETCH_TERMS_OF_SERVICE_MASTER_FAIL,
  STORE_TERMS_OF_SERVICE_MASTER_START,
  STORE_TERMS_OF_SERVICE_MASTER_SUCCESS,
  STORE_TERMS_OF_SERVICE_MASTER_INVALID,
  STORE_TERMS_OF_SERVICE_MASTER_FAIL,
} from '../actions/masterAction';
import { INTERNAL_SERVER_ERROR, NOT_FOUND_ERROR, SS_VALIDATION_ERROR } from 'actions/errorAction';
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, LOGIN_FAIL, LOGIN_START } from 'actions/authenticationActions';
import { DIALOG_CLOSE } from 'actions/dialogAction';
import { FETCH_DROPIN_RESERVATION_LIST_START, FETCH_DROPIN_RESERVATION_LIST_SUCCESS, FETCH_DROPIN_RESERVATION_LIST_FAIL, CANCEL_DROPIN_RESERVATION_SUCCESS, CANCEL_DROPIN_RESERVATION_INVALID, CANCEL_DROPIN_RESERVATION_FAIL, CANCEL_DROPIN_RESERVATION_START, FETCH_DROPIN_RESERVATION_START, FETCH_DROPIN_RESERVATION_SUCCESS, FETCH_DROPIN_RESERVATION_FAIL, AUTHORIZATION_DROPIN_RESERVATION_START, AUTHORIZATION_DROPIN_RESERVATION_SUCCESS, AUTHORIZATION_DROPIN_RESERVATION_INVALID, AUTHORIZATION_DROPIN_RESERVATION_FAIL, CAPTURE_DROPIN_RESERVATION_START, CAPTURE_DROPIN_RESERVATION_SUCCESS, CAPTURE_DROPIN_RESERVATION_INVALID, CAPTURE_DROPIN_RESERVATION_FAIL } from 'actions/dropinReservationAction';
import { FETCH_REGULAR_RESERVATION_START, FETCH_REGULAR_RESERVATION_SUCCESS, FETCH_REGULAR_RESERVATION_FAIL, VALIDATION_REGULAR_RESERVATION_START, VALIDATION_REGULAR_RESERVATION_SUCCESS, VALIDATION_REGULAR_RESERVATION_INVALID, VALIDATION_REGULAR_RESERVATION_FAIL, STORE_REGULAR_RESERVATION_START, STORE_REGULAR_RESERVATION_SUCCESS, STORE_REGULAR_RESERVATION_INVALID, STORE_REGULAR_RESERVATION_FAIL, FETCH_REGULAR_RESERVATION_LIST_START, FETCH_REGULAR_RESERVATION_LIST_SUCCESS, FETCH_REGULAR_RESERVATION_LIST_FAIL } from 'actions/regularReservationAction';
import { FETCH_USER_START, FETCH_USER_SUCCESS, FETCH_USER_FAIL, STORE_USER_START, STORE_USER_SUCCESS, STORE_USER_FAIL, BAN_USER_START, BAN_USER_SUCCESS, RESTORE_USER_START, BAN_USER_FAIL, RESTORE_USER_SUCCESS, RESTORE_USER_FAIL, FORCEDELETE_USER_START, FORCEDELETE_USER_SUCCESS, FORCEDELETE_USER_FAIL, FORCEDELETE_USER_INVALID, RESTORE_USER_INVALID, BAN_USER_INVALID } from 'actions/userAction';
import { STORE_NEWS_FAIL, STORE_NEWS_START, STORE_NEWS_SUCCESS, STORE_NEWS_INVALID, REMOVE_NEWS_SUCCESS, REMOVE_NEWS_INVALID, REMOVE_NEWS_FAIL, REMOVE_NEWS_START, FETCH_NEWS_LIST_START, FETCH_NEWS_LIST_SUCCESS, FETCH_NEWS_LIST_FAIL } from 'actions/newsAction';
import { STORE_PROMOTIONCODE_START, STORE_PROMOTIONCODE_SUCCESS, STORE_PROMOTIONCODE_INVALID, STORE_PROMOTIONCODE_FAIL, FETCH_PROMOTIONCODE_LIST_START, FETCH_PROMOTIONCODE_LIST_SUCCESS, FETCH_PROMOTIONCODE_LIST_FAIL } from 'actions/promotionCodeAction';
import { STORE_CLOSEDAY_START, STORE_CLOSEDAY_SUCCESS, STORE_CLOSEDAY_INVALID, STORE_CLOSEDAY_FAIL, FETCH_CLOSEDAY_LIST_START, FETCH_CLOSEDAY_LIST_SUCCESS, FETCH_CLOSEDAY_LIST_FAIL } from 'actions/spaceCloseDayAction';
import { FETCH_SCHEDULE_LIST_START, FETCH_SCHEDULE_LIST_SUCCESS, FETCH_SCHEDULE_LIST_FAIL, SHOW_SCHEDULE_EVENT } from 'actions/scheduleAction';
import { FETCH_KEYBOXPASS_LIST_START, FETCH_KEYBOXPASS_LIST_SUCCESS, FETCH_KEYBOXPASS_LIST_FAIL, STORE_KEYBOXPASS_START, STORE_KEYBOXPASS_SUCCESS, STORE_KEYBOXPASS_INVALID, STORE_KEYBOXPASS_FAIL} from 'actions/keyBoxPassAction';
import { STORE_SPACE_INVALID, STORE_SPACE_START, STORE_SPACE_SUCCESS, STORE_SPACE_FAIL } from 'actions/spaceAction';


const initialState: ViewState = {
  loading: true,
  dialogOpen: false,
  updateDialogOpen: false,
  rejectDialogOpen: false,
  validationErrorDialogOpen: false
};

const pathChangeState: ViewState = {
  loading: true,
  dialogOpen: false,
  updateDialogOpen: false,
  rejectDialogOpen: false,
  validationErrorDialogOpen: false
};

const page = (
  state: ViewState = initialState,
  action: ActionTypes,
): ViewState => {
  switch (action.type) {
    // 設定取得
    case FETCH_SETTINGS_START:
      return { ...state, loading: true };
    case FETCH_SETTINGS_SUCCESS:
      return { ...state, loading: false };
    case FETCH_SETTINGS_FAIL:
      return { ...state, loading: false };
    // ログイン
    case LOGIN_START:
      return { ...state, loading: true };
    case LOGIN_SUCCESS:
      return { ...state, loading: false };
    case LOGIN_FAIL:
      return { ...state, loading: false };
    // 税率マスタ
    case FETCH_TAX_RATE_MASTER_START:
      return { ...state, loading: true };
    case FETCH_TAX_RATE_MASTER_SUCCESS:
      return { ...state, loading: false };
    case FETCH_TAX_RATE_MASTER_FAIL:
      return { ...state, loading: false };
    case STORE_TAX_RATE_MASTER_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false  };
    case STORE_TAX_RATE_MASTER_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true  };
    case STORE_TAX_RATE_MASTER_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case STORE_TAX_RATE_MASTER_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    // 設備マスタ
    case FETCH_FACILITIES_MASTER_START:
      return { ...state, loading: true };
    case FETCH_FACILITIES_MASTER_SUCCESS:
      return { ...state, loading: false };
    case FETCH_FACILITIES_MASTER_FAIL:
      return { ...state, loading: false };
    case STORE_FACILITIES_MASTER_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false  };
    case STORE_FACILITIES_MASTER_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true  };
    case STORE_FACILITIES_MASTER_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case STORE_FACILITIES_MASTER_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    // 利用用途マスタ
    case FETCH_USAGE_MASTER_START:
      return { ...state, loading: true };
    case FETCH_USAGE_MASTER_SUCCESS:
      return { ...state, loading: false };
    case FETCH_USAGE_MASTER_FAIL:
      return { ...state, loading: false };
    case STORE_USAGE_MASTER_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false  };
    case STORE_USAGE_MASTER_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true  };
    case STORE_USAGE_MASTER_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case STORE_USAGE_MASTER_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    // 利用規約マスタ
    case FETCH_TERMS_OF_SERVICE_MASTER_START:
      return { ...state, loading: true };
    case FETCH_TERMS_OF_SERVICE_MASTER_SUCCESS:
      return { ...state, loading: false };
    case FETCH_TERMS_OF_SERVICE_MASTER_FAIL:
      return { ...state, loading: false };
    case STORE_TERMS_OF_SERVICE_MASTER_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false  };
    case STORE_TERMS_OF_SERVICE_MASTER_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true  };
    case STORE_TERMS_OF_SERVICE_MASTER_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case STORE_TERMS_OF_SERVICE_MASTER_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    // 一時利用    
    /*
    case FETCH_DROPIN_RESERVATION_LIST_START:
      return { ...state, loading: true };
    case FETCH_DROPIN_RESERVATION_LIST_SUCCESS:
      return { ...state, loading: false };
    case FETCH_DROPIN_RESERVATION_LIST_FAIL:
      return { ...state, loading: false };
    */
    // 一時利用
    case FETCH_DROPIN_RESERVATION_START:
      return { ...state, loading: true };
    case FETCH_DROPIN_RESERVATION_SUCCESS:
      return { ...state, loading: false };
    case FETCH_DROPIN_RESERVATION_FAIL:
      return { ...state, loading: false };
    case CANCEL_DROPIN_RESERVATION_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false };
    case CANCEL_DROPIN_RESERVATION_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true };
    case CANCEL_DROPIN_RESERVATION_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case CANCEL_DROPIN_RESERVATION_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    case AUTHORIZATION_DROPIN_RESERVATION_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false };
    case AUTHORIZATION_DROPIN_RESERVATION_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true };
    case AUTHORIZATION_DROPIN_RESERVATION_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case AUTHORIZATION_DROPIN_RESERVATION_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    case CAPTURE_DROPIN_RESERVATION_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false };
    case CAPTURE_DROPIN_RESERVATION_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true };
    case CAPTURE_DROPIN_RESERVATION_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case CAPTURE_DROPIN_RESERVATION_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    // 定期利用 取得
    /*
    case FETCH_REGULAR_RESERVATION_LIST_START:
      return { ...state, loading: true };
    case FETCH_REGULAR_RESERVATION_LIST_SUCCESS:
      return { ...state, loading: false };
    case FETCH_REGULAR_RESERVATION_LIST_FAIL:
      return { ...state, loading: false };
    */
    case FETCH_REGULAR_RESERVATION_START:
      return { ...state, loading: true };
    case FETCH_REGULAR_RESERVATION_SUCCESS:
      return { ...state, loading: false };
    case FETCH_REGULAR_RESERVATION_FAIL:
      return { ...state, loading: false };
    // 定期利用 検証
    case VALIDATION_REGULAR_RESERVATION_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false };
    case VALIDATION_REGULAR_RESERVATION_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true };
    case VALIDATION_REGULAR_RESERVATION_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case VALIDATION_REGULAR_RESERVATION_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    // 定期利用 登録
    case STORE_REGULAR_RESERVATION_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false };
    case STORE_REGULAR_RESERVATION_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true };
    case STORE_REGULAR_RESERVATION_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case STORE_REGULAR_RESERVATION_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    // 定期利用 解除

    // おしらせ 取得
    /*
    case FETCH_NEWS_LIST_START:
      return { ...state, loading: true };
    case FETCH_NEWS_LIST_SUCCESS:
      return { ...state, loading: false };
    case FETCH_NEWS_LIST_FAIL:
      return { ...state, loading: false };
    */  
    // おしらせ 登録
    case STORE_NEWS_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false };
    case STORE_NEWS_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true };
    case STORE_NEWS_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case STORE_NEWS_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    // おしらせ 削除
    case REMOVE_NEWS_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, rejectDialogOpen: false };
    case REMOVE_NEWS_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, rejectDialogOpen: true };
    case REMOVE_NEWS_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case REMOVE_NEWS_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    
    // プロモーションコード 取得
    case FETCH_PROMOTIONCODE_LIST_START:
      return { ...state, loading: true };
    case FETCH_PROMOTIONCODE_LIST_SUCCESS:
      return { ...state, loading: false };
    case FETCH_PROMOTIONCODE_LIST_FAIL:
      return { ...state, loading: false };
    // プロモーションコード 登録
    case STORE_PROMOTIONCODE_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false };
    case STORE_PROMOTIONCODE_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true };
    case STORE_PROMOTIONCODE_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case STORE_PROMOTIONCODE_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    
    // 休業日 取得
    case FETCH_CLOSEDAY_LIST_START:
      return { ...state, loading: true };
    case FETCH_CLOSEDAY_LIST_SUCCESS:
      return { ...state, loading: false };
    case FETCH_CLOSEDAY_LIST_FAIL:
      return { ...state, loading: false };
    // 休業日 登録
    case STORE_CLOSEDAY_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false };
    case STORE_CLOSEDAY_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true };
    case STORE_CLOSEDAY_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case STORE_CLOSEDAY_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    // スペース暗証番号 取得
    case FETCH_KEYBOXPASS_LIST_START:
      return { ...state, loading: true}
    case FETCH_KEYBOXPASS_LIST_SUCCESS:      
      return { ...state, loading: false}
    case FETCH_KEYBOXPASS_LIST_FAIL:
      return { ...state, loading: false}
    case STORE_KEYBOXPASS_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false };
    case STORE_KEYBOXPASS_SUCCESS:      
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true };
    case STORE_KEYBOXPASS_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case STORE_KEYBOXPASS_FAIL:
      return { ...state, loading: false}

    // ユーザー情報更新
    case FETCH_USER_START:
      return { ...state, loading: true };
    case FETCH_USER_SUCCESS:
      return { ...state, loading: false };
    case FETCH_USER_FAIL:
      return { ...state, loading: false };
    // ユーザーデータ保存
    case STORE_USER_START:
      return { ...state, loading: true };
    case STORE_USER_SUCCESS:
      return { ...state, loading: false, dialogOpen: true };
    case STORE_USER_FAIL:
      return { ...state, loading: false };
    // ユーザーアカウント停止
    case BAN_USER_START:
      return { ...state, loading: true, validationErrorDialogOpen: false };
    case BAN_USER_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true }; 
    case BAN_USER_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case BAN_USER_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    case RESTORE_USER_START:
      return { ...state, loading: true, validationErrorDialogOpen: false };
    case RESTORE_USER_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false,  updateDialogOpen: true };     
    case RESTORE_USER_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case RESTORE_USER_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    case FORCEDELETE_USER_START:
      return { ...state, loading: true, validationErrorDialogOpen: false };
    case FORCEDELETE_USER_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false,  rejectDialogOpen: true };         
    case FORCEDELETE_USER_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case FORCEDELETE_USER_FAIL:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    // スケジュール表
    case FETCH_SCHEDULE_LIST_START:
      return { ...state, loading: true };
    case FETCH_SCHEDULE_LIST_SUCCESS:
      return { ...state, loading: false };
    case FETCH_SCHEDULE_LIST_FAIL:
      return { ...state, loading: false };
    // スケジュール表イベント表示
    case SHOW_SCHEDULE_EVENT: 
    return { ...state, loading: false, dialogOpen: true };
    // スペース更新
    case STORE_SPACE_START:
      return { ...state, loading: true, validationErrorDialogOpen: false, updateDialogOpen: false };
    case STORE_SPACE_INVALID:
      return { ...state, loading: false, validationErrorDialogOpen: true };
    case STORE_SPACE_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false, updateDialogOpen: true };
    case STORE_SPACE_FAIL:
      return { ...state, loading: false };

    // Location change
    case PATH_NAME_CHANGE:
      return pathChangeState;
    // ERROR
    case NOT_FOUND_ERROR:
      return { ...state, loading: false };
    case INTERNAL_SERVER_ERROR:
      return { ...state, loading: false };
    case SS_VALIDATION_ERROR:
        return { ...state, loading: false };
    // PAGE_SHOWN
    case PAGE_SHOWN:
      return { ...state, loading: false, validationErrorDialogOpen: false };
    case LOGOUT_SUCCESS:
      return { ...state, loading: false, validationErrorDialogOpen: false }
    // ダイアログ閉じた
    case DIALOG_CLOSE:
      return {
        ...state,
        dialogOpen: false,
        updateDialogOpen: false,
        rejectDialogOpen: false,
        validationErrorDialogOpen: false
      };
    default:
      return { ...state };
  }
};

export default page;
