import AppClient from "../httpClients/appClient";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { CallHistoryMethodAction, push } from "connected-react-router";
import { routes } from "../routes/Route";
import { Action } from "redux";
import { AppState } from "../types/stateTypes";
import { SnackBarAction } from "./actionTypes";

import * as hello from "hellojs";
import {
  AuthenticatedUser,
  AuthRes,
  AfterSignInRedirectRoute
} from "dataObjects/auth";
import { AxiosResponse } from "axios";
import { checkAppResponse } from "actionHelper/responseChecker";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const AFTER_SIGNIN_REDIRECT_ROUTE_SET =
  "AFTER_SIGNIN_REDIRECT_ROUTE_SET";
export const AFTER_SIGNIN_REDIRECT_ROUTE_CLEAR =
  "AFTER_SIGNIN_REDIRECT_ROUTE_CLEAR";


/* --------------------------------------------------------------------------------- */

export type AuthActions =
  | LoginStartAction
  | LoginSuccessAction
  | LoginFailAction
  | CallHistoryMethodAction;

type AuthThunkResult<R> = ThunkAction<R, AppState, undefined, AuthActions>;

export function sharealLoginAction(
  email: string,
  password: string
): AuthThunkResult<void> {
  return async (
    dispatch: ThunkDispatch<AppState, any, AuthActions>,
    getState: () => AppState
  ) => {
    try {
      dispatch(loginStartAction())
      
      const state = getState();
      const response: AxiosResponse<any> = await AppClient.post(
        process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/login",
        null,
        {
          email: email,
          password: password
        }
      );

      const body: AuthRes = await response.data;

      dispatch(
        loginSuccessAction({
          grantType: "password",
          id: body.user.id,
          email: body.user.email,
          role: body.user.role,
          access_token: body.access_token,
          expires_in: body.expires_in,
          name: body.user.name
        })
      );
      dispatch(push(routes.dashBoard.path));
    } catch (err) {
      dispatch(loginFailAction(err.message));
    }
  };
}


export interface LoginStartAction {
  type: typeof LOGIN_START;  
}

export function loginStartAction(  
): LoginStartAction {
  return {
    type: LOGIN_START    
  };
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: AuthenticatedUser;
}

export function loginSuccessAction(
  newAuthState: AuthenticatedUser
): LoginSuccessAction {
  return {
    type: LOGIN_SUCCESS,
    payload: newAuthState
  };
}

export interface LoginFailAction extends SnackBarAction<typeof LOGIN_FAIL> {}

export function loginFailAction(error: string): LoginFailAction {
  return {
    type: LOGIN_FAIL,
    snackBarMessage: error,
    variant: "error"
  };
}

/* --------------------------------------------------------------------------------- */

export type AuthRedirectActions =
  | AfterSignInRedirectRouteSetAction
  | AfterSignInRedirectRouteClearAction
  | CallHistoryMethodAction;

export interface AfterSignInRedirectRouteSetAction {
  type: typeof AFTER_SIGNIN_REDIRECT_ROUTE_SET;
  payload: AfterSignInRedirectRoute;
}

export function afterSignInRedirectRouteSetAction(
  newState: AfterSignInRedirectRoute
): AfterSignInRedirectRouteSetAction {
  return {
    type: AFTER_SIGNIN_REDIRECT_ROUTE_SET,
    payload: newState
  };
}

export interface AfterSignInRedirectRouteClearAction {
  type: typeof AFTER_SIGNIN_REDIRECT_ROUTE_CLEAR;
}

export function afterSignInRedirectRouteClearAction(): AfterSignInRedirectRouteClearAction {
  return {
    type: AFTER_SIGNIN_REDIRECT_ROUTE_CLEAR
  };
}

/* --------------------------------------------------------------------------------- */

export type AuthLogOutActions = LogOutSuccessAction | CallHistoryMethodAction;

type AuthLogOutThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  AuthLogOutActions
>;

export interface LogOutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

export function logOutAction(): LogOutSuccessAction {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function logout(): AuthLogOutThunkResult<void> {
  return (dispatch: ThunkDispatch<AppState, any, AuthLogOutActions>) => {
    dispatch(logOutAction());
    const nextRoute = routes.signOut.path;
    dispatch(push(nextRoute));
  };
}
