import * as React from "react";
import { Theme, createStyles, Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import makeStyles from "@material-ui/core/styles/makeStyles";
import BasePageContainer from "../containers/BasePageContainer";
import ListTable, { ListTableProps } from "components/ListTable/ListTable";
import InquirySearchFormContainer from "containers/InquirySearchFormContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: "100%"
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: "1.0em",
      paddingBottom: theme.spacing(2),
      borderBottom: "1px solid #cecece"
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: "1.6rem",
      paddingBottom: theme.spacing(2),
      paddingTop: "20px",
      paddingLeft: "12px",
      backgroundColor: "#fff"
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: "1.0em",
      marginBottom: theme.spacing(2),
      textAlign: "center"
    },
    list: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8)
    }
  })
);

interface OwnProps { }

export type InquiryListPageProps = OwnProps & ListTableProps;

const InquiryListPage: React.FC<InquiryListPageProps> = (
  props: InquiryListPageProps
) => {
  const classes = useStyles({});
  const {
    columns,
    data,
    total,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    setPage,
    setRowsPerPage,
    selectedRow
  } = props;

  const listTableProps: ListTableProps = {
    columns: columns,
    data: data,
    total: total,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions,
    setPage: setPage,
    setRowsPerPage: setRowsPerPage,
    selectedRow: selectedRow
  };

  return (
    <BasePageContainer>
      <Container fixed className={classes.topcontainer}>
        <Typography variant="h3" className={classes.subtitle}>
          問い合わせ一覧
        </Typography>

        <div>
          <InquirySearchFormContainer />
        </div>

        <div className={classes.list}>
          <ListTable {...listTableProps} />
        </div>
      </Container>
    </BasePageContainer>
  );
};

export default InquiryListPage;
