import { ActionTypes } from '../actions/actionTypes'
import {
  FETCH_SCHEDULE_LIST_START,
  FETCH_SCHEDULE_LIST_SUCCESS,
  FETCH_SCHEDULE_LIST_FAIL,  
  SHOW_SCHEDULE_EVENT
} from '../actions/scheduleAction'
import { CalendarEvent } from 'dataObjects/space';


export interface CalendarEventListState {
  body: CalendarEvent[],
  schedule_from: Date,
  schedule_to: Date,
  view: string,
  defdate: Date
}

const initialCalendarEventListState: CalendarEventListState = {
  body: [],
  schedule_from: undefined,
  schedule_to: undefined,
  view: 'month',
  defdate: new Date((new Date()).setDate(1))
};

/* 予約スケジュールリスト Reducer */
const scheduleList = (
  state: CalendarEventListState = initialCalendarEventListState,
  action: ActionTypes,
): CalendarEventListState => {
  switch (action.type) {
    case FETCH_SCHEDULE_LIST_START:
      return { ...state };
    case FETCH_SCHEDULE_LIST_SUCCESS:
      return {
        ...state,      
        ...action.payload        
      }
    case FETCH_SCHEDULE_LIST_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};



const initialCalendarEvent: CalendarEvent = {
  reserve_id: undefined,
  use_type: '1',
  name: '',
  start: undefined,
  end: undefined,
  title: ''  
} 

export const showEvent = (
  state: CalendarEvent = initialCalendarEvent,
  action: ActionTypes,
): CalendarEvent => {
  switch (action.type) {
    case SHOW_SCHEDULE_EVENT:
      return {
        ...state,      
        ...action.payload        
      }
    default:
      return { ...state };
  }
};

export default scheduleList