import { ActionTypes } from '../actions/actionTypes'
import { SpaceReservation } from '../dataObjects/space';
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';
import { STORE_REGULAR_RESERVATION_START, STORE_REGULAR_RESERVATION_SUCCESS, STORE_REGULAR_RESERVATION_INVALID, STORE_REGULAR_RESERVATION_FAIL, VALIDATION_REGULAR_RESERVATION_INVALID, VALIDATION_REGULAR_RESERVATION_FAIL, VALIDATION_REGULAR_RESERVATION_SUCCESS, VALIDATION_REGULAR_RESERVATION_START, CANCEL_REGULAR_RESERVATION_START, CANCEL_REGULAR_RESERVATION_SUCCESS, CANCEL_REGULAR_RESERVATION_INVALID, CANCEL_REGULAR_RESERVATION_FAIL } from 'actions/regularReservationAction';
import { ServerValidationError } from 'dataObjects/common';
import { STORE_USER_START, STORE_USER_LOCAL, STORE_USER_SUCCESS, STORE_USER_INVALID, STORE_USER_FAIL, BAN_USER_START, BAN_USER_SUCCESS, BAN_USER_INVALID, BAN_USER_FAIL, RESTORE_USER_START, RESTORE_USER_SUCCESS, RESTORE_USER_INVALID, RESTORE_USER_FAIL, FORCEDELETE_USER_START, FORCEDELETE_USER_INVALID, FORCEDELETE_USER_SUCCESS, FORCEDELETE_USER_FAIL } from 'actions/userAction';
import { STORE_NEWS_START, STORE_NEWS_LOCAL, STORE_NEWS_SUCCESS, STORE_NEWS_INVALID, STORE_NEWS_FAIL, REMOVE_NEWS_START, REMOVE_NEWS_SUCCESS, REMOVE_NEWS_INVALID, REMOVE_NEWS_FAIL } from 'actions/newsAction';
import { STORE_PROMOTIONCODE_START, STORE_PROMOTIONCODE_LOCAL, STORE_PROMOTIONCODE_SUCCESS, STORE_PROMOTIONCODE_INVALID, STORE_PROMOTIONCODE_FAIL } from 'actions/promotionCodeAction';
import { STORE_CLOSEDAY_START, STORE_CLOSEDAY_LOCAL, STORE_CLOSEDAY_SUCCESS, STORE_CLOSEDAY_INVALID, STORE_CLOSEDAY_FAIL } from 'actions/spaceCloseDayAction';
import { CANCEL_DROPIN_RESERVATION_START, CANCEL_DROPIN_RESERVATION_SUCCESS, CANCEL_DROPIN_RESERVATION_INVALID, CANCEL_DROPIN_RESERVATION_FAIL, AUTHORIZATION_DROPIN_RESERVATION_START, AUTHORIZATION_DROPIN_RESERVATION_SUCCESS, AUTHORIZATION_DROPIN_RESERVATION_INVALID, AUTHORIZATION_DROPIN_RESERVATION_FAIL, CAPTURE_DROPIN_RESERVATION_START, CAPTURE_DROPIN_RESERVATION_SUCCESS, CAPTURE_DROPIN_RESERVATION_INVALID, CAPTURE_DROPIN_RESERVATION_FAIL } from 'actions/dropinReservationAction';
import { STORE_SPACE_START, STORE_SPACE_SUCCESS, STORE_SPACE_INVALID, STORE_SPACE_FAIL } from 'actions/spaceAction';
import { STORE_TAX_RATE_MASTER_START, STORE_TAX_RATE_MASTER_SUCCESS, STORE_TAX_RATE_MASTER_INVALID, STORE_TAX_RATE_MASTER_FAIL, STORE_FACILITIES_MASTER_START, STORE_FACILITIES_MASTER_SUCCESS, STORE_FACILITIES_MASTER_INVALID, STORE_FACILITIES_MASTER_FAIL, STORE_USAGE_MASTER_START, STORE_USAGE_MASTER_SUCCESS, STORE_USAGE_MASTER_INVALID, STORE_USAGE_MASTER_FAIL, STORE_TERMS_OF_SERVICE_MASTER_START, STORE_TERMS_OF_SERVICE_MASTER_SUCCESS, STORE_TERMS_OF_SERVICE_MASTER_INVALID, STORE_TERMS_OF_SERVICE_MASTER_FAIL } from 'actions/masterAction';


export interface ValidationErrorListState {
  message: string,
  errors: ServerValidationError[]
}

const initialValidationErrorListState: ValidationErrorListState = {
  message: '',
  errors: []
}


const validationError = (
  state: ValidationErrorListState = initialValidationErrorListState,
  action: ActionTypes,
): ValidationErrorListState => {
  switch (action.type) {
    // ユーザー情報
    case STORE_USER_START:
      return { ...initialValidationErrorListState }
    case STORE_USER_LOCAL:
      return { ...initialValidationErrorListState }
    case STORE_USER_SUCCESS:
      return { ...initialValidationErrorListState }
    case STORE_USER_INVALID:
      return { ...state, ...action.payload }
    case STORE_USER_FAIL:
      return { ...initialValidationErrorListState }
    // ユーザーアカウント停止
    case BAN_USER_START:
      return { ...initialValidationErrorListState }
    case BAN_USER_SUCCESS:
      return { ...initialValidationErrorListState }
    case BAN_USER_INVALID:
      return { ...state, ...action.payload }
    case BAN_USER_FAIL:
      return { ...initialValidationErrorListState }
    // ユーザーアカウント復旧
    case RESTORE_USER_START:
      return { ...initialValidationErrorListState }
    case RESTORE_USER_SUCCESS:
      return { ...initialValidationErrorListState }
    case RESTORE_USER_INVALID:
      return { ...state, ...action.payload }
    case RESTORE_USER_FAIL:
      return { ...initialValidationErrorListState }
    // ユーザーアカウント削除
    case FORCEDELETE_USER_START:
      return { ...initialValidationErrorListState }
    case FORCEDELETE_USER_SUCCESS:
      return { ...initialValidationErrorListState }
    case FORCEDELETE_USER_INVALID:
      return { ...state, ...action.payload }
    case FORCEDELETE_USER_FAIL:
      return { ...initialValidationErrorListState }
    // お知らせ
    case STORE_NEWS_START:
      return { ...initialValidationErrorListState }
    case STORE_NEWS_LOCAL:
      return { ...initialValidationErrorListState }
    case STORE_NEWS_SUCCESS:
      return { ...initialValidationErrorListState }
    case STORE_NEWS_INVALID:
      return { ...state, ...action.payload }
    case STORE_NEWS_FAIL:
      return { ...initialValidationErrorListState }
    case REMOVE_NEWS_START:
      return { ...initialValidationErrorListState }
    case REMOVE_NEWS_SUCCESS:
      return { ...initialValidationErrorListState }
    case REMOVE_NEWS_INVALID:
      return { ...state, ...action.payload }
    case REMOVE_NEWS_FAIL:
      return { ...initialValidationErrorListState }
    // プロモーションコード
    case STORE_PROMOTIONCODE_START:
      return { ...initialValidationErrorListState }
    case STORE_PROMOTIONCODE_LOCAL:
      return { ...initialValidationErrorListState }
    case STORE_PROMOTIONCODE_SUCCESS:
      return { ...initialValidationErrorListState }
    case STORE_PROMOTIONCODE_INVALID:
      return { ...state, ...action.payload }
    case STORE_PROMOTIONCODE_FAIL:
      return { ...initialValidationErrorListState }
    // 管理者枠確保/休業日設定
    case STORE_CLOSEDAY_START:
      return { ...initialValidationErrorListState }
    case STORE_CLOSEDAY_LOCAL:
      return { ...initialValidationErrorListState }
    case STORE_CLOSEDAY_SUCCESS:
      return { ...initialValidationErrorListState }
    case STORE_CLOSEDAY_INVALID:
      return { ...state, ...action.payload }
    case STORE_CLOSEDAY_FAIL:
      return { ...initialValidationErrorListState }
    // スペース更新
    case STORE_SPACE_START:
      return { ...initialValidationErrorListState }    
    case STORE_SPACE_SUCCESS:
      return { ...initialValidationErrorListState }    
    case STORE_SPACE_INVALID:
      return { ...state, ...action.payload }
    case STORE_SPACE_FAIL:
      return { ...initialValidationErrorListState };
    // 一時利用
    case CANCEL_DROPIN_RESERVATION_START:
      return { ...initialValidationErrorListState }
    case CANCEL_DROPIN_RESERVATION_SUCCESS:
      return { ...initialValidationErrorListState }
    case CANCEL_DROPIN_RESERVATION_INVALID:
      return { ...state, ...action.payload }
    case CANCEL_DROPIN_RESERVATION_FAIL:
      return { ...initialValidationErrorListState }
    case AUTHORIZATION_DROPIN_RESERVATION_START:
      return { ...initialValidationErrorListState };
    case AUTHORIZATION_DROPIN_RESERVATION_SUCCESS: 
      return { ...initialValidationErrorListState};
    case AUTHORIZATION_DROPIN_RESERVATION_INVALID: 
      return { ...state, ...action.payload }
    case AUTHORIZATION_DROPIN_RESERVATION_FAIL:
      return { ...initialValidationErrorListState };
    case CAPTURE_DROPIN_RESERVATION_START:
      return { ...initialValidationErrorListState };
    case CAPTURE_DROPIN_RESERVATION_SUCCESS:
      return { ...initialValidationErrorListState };
    case CAPTURE_DROPIN_RESERVATION_INVALID: 
      return { ...state, ...action.payload };
    case CAPTURE_DROPIN_RESERVATION_FAIL:
      return { ...initialValidationErrorListState };
    // 定期利用
    case VALIDATION_REGULAR_RESERVATION_START:
      return { ...initialValidationErrorListState }
    case VALIDATION_REGULAR_RESERVATION_SUCCESS:
      return { ...initialValidationErrorListState }
    case VALIDATION_REGULAR_RESERVATION_INVALID:
      return { ...state, ...action.payload }
    case VALIDATION_REGULAR_RESERVATION_FAIL:
      return { ...initialValidationErrorListState }
    case STORE_REGULAR_RESERVATION_START:
      return { ...initialValidationErrorListState }
    case STORE_REGULAR_RESERVATION_SUCCESS: 
      return { ...initialValidationErrorListState }
    case STORE_REGULAR_RESERVATION_INVALID:
      return { ...state, ...action.payload }
    case STORE_REGULAR_RESERVATION_FAIL:
      return { ...initialValidationErrorListState }
    case CANCEL_REGULAR_RESERVATION_START:
      return { ...initialValidationErrorListState }
    case CANCEL_REGULAR_RESERVATION_SUCCESS:
      return { ...initialValidationErrorListState }
    case CANCEL_REGULAR_RESERVATION_INVALID:
      return { ...state, ...action.payload }
    case CANCEL_REGULAR_RESERVATION_FAIL:
      return { ...initialValidationErrorListState }
    // 税率マスタ
    case STORE_TAX_RATE_MASTER_START:
      return { ...initialValidationErrorListState }
    case STORE_TAX_RATE_MASTER_SUCCESS:      
      return { ...initialValidationErrorListState }
    case STORE_TAX_RATE_MASTER_INVALID:
      return { ...state, ...action.payload }
    case STORE_TAX_RATE_MASTER_FAIL:
      return { ...initialValidationErrorListState }
    // 設備マスタ
    case STORE_FACILITIES_MASTER_START:
      return { ...initialValidationErrorListState }
    case STORE_FACILITIES_MASTER_SUCCESS:      
      return { ...initialValidationErrorListState }
    case STORE_FACILITIES_MASTER_INVALID:
      return { ...state, ...action.payload }
    case STORE_FACILITIES_MASTER_FAIL:
      return { ...initialValidationErrorListState }
    // 利用用途マスタ
    case STORE_USAGE_MASTER_START:
      return { ...initialValidationErrorListState }
    case STORE_USAGE_MASTER_SUCCESS:      
      return { ...initialValidationErrorListState }
    case STORE_USAGE_MASTER_INVALID:
      return { ...state, ...action.payload }
    case STORE_USAGE_MASTER_FAIL:
      return { ...initialValidationErrorListState }  
    // 利用規約マスタ
    case STORE_TERMS_OF_SERVICE_MASTER_START:
      return { ...initialValidationErrorListState }
    case STORE_TERMS_OF_SERVICE_MASTER_SUCCESS:      
      return { ...initialValidationErrorListState }
    case STORE_TERMS_OF_SERVICE_MASTER_INVALID:
      return { ...state, ...action.payload }
    case STORE_TERMS_OF_SERVICE_MASTER_FAIL:
      return { ...initialValidationErrorListState }
    // ログアウト
    case LOGOUT_SUCCESS:
      return { ...initialValidationErrorListState }
    default:
      return { ...state };
  }
};

export default validationError