import { ActionTypes } from '../actions/actionTypes'
import {
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  STORE_USER_START,
  STORE_USER_LOCAL,
  STORE_USER_SUCCESS,
  STORE_USER_FAIL,
  BAN_USER_START,
  BAN_USER_SUCCESS,
  BAN_USER_FAIL,
  RESTORE_USER_START,
  RESTORE_USER_FAIL,
  RESTORE_USER_SUCCESS,
  FORCEDELETE_USER_START,
  FORCEDELETE_USER_SUCCESS,
  FORCEDELETE_USER_FAIL
} from '../actions/userAction'
import { UserData } from '../dataObjects/user'
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';


export interface UserDataState extends UserData {
}

const initialUserDataState: UserDataState = {
  user_id: undefined,
  user_name: '',
  user_email: '',
  use_times: 0,
  contact_family_name: '',
  contact_given_name: '',
  contact_family_name_kana: '',
  contact_given_name_kana: '',
  company_name: '',
  contact_email: '',
  contact_email_confirmation: '',
  contact_postal_code: '',
  contact_address_pref: '',
  contact_address_city: '',
  contact_address_street: '',
  contact_address_room: '',
  contact_tel: ''
};

/* userData Reducer */
const userData = (
  state: UserDataState = initialUserDataState,
  action: ActionTypes,
): UserDataState => {
  switch (action.type) {
    // ユーザーリスト読み込み
    case FETCH_USER_START:
      return { ...state };
    case FETCH_USER_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_USER_FAIL:
      return { ...state };
    // ユーザー情報更新
    case STORE_USER_START:
      return { ...state }
    case STORE_USER_LOCAL:
      return { ...state, ...action.payload };
    case STORE_USER_SUCCESS:
      return { ...state, ...action.payload };
    case STORE_USER_FAIL:
      return { ...state }
    // ユーザーアカウント停止
    case BAN_USER_START:
      return { ...state };
    case BAN_USER_SUCCESS:
      return { ...state, ...action.payload };    
    case BAN_USER_FAIL:
      return { ...state };
    // ユーザーアカウント復旧
    case RESTORE_USER_START:
      return { ...state };
    case RESTORE_USER_SUCCESS:
      return { ...state, ...action.payload };
    case RESTORE_USER_FAIL:
      return { ...state };
    // ユーザーアカウント強制削除
    case FORCEDELETE_USER_START:
      return { ...state };
    case FORCEDELETE_USER_SUCCESS:
      return { ...state };
    case FORCEDELETE_USER_FAIL:
      return { ...state };
    case LOGOUT_SUCCESS:
      return { ...state, ...initialUserDataState };
    default:
      return { ...state };
  }
};

export default userData