import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { routes } from '../../routes/Route';
import LoadingComponent from '../LoadingComponent';
import { InternalServerErrorPage } from '../../pages/error/InternalServerErrorPage';

export type NonAuthRouteProps = {
  loading: boolean;
  error?: string;
} & RouteProps;

const NonAuthRoute: React.FC<NonAuthRouteProps> = ({
  component: Component,
  loading,
  error,
  ...rest
}) => {
  if (!Component) {
    throw new Error('component attribute required for NonAuthRoute component');
  }

  if (error) {
    return <InternalServerErrorPage message={error} />;
  }

  return (
    <Route
      {...rest}
      render={props => <Component {...props} /> }
    />
  );
};

export { NonAuthRoute };
