import { ActionTypes } from '../actions/actionTypes';
import { KeyBoxPass } from 'dataObjects/keyboxpass';
import { FETCH_KEYBOXPASS_LIST_START, FETCH_KEYBOXPASS_LIST_SUCCESS, FETCH_KEYBOXPASS_LIST_FAIL, STORE_KEYBOXPASS_LOCAL, STORE_KEYBOXPASS_SUCCESS } from 'actions/keyBoxPassAction';

export interface KeyBoxPassState {
  list: KeyBoxPass[]
}

const initialKeyBoxPassState: KeyBoxPassState = {
  list: []
}


const keyBoxPass = (
  state: KeyBoxPassState = initialKeyBoxPassState,
  action: ActionTypes,
): KeyBoxPassState => {
  switch (action.type) {
    // スペース選択
    case FETCH_KEYBOXPASS_LIST_START:
      return { ...state };
    case FETCH_KEYBOXPASS_LIST_SUCCESS:
      return {
        ...state,
        list: [
          ...action.payload
        ],
      }
    case STORE_KEYBOXPASS_LOCAL:
      return {
        ...state,
        list: [
          ...action.payload
        ],
      }
    case STORE_KEYBOXPASS_SUCCESS:
      return {
        ...state,
        list: [
          ...action.payload
        ],
      }
    case FETCH_KEYBOXPASS_LIST_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default keyBoxPass;
