import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'


import DashboardPage from '../pages/DashboardPage';
import SignInPageContainer from '../containers/SignInPageContainer'

import SpaceEditPageContainer from '../containers/SpaceEditPageContainer'


import AuthRoute from '../containers/AuthContainer';
import NonAuthRoute from '../containers/NonAuthContainer';
import NotFoundContainer from '../containers/NotFoundContainer';

import { fetchSettings } from '../actions/settingsAction';
import { fetchMasterAction } from '../actions/masterAction'
import TaxRateMasterPageContainer from 'containers/TaxRateMasterPageContainer';
import RegularReserveDetailPage from 'pages/RegularReserveDetailPage';
import DropInReserveListPageContainer from 'containers/DropInReserveListPageContainer';
import UserListPageContainer from 'containers/UserListPageContainer';
import InquiryListPageContainer from 'containers/InquiryListPageContainer';
import RegularInquiryListPageContainer from 'containers/RegularInquiryListPageContainer';
import UserDetailPageContainer from 'containers/UserDetailPageContainer';
import InquiryDetailPageContainer from 'containers/InquiryDetailPageContainer'
import RegularInquiryDetailPageContainer from 'containers/RegularInquiryDetailPageContainer'
import SignOutContainer from 'containers/SignOutContainer';
import RegularReserveListPageContainer from 'containers/RegularReservationListPageContainer';
import RegularReservaionDetailPageContainer from 'containers/RegularReservaionDetailPageContainer'
import DashboardPageContainer from 'containers/DashboardPageContainer';
import NewsListPageContainer from 'containers/NewsListPageContainer';
import NewsDetailPageContainer from 'containers/NewsDetailPageContainer';
import PromotionCodePageContainer from 'containers/PromotionCodePageContainer';
import CloseDayPageContainer from 'containers/CloseDayPageContainer';
import ReserveScheduleCalendarPageContainer from 'containers/ReserveScheduleCalendarPageContainer';
import DropInReservationDetailPageContainer from 'containers/DropInReservationDetailPageContainer';
import KeyBoxPassPageContainer from 'containers/KeyBoxPassPageContainer';
import SpaceListPageContainer from 'containers/SpaceListPageContainer';
import FacilitiesMasterPageContainer from 'containers/FacilitiesMasterPageContainer';
import TermsOfServiceMasterPageContainer from 'containers/TermsOfServiceMasterPageContainer';
import UsageMasterPageContainer from 'containers/UsageMasterPageContainer';

interface route {
  [name: string]: {
    path: string;
    getPath: (...params: any) => string; 
  };
}

export const routes: route = {
  dashBoard: {
    path: '/dashBoard',
    getPath: () => '/dashBoard',
  },  
  reserveSchedule: {
    path: '/reserveSchedule',
    getPath: () => '/reserveSchedule',
  },
  termsOfService: {
    path: '/master/termsofservice',
    getPath: () => '/master/termsofservice'
  },
  usage: {
    path: '/master/usage',
    getPath: () => '/master/usage'
  },
  facilities: {
    path: '/master/facilities',
    getPath: () => '/master/facilities'
  },
  taxType: {
    path: '/master/taxType',
    getPath: () => '/master/taxType'
  },
  taxRate: {
    path: '/master/taxRate',
    getPath: () => '/master/taxRate'
  },
  signIn: {
    path: '/',
    getPath: () => '/'
  },
  signOut: {
    path: '/signOut',
    getPath: () => '/signOut'
  },
  spaceList: {
    path: '/spaces',
    getPath: () => `/spaces`,
  },
  spaceCreate: {
    path: '/space/create',
    getPath: () => `/space/create`,
  },
  spaceUpdate: {
    path: '/space/update/:space_id',
    getPath: (space_id: string) => `/space/update/${space_id}`,
  },
  dropInReserveList: {
    path: '/dropin/reserves',
    getPath: () => `/dropin/reserves`
  },
  dropInReserve: {
    path: '/dropin/reserve/:reserve_id',
    getPath: (reserve_id: string) => `/dropin/reserve/${reserve_id}`
  },
  regularReserveList: {
    path: '/regular/reserves',
    getPath: () => `/regular/reserves`
  },
  regularReserveCreate: {
    path: '/regular/reserve/create',
    getPath: () => `/regular/reserve/create`
  },
  regularReserveUpdate: {
    path: '/regular/reserve/update/:reserve_id',
    getPath: (reserve_id: string) => `/regular/reserve/update/${reserve_id}`
  },
  userList: {
    path: '/user/list',
    getPath: () => '/user/list'
  },
  userDetail: {
    path: '/user/:user_id',
    getPath: (user_id: string) => `/user/${user_id}`
  },
  inquiryList: {
    path: '/inquiry/list',
    getPath: () => '/inquiry/list'
  },
  inquiryDetail: {
    path: '/inquiry/:inquiry_id',
    getPath: (inquiry_id: string) => `/inquiry/${inquiry_id}`
  },
  regularInquiryList: {
    path: '/regularInquiry/list',
    getPath: () => `/regularInquiry/list`,
  },
  regularInquiryDetail: {
    path: '/regularInquiry/:inquiry_id',
    getPath: (inquiry_id: string) => `/regularInquiry/${inquiry_id}`
  },
  newsList: {
    path: '/news/list',
    getPath: () => `/news/list`,
  },
  newsCreate: {
    path: '/news',
    getPath: () => `/news`
  },
  newsDetail: {
    path: '/news/:news_id',
    getPath: (news_id: string) => `/news/${news_id}`
  },
  promotioncode: {
    path: '/promotioncode',
    getPath: () => `/promotioncode`
  },
  closeday: {
    path: '/closeday',
    getPath: () => `/closeday`
  },
  keyboxpass: {
    path: '/keyboxpass',
    getPath: () => `/keyboxpass`
  }

};

export const AppRoute: React.FC = () => {
  const dispatch = useDispatch<CustomThunkDispatch>()
 
  useEffect(() => {
    dispatch(fetchSettings())
    dispatch(fetchMasterAction())
  }, []);
  
  return (
    <Switch>
      <NonAuthRoute
        exact
        path={routes.signIn.path}
        component={SignInPageContainer}
      />

      <NonAuthRoute
        exact
        path={routes.signOut.path}
        component={SignOutContainer}
      />
      

      <AuthRoute exact path={routes.dashBoard.path} component={DashboardPageContainer} />

      <AuthRoute exact path={routes.reserveSchedule.path} component={ReserveScheduleCalendarPageContainer} />

      <AuthRoute exact path={routes.termsOfService.path} component={TermsOfServiceMasterPageContainer} />

      <AuthRoute exact path={routes.usage.path} component={UsageMasterPageContainer} />

      <AuthRoute exact path={routes.facilities.path} component={FacilitiesMasterPageContainer} />

      <AuthRoute exact path={routes.taxRate.path} component={TaxRateMasterPageContainer} />

      <AuthRoute exact path={routes.promotioncode.path} component={PromotionCodePageContainer} />

      <AuthRoute exact path={routes.spaceList.path} component={SpaceListPageContainer} />

      <AuthRoute exact path={routes.spaceCreate.path} component={SpaceEditPageContainer} />
      <AuthRoute exact path={routes.spaceUpdate.path} component={SpaceEditPageContainer} />


      <AuthRoute exact path={routes.dropInReserveList.path} component={DropInReserveListPageContainer} />
      <AuthRoute exact path={routes.dropInReserve.path} component={DropInReservationDetailPageContainer} />

      <AuthRoute exact path={routes.regularReserveList.path} component={RegularReserveListPageContainer} />
      <AuthRoute exact path={routes.regularReserveCreate.path} component={RegularReservaionDetailPageContainer} />
      <AuthRoute exact path={routes.regularReserveUpdate.path} component={RegularReservaionDetailPageContainer} />

      <AuthRoute exact path={routes.userList.path} component={UserListPageContainer} />
      <AuthRoute exact path={routes.userDetail.path} component={UserDetailPageContainer} />

      <AuthRoute exact path={routes.inquiryList.path} component={InquiryListPageContainer} />
      <AuthRoute exact path={routes.inquiryDetail.path} component={InquiryDetailPageContainer} />

      <AuthRoute exact path={routes.regularInquiryList.path} component={RegularInquiryListPageContainer} />
      <AuthRoute exact path={routes.regularInquiryDetail.path} component={RegularInquiryDetailPageContainer} />

      <AuthRoute exact path={routes.newsList.path} component={NewsListPageContainer} />
      <AuthRoute exact path={routes.newsCreate.path} component={NewsDetailPageContainer} />
      <AuthRoute exact path={routes.newsDetail.path} component={NewsDetailPageContainer} />
      
      <AuthRoute exact path={routes.closeday.path} component={CloseDayPageContainer} />
      
      <AuthRoute exact path={routes.keyboxpass.path} component={KeyBoxPassPageContainer} />

      <Route component={NotFoundContainer} />
    </Switch>
  );
};
