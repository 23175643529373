import { ActionTypes } from "../actions/actionTypes";
import { SpaceForUpdate } from "dataObjects/space";
import {
  FETCH_SPACE_START,
  FETCH_SPACE_SUCCESS,
  FETCH_SPACE_FAIL,
  STORE_SPACE_LOCAL,
  STORE_SPACE_SUCCESS
} from 'actions/spaceAction'


export interface SpaceForUpdateState
  extends SpaceForUpdate { }

const initialSpaceForUpdateState: SpaceForUpdateState = {
  space: {
    id: undefined,
    name: '',
    address: '',
    area: '',
    capacity: '',
    around: '',
    enter_and_leave_method: '',
    description: '',
    map_lat: 0,
    map_lng: 0,
    reservation_stop: false
  },

  space_usage: [],

  space_facilities: [],

  space_access: [],

  space_prohibited_matter: [],

  space_faq: [],

  space_images: [],

  space_term_of_service: {
    id: undefined,
    space_id: undefined,
    terms_of_service_id: '0'
  },

  space_rental_plan: {
    id: undefined,
    space_id: undefined,
    period_unit: 0,
    at_least_reserve_unit: 0
  },

  space_business_dayofweek: [],  

  space_dropin_pricing: [],

  space_dropin_specific_day_pricing: [],

  space_regular_pricing: [],

  space_regular_specific_day_pricing: []
}


/* SpaceForUpdate Reducer */
const spaceForUpdate = (
  state: SpaceForUpdateState = initialSpaceForUpdateState,
  action: ActionTypes
): SpaceForUpdateState => {
  switch (action.type) {
    case FETCH_SPACE_START:
      return { ...state };
    case FETCH_SPACE_SUCCESS:
      return { ...state, ...action.payload }
    case FETCH_SPACE_FAIL:
      return { ...state };
    case STORE_SPACE_LOCAL:
      return { ...state, ...action.payload }
    case STORE_SPACE_SUCCESS:
        return { ...state, ...action.payload }
    default:
      return { ...state };
  }
};

export default spaceForUpdate;