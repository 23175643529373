/* yyyy/mm/dd から Dateオブジェクトへ変換 */
export const toDate: (value: string, delimiter: string) => Date = (value, delimiter) => {
  if(value) {
    try{
      var arr = value.split(delimiter)
      return new Date(parseInt(arr[0]), parseInt(arr[1]) - 1, parseInt(arr[2]))
    } catch (err) {
      console.log(err)
    }
  }
  return undefined  
}

export const toDateNonDelimiter: (value: string) => Date = (value) => {  
  if(value) {
    try{
      var arr = (value.substr(0, 4) + '/' + value.substr(4, 2) + '/' + value.substr(6, 2)).split('/');
      return new Date(parseInt(arr[0]), parseInt(arr[1]) - 1, parseInt(arr[2]))
    } catch (err) {
      console.log(err)
    }
  }
  return undefined  
};

export const dateToString: (dt: Date) => string = (dt) => {
  let result = '';
  try {
    if(dt) {
      const y = dt.getFullYear();
      const m = ("00" + (dt.getMonth()+1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      result = y + "/" + m + "/" + d;
    }
  } catch (err) {
    if (typeof(dt) == 'string') {
      result = dt
    }
  }
  return result;
}

export const dayOfWeeksJp: (dt: Date) => string = (dt) => {
  
  if(dt) {
    switch(dt.getDay()) {
      case 0:
        return '日'
      case 1:
        return '月'
      case 2:
        return '火'
      case 3:
        return '水'
      case 4:
        return '木'
      case 5:
        return '金'
      case 6:
        return '土'
      default: 
        return ''
    }    
  }
  return '';
}

export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const value = reader.result as string
      const offset = value.indexOf(",") + 1;
      resolve(value.slice(offset));
    }
    reader.onerror = error => reject(error)
    reader.readAsDataURL(file)
  })
}


export const timeString: (dt: Date) => string = (dt) => {
  return dt.toTimeString().replace(' GMT+0900 (日本標準時)', '')
}

