import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { AppState } from '../types/stateTypes';
import { LOCATION_CHANGE } from 'connected-react-router';
import { pathNameChangeAction } from '../actions/locationChangeAction';
import { ActionTypes } from '../actions/actionTypes';
import { afterSignInRedirectRouteSetAction, afterSignInRedirectRouteClearAction } from 'actions/authenticationActions';
import { routes } from 'routes/Route';

// react-routerのページ遷移発火時、pathnameが変わった場合は独自のactionを発火する
const checkLocationChange: Middleware = <S extends AppState>(
  store: MiddlewareAPI<Dispatch, S>,
) => (next: Dispatch<ActionTypes>) => (action: ActionTypes): any => {
  const { getState, dispatch } = store;
  if (action.type !== LOCATION_CHANGE) {
    return next(action);
  }

  const { router, authStatus } = getState();
  const currentPath = router.location.pathname;
  const nextPath = action.payload.location.pathname;

  if (currentPath === nextPath) {
    return next(action);
  }

  if(authStatus.hasAuthenticate == false && nextPath != routes.signIn.path) {
    // 未ログイン且つ次に遷移する画面がサインインページでなければ
    // ログイン成功時にリダイレクトするパスとして記録
    dispatch(afterSignInRedirectRouteSetAction({
      route: nextPath
    }))
  } else if (nextPath != routes.signIn.path) {
    // リセット
    dispatch(afterSignInRedirectRouteClearAction())
  }

  // スクロール位置をトップにセット
  window.scrollTo(0, 0);
  // 遷移アクション発火
  dispatch(pathNameChangeAction());
  return next(action);
};

export default checkLocationChange;
