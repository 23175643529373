import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes';
import InquiryDetailPage, { InquiryDetailPageProps } from '../pages/InquiryDetailPage';
import { observeShownAction } from 'actions/pagingAction';
import { routes } from 'routes/Route';
import { push } from 'connected-react-router';

interface OwnProps {
}

export type InquiryDetailPageContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function InquiryDetailPageContainer(props: InquiryDetailPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  // ユーザーデータ読み込み
  useEffect(() => {
    dispatch(observeShownAction());
  }, []);

  const _props: InquiryDetailPageProps = {
    inquiry_id: state.commonInquiry.id,
    name: state.commonInquiry.name,
    email: state.commonInquiry.email,
    content: state.commonInquiry.content,
    already_read: '',
    str_inquiry_at: state.commonInquiry.str_inquiry_at,
    user_id: state.commonInquiry.user_id,
    user_name: state.commonInquiry.user_name,
    user_email: state.commonInquiry.user_email,
    onBack: () => {
      dispatch(push(routes.inquiryList.path))
    }
  }

  return <InquiryDetailPage {..._props} />

}