import { ActionTypes } from '../actions/actionTypes'
import { RegularReservationHeaderPagenation } from '../dataObjects/space';
import {
  FETCH_REGULAR_RESERVATION_LIST_START,
  FETCH_REGULAR_RESERVATION_LIST_SUCCESS,
  FETCH_REGULAR_RESERVATION_LIST_FAIL
} from "../actions/regularReservationAction";
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';



export interface RegularReservationHeaderState
  extends RegularReservationHeaderPagenation {}

const initialRegularReservationHeaderState: RegularReservationHeaderState = {
  data: [],
  current_page: 1,
  first_page_url:
    process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/regular/list/20?page=1",
  from: null,
  last_page: 1,
  last_page_url:
    process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/regular/list/20?page=1",
  next_page_url: null,
  path: process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/regular/list/20",
  per_page: 20,
  prev_page_url: null,
  to: null,
  total: 0
};


/* regularReservationList Reducer */
const regularReservationList = (
  state: RegularReservationHeaderState = initialRegularReservationHeaderState,
  action: ActionTypes
): RegularReservationHeaderState => {
  switch (action.type) {
    // ユーザー問い合わせ読み込み
    case FETCH_REGULAR_RESERVATION_LIST_START:
      return { ...state };
    case FETCH_REGULAR_RESERVATION_LIST_SUCCESS:
      console.log(action.payload);
      return { ...state, ...action.payload };
    case FETCH_REGULAR_RESERVATION_LIST_FAIL:
      return { ...state };
    case LOGOUT_SUCCESS:
      return { ...state, ...initialRegularReservationHeaderState };
    default:
      return { ...state };
  }
};

export default regularReservationList;
