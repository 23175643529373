import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { ErrorActions } from './errorAction';
import moment from 'moment'
import { CalendarEvent } from 'dataObjects/space';
import { SnackBarAction } from './actionTypes';

export const FETCH_SCHEDULE_LIST_START = 'FETCH_SCHEDULE_LIST_START';
export const FETCH_SCHEDULE_LIST_SUCCESS = 'FETCH_SCHEDULE_LIST_SUCCESS';
export const FETCH_SCHEDULE_LIST_FAIL = 'FETCH_SCHEDULE_LIST_FAIL';

export type FetchScheduleListActions =
  | FetchScheduleListStartAction
  | FetchScheduleListSuccessAction
  | FetchScheduleListFailAction
  | ErrorActions;
type FetchScheduleListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchScheduleListActions>;


export const SHOW_SCHEDULE_EVENT = 'SHOW_SCHEDULE_EVENT';



/* ---------------------------------------------------------------------------------------------------- */

/* スケジュール取得 ThunkAction */
export function fetchScheduleList(space_id: number, startDate: Date, endDate: Date, flipUnit?: string, defDate?: Date)
  : FetchScheduleListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchScheduleListActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchScheduleListStartAction());

      const sDateStr = (moment(startDate)).format('YYYY-MM-DD')
      const eDateStr = (moment(endDate)).format('YYYY-MM-DD')
      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const response = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/reserve/schedule/${space_id}/${sDateStr}/${eDateStr}`, header)
      const body: CalendarEvent[] = await response.data;

      if(!flipUnit) {
        flipUnit = 'month'
      }
      if(!defDate) {
        defDate = new Date()
        defDate.setDate(1)
      }
      const payload = {
        body: body,
        schedule_from: startDate,
        schedule_to: endDate,
        view: flipUnit,
        defdate: defDate
      }
      dispatch(
        fetchScheduleListSuccessAction(payload),
      )


    } catch (err) {
      dispatch<FetchScheduleListActions>(
        await ajaxErrorHandler(err, fetchScheduleListFailAction),
      )
    }
  }
}

export interface FetchScheduleListStartAction
  extends Action<typeof FETCH_SCHEDULE_LIST_START> { }

const fetchScheduleListStartAction = (): FetchScheduleListStartAction => ({
  type: 'FETCH_SCHEDULE_LIST_START',
});

export interface FetchScheduleListSuccessAction
  extends Action<typeof FETCH_SCHEDULE_LIST_SUCCESS> {
  payload: {
    body: CalendarEvent[],
    schedule_from: Date,
    schedule_to: Date,
    view: string,
    defdate: Date
  }
}

const fetchScheduleListSuccessAction = (payload: {
  body: CalendarEvent[],
  schedule_from: Date,
  schedule_to: Date,
  view: string,
  defdate: Date
}): FetchScheduleListSuccessAction => ({
  type: 'FETCH_SCHEDULE_LIST_SUCCESS',
  payload: payload
})

export interface FetchScheduleListFailAction
  extends SnackBarAction<typeof FETCH_SCHEDULE_LIST_FAIL> {
  
}

const fetchScheduleListFailAction = (message: string): FetchScheduleListFailAction => ({
  type: 'FETCH_SCHEDULE_LIST_FAIL',
  snackBarMessage: message,
  variant: 'error',
})


/* ---------------------------------------------------------------------------------------------------- */

export interface ShowScheduleEvent extends Action<typeof SHOW_SCHEDULE_EVENT> {
  payload: CalendarEvent
}

export const showScheduleEvent = (payload: CalendarEvent) : ShowScheduleEvent => ({
  type: 'SHOW_SCHEDULE_EVENT',
  payload : payload
})