import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, formValueSelector, stopAsyncValidation } from 'redux-form'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import NewsForm, { NewsFormPropsType, validate } from '../components/News/NewsForm'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'
import { ValidationError } from 'errors/RequestValidationError';
import { routes } from 'routes/Route';
import { push } from 'connected-react-router';
import { storeNewsLocalAction, storeNews, removeNews } from 'actions/newsAction';
import { News } from 'dataObjects/news';


interface OwnProps {
}

type Props = OwnProps

const CN_FORM_NAME = 'NewsForm'

const appStateSelector = (state: AppState) => state

export default function NewsFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
  }, [])

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (Object.keys(errors).length > 0) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {
      const payload: News = {
        id: values['id'],
        title: values['title'],
        contents: values['contents'],
        publish_date: values['publish_date'],
        files: values['files']
      }
      setIsDisplay(false)
      ownDispatch(storeNewsLocalAction(payload))
      ownDispatch(storeNews(payload))
    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if (isDisplay === false) {
      console.log({ errors })
           
      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)

      ownDispatch(stopAsyncValidation(CN_FORM_NAME, errors))
      setIsDisplay(true)
    }
  }

  const onDelete = () => {
    ownDispatch(removeNews(state.news.id))
  }

  const _props = {
    onSubmit: submit,
    onDelete: onDelete,
    id: state.news.id,
    validationResult: state.ss422ValidationErrorResult,
    ssValidation: showValidationError,
    initialValues: convertFormInitialValues<News>(
      state.news
    ),
  }

  return <NewsForm {..._props} />

}
