import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { ListTableColumn } from '../components/ListTable/ListTable'
import UserListPage, { UserListPageProps } from 'pages/UserListPage';
import { fetchUserList, fetchUser } from 'actions/userAction';
import { UserListHeader } from 'dataObjects/user';
import { observeShownAction } from 'actions/pagingAction';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const columns: ListTableColumn[] = [
  { id: 'user_id', label: 'ID', minWidth: 50 },
  {
    id: 'account_name',
    label: 'ユーザー名',
    minWidth: 100,
    format: (value: string) => value && value.length > 30 ? value.substring(0, 30) + "..." : value
  },
  {
    id: 'user_email',
    label: 'email',
    minWidth: 100,
  }
  , {
    id: 'user_name',
    label: '氏名',
    minWidth: 100,
    format: (value: string) => value && value.length > 80 ? value.substring(0, 80) + "..." : value
  },
  {
    id: 'provider',
    label: 'アカウント種別',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'status',
    label: 'アカウント状態',
    minWidth: 100,
    align: 'center',
    format: (value: string) => !value || value == '0' ? '' : '停止'
  }
];

interface OwnProps {
}

export type UserListPageContainerProps = OwnProps & RouteComponentProps<{}>

const appStateSelector = (state: AppState) => state

export default function UserListPageContainer(props: UserListPageContainerProps) {
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  const _props: UserListPageProps = {
    columns: columns,
    data: state.userList.data,
    total: state.userList.total,
    page: state.userList.current_page - 1,
    rowsPerPage: Object.keys(state.userList.data).length,
    rowsPerPageOptions: [
      20,
      50,
      100,
      Object.keys(state.userList.data).length
    ],
    setPage: (page: number) => {
      ownDispatch(
        fetchUserList(
          state.userListCondition,
          state.userList.per_page,
          page
        )
      );
    },
    setRowsPerPage: (perPage: number) => {
      ownDispatch(
        fetchUserList(
          state.userListCondition,
          perPage,
          0
        )
      );
    },
    selectedRow: (data: any) => {
      const userData = data as UserListHeader;
      ownDispatch(fetchUser(userData.user_id));
    }
  };

  useEffect(() => {
    ownDispatch(observeShownAction())
  }, [])

  return <UserListPage {..._props} />

}