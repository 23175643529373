import { ActionTypes } from '../actions/actionTypes'
import { RegularReservationSearchCondition } from '../dataObjects/space';
import {
  STORE_REGULAR_RESERVATION_LIST_CONDITION
} from '../actions/regularReservationAction'


export interface RegularReservationSearchConditionState extends RegularReservationSearchCondition {

}

const initialRegularReservationSearchConditionState: RegularReservationSearchConditionState = {
  date_from: new Date(),
  date_to: null,
  company_name: '',
  email: ''
}


/* dropinReservationListCondition Reducer */
const regularReservationListCondition = (
  state: RegularReservationSearchConditionState = initialRegularReservationSearchConditionState,
  action: ActionTypes,
): RegularReservationSearchConditionState => {
  switch (action.type) {
    // 定期利用予約 検索条件
    case STORE_REGULAR_RESERVATION_LIST_CONDITION:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default regularReservationListCondition
