import { ActionTypes } from '../actions/actionTypes'
import { SpaceSearchCondition } from '../dataObjects/space';
import { STORE_SPACE_LIST_CONDITION } from 'actions/spaceAction';


export interface SpaceSearchConditionState extends SpaceSearchCondition {

}

const initialSpaceSearchConditionState: SpaceSearchConditionState = {
  space_name: '',
  address: ''
}


/* dropinReservationListCondition Reducer */
const spaceListCondition = (
  state: SpaceSearchConditionState = initialSpaceSearchConditionState,
  action: ActionTypes,
): SpaceSearchConditionState => {
  switch (action.type) {
    // スペース 検索条件
    case STORE_SPACE_LIST_CONDITION:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default spaceListCondition
