import { ActionTypes } from '../actions/actionTypes'
import {
  FETCH_NEWS_LIST_START,
  FETCH_NEWS_LIST_SUCCESS,
  FETCH_NEWS_LIST_FAIL
} from "../actions/newsAction";
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';
import { NewsListHeaderPagenation } from 'dataObjects/news';



export interface NewsListHeaderState
  extends NewsListHeaderPagenation { }

const initialNewsListHeaderState: NewsListHeaderState = {
  data: [],
  current_page: 1,
  first_page_url:
    process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/news/list/20?page=1",
  from: null,
  last_page: 1,
  last_page_url:
    process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/news/list//20?page=1",
  next_page_url: null,
  path: process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/news/list/20",
  per_page: 20,
  prev_page_url: null,
  to: null,
  total: 0
};


/* NewsList Reducer */
const newsList = (
  state: NewsListHeaderState = initialNewsListHeaderState,
  action: ActionTypes
): NewsListHeaderState => {
  switch (action.type) {
    // Newsリスト読み込み
    case FETCH_NEWS_LIST_START:
      return { ...state };
    case FETCH_NEWS_LIST_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_NEWS_LIST_FAIL:
      return { ...state };
    case LOGOUT_SUCCESS:
      return { ...state, ...initialNewsListHeaderState };
    default:
      return { ...state };
  }
};

export default newsList;
