import thunk from 'redux-thunk'
import client from 'axios'
import storage from 'redux-persist/es/storage'; // default: localStorage if web, AsyncStorage if react-native

import { applyMiddleware, createStore, Reducer, Store, compose } from 'redux';
import { History } from 'history';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router'
import middleware from '../middlewares';

export const configureStore = (reducer: Reducer, history: History): [Store, Persistor] => {
    let store : Store

    const logger = createLogger({
        collapsed: true,
        diff: true
    })

    if (process.env.NODE_ENV !== 'production') {
        middleware.push(logger) // DEV middlewares
    }

    // thunk
    const thunkWithClient = thunk.withExtraArgument(client)
    middleware.push(thunkWithClient)

    // route history
    middleware.push(routerMiddleware(history))

    const composeEnhancers =
        typeof window === 'object' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
            ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({
                // Specify extension options like name, actionsBlacklist, actionsCreators, serialize...
            })
            : compose

    const enhancer = composeEnhancers(applyMiddleware(...middleware))

    const persistorConfig = {
        key: 'shareal',
        storage,
        blacklist: [
            'form', 
            'connection', 
            'initialization', 
            'messaging', 
            'router', 
            'error',
            'page',
            'snackBar'
        ]
    }

    const persistedReducer = persistReducer(persistorConfig, reducer)
    store = createStore(persistedReducer, enhancer)    
    const persistor = persistStore(store)
    
    return [store, persistor]
}