import {
  STORE_TERMS_OF_SERVICE_MASTER_START,
  STORE_TERMS_OF_SERVICE_MASTER_SUCCESS,
  STORE_TERMS_OF_SERVICE_MASTER_FAIL,
  FETCH_TERMS_OF_SERVICE_MASTER_SUCCESS,
  FETCH_TERMS_OF_SERVICE_MASTER_START,
  FETCH_TERMS_OF_SERVICE_MASTER_FAIL,
  STORE_TERMS_OF_SERVICE_MASTER_LOCAL,
} from '../actions/masterAction';

import { ActionTypes } from '../actions/actionTypes';
import { TermsOfServiceList } from '../dataObjects/masters'

export interface TermsOfServiceState extends TermsOfServiceList {
}

const initialMasterState: TermsOfServiceState = {
  termsofservice: []
}


const termsOfServiceMaster = (
  state: TermsOfServiceState = initialMasterState,
  action: ActionTypes,
): TermsOfServiceState => {
  switch (action.type) {    
    case FETCH_TERMS_OF_SERVICE_MASTER_START:
      return { ...state };
    case FETCH_TERMS_OF_SERVICE_MASTER_SUCCESS:
      return {
        ...state,
        termsofservice: [
          ...action.payload
        ],
      }
    case STORE_TERMS_OF_SERVICE_MASTER_LOCAL:
      return {
        ...state,
        termsofservice: [
          ...action.payload
        ],
      }
    case STORE_TERMS_OF_SERVICE_MASTER_SUCCESS:
      return {
        ...state,
        termsofservice: [
          ...action.payload
        ],
      }
    case FETCH_TERMS_OF_SERVICE_MASTER_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default termsOfServiceMaster;
