import {
  FETCH_MASTER_START,
  FETCH_MASTER_SUCCESS,
  FETCH_MASTER_FAIL,
} from '../actions/masterAction';

import { ActionTypes } from '../actions/actionTypes';
import { Masters } from '../dataObjects/masters'

export interface MasterState extends Masters {
  hasLoad: boolean,
  fetchErrorCount: number
}

const initialMasterState: MasterState = {
  hasLoad: false,
  usage: [],
  facilities: [],
  terms_of_service: [],
  items: [],
  taxrates: [],
  taxratetypes: [],
  fetchErrorCount: 0
};


const master = (
  state: MasterState = initialMasterState,
  action: ActionTypes,
): MasterState => {
  switch (action.type) {
    // スペース選択
    case FETCH_MASTER_START:
      return { ...state, hasLoad: false };
    case FETCH_MASTER_SUCCESS:
      return { ...state, hasLoad: true, fetchErrorCount: 0, ...action.payload };
    case FETCH_MASTER_FAIL:
      return { ...state, hasLoad: false, fetchErrorCount: ++state.fetchErrorCount};
    default:
      return { ...state };
  }
};

export default master;
