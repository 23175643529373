import { ActionTypes } from '../actions/actionTypes'
import { SpaceListHeaderPagenation, SpaceListHeader } from '../dataObjects/space';
import {
  FETCH_SPACE_ALL_START,
  FETCH_SPACE_ALL_SUCCESS,
  FETCH_SPACE_ALL_FAIL
} from "../actions/spaceAction";
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';



export interface SpaceAllState {
  list: SpaceListHeader[]
}

const initialSpaceAllState: SpaceAllState = {
  list: []
}


/* SpaceAll Reducer */
const spaceAll = (
  state: SpaceAllState = initialSpaceAllState,
  action: ActionTypes
): SpaceAllState => {
  switch (action.type) {
    // スペース全件
    case FETCH_SPACE_ALL_START:
      return { ...state };
    case FETCH_SPACE_ALL_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload
        },
      }
    case FETCH_SPACE_ALL_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default spaceAll;
