import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes'
import { useSelector, useDispatch } from 'react-redux'
import { CalendarEvent } from "dataObjects/space"
import ReserveScheduleCalendarPage from 'pages/ReserveScheduleCalendarPage'
import { observeShownAction } from 'actions/pagingAction'
import { RouteComponentProps } from 'react-router'
import { CustomThunkDispatch } from 'types/dispatchTypes'
import { fetchScheduleList } from 'actions/scheduleAction'

interface OwnProps {
  space_id: number
}

const appStateSelector = (state: AppState) => state

export type ReserveScheduleCalendarPageContainerProps = OwnProps & RouteComponentProps<{ space_id: string }>

export default function ReserveScheduleCalendarPageContainer(props: ReserveScheduleCalendarPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const fromDate = new Date()
  fromDate.setDate(1)
  const toDate = new Date()
  toDate.setDate(1);
  toDate.setMonth(toDate.getMonth() + 1);
  toDate.setDate(0);

  useEffect(() => {
    dispatch(fetchScheduleList(1, fromDate, toDate));
  }, []);

  const _props = {
    space_id: 1,
    space_name: '',
    eventList: state.scheduleList.body
  }


  return <ReserveScheduleCalendarPage {..._props} />

}
