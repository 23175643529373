import * as React from 'react';
import useReacdlouter from 'use-react-router';
import { routes } from '../routes/Route';
import { Theme, createStyles, Container, Card, CardContent, Divider, Button, DialogTitle, DialogContent, Dialog, DialogActions, DialogContentText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import { SpaceUsage, Invoice, SquarePaymentResponseError, StripePaymentIntent, StripePaymentBatchProcess } from '../dataObjects/space'

import BasePageContainer from '../containers/BasePageContainer';
import ReservationStepperContainer from '../containers/ReservationStepperContainer'
import { UserData } from 'dataObjects/user';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment';

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fff'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center'
    },
    reserveInfoContainer: {
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        padding: '2% 0'
      },
      '& dl:nth-child(odd)': {
        backgroundColor: '#fbfbfb'
      },
      '& dt': {
        display: 'inline-block',
        width: '20%',
        paddingLeft: '10px',
        verticalAlign: 'middle',
      },
      '& dd': {
        marginInlineStart: '40px',
        display: 'inlineBlock',
        width: '64%',
        verticalAlign: 'middle',
        letterSpacing: '.3px'
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          minHeight: '80px',
          backgroundColor: '#fff',
          marginBlockStart: '0px',
          marginBlockEnd: '0px',
        },
        '& dl:nth-child(odd)': {
          backgroundColor: '#fff'
        },
        '& dt': {
          backgroundColor: '#ffffff',
          paddingTop: '10px',
          paddingLeft: '10px',
          width: '100%',
          minHeight: '40px'
        },
        '& dd': {
          backgroundColor: '#fff',
          width: '100%',
          paddingTop: '10px',
          paddingLeft: '10px',
          marginInlineStart: '0px',
          minHeight: '40px'
        },
      }
    },
    invoice: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8)
    },

    rowWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: 1,
      marginBottom: 1
    },
    colWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'start',
      width: '100%'
    },
    invoice_content: {
      fontSize: '1.7rem'
    },
    invoice_content_value: {
      minWidth: '25%',
      textAlign: 'right'
    },
    invoice_amount: {
      fontSize: '2.0rem'
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    submitButton: {
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
    dialogSubmitButton: {     
      color: '#FFF',
      backgroundColor: '#26A78E',
      width: '100px',
      height: '30px',
      marginRight: '10px',
      fontWeight: 'bold',
      fontSize: '1.4rem',      
    },
    dialogCancelButton: {           
      width: '100px',
      height: '30px',
      fontWeight: 'bold',
      fontSize: '1.4rem',      
    },
    buttonGreen: {
      color: '#FFF',
      backgroundColor: '#26A78E',
      height: '45px',
      fontSize: '1.4rem'
    },
    buttonRed: {
      color: '#FFF',
      backgroundColor: '#fa2120',
      height: '45px',
      fontSize: '1.4rem'
    },
    table: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      fontSize: '1.5rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'center',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2),
          backgroundColor: '#F5F5F5'
        }
      },
      '& tbody': {
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2),
          textAlign: 'center',
        }

      }
    },
  })
);

interface OwnProps {
  space_id: number,
  space_name: string,
  selectedDate: Date,
  timerange: string,
  users_adult: string | number,
  users_children: string | number,
  usage: SpaceUsage,
  detail: string,
  invoice: Invoice,
  family_name: string,
  given_name: string,
  family_name_kana: string,
  given_name_kana: string,
  email: string,
  postal_code: string,
  address_pref: string,
  address_city: string,
  address_street: string,
  address_room: string,
  tel: string,
  promotion_code: string,
  reserve_status: string,
  payemnt_status: string,
  payemnt_amout_money: number,
  payemnt_card_brand: string,
  payemnt_last_4: string,
  payemnt_exp_year: number,
  payemnt_exp_month: number,
  payemnt_country: string,
  payemnt_three_d_secure: string,
  payment_intents: StripePaymentIntent[],
  payment_batch_process: StripePaymentBatchProcess[],
  user_name: string,
  user_email: string,
  send_thunks_flg: boolean,
  send_cancel_flg: boolean,
  send_reminder_flg: boolean,
  send_confirm_period_ext_flg: boolean,
  dialogOpen: boolean,
  handleClose: () => void,
  onBack: () => void,  
  onCancel: () => void,
  onAuthorization: () => void,
  onCapture: () => void
}

export type DropInReserveDetailPageProps = OwnProps;

const DropInReserveDetailPage: React.FC<DropInReserveDetailPageProps> = (props: DropInReserveDetailPageProps) => {
  const [isShowConfirmDialog, setConfirmDialogState] = React.useState(false)
  const [actionType, setActionType] = React.useState('doCancel')
  const classes = useStyles({} as StyleProps);
  const formatter = new Intl.NumberFormat('ja-JP')

  const { space_id, space_name, selectedDate, timerange, users_adult,
    users_children, usage, detail, invoice,
    family_name, given_name, family_name_kana, given_name_kana,
    email, postal_code, address_pref, address_city,
    address_street, address_room, tel, promotion_code,
    user_name, user_email,
    reserve_status, onBack, onCancel, onAuthorization, onCapture
  } = props

  const steperProps = { activeStep: parseInt(reserve_status) }

  const createSubtitle = (status) => {
    switch (status) {
      case '0':
        return '以下の予約内容で時間仮予約中です'
      case '1':
        return '以下の予約内容が決済処理前で中断されています'
      case '2':
        return '決済処理中です'
      case '3':
        return '以下の予約内容で予約完了しています'
      case '4':
        return '決済処理でエラーが発生しています'
      case '9':
        return '予約がキャンセルされています'
      default:
        return '予約システムエラーが発生しています'
    }
  }

  const showConfirmDialog = (e) => {
    setActionType(e.currentTarget.dataset.action)
    setConfirmDialogState(true)
  }

  const onSubmitInDialog = () => {
    setConfirmDialogState(false)
    switch (actionType) {
      case 'doCancel':
        if(onCancel) {
          onCancel()
        }
        break;
      case 'doAuthorization':
        if(onAuthorization) {
          onAuthorization()
        }
        break;
      case 'doCapture':
        if(onCapture) {
          onCapture()
        }
        break;
      default:
        // do nothing
    }
    
  }

  const onCancelInDialog = () => {
    setConfirmDialogState(false)
    setActionType('')
  }

  const createButton = (status) => {
    const today = new Date()        

    if (status === '') {
      return null
    } else if (status === '3' || status === '4') {

      return (
        <Grid container spacing={2} alignItems="center" justify="center" style={{ marginTop: '100px', marginBottom: '100px' }}>
          <Grid item xs={4}>
            <Button fullWidth className={classes.buttonRed} data-action="doCancel" onClick={showConfirmDialog}>
              キャンセル
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth className={classes.buttonGreen} data-action="doAuthorization" onClick={showConfirmDialog}>
              再オーソリ
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth className={classes.buttonGreen} data-action="doCapture" onClick={showConfirmDialog}>
              キャプチャ(本決済)
            </Button>
          </Grid>
        </Grid>
             
      )

    } else {
      
      return (
        <Grid container spacing={2} alignItems="center" justify="center" style={{ marginTop: '100px', marginBottom: '100px' }}>
          <Grid item xs={6}>
            <Button fullWidth className={classes.buttonRed} onClick={showConfirmDialog}>
              キャンセル
            </Button>
          </Grid>
        </Grid>    
      )
    }

  }

  return (
    <BasePageContainer>
      <Container fixed className={classes.topcontainer} >

        <div className={classes.container}>

          <Typography variant="h2" className={classes.title}>
            [予約内容] {space_name}
          </Typography>

          <ReservationStepperContainer {...steperProps} />

          <Typography variant="h3" className={classes.subtitle}>
            {createSubtitle(reserve_status)}
          </Typography>

          {/* 予約スケジュール表示 */}
          <div className={classes.reserveInfoContainer}>
            <dl>
              <dt>利用スペース</dt>
              <dd>{space_name}</dd>
            </dl>
            <dl>
              <dt>利用時間</dt>
              <dd>{timerange}</dd>
            </dl>
            <dl>
              <dt>利用人数</dt>
              <dd>大人：{users_adult}人&nbsp;&nbsp;子供：{users_children}人</dd>
            </dl>
            <dl>
              <dt>利用目的</dt>
              <dd>{usage ? usage.usage : ''}</dd>
            </dl>
            <dl>
              <dt>連絡事項</dt>
              <dd>{detail}</dd>
            </dl>

            <Divider className={classes.divider} />
            <dl>
              <dt>ユーザーアカウント 名</dt>
              <dd>{user_name}</dd>
            </dl>
            <dl>
              <dt>ユーザーアカウント メールアドレス</dt>
              <dd>{user_email}</dd>
            </dl>

            <Divider className={classes.divider} />
            <dl>
              <dt>氏名かな</dt>
              <dd>{family_name_kana}{given_name_kana}</dd>
            </dl>
            <dl>
              <dt>氏名</dt>
              <dd>{family_name}{given_name}</dd>
            </dl>
            <dl>
              <dt>メールアドレス</dt>
              <dd>{email}</dd>
            </dl>
            <dl>
              <dt>郵便番号</dt>
              <dd>{postal_code}</dd>
            </dl>
            <dl>
              <dt>都道府県</dt>
              <dd>{address_pref}</dd>
            </dl>
            <dl>
              <dt>市区町村</dt>
              <dd>{address_city}</dd>
            </dl>
            <dl>
              <dt>番地</dt>
              <dd>{address_street}</dd>
            </dl>
            <dl>
              <dt>建物名・部屋番号</dt>
              <dd>{address_room}</dd>
            </dl>
            <dl>
              <dt>電話番号</dt>
              <dd>{tel}</dd>
            </dl>
            <dl>
              <dt>使用プロモーションコード</dt>
              <dd>{promotion_code}</dd>
            </dl>
            

            <Card variant="outlined" className={classes.invoice}>
              <CardContent>
                <Typography variant="h5" component="h2" className={classes.invoice_amount}>
                  ご請求明細
                </Typography>

                <Divider className={classes.divider} />

                {
                  invoice.details.map(d => {
                    return (
                      <div className={classes.colWrapper}>
                        <div className={classes.rowWrapper}>
                          <div>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                              利用時間計 金額：
                            </Typography>
                          </div>
                          <div className={classes.invoice_content_value}>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                             ￥ {formatter.format(d.subtotal)}
                            </Typography>
                          </div>
                        </div>

                        <div className={classes.rowWrapper}>
                          <div>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                              税({d.tax_rate * 100}%)：
                            </Typography>
                          </div>
                          <div className={classes.invoice_content_value}>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                              ￥ {formatter.format(d.tax)}
                            </Typography>
                          </div>
                        </div>

                        <div className={classes.rowWrapper}>
                          <div>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                              小計：
                            </Typography>
                          </div>
                          <div className={classes.invoice_content_value}>
                            <Typography variant="body2" component="p" className={classes.invoice_content}>
                             ￥ {formatter.format(d.amount)}
                            </Typography>
                          </div>
                        </div>

                      </div>
                    )

                  })
                }

                <Divider className={classes.divider} />

                <div className={classes.rowWrapper}>
                  <div>
                    <Typography variant="body2" component="p" className={classes.invoice_content}>
                      プロモーションコードによる値引き：
                    </Typography>
                  </div>
                  <div className={classes.invoice_content_value}>
                    <Typography variant="body2" component="p" className={classes.invoice_content}>
                    ￥ {formatter.format(invoice.discount)}
                    </Typography>
                  </div>
                </div>

                <Divider className={classes.divider} />

                <div className={classes.rowWrapper}>
                  <div>
                    <Typography variant="body2" component="p" className={classes.invoice_amount}>
                      合計（税込）：
                    </Typography>
                  </div>
                  <div className={classes.invoice_content_value}>
                    <Typography variant="body2" component="p" className={classes.invoice_amount}>
                    ￥ {formatter.format(invoice.amount)}
                    </Typography>
                  </div>
                </div>

              </CardContent>
            </Card>

            {/* 決済処理結果表示 */}
            
            <Typography variant="h3" className={classes.subtitle}>
              決済カード情報
            </Typography>

            <table className={classes.table} aria-label="customized table">
              <thead>
                <tr>
                  <th>決済ステータス</th>                  
                  <th>カードブランド</th>
                  <th>カード下4桁</th>
                  <th>有効期限</th>
                  <th>発行国</th>
                  <th>セキュア3D</th>                  
                </tr>
              </thead>
              <tbody>
                <tr key="peyament">
                  <td>{props.payemnt_status}</td>                  
                  <td>{props.payemnt_card_brand}</td>
                  <td>{props.payemnt_last_4}</td>
                  <td>{props.payemnt_exp_year}年{props.payemnt_exp_month}月</td>
                  <td>{props.payemnt_country}</td>
                  <td>{props.payemnt_three_d_secure == '1' ? 'ON' : 'OFF'}</td>
                </tr>
              </tbody>
            </table>

            <Typography variant="h3" className={classes.subtitle}>
              Stripe処理履歴
            </Typography>

            <table className={classes.table} aria-label="customized table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>PaymentIntent ID</th>
                  <th>ステータス</th>
                  <th>本決済日時</th>
                  <th>キャンセル日時</th>
                  <th>キャンセル理由</th>
                  <th>削除日時</th>
                </tr>
              </thead>
              <tbody>
                { props.payment_intents 
                    && props.payment_intents.map(pi => {
                      const payment_date = pi.payment_date ? (moment(pi.payment_date)).format('YYYY-MM-DD HH:mm:ss') : ''
                      const canceled_date = pi.canceled_at ? (moment(pi.canceled_at)).format('YYYY-MM-DD HH:mm:ss') : ''
                      const deleted_at = pi.deleted_at ? (moment(pi.deleted_at)).format('YYYY-MM-DD HH:mm:ss') : ''
                      return (
                        <tr key={pi.id}>
                          <td>{pi.id}</td>
                          <td>{pi.intent_id}</td>
                          <td>{pi.status}</td>
                          <td>{payment_date}</td>
                          <td>{canceled_date}</td>
                          <td>{pi.cancellation_reason}</td>
                          <td>{deleted_at}</td>
                        </tr>
                      )
                    })
                }
              </tbody>
            </table>

            <Typography variant="h3" className={classes.subtitle}>
              決済バッチ処理状況
            </Typography>

            <table className={classes.table} aria-label="customized table">
              <thead>
                <tr>
                  <th rowSpan={3}>ID</th>
                  <th>処理タイプ</th>
                  <th>ステータス</th>
                  <th>処理回数</th>
                  <th>処理開始日時</th>
                  <th>処理終了日時</th>
                  <th></th>
                </tr>
                <tr>
                  <th>オーソリエラー通知送信フラグ</th>
                  <th>オーソリエラー通知送信日時</th>
                  <th>オーソリ期限通知送信フラグ</th>
                  <th>オーソリ期限通知送信日時</th>
                  <th>強制キャンセル通知送信フラグ</th>
                  <th>強制キャンセル通知送信日時</th>
                </tr>
                <tr>
                  <th>エラーコード</th>
                  <th colSpan={5}>エラーメッセージ</th>
                </tr>
              </thead>
              <tbody>
                { props.payment_batch_process 
                    && props.payment_batch_process.map(bp => {
                      const started_at = bp.started_at ? (moment(bp.started_at)).format('YYYY-MM-DD HH:mm:ss') : ''
                      const finished_at = bp.finished_at ? (moment(bp.finished_at)).format('YYYY-MM-DD HH:mm:ss') : ''
                      const send_mail_at = bp.send_mail_at ? (moment(bp.send_mail_at)).format('YYYY-MM-DD HH:mm:ss') : ''
                      const send_reminder_mail_at = bp.send_reminder_mail_at ? (moment(bp.send_reminder_mail_at)).format('YYYY-MM-DD') : ''
                      const send_forcecancel_mail_at = bp.send_forcecancel_mail_at ? (moment(bp.send_forcecancel_mail_at)).format('YYYY-MM-DD') : ''
                      
                      let send_mail_status = ''
                      switch (bp.send_mail) {
                        case 0:
                          send_mail_status = ''
                          break;
                        case 1:
                          send_mail_status = '送信中'
                          break;
                        case 2:
                          send_mail_status = '送信済み'
                          break;                      
                        default:
                          break;
                      }

                      let send_reminder_mail_status = ''
                      switch (bp.send_reminder_mail) {
                        case 0:
                          send_reminder_mail_status = ''
                          break;
                        case 1:
                          send_reminder_mail_status = '送信中'
                          break;
                        case 2:
                          send_reminder_mail_status = '送信済み'
                          break;                      
                        default:
                          break;
                      }

                      let send_forcecancel_mail_status = ''
                      switch (bp.send_forcecancel_mail) {
                        case 0:
                          send_forcecancel_mail_status = ''
                          break;
                        case 1:
                          send_forcecancel_mail_status = '送信中'
                          break;
                        case 2:
                          send_forcecancel_mail_status = '送信済み'
                          break;                      
                        default:
                          break;
                      }

                      return (
                        <>
                          <tr key={bp.id + 0.1}>
                            <td rowSpan={3}>{bp.id}</td>
                            <td>{bp.type}</td>
                            <td>{bp.status}</td>
                            <td>{bp.batch_count}</td>
                            <td>{started_at}</td>
                            <td>{finished_at}</td>
                            <td></td>
                          </tr>
                          <tr key={bp.id + 0.2}>
                            <td>{send_mail_status}</td>
                            <td>{send_mail_at}</td>
                            <td>{send_reminder_mail_status}</td>
                            <td>{send_reminder_mail_at}</td>
                            <td>{send_forcecancel_mail_status}</td>
                            <td>{send_forcecancel_mail_at}</td>
                          </tr>
                          <tr key={bp.id + 0.3}>
                            <td>{bp.code === '' ? ' ' : bp.code}</td>
                            <td colSpan={5}>{bp.message === '' ? ' ' : bp.message}</td>                            
                          </tr>
                        </>
                      )
                    })
                }
              </tbody>
            </table>

            <Typography variant="h3" className={classes.subtitle}>
              メール送信状況
            </Typography>

            <table className={classes.table} aria-label="customized table">
              <thead>
                <tr>
                  <th>予約完了メール</th>
                  <th>キャンセルメール</th>
                  <th>1日前リマインダー</th>
                  <th>延長確認</th>
                </tr>
              </thead>
              <tbody>
                <tr key="send_mail">
                  <td style={{textAlign: "center"}}>{props.send_thunks_flg ? (<CheckCircleIcon />) : (<></>) }</td>
                  <td style={{textAlign: "center"}}>{props.send_cancel_flg ? (<CheckCircleIcon />) : (<></>) }</td>
                  <td style={{textAlign: "center"}}>{props.send_reminder_flg ? (<CheckCircleIcon />) : (<></>) }</td>
                  <td style={{textAlign: "center"}}>{props.send_confirm_period_ext_flg ? (<CheckCircleIcon />) : (<></>) }</td>
                </tr>                
              </tbody>
            </table>



            {createButton(reserve_status)}

          </div>

          <Grid container justify="center" spacing={2} style={{marginTop: '30px', marginBottom: '100px'}}>
            <Grid item xs={6}>
              <Button fullWidth variant="outlined" color="secondary" style={{height: '50px'}} onClick={onBack}>
                戻る
              </Button>
            </Grid>
          </Grid>

        </div>

        <Dialog
          open={isShowConfirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">実行確認</DialogTitle>
          <DialogContent dividers style={{display: 'flex'}}>
            <div>
              <NewReleasesIcon style={{ width: '30px', height: '30px', marginRight: '4px', color: '#AA0000'}} />
            </div>
            <div>
              <ul>
                  <li>実行してもよろしいですか？</li>
              </ul>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onSubmitInDialog} className={classes.dialogSubmitButton}>
              実行
            </Button>
            <Button onClick={onCancelInDialog} className={classes.dialogCancelButton} autoFocus>
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={props.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"処理結果"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              処理が完了しました。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>

      </Container>
    </BasePageContainer>

  );

}

export default DropInReserveDetailPage