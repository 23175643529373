import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { checkAppResponse } from '../actionHelper/responseChecker';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { routes } from 'routes/Route';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import {
  SpaceListHeader,
  SpaceListHeaderPagenation,
  SpaceSearchCondition,
  SpaceImageForUpdate,
  SpaceForUpdate,
  SpaceForUpdateValidationResult
} from "dataObjects/space";
import { SnackBarAction } from './actionTypes';
import { ValidationErrorListState } from 'reducers/validationErrorReducer';
import moment from 'moment';
import { getBase64 } from 'utils/convertor';


/**
 * スペース全件(マスタ参照用)取得アクション
 */
export const FETCH_SPACE_ALL_START = 'FETCH_SPACE_ALL_START';
export const FETCH_SPACE_ALL_SUCCESS = 'FETCH_SPACE_ALL_SUCCESS';
export const FETCH_SPACE_ALL_FAIL = 'FETCH_SPACE_ALL_FAIL';

export type FetchSpaceAllActions =
  | FetchSpaceAllStartAction
  | FetchSpaceAllSuccessAction
  | FetchSpaceAllFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchSpaceAllThunkResult<R> = ThunkAction<R, AppState, undefined, FetchSpaceAllActions>;


/**
 * スペース一覧取得アクション
 */
export const FETCH_SPACE_LIST_START = 'FETCH_SPACE_LIST_START';
export const FETCH_SPACE_LIST_SUCCESS = 'FETCH_SPACE_LIST_SUCCESS';
export const FETCH_SPACE_LIST_FAIL = 'FETCH_SPACE_LIST_FAIL';
/**
 * スペース 検索条件 保存
 */
export const STORE_SPACE_LIST_CONDITION = 'STORE_SPACE_LIST_CONDITION'

export type FetchSpaceListActions =
  | FetchSpaceListStartAction
  | FetchSpaceListSuccessAction
  | FetchSpaceListFailAction
  | StoreSpaceListConditionAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchSpaceListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchSpaceListActions>;


/**
 * スペース 取得アクション
 */
export const FETCH_SPACE_START = 'FETCH_SPACE_START';
export const FETCH_SPACE_SUCCESS = 'FETCH_SPACE_SUCCESS';
export const FETCH_SPACE_FAIL = 'FETCH_SPACE_FAIL';

export type FetchSpaceActions =
  | FetchSpaceStartAction
  | FetchSpaceSuccessAction
  | FetchSpaceFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchSpaceThunkResult<R> = ThunkAction<R, AppState, undefined, FetchSpaceActions>;



/**
 * スペース 更新アクション
 */
export const STORE_SPACE_START = 'STORE_SPACE_START';
export const STORE_SPACE_LOCAL = 'STORE_SPACE_LOCAL';
export const STORE_SPACE_SUCCESS = 'STORE_SPACE_SUCCESS';
export const STORE_SPACE_INVALID = 'STORE_SPACE_INVALID';
export const STORE_SPACE_FAIL = 'STORE_SPACE_FAIL';

export type StoreSpaceActions =
  | StoreSpaceStartAction
  | StoreSpaceSuccessAction
  | StoreSpaceInvalidAction
  | StoreSpaceFailAction
  | StoreSpaceLocalAction
  | CallHistoryMethodAction
  | ErrorActions;

type StoreSpaceThunkResult<R> = ThunkAction<R, AppState, undefined, StoreSpaceActions>;


/* ---------------------------------------------------------------------------------------------------- */


export interface FetchSpaceAllStartAction
  extends Action<typeof FETCH_SPACE_ALL_START> { }

const fetchSpaceAllStartAction = (): FetchSpaceAllStartAction => ({
  type: 'FETCH_SPACE_ALL_START',
});

export interface FetchSpaceAllSuccessAction
  extends Action<typeof FETCH_SPACE_ALL_SUCCESS> {
  payload: SpaceListHeader[]
}

const fetchSpaceAllSuccessAction = (
  headerList: SpaceListHeader[]
): FetchSpaceAllSuccessAction => ({
  type: "FETCH_SPACE_ALL_SUCCESS",
  payload: headerList
});

export interface FetchSpaceAllFailAction
  extends Action<typeof FETCH_SPACE_ALL_FAIL> {
  message: string;
}

const fetchSpaceAllFailAction = (message: string): FetchSpaceAllFailAction => ({
  type: 'FETCH_SPACE_ALL_FAIL',
  message,
})


/* スペース全件 取得 ThunkAction */
export function fetchSpaceAll(): FetchSpaceAllThunkResult<void> {
  return async (
    dispatch: ThunkDispatch<AppState, any, FetchSpaceAllActions>,
    getState: () => AppState
  ) => {
    try {
      const state = getState();

      dispatch(fetchSpaceAllStartAction());

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/space/all`;
      const response = await AppClient.get(url, header);
      const body: SpaceListHeader[] = await response.data;
      
      dispatch(fetchSpaceAllSuccessAction(body));
    } catch (err) {
      dispatch<FetchSpaceAllActions>(
        await ajaxErrorHandler(err, fetchSpaceAllFailAction)
      );
    }
  };
}

/* ---------------------------------------------------------------------------------------------------- */


export interface FetchSpaceListStartAction
  extends Action<typeof FETCH_SPACE_LIST_START> { }

const fetchSpaceListStartAction = (): FetchSpaceListStartAction => ({
  type: 'FETCH_SPACE_LIST_START',
});

export interface FetchSpaceListSuccessAction
  extends Action<typeof FETCH_SPACE_LIST_SUCCESS> {
  payload: SpaceListHeaderPagenation
}

const fetchSpaceListSuccessAction = (
  headerList: SpaceListHeaderPagenation
): FetchSpaceListSuccessAction => ({
  type: "FETCH_SPACE_LIST_SUCCESS",
  payload: headerList
});

export interface FetchSpaceListFailAction
  extends Action<typeof FETCH_SPACE_LIST_FAIL> {
  message: string;
}

const fetchSpaceListFailAction = (message: string): FetchSpaceListFailAction => ({
  type: 'FETCH_SPACE_LIST_FAIL',
  message,
})

/**
 * スペース 検索条件 保存
 */
export interface StoreSpaceListConditionAction
  extends Action<typeof STORE_SPACE_LIST_CONDITION> {
  payload: SpaceSearchCondition;
}

const storeSpaceListCondition = (
  condition: SpaceSearchCondition
): StoreSpaceListConditionAction => ({
  type: "STORE_SPACE_LIST_CONDITION",
  payload: condition
});

/* スペース一覧 取得 ThunkAction */
export function fetchSpaceList(
  condition: SpaceSearchCondition,
  perpage: number = 20,
  page: number = 0
): FetchSpaceListThunkResult<void> {
  return async (
    dispatch: ThunkDispatch<AppState, any, FetchSpaceListActions>,
    getState: () => AppState
  ) => {
    try {
      const state = getState();

      dispatch(fetchSpaceListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const url = `${
        process.env.REACT_APP_API_SERVER_HOST
      }api/backoffice/space/list/${perpage}?page=${page + 1}`;
      const response = await AppClient.get(url, header, condition);
      const body: SpaceListHeaderPagenation = await response.data;
      dispatch(storeSpaceListCondition(condition));
      dispatch(fetchSpaceListSuccessAction(body));
    } catch (err) {
      dispatch<FetchSpaceListActions>(
        await ajaxErrorHandler(err, fetchSpaceListFailAction)
      );
    }
  };
}

/* ---------------------------------------------------------------------------------------------------- */


export interface FetchSpaceStartAction
  extends Action<typeof FETCH_SPACE_START> { }

const fetchSpaceStartAction = (): FetchSpaceStartAction => ({
  type: 'FETCH_SPACE_START',
});

export interface FetchSpaceSuccessAction
  extends Action<typeof FETCH_SPACE_SUCCESS> {
  payload: SpaceForUpdate
}

const fetchSpaceSuccessAction = (
  data: SpaceForUpdate,
): FetchSpaceSuccessAction => ({
  type: 'FETCH_SPACE_SUCCESS',
  payload: data
})

export interface FetchSpaceFailAction
  extends SnackBarAction<typeof FETCH_SPACE_FAIL> {
  
}

const fetchSpaceFailAction = (message: string): FetchSpaceFailAction => ({
  type: 'FETCH_SPACE_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* スペース 取得 ThunkAction */
export function fetchSpace(space_id: number): FetchSpaceThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchSpaceActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchSpaceStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/space/find/${space_id}`;
      const response = await AppClient.get(url, header)
      const body: SpaceForUpdate = await response.data;

      if(body.space_dropin_specific_day_pricing) {
        body.space_dropin_specific_day_pricing.forEach(value => {
          value.day = moment(value.day).toDate();
        })
      }
      if(body.space_regular_specific_day_pricing) {
        body.space_regular_specific_day_pricing.forEach(value => {
          value.day = moment(value.day).toDate();
        })
      }

      dispatch(
        fetchSpaceSuccessAction(body)
      )
      dispatch(push(routes.spaceUpdate.getPath(space_id)))
    } catch (err) {
      dispatch<FetchSpaceActions>(
        await ajaxErrorHandler(err, fetchSpaceFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */

export interface StoreSpaceStartAction
  extends Action<typeof STORE_SPACE_START> { }

const storeSpaceStartAction = (): StoreSpaceStartAction => ({
  type: 'STORE_SPACE_START',
});

export interface StoreSpaceLocalAction
  extends Action<typeof STORE_SPACE_LOCAL> {
  payload: SpaceForUpdate
}

export const storeSpaceLocalAction = (
  data: SpaceForUpdate,
): StoreSpaceLocalAction => ({
  type: 'STORE_SPACE_LOCAL',
  payload: data
})

export interface StoreSpaceSuccessAction
  extends Action<typeof STORE_SPACE_SUCCESS> {
  payload: SpaceForUpdate
}

const storeSpaceSuccessAction = (
  data: SpaceForUpdate,
): StoreSpaceSuccessAction => ({
  type: 'STORE_SPACE_SUCCESS',
  payload: data
  })

export interface StoreSpaceInvalidAction
  extends Action<typeof STORE_SPACE_INVALID> {
  payload: ValidationErrorListState
}

const storeSpaceInvalidAction = (
  payload: ValidationErrorListState
)
  : StoreSpaceInvalidAction => {
  return {
    type: STORE_SPACE_INVALID,
    payload: payload
  }
}

export interface StoreSpaceFailAction
  extends SnackBarAction<typeof STORE_SPACE_FAIL> {
  
}

const storeSpaceFailAction = (message: string)
  : StoreSpaceFailAction => ({
  type: 'STORE_SPACE_FAIL',
    snackBarMessage: message,
    variant: "error"
})


/* 定期利用予約 更新 ThunkAction */
export function storeSpace(payload: SpaceForUpdate): StoreSpaceThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreSpaceActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(storeSpaceStartAction());
      
      const header: Record<string, string> = {}
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const images : SpaceImageForUpdate[] = payload.space_images
      payload.space_images = null

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/space/store`;
      const response = await AppClient.post(url, header, payload)
      const data: SpaceForUpdateValidationResult = await response.data;

      let imgdata: SpaceForUpdateValidationResult = null
      // 画像送信
      if(images && images.length > 0) {
        
        await Promise.all(images.map(async (value, index, array)  => {
          value.space_id = data.payload.space.id
          value.order_number = index
          if(value.image_file) {
            value.image_file_name = value.image_file.name
            value.image_file_base64 = await getBase64(value.image_file) as string 
          }
          value.image_file = null    
          return
        }))        
        console.log(images)
        const payload = {
          space_images: images
        }
        const imageUrl = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/space/image/upload`;
        const imageResponse = await AppClient.post(imageUrl, header, payload)
        imgdata = await imageResponse.data
      }

      if(imgdata && imgdata.validationResult && imgdata.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeSpaceInvalidAction({
          message: 'スペース画像 登録処理',
          errors: imgdata.validationResult
        }))
      } else if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeSpaceInvalidAction({
          message: 'スペース登録処理',
          errors: data.validationResult
        }))
        if(data.validationResult.some((v, i, a) => {
          return v.item == 'space.plan.onlybasic' || v.item == 'space.plan.allupdated'
        } )) {
          // 日付        
          if(data.payload.space_dropin_specific_day_pricing) {
            data.payload.space_dropin_specific_day_pricing.forEach(value => {
              value.day = moment(value.day).toDate();
            })
          }
          if(data.payload.space_regular_specific_day_pricing) {
            data.payload.space_regular_specific_day_pricing.forEach(value => {
              value.day = moment(value.day).toDate();
            })
          }

          dispatch(
            storeSpaceSuccessAction(data.payload)
          )
        }
      } else {
        // 日付
        if(data.payload.space_dropin_specific_day_pricing) {
          data.payload.space_dropin_specific_day_pricing.forEach(value => {
            value.day = moment(value.day).toDate();
          })
        }
        if(data.payload.space_regular_specific_day_pricing) {
          data.payload.space_regular_specific_day_pricing.forEach(value => {
            value.day = moment(value.day).toDate();
          })
        }

        dispatch(
          storeSpaceSuccessAction(data.payload)
        )
      }
      
    } catch (err) {
      dispatch<StoreSpaceActions>(
        await ajaxErrorHandler(err, storeSpaceFailAction),
      )
    }
  }
}
