import { ActionTypes } from '../actions/actionTypes'
import { InquirySearchCondition } from '../dataObjects/inquiry';
import { STORE_COMMON_INQUIRY_LIST_CONDITION } from 'actions/inquiryAction';


export interface InquirySearchConditionState extends InquirySearchCondition {

}

const initialInquirySearchConditionState: InquirySearchConditionState = {
  user_name: '',
  email: '',
  content: '',
  inquiry_date_from: undefined,
  inquiry_date_to: undefined
}


/* commonInquiryListCondition Reducer */
const commonInquiryListCondition = (
  state: InquirySearchConditionState = initialInquirySearchConditionState,
  action: ActionTypes,
): InquirySearchConditionState => {
  switch (action.type) {
    // スペース 検索条件
    case STORE_COMMON_INQUIRY_LIST_CONDITION:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default commonInquiryListCondition
