import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { routes } from 'routes/Route';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { ValidationErrorListState } from 'reducers/validationErrorReducer';
import { KeyBoxPass, KeyBoxPassUpdateResult, KeyBoxPassStoreRequest } from '../dataObjects/keyboxpass'
import { SnackBarAction } from './actionTypes';
import { dateToString } from 'utils/convertor'

/**
 * KEYBOXPASS一覧取得アクション
 */
export const FETCH_KEYBOXPASS_LIST_START = 'FETCH_KEYBOXPASS_LIST_START';
export const FETCH_KEYBOXPASS_LIST_SUCCESS = 'FETCH_KEYBOXPASS_LIST_SUCCESS';
export const FETCH_KEYBOXPASS_LIST_FAIL = 'FETCH_KEYBOXPASS_LIST_FAIL';


export type FetchKeyBoxPassListActions =
  | FetchKeyBoxPassListStartAction
  | FetchKeyBoxPassListSuccessAction
  | FetchKeyBoxPassListFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchKeyBoxPassListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchKeyBoxPassListActions>;


/**
 * KEYBOXPASS 更新アクション
 */
export const STORE_KEYBOXPASS_START = 'STORE_KEYBOXPASS_START';
export const STORE_KEYBOXPASS_LOCAL = 'STORE_KEYBOXPASS_LOCAL';
export const STORE_KEYBOXPASS_SUCCESS = 'STORE_KEYBOXPASS_SUCCESS';
export const STORE_KEYBOXPASS_INVALID = 'STORE_KEYBOXPASS_INVALID';
export const STORE_KEYBOXPASS_FAIL = 'STORE_KEYBOXPASS_FAIL';

export type StoreKeyBoxPassActions =
  | StoreKeyBoxPassStartAction
  | StoreKeyBoxPassSuccessAction
  | StoreKeyBoxPassInvalidAction
  | StoreKeyBoxPassFailAction
  | StoreKeyBoxPassLocalAction
  | CallHistoryMethodAction
  | ErrorActions;

type StoreKeyBoxPassThunkResult<R> = ThunkAction<R, AppState, undefined, StoreKeyBoxPassActions>;


/* ---------------------------------------------------------------------------------------------------- */


export interface FetchKeyBoxPassListStartAction
  extends Action<typeof FETCH_KEYBOXPASS_LIST_START> { }

const fetchKeyBoxPassListStartAction = (): FetchKeyBoxPassListStartAction => ({
  type: 'FETCH_KEYBOXPASS_LIST_START',
});

export interface FetchKeyBoxPassListSuccessAction
  extends Action<typeof FETCH_KEYBOXPASS_LIST_SUCCESS> {
  payload: KeyBoxPass[]
}

const fetchKeyBoxPassListSuccessAction = (
  list: KeyBoxPass[],
): FetchKeyBoxPassListSuccessAction => ({
  type: 'FETCH_KEYBOXPASS_LIST_SUCCESS',
  payload: list
})

export interface FetchKeyBoxPassListFailAction
  extends SnackBarAction<typeof FETCH_KEYBOXPASS_LIST_FAIL> {

}

const fetchKeyBoxPassListFailAction = (message: string): FetchKeyBoxPassListFailAction => ({
  type: 'FETCH_KEYBOXPASS_LIST_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* KEYBOXPASS一覧 取得 ThunkAction */
export function fetchKeyBoxPassList(): FetchKeyBoxPassListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchKeyBoxPassListActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchKeyBoxPassListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/keyboxpass/list`
      const response = await AppClient.get(url, header)
      const body: KeyBoxPass[] = await response.data;

      const toString = Object.prototype.toString
      body.forEach((value) => {
        value.enable_from = toString.call(value.enable_from) == '[object Date]' ? value.enable_from : new Date(Date.parse(dateToString(value.enable_from).replace(/-/g,"/")))
        value.enable_to = toString.call(value.enable_to) == '[object Date]' ? value.enable_to : new Date(Date.parse(dateToString(value.enable_to).replace(/-/g,"/")))
      })

      dispatch(
        fetchKeyBoxPassListSuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchKeyBoxPassListActions>(
        await ajaxErrorHandler(err, fetchKeyBoxPassListFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */

export interface StoreKeyBoxPassStartAction
  extends Action<typeof STORE_KEYBOXPASS_START> { }

const storeKeyBoxPassStartAction = (): StoreKeyBoxPassStartAction => ({
  type: 'STORE_KEYBOXPASS_START',
});

export interface StoreKeyBoxPassLocalAction
  extends Action<typeof STORE_KEYBOXPASS_LOCAL> {
  payload: KeyBoxPass[]
}

export const storeKeyBoxPassLocalAction = (
  data: KeyBoxPass[]
): StoreKeyBoxPassLocalAction => ({
  type: 'STORE_KEYBOXPASS_LOCAL',
  payload: data
})

export interface StoreKeyBoxPassSuccessAction
  extends Action<typeof STORE_KEYBOXPASS_SUCCESS> {
  payload: KeyBoxPass[]
}

const storeKeyBoxPassSuccessAction = (
  data: KeyBoxPass[],
): StoreKeyBoxPassSuccessAction => ({
  type: 'STORE_KEYBOXPASS_SUCCESS',
  payload: data
})

export interface StoreKeyBoxPassInvalidAction
  extends Action<typeof STORE_KEYBOXPASS_INVALID> {
  payload: ValidationErrorListState
}

const storeKeyBoxPassInvalidAction = (
  payload: ValidationErrorListState
)
  : StoreKeyBoxPassInvalidAction => {
  return {
    type: STORE_KEYBOXPASS_INVALID,
    payload: payload
  }
}

export interface StoreKeyBoxPassFailAction
  extends SnackBarAction<typeof STORE_KEYBOXPASS_FAIL> {

}

const storeKeyBoxPassFailAction = (message: string)
  : StoreKeyBoxPassFailAction => ({
    type: 'STORE_KEYBOXPASS_FAIL',
    snackBarMessage: message,
    variant: "error"
  })


/* KEYBOXPASS 更新 ThunkAction */
export function storeKeyBoxPass(payload: KeyBoxPassStoreRequest): StoreKeyBoxPassThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreKeyBoxPassActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(storeKeyBoxPassStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/keyboxpass/store`;
      const response = await AppClient.post(url, header, payload)
      const data: KeyBoxPassUpdateResult = await response.data;
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeKeyBoxPassInvalidAction({
          message: '暗証番号の登録処理が中断しました。',
          errors: data.validationResult
        }))
      } else {
        dispatch(
          storeKeyBoxPassSuccessAction(data.payload)
        )
      }

    } catch (err) {
      dispatch<StoreKeyBoxPassActions>(
        await ajaxErrorHandler(err, storeKeyBoxPassFailAction),
      )
    }
  }
}