import React, { useEffect, SyntheticEvent } from 'react'
import ScheduleCalendar from "components/Schedule/ScheduleCalendar";
import { CalendarEvent } from "dataObjects/space"
import { AppState } from 'types/stateTypes';
import { useSelector, useDispatch } from 'react-redux';
import { fetchScheduleList, showScheduleEvent } from 'actions/scheduleAction';
import { CustomThunkDispatch } from 'types/dispatchTypes';
import { getStartAndEndDateOfWeek } from 'utils/DateExtention';



interface OwnPops {
  eventList: CalendarEvent[]
}

export type ScheduleCalendarContainerProps = OwnPops;

const appStateSelector = (state: AppState) => state
export default function ScheduleCalendarContainer(
  props: ScheduleCalendarContainerProps
) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const _props = {
    timeZone: "Asia/Tokyo",
    events: props.eventList,
    view: state.scheduleList.view,    
    defDate: state.scheduleList.defdate,
    onNavigate: (focusDate: Date, flipUnit: any, prevOrNext: String) => {
      
      let fromDate2 = state.scheduleList.schedule_from
      let toDate2 = state.scheduleList.schedule_to
      let defDate = state.scheduleList.defdate

      let chosei = 0
      if (prevOrNext.toLowerCase() == 'prev') {
        chosei = -1
      } else if (prevOrNext.toLowerCase() == 'next') {
        chosei = 1
      }

      if(prevOrNext.toLowerCase() == 'today') {
        if (flipUnit == 'month') {
          fromDate2.setMonth(focusDate.getMonth())
          toDate2 = new Date(focusDate.getFullYear(), focusDate.getMonth() + 1, 0); // 翌月の 0日は当月の最終日
          defDate = focusDate
        } else if (flipUnit == 'week') {
          const seDate = getStartAndEndDateOfWeek(focusDate)
          fromDate2 = seDate.startDate
          toDate2 = seDate.endDate
          defDate = focusDate
        } else if (flipUnit == 'day') {          
          fromDate2.setDate(focusDate.getDate())
          toDate2.setDate(focusDate.getDate())
          defDate = focusDate
        } else if (flipUnit == 'agenda') {
          fromDate2.setMonth(focusDate.getMonth())
          toDate2 = new Date(focusDate.getFullYear(), focusDate.getMonth() + 1, 0); 
          defDate = focusDate
        } else {
          fromDate2.setMonth(focusDate.getMonth())
          toDate2 = new Date(focusDate.getFullYear(), focusDate.getMonth() + 1, 0); 
          defDate = focusDate
        }
      } else {
        if (flipUnit == 'month') {
          fromDate2.setMonth(defDate.getMonth() + chosei)
          const nextMonth = defDate.getMonth() + chosei + 1 // prev or next 後の月の翌月を算出
          toDate2 = new Date(defDate.getFullYear(), nextMonth, 0); // 翌月の 0日は当月の最終日
          defDate = new Date(fromDate2)
          defDate.setDate(1)
        } else if (flipUnit == 'week') {
          defDate.setDate(defDate.getDate() + (chosei * 7))
          const seDate = getStartAndEndDateOfWeek(defDate)
          fromDate2 = seDate.startDate
          toDate2 = seDate.endDate
        } else if (flipUnit == 'day') {
          defDate.setDate(defDate.getDate() + chosei)
          fromDate2.setDate(defDate.getDate())
          toDate2.setDate(defDate.getDate())
        } else if (flipUnit == 'agenda') {
          fromDate2.setMonth(defDate.getMonth() + chosei)
          const nextMonth = defDate.getMonth() + chosei + 1
          toDate2 = new Date(defDate.getFullYear(), nextMonth, 0)
          defDate = new Date(fromDate2)
          defDate.setDate(1)
        } else {
          fromDate2.setMonth(defDate.getMonth() + chosei)
          const nextMonth = defDate.getMonth() + chosei + 1 
          toDate2 = new Date(defDate.getFullYear(), nextMonth, 0)
          defDate = new Date(fromDate2)
          defDate.setDate(1)
        }
      }

      dispatch(fetchScheduleList(1, fromDate2, toDate2, flipUnit, defDate))
    },
    onView: (view: string, date: Date) => {
      let fromDate2 = state.scheduleList.schedule_from
      let toDate2 = state.scheduleList.schedule_to
      let defDate = state.scheduleList.defdate

      if(date) {
        defDate = date
      }

      if (view == 'month') {
        fromDate2.setMonth(defDate.getMonth())
        toDate2 = new Date(defDate.getFullYear(), defDate.getMonth() + 1, 0); // 翌月の 0日は当月の最終日        
      } else if (view == 'week') {
        const seDate = getStartAndEndDateOfWeek(defDate)
        fromDate2 = seDate.startDate
        toDate2 = seDate.endDate
      } else if (view == 'day') {          
        fromDate2.setDate(defDate.getDate())
        toDate2.setDate(defDate.getDate())        
      } else if (view == 'agenda') {
        fromDate2.setMonth(defDate.getMonth())
        toDate2 = new Date(defDate.getFullYear(), defDate.getMonth() + 1, 0);         
      } else {
        fromDate2.setMonth(defDate.getMonth())
        toDate2 = new Date(defDate.getFullYear(), defDate.getMonth() + 1, 0);         
      }

      dispatch(fetchScheduleList(1, fromDate2, toDate2, view, defDate))
    },
    onSelectEvent: (event: any, e: SyntheticEvent) => {
      dispatch(showScheduleEvent(event))
    }
  };

  return <ScheduleCalendar {..._props} />;
}
