import React, { useEffect } from "react";
import { AppState } from "../types/stateTypes";
import { useSelector, useDispatch } from "react-redux";
import { CustomThunkDispatch } from "../types/dispatchTypes";
import {
  fetchNewsList
} from "../actions/newsAction";
import { Values, Errors, convertFormInitialValues } from "../types/formTypes";
import NewsSearchForm, { validate } from "../components/News/NewsSearchForm";
import { SubmissionError } from "redux-form";
import { NewsSearchCondition } from "../dataObjects/news";

interface OwnProps {
}

export type NewsSearchFromContainerProps = OwnProps;

const appStateSelector = (state: AppState) => state

export default function NewsSearchFormContainer(props: NewsSearchFromContainerProps) {
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {

      const condition = {
        publish_date_from: values['publish_date_from'],
        publish_date_to: values['publish_date_to'],
        title: values['title']
      }

      ownDispatch(
        fetchNewsList(
          condition,
          state.newsList.per_page,
          0
        )
      );
    }
  }

  useEffect(() => {
    ownDispatch(
      fetchNewsList(state.newsListCondition)
    );
  }, []);

  const _props = {
    onSubmit: submit,
    initialValues: convertFormInitialValues<NewsSearchCondition>(
      state.newsListCondition
    )
  }

  return <NewsSearchForm {..._props} />

}
