import React from "react";
import {
  Field,
  FieldArray,
  reduxForm,
  WrappedFieldProps,
  WrappedFieldArrayProps,
  InjectedFormProps,
  GenericFieldArray,
  FieldArrayFieldsProps
} from "redux-form";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles
} from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {
  renderTextField,
  renderRadio,
  renderFormHelper,
  renderCheckBox,
  renderSelect,
  renderDateCalendarField
} from "../renderFormControl";
import { Values, Errors } from "../../types/formTypes";
import { required, maxLength, digit, lessThanDateTime, greaterThanDateTime, number, requiredArray } from "../../utils/validation";
import { fontWeight } from "@material-ui/system";
import { TaxRate, TaxRateType } from "dataObjects/masters";

import RadioGroup from '@material-ui/core/RadioGroup';

import { Paper, Tabs, Tab, MenuItem, Divider, Icon, Input } from "@material-ui/core";
import { theme } from "materialui/theme";

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import DirectionsSubwayIcon from '@material-ui/icons/DirectionsSubway';
import PowerIcon from '@material-ui/icons/Power';
import SignalWifi4BarLockIcon from '@material-ui/icons/SignalWifi4BarLock';
import VideocamIcon from '@material-ui/icons/Videocam';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import WcIcon from '@material-ui/icons/Wc';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import SmokeFreeIcon from '@material-ui/icons/SmokeFree';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import HotelIcon from '@material-ui/icons/Hotel';
import DeckIcon from '@material-ui/icons/Deck';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import AccessibleIcon from '@material-ui/icons/Accessible';
import { iconList } from "utils/MaterialUIIcnos";
import { ValidationError } from "errors/RequestValidationError";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(2),
      flexGrow: 1,
      boxShadow: 'none',
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      width: "100%",
      borderRadius: "3px",
      fontSize: "1.3rem",
      lineHeight: 1.3,
      "& dl": {
        display: "flex",
        flexWrap: "wrap",
        marginTop: 0,
        marginBottom: 0,
        width: "100%",
        height: "120px",
        alignItems: "center"
      },
      "& dt": {
        backgroundColor: "#fbfbfb",
        display: "flex",
        alignItems: "center",
        width: "20%",
        height: "100%",
        paddingLeft: "10px",
        borderBottom: "1px solid #f0f0f0",
        "& dt:last-child": {
          borderBottom: "none"
        }
      },
      "& dd": {
        marginLeft: "0",
        paddingLeft: "40px",
        display: "inline-block",
        alignItems: "center",
        width: "70%",
        height: "100%",
        borderBottom: "1px solid #f0f0f0",
        "& dd:last-child": {
          borderBottom: "none"
        }
      },
      [theme.breakpoints.down("sm")]: {
        "& dl": {
          flexFlow: "column nowrap",
          height: "150px"
        },
        "& dt": {
          backgroundColor: "#ffffff",
          paddingLeft: "10px",
          width: "100%",
          height: "80px",
          borderBottom: "1px solid #fefefe"
        },
        "& dd": {
          width: "100%",
          paddingLeft: "10px"
        }
      }
    },
    cardGrid: {
      textAlign: "center"
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: "1.3rem",
      paddingBottom: theme.spacing(2),
      paddingTop: "20px",
      paddingLeft: "12px",
      backgroundColor: "#fbfbfb"
    },
    subsubtitle: {
      marginTop: theme.spacing(5),
      fontSize: "1.5rem",
      paddingBottom: theme.spacing(2),
      borderBottom: "1px solid #cecece"
    },
    description: {
      fontWeight: "normal",
      fontSize: "1.3rem",
      lineHeight: 1.4,
      marginTop: theme.spacing(2)
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: "50px",
      marginBottom: "20px",
      fontWeight: "bold",
      fontSize: "1.4rem"
    },
    notice: {
      marginBottom: "180px"
    },
    inputTextField25: {
      width: "25%",
      marginRight: "15px",
      [theme.breakpoints.down("sm")]: {
        width: "40%"
      }
    },
    inputTextField50: {
      width: "50%",
      marginRight: "15px",
      [theme.breakpoints.down("sm")]: {
        width: "70%"
      }
    },
    inputTextField80: {
      width: "80%",
      marginRight: "15px",
      [theme.breakpoints.down("sm")]: {
        width: "90%"
      }
    }
  })
);

export const validate = (values: Values) => {
  const errors: Errors = {};
  const requiredFields = [
    'name',
    'icon'
  ];
  const facilities_errors = requiredArray(values.facilities, requiredFields)
  if (facilities_errors.length > 0) {
    errors['facilities'] = facilities_errors
  }

  return errors;
};


/**
 * 行レンダリング
 * @param props 
 */
const renderFacility = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>      
      <ul>
        {fields.map(renderFacilityFields)}
      </ul>
      <li>
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
          <Button fullWidth variant="outlined" color="secondary" type="button" style={{height: '50px', fontSize: '1.4rem', marginTop: '8px'}} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;設備 追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>
      </li>
    </ul>
  )
}

const renderFacilityFields = (fac, index, fields) => {
  
  return (
    <li key={index} className={'list-group-item'}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <span style={{ marginTop: '16px', marginBottom: '8px', marginRight: '10px'  }} >設備 {index + 1}</span>

        <Field
          component={Input}
          name={`${fac}.id`}
          type="hidden"
          style={{ height: 0 }}
        />

        <Field
          name={`${fac}.name`}
          type="text"
          component={renderTextField}
          label="設備名"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          style={{width: '30%'}}
        />

        <div style={{width: '50%', marginLeft: '24px'}}>
          <Field name={`${fac}.icon`} label='アイコン' component={renderSelect} required style={{width: '30%', marginTop: '16px', marginBottom: '8px'}}>
            {              
              Object.keys(iconList).map((iconKey) => {
                const Tag = iconList[iconKey]
                const iconDiv = (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Tag />
                  </div>
                )
                return (
                  <MenuItem value={iconKey} style={{width: '100%'}}>{iconDiv}</MenuItem>
                )
              })
            }
          </Field>
        </div>
        
        <Button type="button" variant="outlined" color="secondary"　style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

interface OwnProps {
  validationResult?: ValidationError,
  ssValidation?: (errors: ValidationError, touch: (...fields: string[]) => void) => void,
}


export type FacilitiesMasterFormPropsType = OwnProps &
  InjectedFormProps<{}, OwnProps>;


const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

let outMasters = null

const Form: React.FC<FacilitiesMasterFormPropsType> = (
  props: FacilitiesMasterFormPropsType
) => {
  const classes = useStyles({});  
  const { handleSubmit, pristine, submitting, reset, validationResult, ssValidation, change, touch } = props;
  
  if (validationResult && validationResult.status != '') {
    if (ssValidation) {
      ssValidation(validationResult.errors, touch)
    }
  }

  /**
   * render
   */
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <FieldArrayCustom
            name="facilities"
            component={renderFacility}
          />
        </div>

        <div style={{marginTop: theme.spacing(3)}}></div>
        
        <Grid container spacing={2} justify="center">
          <Grid item xs={6}>
            <Button
              type="submit"
              disabled={pristine || submitting}
              fullWidth
              className={classes.submitButton}
            >
              登録する
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="center">
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="outlined"
              color="secondary"　
              disabled={pristine || submitting}
              fullWidth
              onClick={reset}
              style={{height: '50px'}}
            >
              リセット
            </Button>
          </Grid>
        </Grid>

      </form>
    </>
  );
};

const FacilitiesMasterForm = reduxForm({
  form: "FacilitiesMasterForm",
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(Form);

export default FacilitiesMasterForm;
