import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { checkAppResponse } from '../actionHelper/responseChecker';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { routes } from 'routes/Route';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import { SpaceReservation, SpaceReservationResult } from 'dataObjects/space'
import { DropInReservationHeaderPagenation, DropinReservationSearchCondition } from 'dataObjects/space';
import { SnackBarAction } from './actionTypes';
import { ValidationErrorListState } from 'reducers/validationErrorReducer';



/**
 * 一時利用一覧取得アクション
 */
export const FETCH_DROPIN_RESERVATION_LIST_START = 'FETCH_DROPIN_RESERVATION_LIST_START';
export const FETCH_DROPIN_RESERVATION_LIST_SUCCESS = 'FETCH_DROPIN_RESERVATION_LIST_SUCCESS';
export const FETCH_DROPIN_RESERVATION_LIST_FAIL = 'FETCH_DROPIN_RESERVATION_LIST_FAIL';
/**
 * 一時利用予約 検索条件 保存
 */
export const STORE_DROPIN_RESERVATION_LIST_CONDITION = 'STORE_DROPIN_RESERVATION_LIST_CONDITION'

export type FetchDropinReservationListActions =
  | FetchDropinReservationListStartAction
  | FetchDropinReservationListSuccessAction
  | FetchDropinReservationListFailAction
  | StoreDropinReservationListConditionAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchDropInReservationListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchDropinReservationListActions>;


/**
 * 一時利用予約 取得アクション
 */
export const FETCH_DROPIN_RESERVATION_START = 'FETCH_DROPIN_RESERVATION_START';
export const FETCH_DROPIN_RESERVATION_SUCCESS = 'FETCH_DROPIN_RESERVATION_SUCCESS';
export const FETCH_DROPIN_RESERVATION_FAIL = 'FETCH_DROPIN_RESERVATION_FAIL';

export type FetchDropinReservationActions =
  | FetchDropinReservationStartAction
  | FetchDropinReservationSuccessAction
  | FetchDropinReservationFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchDropinReservationThunkResult<R> = ThunkAction<R, AppState, undefined, FetchDropinReservationActions>;


/* 予約キャンセルアクションType */
export const CANCEL_DROPIN_RESERVATION_START = 'CANCEL_DROPIN_RESERVATION_START'
export const CANCEL_DROPIN_RESERVATION_SUCCESS = 'CANCEL_DROPIN_RESERVATION_SUCCESS'
export const CANCEL_DROPIN_RESERVATION_REFRESH = 'CANCEL_DROPIN_RESERVATION_REFRESH'
export const CANCEL_DROPIN_RESERVATION_INVALID = 'CANCEL_DROPIN_RESERVATION_INVALID'
export const CANCEL_DROPIN_RESERVATION_FAIL = 'CANCEL_DROPIN_RESERVATION_FAIL'

/* キャンセル処理 アクション */
export type CancelDropinReservationActions = 
  | CancelDropinReservationStartAction
  | CancelDropinReservationSuccessAction
  | CancelDropinReservationRefreshAction
  | CancelDropinReservationInvalidAction
  | CancelDropinReservationFailAction
  | ErrorActions
  | CallHistoryMethodAction

type CancelDropinReservationThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  CancelDropinReservationActions
  >
  

/* 再オーソリアクションType */
export const AUTHORIZATION_DROPIN_RESERVATION_START = 'AUTHORIZATION_DROPIN_RESERVATION_START'
export const AUTHORIZATION_DROPIN_RESERVATION_SUCCESS = 'AUTHORIZATION_DROPIN_RESERVATION_SUCCESS'
export const AUTHORIZATION_DROPIN_RESERVATION_REFRESH = 'AUTHORIZATION_DROPIN_RESERVATION_REFRESH'
export const AUTHORIZATION_DROPIN_RESERVATION_INVALID = 'AUTHORIZATION_DROPIN_RESERVATION_INVALID'
export const AUTHORIZATION_DROPIN_RESERVATION_FAIL = 'AUTHORIZATION_DROPIN_RESERVATION_FAIL'

/* 再オーソリ アクション */
export type AuthorizationDropinReservationActions = 
  | AuthorizationDropinReservationStartAction
  | AuthorizationDropinReservationSuccessAction
  | AuthorizationDropinReservationRefreshAction
  | AuthorizationDropinReservationInvalidAction
  | AuthorizationDropinReservationFailAction
  | ErrorActions
  | CallHistoryMethodAction

type AuthorizationDropinReservationThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  AuthorizationDropinReservationActions
  >

/* キャプチャアクションType */
export const CAPTURE_DROPIN_RESERVATION_START = 'CAPTURE_DROPIN_RESERVATION_START'
export const CAPTURE_DROPIN_RESERVATION_SUCCESS = 'CAPTURE_DROPIN_RESERVATION_SUCCESS'
export const CAPTURE_DROPIN_RESERVATION_REFRESH = 'CAPTURE_DROPIN_RESERVATION_REFRESH'
export const CAPTURE_DROPIN_RESERVATION_INVALID = 'CAPTURE_DROPIN_RESERVATION_INVALID'
export const CAPTURE_DROPIN_RESERVATION_FAIL = 'CAPTURE_DROPIN_RESERVATION_FAIL'

/* キャプチャ アクション */
export type CaptureDropinReservationActions = 
  | CaptureDropinReservationStartAction
  | CaptureDropinReservationSuccessAction
  | CaptureDropinReservationRefreshAction
  | CaptureDropinReservationInvalidAction
  | CaptureDropinReservationFailAction
  | ErrorActions
  | CallHistoryMethodAction

type CaptureDropinReservationThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  CaptureDropinReservationActions
  >

/* ---------------------------------------------------------------------------------------------------- */


export interface FetchDropinReservationListStartAction
  extends Action<typeof FETCH_DROPIN_RESERVATION_LIST_START> { }

const fetchDropinReservationListStartAction = (): FetchDropinReservationListStartAction => ({
  type: 'FETCH_DROPIN_RESERVATION_LIST_START',
});

export interface FetchDropinReservationListSuccessAction
  extends Action<typeof FETCH_DROPIN_RESERVATION_LIST_SUCCESS> {
  payload: DropInReservationHeaderPagenation
}

const fetchDropinReservationListSuccessAction = (
  headerList: DropInReservationHeaderPagenation,
): FetchDropinReservationListSuccessAction => ({
  type: 'FETCH_DROPIN_RESERVATION_LIST_SUCCESS',
  payload: headerList
})

export interface FetchDropinReservationListFailAction
  extends SnackBarAction<typeof FETCH_DROPIN_RESERVATION_LIST_FAIL> {
}

const fetchDropinReservationListFailAction = (message: string): FetchDropinReservationListFailAction => ({
  type: 'FETCH_DROPIN_RESERVATION_LIST_FAIL',
  snackBarMessage: message,
  variant: "error"
})

/**
 * 一時利用予約 検索条件 保存
 */
export interface StoreDropinReservationListConditionAction 
  extends Action<typeof STORE_DROPIN_RESERVATION_LIST_CONDITION> {
    payload: DropinReservationSearchCondition
  }

const storeDropinReservationListCondition = (
  condition: DropinReservationSearchCondition
): StoreDropinReservationListConditionAction => ({
    type: 'STORE_DROPIN_RESERVATION_LIST_CONDITION',
    payload: condition
})

/* 一時利用予約一覧 取得 ThunkAction */
export function fetchDropinReservationList(condition: DropinReservationSearchCondition,
  perpage: number = 20, page: number = 0): FetchDropInReservationListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchDropinReservationListActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchDropinReservationListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dropin/list/${perpage}?page=${page + 1}`
      const response = await AppClient.get(url, header, condition)
      const body: DropInReservationHeaderPagenation = await response.data;
      dispatch(
        storeDropinReservationListCondition(condition)
      )
      dispatch(
        fetchDropinReservationListSuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchDropinReservationListActions>(
        await ajaxErrorHandler(err, fetchDropinReservationListFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */


export interface FetchDropinReservationStartAction
  extends Action<typeof FETCH_DROPIN_RESERVATION_START> { }

const fetchDropinReservationStartAction = (): FetchDropinReservationStartAction => ({
  type: 'FETCH_DROPIN_RESERVATION_START',
});

export interface FetchDropinReservationSuccessAction
  extends Action<typeof FETCH_DROPIN_RESERVATION_SUCCESS> {
  payload: SpaceReservation
}

const fetchDropinReservationSuccessAction = (
  data: SpaceReservation,
): FetchDropinReservationSuccessAction => ({
  type: 'FETCH_DROPIN_RESERVATION_SUCCESS',
  payload: data
})

export interface FetchDropinReservationFailAction
  extends SnackBarAction<typeof FETCH_DROPIN_RESERVATION_FAIL> {
  
}

const fetchDropinReservationFailAction = (message: string): FetchDropinReservationFailAction => ({
  type: 'FETCH_DROPIN_RESERVATION_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* 一時利用予約 取得 ThunkAction */
export function fetchDropinReservation(reserve_id: number): FetchDropinReservationThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchDropinReservationActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchDropinReservationStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dropin/find/${reserve_id}`;
      const response = await AppClient.get(url, header)
      const body: SpaceReservation = await response.data;
      dispatch(
        fetchDropinReservationSuccessAction(body)
      )
      dispatch(push(routes.dropInReserve.getPath(reserve_id)))
    } catch (err) {
      dispatch<FetchDropinReservationActions>(
        await ajaxErrorHandler(err, fetchDropinReservationFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */

/* キャンセル 処理START Action */
export interface CancelDropinReservationStartAction
  extends Action<typeof CANCEL_DROPIN_RESERVATION_START> { 
  }

const cancelDropinReservationStartAction = (): CancelDropinReservationStartAction => {
  return {
    type: CANCEL_DROPIN_RESERVATION_START,
  }
}

/* キャンセル 処理成功 Action */
export interface CancelDropinReservationSuccessAction
  extends Action<typeof CANCEL_DROPIN_RESERVATION_SUCCESS> {
  payload: SpaceReservationResult
}

const cancelDropinReservationSuccessAction = (payload: SpaceReservationResult)
  : CancelDropinReservationSuccessAction => {
  return {
    type: CANCEL_DROPIN_RESERVATION_SUCCESS,
    payload,
  };
};

/* 再オーソリ データリフレッシュ Action */
export interface CancelDropinReservationRefreshAction
  extends Action<typeof CANCEL_DROPIN_RESERVATION_REFRESH> {
  payload: SpaceReservationResult
}

const CancelDropinReservationRefreshAction = (payload: SpaceReservationResult)
  : CancelDropinReservationRefreshAction => {
  return {
    type: CANCEL_DROPIN_RESERVATION_REFRESH,
    payload,
  };
};

/* キャンセル 処理失敗 Action */
export interface CancelDropinReservationFailAction
  extends SnackBarAction<typeof CANCEL_DROPIN_RESERVATION_FAIL>  {}

const cancelDropinReservationFailAction = (
  message: string
): CancelDropinReservationFailAction => {
  return {
    type: CANCEL_DROPIN_RESERVATION_FAIL,
    snackBarMessage: message,
    variant: 'error'
  }
}

/* キャンセル Validationエラー発生 Action */
export interface CancelDropinReservationInvalidAction
  extends Action<typeof CANCEL_DROPIN_RESERVATION_INVALID> {
  payload: ValidationErrorListState
}

const cancelDropinReservationInvalidAction = (
  payload: ValidationErrorListState
): CancelDropinReservationInvalidAction => {
  return {
    type: CANCEL_DROPIN_RESERVATION_INVALID,
    payload
  }
}

/* キャンセル処理を開始する */
export function reservationCancelAction(space_id: number, reserve_id: number): CancelDropinReservationThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, CancelDropinReservationActions>,
    getState: () => AppState) => {
    
    dispatch(cancelDropinReservationStartAction())

    try {
      // nonce送信
      const state = getState()
      const data = await cancelReserve(space_id, reserve_id, state.authStatus.access_token)
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(cancelDropinReservationInvalidAction({
          message: '予約キャンセル処理が中断しました。',
          errors: data.validationResult
        }))

      } else {
        dispatch(
          cancelDropinReservationSuccessAction({
            payload: data.payload,
            validationResult: data.validationResult
          })
        )
        // ユーザー予約履歴一覧画面に遷移
        const nextViewPath = routes.dropInReserveList.getPath()
        dispatch(push(nextViewPath))
      }
    } catch (err) {
      dispatch<CancelDropinReservationActions>(
        await ajaxErrorHandler(err, cancelDropinReservationFailAction)
      );
    }
  }
}

/* キャンセル処理を実行 */
async function cancelReserve(space_id: number, reserve_id: number, access_token: string): Promise<SpaceReservationResult> {
  const header: Record<string, string> = {};
  header.Authorization = 'Bearer ' + access_token

  const payload = {
    space_id: space_id,
    reserve_id: reserve_id,
    is_sendmail: false
  }
  const reservationResultRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dropin/cancel`, header, payload);

  const result: SpaceReservationResult = await reservationResultRes.data;
  
  return result;
}


/* ---------------------------------------------------------------------------------------------------- */

/* 再オーソリ 処理START Action */
export interface AuthorizationDropinReservationStartAction
  extends Action<typeof AUTHORIZATION_DROPIN_RESERVATION_START> { 
  }

const AuthorizationDropinReservationStartAction = (): AuthorizationDropinReservationStartAction => {
  return {
    type: AUTHORIZATION_DROPIN_RESERVATION_START,
  }
}

/* 再オーソリ 処理成功 Action */
export interface AuthorizationDropinReservationSuccessAction
  extends Action<typeof AUTHORIZATION_DROPIN_RESERVATION_SUCCESS> {
  payload: SpaceReservationResult
}

const AuthorizationDropinReservationSuccessAction = (payload: SpaceReservationResult)
  : AuthorizationDropinReservationSuccessAction => {
  return {
    type: AUTHORIZATION_DROPIN_RESERVATION_SUCCESS,
    payload,
  };
};

/* 再オーソリ データリフレッシュ Action */
export interface AuthorizationDropinReservationRefreshAction
  extends Action<typeof AUTHORIZATION_DROPIN_RESERVATION_REFRESH> {
  payload: SpaceReservationResult
}

const AuthorizationDropinReservationRefreshAction = (payload: SpaceReservationResult)
  : AuthorizationDropinReservationRefreshAction => {
  return {
    type: AUTHORIZATION_DROPIN_RESERVATION_REFRESH,
    payload,
  };
};

/* 再オーソリ 処理失敗 Action */
export interface AuthorizationDropinReservationFailAction
  extends SnackBarAction<typeof AUTHORIZATION_DROPIN_RESERVATION_FAIL>  {}

const AuthorizationDropinReservationFailAction = (
  message: string
): AuthorizationDropinReservationFailAction => {
  return {
    type: AUTHORIZATION_DROPIN_RESERVATION_FAIL,
    snackBarMessage: message,
    variant: 'error'
  }
}

/* 再オーソリ　Validationエラー発生 Action */
export interface AuthorizationDropinReservationInvalidAction
  extends Action<typeof AUTHORIZATION_DROPIN_RESERVATION_INVALID> {
  payload: ValidationErrorListState
}

const AuthorizationDropinReservationInvalidAction = (
  payload: ValidationErrorListState
): AuthorizationDropinReservationInvalidAction => {
  return {
    type: AUTHORIZATION_DROPIN_RESERVATION_INVALID,
    payload
  }
}

/* キャンセル処理を開始する */
export function reservationAuthorizationAction(reserve_id: number): AuthorizationDropinReservationThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, AuthorizationDropinReservationActions>,
    getState: () => AppState) => {
    
    dispatch(AuthorizationDropinReservationStartAction())

    try {
      // nonce送信
      const state = getState()
      const data = await AuthorizationReserve(reserve_id, state.authStatus.access_token)
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(AuthorizationDropinReservationInvalidAction({
          message: '再オーソリ処理が失敗しました。',
          errors: data.validationResult
        }))
        dispatch(
          AuthorizationDropinReservationRefreshAction({
            payload: data.payload,
            validationResult: data.validationResult
          })
        )
        
      } else {
        dispatch(
          AuthorizationDropinReservationSuccessAction({
            payload: data.payload,
            validationResult: data.validationResult
          })
        )
      }
    } catch (err) {
      dispatch<AuthorizationDropinReservationActions>(
        await ajaxErrorHandler(err, AuthorizationDropinReservationFailAction)
      );
    }
  }
}

/* オーソリ処理を実行 */
async function AuthorizationReserve(reserve_id: number, access_token: string): Promise<SpaceReservationResult> {
  const header: Record<string, string> = {};
  header.Authorization = 'Bearer ' + access_token

  const payload = {    
    reserve_id: reserve_id
  }
  const reservationResultRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dropin/authorization`, header, payload);

  const result: SpaceReservationResult = await reservationResultRes.data;
  
  return result;
}

/* ---------------------------------------------------------------------------------------------------- */

/* キャプチャ 処理START Action */
export interface CaptureDropinReservationStartAction
  extends Action<typeof CAPTURE_DROPIN_RESERVATION_START> { 
  }

const CaptureDropinReservationStartAction = (): CaptureDropinReservationStartAction => {
  return {
    type: CAPTURE_DROPIN_RESERVATION_START,
  }
}

/* キャプチャ 処理成功 Action */
export interface CaptureDropinReservationSuccessAction
  extends Action<typeof CAPTURE_DROPIN_RESERVATION_SUCCESS> {
  payload: SpaceReservationResult
}

const CaptureDropinReservationSuccessAction = (payload: SpaceReservationResult)
  : CaptureDropinReservationSuccessAction => {
  return {
    type: CAPTURE_DROPIN_RESERVATION_SUCCESS,
    payload,
  };
};

/* キャプチャ データリフレッシュ Action */
export interface CaptureDropinReservationRefreshAction
  extends Action<typeof CAPTURE_DROPIN_RESERVATION_REFRESH> {
  payload: SpaceReservationResult
}

const CaptureDropinReservationRefreshAction = (payload: SpaceReservationResult)
  : CaptureDropinReservationRefreshAction => {
  return {
    type: CAPTURE_DROPIN_RESERVATION_REFRESH,
    payload,
  };
};

/* キャプチャ 処理失敗 Action */
export interface CaptureDropinReservationFailAction
  extends SnackBarAction<typeof CAPTURE_DROPIN_RESERVATION_FAIL>  {}

const CaptureDropinReservationFailAction = (
  message: string
): CaptureDropinReservationFailAction => {
  return {
    type: CAPTURE_DROPIN_RESERVATION_FAIL,
    snackBarMessage: message,
    variant: 'error'
  }
}

/* キャプチャ Validationエラー発生 Action */
export interface CaptureDropinReservationInvalidAction
  extends Action<typeof CAPTURE_DROPIN_RESERVATION_INVALID> {
  payload: ValidationErrorListState
}

const CaptureDropinReservationInvalidAction = (
  payload: ValidationErrorListState
): CaptureDropinReservationInvalidAction => {
  return {
    type: CAPTURE_DROPIN_RESERVATION_INVALID,
    payload
  }
}

/* キャプチャ処理を開始する */
export function reservationCaptureAction(reserve_id: number): CaptureDropinReservationThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, CaptureDropinReservationActions>,
    getState: () => AppState) => {
    
    dispatch(CaptureDropinReservationStartAction())

    try {
      // nonce送信
      const state = getState()
      const data = await CaptureReserve(reserve_id, state.authStatus.access_token)
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(CaptureDropinReservationInvalidAction({
          message: 'キャプチャ処理が中断しました。',
          errors: data.validationResult
        }))
        dispatch(
          CaptureDropinReservationRefreshAction({
            payload: data.payload,
            validationResult: data.validationResult
          })
        )
      } else {
        dispatch(
          CaptureDropinReservationSuccessAction({
            payload: data.payload,
            validationResult: data.validationResult
          })
        )
      }
    } catch (err) {
      dispatch<CaptureDropinReservationActions>(
        await ajaxErrorHandler(err, CaptureDropinReservationFailAction)
      );
    }
  }
}

/* 決済処理を実行 */
async function CaptureReserve(reserve_id: number, access_token: string): Promise<SpaceReservationResult> {
  const header: Record<string, string> = {};
  header.Authorization = 'Bearer ' + access_token

  const payload = {    
    reserve_id: reserve_id    
  }
  const reservationResultRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dropin/capture`, header, payload);

  const result: SpaceReservationResult = await reservationResultRes.data;
  
  return result;
}