import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { routes } from 'routes/Route';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { ValidationErrorListState } from 'reducers/validationErrorReducer';
import { News, NewsListHeaderPagenation, NewsSearchCondition, NewsUpdateResult, NewsAttachmentFileForUpdate } from '../dataObjects/news'
import { SnackBarAction } from './actionTypes';
import { getBase64 } from 'utils/convertor';
import moment from 'moment';


/**
 * NEWS一覧取得アクション
 */
export const FETCH_NEWS_LIST_START = 'FETCH_NEWS_LIST_START';
export const FETCH_NEWS_LIST_SUCCESS = 'FETCH_NEWS_LIST_SUCCESS';
export const FETCH_NEWS_LIST_FAIL = 'FETCH_NEWS_LIST_FAIL';
/**
 * NEWS 検索条件 保存
 */
export const STORE_NEWS_CONDITION = 'STORE_NEWS_CONDITION'

export type FetchNewsListActions =
  | FetchNewsListStartAction
  | FetchNewsListSuccessAction
  | FetchNewsListFailAction
  | StoreNewsListConditionAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchNewsListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchNewsListActions>;


/**
 * NEWS 取得アクション
 */
export const FETCH_NEWS_START = 'FETCH_NEWS_START';
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';
export const FETCH_NEWS_FAIL = 'FETCH_NEWS_FAIL';

export type FetchNewsActions =
  | FetchNewsStartAction
  | FetchNewsSuccessAction
  | FetchNewsFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchNewsThunkResult<R> = ThunkAction<R, AppState, undefined, FetchNewsActions>;


/**
 * NEWS 更新アクション
 */
export const STORE_NEWS_START = 'STORE_NEWS_START';
export const STORE_NEWS_LOCAL = 'STORE_NEWS_LOCAL';
export const STORE_NEWS_SUCCESS = 'STORE_NEWS_SUCCESS';
export const STORE_NEWS_INVALID = 'STORE_NEWS_INVALID';
export const STORE_NEWS_FAIL = 'STORE_NEWS_FAIL';

export type StoreNewsActions =
  | StoreNewsStartAction
  | StoreNewsSuccessAction
  | StoreNewsInvalidAction
  | StoreNewsFailAction
  | StoreNewsLocalAction
  | CallHistoryMethodAction
  | ErrorActions;

type StoreNewsThunkResult<R> = ThunkAction<R, AppState, undefined, StoreNewsActions>;


/**
 * NEWS 削除アクション
 */
export const REMOVE_NEWS_START = 'REMOVE_NEWS_START';
export const REMOVE_NEWS_SUCCESS = 'REMOVE_NEWS_SUCCESS';
export const REMOVE_NEWS_INVALID = 'REMOVE_NEWS_INVALID';
export const REMOVE_NEWS_FAIL = 'REMOVE_NEWS_FAIL';

export type RemoveNewsActions =
  | RemoveNewsStartAction
  | RemoveNewsSuccessAction
  | RemoveNewsInvalidAction
  | RemoveNewsFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type RemoveNewsThunkResult<R> = ThunkAction<R, AppState, undefined, RemoveNewsActions>;


/* ---------------------------------------------------------------------------------------------------- */


export interface FetchNewsListStartAction
  extends Action<typeof FETCH_NEWS_LIST_START> { }

const fetchNewsListStartAction = (): FetchNewsListStartAction => ({
  type: 'FETCH_NEWS_LIST_START',
});

export interface FetchNewsListSuccessAction
  extends Action<typeof FETCH_NEWS_LIST_SUCCESS> {
  payload: NewsListHeaderPagenation
}

const fetchNewsListSuccessAction = (
  headerList: NewsListHeaderPagenation,
): FetchNewsListSuccessAction => ({
  type: 'FETCH_NEWS_LIST_SUCCESS',
  payload: headerList
})

export interface FetchNewsListFailAction
  extends SnackBarAction<typeof FETCH_NEWS_LIST_FAIL> {

}

const fetchNewsListFailAction = (message: string): FetchNewsListFailAction => ({
  type: 'FETCH_NEWS_LIST_FAIL',
  snackBarMessage: message,
  variant: "error"
})

/**
 * NEWS 検索条件 保存
 */
export interface StoreNewsListConditionAction
  extends Action<typeof STORE_NEWS_CONDITION> {
  payload: NewsSearchCondition
}

const storeNewsListCondition = (
  condition: NewsSearchCondition
): StoreNewsListConditionAction => ({
  type: 'STORE_NEWS_CONDITION',
  payload: condition
})

/* NEWS一覧 取得 ThunkAction */
export function fetchNewsList(condition: NewsSearchCondition,
  perpage: number = 20, page: number = 0): FetchNewsListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchNewsListActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchNewsListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/news/${perpage}?page=${page + 1}`
      const response = await AppClient.get(url, header, condition)
      const body: NewsListHeaderPagenation = await response.data;
      dispatch(
        storeNewsListCondition(condition)
      )
      dispatch(
        fetchNewsListSuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchNewsListActions>(
        await ajaxErrorHandler(err, fetchNewsListFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */


export interface FetchNewsStartAction
  extends Action<typeof FETCH_NEWS_START> { }

const fetchNewsStartAction = (): FetchNewsStartAction => ({
  type: 'FETCH_NEWS_START',
});

export interface FetchNewsSuccessAction
  extends Action<typeof FETCH_NEWS_SUCCESS> {
  payload: News
}

const fetchNewsSuccessAction = (
  data: News,
): FetchNewsSuccessAction => ({
  type: 'FETCH_NEWS_SUCCESS',
  payload: data
})

export interface FetchNewsFailAction
  extends SnackBarAction<typeof FETCH_NEWS_FAIL> {

}

const fetchNewsFailAction = (message: string): FetchNewsFailAction => ({
  type: 'FETCH_NEWS_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* NEWS 取得 ThunkAction */
export function fetchNews(news_id: number): FetchNewsThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchNewsActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchNewsStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/news/find/${news_id}`;
      const response = await AppClient.get(url, header)
      const body: News = await response.data;
      dispatch(
        fetchNewsSuccessAction(body)
      )
      dispatch(push(routes.newsDetail.getPath(body.id)))
    } catch (err) {
      dispatch<FetchNewsActions>(
        await ajaxErrorHandler(err, fetchNewsFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */

export interface StoreNewsStartAction
  extends Action<typeof STORE_NEWS_START> { }

const storeNewsStartAction = (): StoreNewsStartAction => ({
  type: 'STORE_NEWS_START',
});

export interface StoreNewsLocalAction
  extends Action<typeof STORE_NEWS_LOCAL> {
  payload: News
}

export const storeNewsLocalAction = (
  data: News,
): StoreNewsLocalAction => ({
  type: 'STORE_NEWS_LOCAL',
  payload: data
})

export interface StoreNewsSuccessAction
  extends Action<typeof STORE_NEWS_SUCCESS> {
  payload: NewsUpdateResult
}

const storeNewsSuccessAction = (
  data: NewsUpdateResult,
): StoreNewsSuccessAction => ({
  type: 'STORE_NEWS_SUCCESS',
  payload: data
})

export interface StoreNewsInvalidAction
  extends Action<typeof STORE_NEWS_INVALID> {
  payload: ValidationErrorListState
}

const storeNewsInvalidAction = (
  payload: ValidationErrorListState
)
  : StoreNewsInvalidAction => {
  return {
    type: STORE_NEWS_INVALID,
    payload: payload
  }
}

export interface StoreNewsFailAction
  extends SnackBarAction<typeof STORE_NEWS_FAIL> {

}

const storeNewsFailAction = (message: string)
  : StoreNewsFailAction => ({
    type: 'STORE_NEWS_FAIL',
    snackBarMessage: message,
    variant: "error"
  })


/* NEWS 更新 ThunkAction */
export function storeNews(payload: News): StoreNewsThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreNewsActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(storeNewsStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/news/store`;
      const response = await AppClient.post(url, header, payload)
      const data: NewsUpdateResult = await response.data;

      const files : NewsAttachmentFileForUpdate[] = payload.files
      payload.files = null
      let fileData: NewsUpdateResult = null
      // 添付ファイル送信
      if(files && files.length > 0) {
        
        await Promise.all(files.map(async (value, index, array)  => {
          value.news_id = data.payload.id     
          value.sort_no = index     
          if(value.file) {
            value.file_name = value.file.name
            value.file_base64 = await getBase64(value.file) as string 
          }
          value.file = null    
          return
        }))        
        console.log(files)
        const payload = {
          files: files
        }
        const fileUrl = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/news/file/upload`;
        const fileResponse = await AppClient.post(fileUrl, header, payload)
        fileData = await fileResponse.data
      }

      if(fileData && fileData.validationResult && fileData.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeNewsInvalidAction({
          message: 'NEWS添付ファイル 登録処理',
          errors: fileData.validationResult
        }))
      } else if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeNewsInvalidAction({
          message: 'NEWSの登録処理が中断しました。',
          errors: data.validationResult
        }))
      } else {        
        data.payload.publish_date = moment(data.payload.publish_date).toDate();
                  
        dispatch(
          storeNewsSuccessAction(data)
        )
      }

    } catch (err) {
      dispatch<StoreNewsActions>(
        await ajaxErrorHandler(err, storeNewsFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */

export interface RemoveNewsStartAction
  extends Action<typeof REMOVE_NEWS_START> { }

const removeNewsStartAction = (): RemoveNewsStartAction => ({
  type: 'REMOVE_NEWS_START',
});

export interface RemoveNewsSuccessAction
  extends Action<typeof REMOVE_NEWS_SUCCESS> {
  payload: NewsUpdateResult
}

const removeNewsSuccessAction = (
  data: NewsUpdateResult,
): RemoveNewsSuccessAction => ({
  type: 'REMOVE_NEWS_SUCCESS',
  payload: data
})

export interface RemoveNewsInvalidAction
  extends Action<typeof REMOVE_NEWS_INVALID> {
  payload: ValidationErrorListState
}

const removeNewsInvalidAction = (
  payload: ValidationErrorListState
)
  : RemoveNewsInvalidAction => {
  return {
    type: REMOVE_NEWS_INVALID,
    payload: payload
  }
}

export interface RemoveNewsFailAction
  extends SnackBarAction<typeof REMOVE_NEWS_FAIL> {

}

const removeNewsFailAction = (message: string)
  : RemoveNewsFailAction => ({
    type: 'REMOVE_NEWS_FAIL',
    snackBarMessage: message,
    variant: "error"
  })


/* NEWS 削除 ThunkAction */
export function removeNews(id: number): RemoveNewsThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, RemoveNewsActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(removeNewsStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const payload = {
        id: id
      }

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/news/remove`;
      const response = await AppClient.post(url, header, payload)
      const data: NewsUpdateResult = await response.data;
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(removeNewsInvalidAction({
          message: 'NEWSの削除処理が中断しました。',
          errors: data.validationResult
        }))
      } else {
        dispatch(
          removeNewsSuccessAction(data)
        )
      }

    } catch (err) {
      dispatch<RemoveNewsActions>(
        await ajaxErrorHandler(err, removeNewsFailAction),
      )
    }
  }
}