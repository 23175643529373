import React, { useEffect } from 'react'
import { routes } from '../routes/Route';
import { RouteComponentProps } from 'react-router-dom'
import { push } from "connected-react-router"
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import TaxRateMasterPage, { TaxRateMasterPageProps } from '../pages/TaxRateMasterPage'
import { observeShownAction } from 'actions/pagingAction';
import moment from 'moment';
import { fetchFacilitiesMasterAction } from 'actions/masterAction';
import FacilitiesMasterPage, { FacilitiesMasterPageProps } from 'pages/FacilitiesMasterPage';
import { closeDialog } from 'actions/dialogAction';


interface OwnProps {
}

export type FacilitiesMasterPageContainerProps = OwnProps & RouteComponentProps<{ space_id?: string }>

const appStateSelector = (state: AppState) => state

export default function FacilitiesMasterPageContainer(props: FacilitiesMasterPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const _props: FacilitiesMasterPageProps = {
    updateDialogOpen: state.page.updateDialogOpen,    
    onDialogClose: () => {
      dispatch(closeDialog())
    }
  }

  useEffect(() => {
    dispatch(fetchFacilitiesMasterAction())
  }, [])

  return <FacilitiesMasterPage {..._props} />

}