import React, { useEffect, useState } from "react";
import { AppState } from "../types/stateTypes";
import { useSelector, useDispatch } from "react-redux";
import { CustomThunkDispatch } from "../types/dispatchTypes";
import { DropinReservationSearchCondition } from "dataObjects/space";
import {
  fetchDropinReservationList
} from "actions/dropinReservationAction";
import { Values, Errors, convertFormInitialValues } from "types/formTypes";
import ReservationSearchForm, { validate } from "../components/Reserve/ReservationSearchForm";
import { SubmissionError, stopAsyncValidation } from "redux-form";
import { ValidationError } from "errors/RequestValidationError";

interface OwnProps {
}

export type DropInReservationSearchFromContainerProps = OwnProps;

const appStateSelector = (state: AppState) => state

export default function DropInReservationSearchFromContainer(props: DropInReservationSearchFromContainerProps) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {

      const condition = {
        date_from: values['date_from'],
        date_to: values['date_to'],
        family_name: values['family_name'],
        given_name: values['given_name'],
        email: values['email']
      }

      ownDispatch(
        fetchDropinReservationList(
          condition,
          state.dropinReservationList.per_page,
          0
        )
      );
    }
  }

  useEffect(() => {
    console.log('DropInReservationSearchFromContainer::useEffect')
    ownDispatch(
      fetchDropinReservationList(state.dropinReservationListCondition)
    );
  }, []);

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if(isDisplay === false) {
      console.log({errors})     
        
      const convErrors = []
      Object.keys(errors).map((key) => {
        convErrors[key] = errors[key][key]
      })      
      errors = convErrors

      const regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
      const toTouch = []            
      const keyReducer = (err, result) => {
        if(err) {
          Object.keys(err).map((key) => {
            if(regexp.test(key)) {
              result[key] = []
              keyReducer(err[key], result[key])
            } else {
              if(typeof err[key] == 'string') {
                result.push(key)
              } else {
                keyReducer(err[key], result)
              }
            }
          })  
        }
      }
      keyReducer(errors, toTouch)
      touch(...toTouch)
 
      ownDispatch(stopAsyncValidation('ReservationSearchForm', errors))
      setIsDisplay(true)
    }
  }  

  const _props = {
    onSubmit: submit,
    validationResult: state.ss422ValidationErrorResult,
    ssValidation: showValidationError,
    initialValues: convertFormInitialValues<DropinReservationSearchCondition>(
      state.dropinReservationListCondition
    )
  }

  return <ReservationSearchForm {..._props} />

}
