import React, { useEffect } from 'react'
import { routes } from '../routes/Route';
import { RouteComponentProps } from 'react-router-dom'
import { push } from "connected-react-router"
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import SpaceEditPage, { SpaceEditPageProps } from '../pages/SpaceEditPage'
import { observeShownAction } from 'actions/pagingAction';
import Slider from 'react-slick'
import moment from 'moment';
import { fetchMasterAction } from 'actions/masterAction';
import { closeDialog } from 'actions/dialogAction';


interface OwnProps {
}

export type SpaceEditPageContainerProps = OwnProps & RouteComponentProps<{space_id?: string}>

const appStateSelector = (state: AppState) => state

export default function SpaceEditPageContainer(props: SpaceEditPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()
  
  const _props: SpaceEditPageProps = {
    updateDialogOpen: state.page.updateDialogOpen,
    rejectDialogOpen: state.page.rejectDialogOpen,
    onDialogClose: () => {
      dispatch(closeDialog())
    },
    onBack: () => {
      dispatch(push(routes.spaceList.path))
    }    
  }

  useEffect(() => {
    dispatch(fetchMasterAction())
    dispatch(observeShownAction())
  }, [])

  return <SpaceEditPage {..._props} />

}