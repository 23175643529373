import React from "react";
import {
  Field,
  FieldArray,
  reduxForm,
  WrappedFieldProps,
  WrappedFieldArrayProps,
  InjectedFormProps,
  GenericFieldArray,
  FieldArrayFieldsProps
} from "redux-form";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles
} from "@material-ui/core/styles";
import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {
  renderTextField,
  renderRadio,
  renderFormHelper,
  renderCheckBox,
  renderDateCalendarField,
  renderSelect,
  renderTimeField,
  renderFile,
  renderSwitch
} from "../renderFormControl";
import { Values, Errors } from "../../types/formTypes";
import { required, maxLength, number, requiredArray } from "../../utils/validation";
import { fontWeight } from "@material-ui/system";
import { SpaceUsage, TermOfService } from "dataObjects/space";
import { Usage, Facility, TaxRate, TaxRateType } from "dataObjects/masters";

import RadioGroup from '@material-ui/core/RadioGroup';

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import DirectionsSubwayIcon from "@material-ui/icons/DirectionsSubway";
import PowerIcon from "@material-ui/icons/Power";
import SignalWifi4BarLockIcon from "@material-ui/icons/SignalWifi4BarLock";
import VideocamIcon from "@material-ui/icons/Videocam";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import WcIcon from "@material-ui/icons/Wc";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import SmokingRoomsIcon from "@material-ui/icons/SmokingRooms";
import SmokeFreeIcon from "@material-ui/icons/SmokeFree";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import HotelIcon from "@material-ui/icons/Hotel";
import DeckIcon from "@material-ui/icons/Deck";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import AccessibleIcon from "@material-ui/icons/Accessible";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AirlineSeatLegroomReducedIcon from '@material-ui/icons/AirlineSeatLegroomReduced';
import AirplayIcon from '@material-ui/icons/Airplay';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import Forward5Icon from '@material-ui/icons/Forward5';
import Forward10Icon from '@material-ui/icons/Forward10';
import Forward30Icon from '@material-ui/icons/Forward30';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import HdIcon from '@material-ui/icons/Hd';
import SurroundSoundIcon from '@material-ui/icons/SurroundSound';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import WeekendIcon from '@material-ui/icons/Weekend';

import { Paper, Tabs, Tab, Divider, MenuItem, Icon, Input } from "@material-ui/core";
import { a11yProps, TabPanel } from "components/Tab/TabPanel";
import { ValidationError } from "errors/RequestValidationError";
import { iconList } from "utils/MaterialUIIcnos";
import { dateToString } from "utils/convertor";
import { isNumber } from "util";


interface StyleProps {
  root: BaseCSSProperties;
  formContainer: BaseCSSProperties;
  subtitle: BaseCSSProperties;
  detail: BaseCSSProperties;
  submitButton: BaseCSSProperties;
  bottomNav: BaseCSSProperties;
  bottomNavContent: BaseCSSProperties;
  bottomNavli: BaseCSSProperties;
  notice: BaseCSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(2),
      flexGrow: 1,
      boxShadow: 'none',
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      width: "100%",
      borderRadius: "3px",
      fontSize: "1.3rem",
      lineHeight: 1.3,
      "& dl": {
        display: "flex",
        flexWrap: "wrap",
        marginTop: 0,
        marginBottom: 0,
        width: "100%",
        height: "120px",
        alignItems: "center"
      },
      "& dt": {
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        width: "20%",
        height: "100%",
        paddingLeft: "10px",
        borderBottom: "1px solid #f0f0f0",
        "& dt:last-child": {
          borderBottom: "none"
        }
      },
      "& dd": {
        marginLeft: "0",
        paddingLeft: "40px",
        display: "inline-block",
        alignItems: "center",
        width: "70%",
        height: "100%",
        borderBottom: "1px solid #f0f0f0",
        "& dd:last-child": {
          borderBottom: "none"
        }
      },
      [theme.breakpoints.down("sm")]: {
        "& dl": {
          flexFlow: "column nowrap",
          height: "150px"
        },
        "& dt": {
          backgroundColor: "#ffffff",
          paddingLeft: "10px",
          width: "100%",
          height: "80px",
          borderBottom: "1px solid #fefefe"
        },
        "& dd": {
          width: "100%",
          paddingLeft: "10px"
        }
      }
    },
    cardGrid: {
      textAlign: "center"
    },
    subtitle: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fff',      
      borderRadius: 5
    },
    centensetitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subsubtitle: {
      marginTop: theme.spacing(5),
      fontSize: "1.5rem",
      paddingBottom: theme.spacing(2),
      borderBottom: "1px solid #cecece"
    },
    description: {
      fontWeight: "normal",
      fontSize: "1.3rem",
      lineHeight: 1.4,
      marginTop: theme.spacing(2)
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: "50px",
      marginBottom: "20px",
      fontWeight: "bold",
      fontSize: "1.4rem"
    },
    notice: {
      marginBottom: "180px"
    },
    inputTextField25: {
      width: "25%",
      marginRight: "15px",
      [theme.breakpoints.down("sm")]: {
        width: "40%"
      }
    },
    inputTextField50: {
      width: "50%",
      marginRight: "15px",
      [theme.breakpoints.down("sm")]: {
        width: "70%"
      }
    },
    inputTextField80: {
      width: "80%",
      marginRight: "15px",
      [theme.breakpoints.down("sm")]: {
        width: "90%"
      }
    }
  })
);

export const validate = (values: Values) => {
  const errors: Errors = {};
  const topLevelRequiredFields = [
    'space',
    'space_usage',
    'space_facilities',
    'space_access',    
    'space_rental_plan',
    'space_business_dayofweek',    
    'space_dropin_pricing',
    'space_regular_pricing',
    'space_term_of_service'
  ];
  topLevelRequiredFields.forEach(field => {
    console.log(`${field}: ${values[field]}`);
    if (!required(values[field])) {
      errors[field] = "必須項目です";
    }
  });
  const spaceRequiredFields = [
    'name',
    'address',
    'area',
    'capacity',
    'around',
    'enter_and_leave_method',
    'description'
  ]
 
  if(values.space) {    
    const memberErrors: { [key: string]: string } = {};
    spaceRequiredFields.forEach(field => {
      console.log(`${field}: ${values.space[field]}`);
      if (!required(values.space[field])) {              
        memberErrors[field] = "必須項目です"
      }
    })
    errors.space = memberErrors
  }

  const space_usage_errors = requiredArray(values.space_usage, ['usage'])
  if (space_usage_errors.length > 0) {
    errors.space_usage = space_usage_errors
  }
  const space_facilities_errors = requiredArray(values.space_facilities, ['facility_id'])
  if (space_facilities_errors.length > 0) {
    errors.space_facilities = space_facilities_errors
  }
  const space_access_errors = requiredArray(values.space_access, ['type', 'description'])
  if (space_access_errors.length > 0) {
    errors.space_access = space_access_errors
  }
  const space_faq_errors = requiredArray(values.space_faq, ['question', 'answer'])
  if (space_faq_errors.length > 0) {
    errors.space_faq = space_faq_errors
  }
  const space_key_box_pass_errors = requiredArray(values.space_key_box_pass, ['enable_from', 'enable_to', 'pass'])
  if (space_key_box_pass_errors.length > 0) {
    errors.space_key_box_pass = space_key_box_pass_errors
  }
  const space_images_errors = requiredArray(values.space_images, ['parts_type'])
  if (space_images_errors.length > 0) {
    errors.space_images = space_images_errors
  }
  const space_rental_plan_errors = requiredArray(values.space_rental_plan, ['period_unit', 'at_least_reserve_unit'])
  if (space_rental_plan_errors.length > 0) {
    errors.space_rental_plan = space_rental_plan_errors
  }
  const space_business_dayofweek_errors = requiredArray(values.space_business_dayofweek, ['day_of_week', 'opening_time', 'closing_time'])
  if (space_business_dayofweek_errors.length > 0) {
    errors.space_business_dayofweek = space_business_dayofweek_errors
  }
  const space_space_closeday_errors = requiredArray(values.space_space_closeday, ['day', 'start_time', 'end_time'])
  if (space_space_closeday_errors.length > 0) {
    errors.space_space_closeday = space_space_closeday_errors
  }
  const space_dropin_pricing_errors = requiredArray(values.space_dropin_pricing, ['day_of_week', 'start_time', 'end_time', 'price'])
  if (space_dropin_pricing_errors.length > 0) {
    errors.space_dropin_pricing = space_dropin_pricing_errors
  }
  const space_dropin_specific_day_pricing_errors = requiredArray(values.space_dropin_specific_day_pricing, ['day', 'start_time', 'end_time', 'price'])
  if (space_dropin_specific_day_pricing_errors.length > 0) {
    errors.space_dropin_specific_day_pricing = space_dropin_specific_day_pricing_errors
  }
  const space_regular_pricing_errors = requiredArray(values.space_regular_pricing, ['day_of_week', 'start_time', 'end_time', 'price'])
  if (space_regular_pricing_errors.length > 0) {
    errors.space_regular_pricing = space_regular_pricing_errors
  }
  const space_regular_specific_day_pricing_errors = requiredArray(values.space_regular_specific_day_pricing, ['day', 'start_time', 'end_time', 'price'])
  if (space_regular_specific_day_pricing_errors.length > 0) {
    errors.space_regular_specific_day_pricing = space_regular_specific_day_pricing_errors
  }

  return errors
}




  /**
   * 禁止事項
   * @param props 
   */
  const renderProhibitedMatter = (props: WrappedFieldArrayProps) => {
    const { error } = props.meta
    const { fields } = props

    return (
      <ul>
        <ul>
          {fields.map(renderProhibitedMatterFields)}
        </ul>

        <li>
          <Grid container spacing={2} justify="center">
            <Grid item xs={4}>
              <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
                <Icon color="primary">add_circle</Icon>&nbsp;禁止事項 追加
            </Button>
              {error && <span>{error}</span>}
            </Grid>
          </Grid>
        </li>
      </ul>
    )
  }

  const renderProhibitedMatterFields = (prohibited, index, fields) => (
    <li key={index} className={'list-group-item'}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <span style={{marginRight: '10px'}}>禁止事項 {index + 1}</span>
        <Field
          name={`${prohibited}.prohibited_matter`}
          type="text"
          component={renderTextField}
          label="禁止事項"
          multiline
          rows="5"
          rowsMax="10"
          margin="normal"
          variant="outlined"
          style={{width: '600px'}}
        />
        <Button type="button" variant="outlined" color="secondary" style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )

  /**
   * 利用用途フィールド生成
   * 
   * @param usage 
   * @param index 
   * @param fields 
   */
  const renderUsageFields = (usage, index, fields) => {
    return (
      <li key={index} className={'list-group-item'}>
        <div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
          <span style={{ width: '10%', marginLeft: '24px', marginTop: '16px', marginBottom: '8px', marginRight: '10px'  }} >用途 {index + 1}</span>
          <Field
            component={Input}
            name={`${usage}.id`}
            type="hidden"
            style={{ height: 0, width: 0 }}
          />
          <Field
            component={Input}
            name={`${usage}.space_id`}
            type="hidden"
            style={{ height: 0, width: 0}}
          />
          <div style={{width: '70%', marginLeft: '24px'}}>
            <Field name={`${usage}.usage`} label='用途' component={renderSelect} required style={{width: '30%', marginTop: '8px', marginBottom: '8px'}}>
              {
                
                outMasters.usage.map((usg) => {
                  return (
                    <MenuItem value={usg.usage}>{usg.usage}</MenuItem>
                  )
                })
              }
            </Field>
          </div>
          <Button type="button" variant="outlined" color="secondary" style={{ marginTop: '16px', marginLeft: '10px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
        </div>
        <Divider></Divider>
      </li>
    )
  }

  /**
   * 利用用途生成
   * 
   * @param props 
   */
  const renderUsageList = (props: WrappedFieldArrayProps) => {
    const { error } = props.meta
    const { fields } = props

    return (
      <ul>
        <ul>
          {fields.map(renderUsageFields)}
        </ul>
        <li>
          <Grid container spacing={2} justify="center">
            <Grid item xs={4}>
              <Button fullWidth variant="outlined" color="secondary" type="button" style={{height: '50px', fontSize: '1.4rem', marginTop: '8px'}} onClick={() => fields.push({})}>
                <Icon color="primary">add_circle</Icon>&nbsp;追加
              </Button>
              {error && <span>{error}</span>}
            </Grid>
          </Grid>
        </li>        
      </ul>
    )
  }
  
  /**
   * 設備フィールド生成
   * 
   * @param facility 
   * @param index 
   * @param fields 
   */
  const renderFacilityFields = (facility, index, fields) => {
    return (
      <li key={index} className={'list-group-item'}>
        <div style={{ display: 'flex', justifyContent: 'start', width: '100%' }}>
          <span style={{ width: '10%', marginLeft: '24px', marginTop: '16px', marginBottom: '8px', marginRight: '10px'  }} >用途 {index + 1}</span>
          <Field
            component={Input}
            name={`${facility}.id`}
            type="hidden"
            style={{ height: 0, width: 0 }}
          />
          <Field
            component={Input}
            name={`${facility}.space_id`}
            type="hidden"
            style={{ height: 0, width: 0 }}
          />
          <div style={{width: '70%', marginLeft: '24px'}}>
            <Field name={`${facility}.facility_id`} label='設備' component={renderSelect} required style={{width: '30%', marginTop: '8px', marginBottom: '8px'}}>
              {              
                outMasters.facilities.map((f) => {
                  const Tag = iconList[f.icon]
                  const iconDiv = (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Tag />
                      &nbsp;&nbsp;{f.name}
                    </div>
                  )
                  return (
                    <MenuItem value={f.id} style={{width: '100%'}}>{iconDiv}</MenuItem>
                  )
                })
              }
            </Field>
          </div>

          <Button type="button" variant="outlined" color="secondary" style={{ marginTop: '16px', marginLeft: '10px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
        </div>
        <Divider></Divider>
      </li>
    )
  }

  /**
   * 設備生成
   * 
   * @param props 
   */
  const renderFacilityList = (props: WrappedFieldArrayProps) => {
    const { error } = props.meta
    const { fields } = props

    return (
      <ul>
        <ul>
          {fields.map(renderFacilityFields)}
        </ul>
        <li>
          <Grid container spacing={2} justify="center">
            <Grid item xs={4}>
              <Button fullWidth variant="outlined" color="secondary" type="button" style={{height: '50px', fontSize: '1.4rem', marginTop: '8px'}} onClick={() => fields.push({})}>
                <Icon color="primary">add_circle</Icon>&nbsp;追加
              </Button>
              {error && <span>{error}</span>}
            </Grid>
          </Grid>
        </li>
      </ul>
    )
  }

  

  /**
   * アクセス方法
   * @param param0 
   */
  const renderAccessInfo = (props: WrappedFieldArrayProps & OwnProps) => {
    const { error } = props.meta
    const { fields } = props

    return (
      <ul>
        {fields.map((member, index) => (
          <li key={index}>            
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <h4>アクセス #{index + 1}</h4>
              <div>
                <Field
                  component={Input}
                  name={`${member}.id`}
                  type="hidden"
                  style={{ height: 0, width: 0 }}
                />
                <Field
                  component={Input}
                  name={`${member}.space_id`}
                  type="hidden"
                  style={{ height: 0, width: 0}}
                />
                <Field name={`${member}.type`} component={renderRadio}>
                  <FormControlLabel
                    key={`${member}.train`}
                    value="train"
                    control={<Radio />}
                    label="電車"
                  />
                  <FormControlLabel
                    key={`${member}.car`}
                    value="car"
                    control={<Radio />}
                    label="自動車"
                  />
                </Field>
                
              </div>
              <div>
                <Field
                  name={`${member}.description`}
                  type="text"
                  component={renderTextField}
                  label="アクセス"
                  margin="normal"
                  variant="outlined"
                  multiline="true"
                  rowsMax="4"
                  rows="1"
                  style={{width: '500px'}}
                />
              </div>
              <Button type="button" variant="outlined" color="secondary" style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
            </div>
            <Divider></Divider>
          </li>
        ))}

        <li>
          <Grid container spacing={2} justify="center">
            <Grid item xs={4}>
              <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
                <Icon color="primary">add_circle</Icon>&nbsp;アクセス方法 追加
            </Button>
              {error && <span>{error}</span>}
            </Grid>
          </Grid>
        </li>
      </ul>
    )
  }

  /**
   * FAQ
   * @param param0 
   */
  const renderQA = (props: WrappedFieldArrayProps & OwnProps) => {
    const { error } = props.meta
    const { fields } = props

    return (
      <ul>
        {fields.map((member, index) => (
          <li key={index}>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
              <h4>QA #{index + 1}</h4>
              <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                <div>
                  <Field
                    component={Input}
                    name={`${member}.id`}
                    type="hidden"
                    style={{ height: 0, width: 0 }}
                  />
                  <Field
                    component={Input}
                    name={`${member}.space_id`}
                    type="hidden"
                    style={{ height: 0, width: 0}}
                  />
                  <Field
                    name={`${member}.question`}
                    type="text"
                    component={renderTextField}
                    label="質問"
                    margin="normal"
                    variant="outlined"
                    multiline="true"
                    rowsMax="10"
                    rows="5"
                    style={{width: '100%'}}
                  />
                </div>
                <div>
                  <Field
                    name={`${member}.answer`}
                    type="text"
                    component={renderTextField}
                    label="回答"
                    margin="normal"
                    variant="outlined"
                    multiline="true"
                    rowsMax="10"
                    rows="5"
                    style={{width: '100%'}}
                  />
                </div>
              </div>
              <Button type="button" variant="outlined" color="secondary" style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
            </div>
            <Divider></Divider>
          </li>
        ))}

        <li>
          <Grid container spacing={2} justify="center">
            <Grid item xs={4}>
              <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
                <Icon color="primary">add_circle</Icon>&nbsp;QA 追加
              </Button>
              {error && <span>{error}</span>}
            </Grid>
          </Grid>
        </li>
      </ul>
    )
  }
  
  const renderImagesField = (image, index, fields) => {
    const value = fields.get(index)
    const image_source = `${process.env.REACT_APP_API_SERVER_HOST}storage/images/space/${value.space_id}/${value.file}`
    
    return (
      <li key={index}>
        <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
          <h4>画像 #{index + 1}</h4>

          <div>
            <img src={image_source} style={{width: '200px', margin: '8px'}} />
            <Field
                component={Input}
                name={`${image}.id`}
                type="hidden"
                style={{ height: 0, width: 0 }}
              />
            <Field
              component={Input}
              name={`${image}.space_id`}
              type="hidden"
              style={{ height: 0, width: 0}}
            />
            <Field
              name={`${image}.title`}
              type="text"
              component={renderTextField}
              label="タイトル"
              rowsMax="1"
              margin="normal"
              variant="outlined"
              style={{width: '200px'}}
            />
          </div>
          <div>
            <Field name={`${image}.parts_type`} label='画像区分' component={renderSelect} required >
              {
                [
                  { name: 'メイン', id: '1' },
                  { name: 'サブ', id: '2' }
                ].map((type) => {
                  return (
                    <MenuItem value={type.id}>{type.name}</MenuItem>
                  )
                })
              }
            </Field>
          </div>
          <div>
            <Field
              name={`${image}.image_file`}
              label='スペース画像'
              accept='image/*'
              component={renderFile}
            />
          </div>
          <Button type="button" variant="outlined" color="secondary" style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
        </div>
        <Divider></Divider>
      </li>
    )
  }

/**
   * 画像
   * @param param0 
   */
const renderImages = (props: WrappedFieldArrayProps & OwnProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>
      {fields.map(renderImagesField)}

      <li>
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;画像 追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>
      </li>
    </ul>
  )
}

const renderTermsOfService = ({
  input: { value, name, onChange },
  label,
  meta: { touched, error },
  classes,
  onFieldChange,  
  accept = '*',  
  rootClass = '',
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', width: '100%' }}>
      <Field
        component={Input}
        name="space_term_of_service.id"
        type="hidden"
        style={{ height: 0, width: 0 }}
      />
      <Field
        component={Input}
        name="space_term_of_service.space_id"
        type="hidden"
        style={{ height: 0, width: 0 }}
      />
      <div style={{width: '100%',  marginLeft: '24px'}}>
        
        <Field name="space_term_of_service.terms_of_service_id" component={renderRadio} label="使用する規約を選択" required>
          {              
            outMasters.terms_of_services.map((f) => {
              return (
                <div style={{ width: '100%', height: '350px', marginTop: '24px' }}>
                  <FormControlLabel key={f.id} value={`${f.id}`} control={<Radio />} label={'規約 ' + f.id}/>
                  <div style={{ width: '100%', height: '300px', overflowY: 'scroll', whiteSpace: 'pre-line', fontWeight: 'normal', fontSize: '1.3rem', lineHeight: 1.4 }}>
                    {f.contents}
                  </div>
                </div>
              )
            })
          }
        </Field>
        
      </div>
      
    </div>
  )
}

/**
* 稼働スケジュールフィールド生成
* 
* @param tax 
* @param index 
* @param fields 
*/
const renderBusinessScheduleFields = (business, index, fields) => {

  return (
    <li key={index} className={'list-group-item'}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <span style={{ marginTop: '16px', marginBottom: '8px', marginRight: '10px' }} >稼働設定 {index + 1}</span>

        <Field name={`${business}.day_of_week`} label='曜日' component={renderSelect} required >
          {
            [
              { name: '日', id: '0' },
              { name: '月', id: '1' },
              { name: '火', id: '2' },
              { name: '水', id: '3' },
              { name: '木', id: '4' },
              { name: '金', id: '5' },
              { name: '土', id: '6' }
            ].map((dayOfWeek) => {
              return (
                <MenuItem value={dayOfWeek.id}>{dayOfWeek.name}</MenuItem>
              )
            })
          }
        </Field>

        <Field
          name={`${business}.opening_time`}
          type="text"
          component={renderTimeField}
          label="開始時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={lessThanTime(`${biweekly}.end_time`)}
          className="inputTextField25"
        />
        <Field
          name={`${business}.closing_time`}
          type="text"
          component={renderTimeField}
          label="終了時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={greaterThanTime(`${biweekly}.start_time`)}
          className="inputTextField25"
        />
        <Button type="button" variant="outlined" color="secondary" style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

/**
 * 稼働スケジュール生成
 * 
 * @param props 
 */
const renderBusinessSchedule = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>
      <ul>
        {fields.map(renderBusinessScheduleFields)}
      </ul>
      <li>
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;稼働スケジュール 追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>
      </li>
    </ul>
  )
}

/**
* 非稼働スケジュールフィールド生成
* 
* @param tax 
* @param index 
* @param fields 
*/
const renderCloseDayScheduleFields = (closeDay, index, fields) => {

  return (
    <li key={index} className={'list-group-item'}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <span style={{ marginTop: '16px', marginBottom: '8px', marginRight: '10px' }} >稼働設定 {index + 1}</span>

        <Field
          name={`${closeDay}.day`}
          type="text"
          component={renderDateCalendarField}
          label="非稼働日"
          rowsMax="1"
          margin="normal"
        />

        <Field
          name={`${closeDay}.start_time`}
          type="text"
          component={renderTimeField}
          label="休業開始時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={1}
          //normalize={lessThanTime(`${biweekly}.end_time`)}
          className="inputTextField25"
        />
        <Field
          name={`${closeDay}.end_time`}
          type="text"
          component={renderTimeField}
          label="休業終了時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={1}
          //normalize={greaterThanTime(`${biweekly}.start_time`)}
          className="inputTextField25"
        />
        <Button type="button" variant="outlined" color="secondary" style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

/**
 * 非稼働スケジュール生成
 * 
 * @param props 
 */
const renderCloseDaySchedule = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>
      <ul>
        {fields.map(renderCloseDayScheduleFields)}
      </ul>
      <li>
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;非稼働スケジュール 追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>
      </li>
    </ul>
  )
}

const getTaxIncludedPriceString : (value: any) => string = (value: any) => {
  const regexp = new RegExp(/^[-]?([1-9]\d*|0)$/);
  const today = new Date()
  const toString = Object.prototype.toString  
  const taxratetypes = outMasters.taxratetypes
  const tax = outMasters.taxrates.find(tax => {
    const rate_type = outMasters.taxratetypes.find(rateType => rateType.id === tax.tax_rate_type_id)
    const from = toString.call(tax.enable_from) == '[object Date]' ? tax.enable_from : dateToString(tax.enable_from).replace(/-/g,"/")
    const to = toString.call(tax.enable_to) == '[object Date]' ? tax.enable_to : dateToString(tax.enable_to).replace(/-/g,"/")
    return today >= (new Date(from)) && today <= (new Date(to)) && 1 == rate_type.id
  })
  const formatter = new Intl.NumberFormat('ja-JP')

  // 消費税計算して総額を算出    
  if (isNumber(value) || regexp.test(value)) {
    const price = parseInt(value)
    const rate = tax ? tax.rate : 0
    const t = Math.floor(price * rate)        
    return '￥' + formatter.format(price + t) + ' (税込)'
  }
  return ''
}

/**
* 金額フィールド生成
* 
* @param tax 
* @param index 
* @param fields 
*/
const renderPricingFields = (pricing, index, fields) => {
  const value = fields.get(index)
  // 消費税計算して総額文字列を算出
  value.tax_included_price = getTaxIncludedPriceString(value.price)
  

  return (
    <li key={index} className={'list-group-item'}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <span style={{ marginTop: '16px', marginBottom: '8px', marginRight: '10px' }} >金額設定 {index + 1}</span>

        <Field name={`${pricing}.day_of_week`} label='曜日' component={renderSelect} required >
          {
            [
              { name: '日', id: '0' },
              { name: '月', id: '1' },
              { name: '火', id: '2' },
              { name: '水', id: '3' },
              { name: '木', id: '4' },
              { name: '金', id: '5' },
              { name: '土', id: '6' }
            ].map((dayOfWeek) => {
              return (
                <MenuItem value={dayOfWeek.id}>{dayOfWeek.name}</MenuItem>
              )
            })
          }
        </Field>

        <Field
          name={`${pricing}.start_time`}
          type="text"
          component={renderTimeField}
          label="適用開始時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={lessThanTime(`${biweekly}.end_time`)}
          className="inputTextField25"
        />
        <Field
          name={`${pricing}.end_time`}
          type="text"
          component={renderTimeField}
          label="適用終了時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={greaterThanTime(`${biweekly}.start_time`)}
          className="inputTextField25"
        />
        <Field
          name={`${pricing}.price`}
          type="text"
          component={renderTextField}
          label="金額"
          rowsMax="1"
          margin="normal"
          normalize={number}
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {outChange(`${pricing}.tax_included_price`, getTaxIncludedPriceString(event.target.value))}}
        />
        <div style={{ width: '100px', marginTop: '34px', marginBottom: '8px', marginRight: '10px'}}><span>{value.tax_included_price}</span></div>
        <Button type="button" variant="outlined" color="secondary" style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

/**
 * 一時利用金額生成
 * 
 * @param props 
 */
const renderDropinPricing = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props
console.log({props})
  return (
    <ul>      
      <ul>
        {fields.map(renderPricingFields)}
      </ul>
      <li>      
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;一時利用金額 追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>      
      </li>
    </ul>
  )
}

/**
 * 定期利用金額生成
 * 
 * @param props 
 */
const renderRegularPricing = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>
      <ul>
        {fields.map(renderPricingFields)}
      </ul>
      <li>      
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;定期利用金額 追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>      
      </li>
    </ul>
  )
}

/**
* 特定日金額フィールド生成
* 
* @param tax 
* @param index 
* @param fields 
*/
const renderSpecificDayPricingFields = (pricing, index, fields) => {
  const value = fields.get(index)
  // 消費税計算して総額文字列を算出
  value.tax_included_price = getTaxIncludedPriceString(value.price)

  return (
    <li key={index} className={'list-group-item'}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <span style={{ marginTop: '16px', marginBottom: '8px', marginRight: '10px' }} >金額設定 {index + 1}</span>

        <Field
          name={`${pricing}.day`}
          type="text"
          component={renderDateCalendarField}
          label="適用日"
          rowsMax="1"
          margin="normal"
        />

        <Field
          name={`${pricing}.start_time`}
          type="text"
          component={renderTimeField}
          label="適用開始時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={lessThanTime(`${biweekly}.end_time`)}
          className="inputTextField25"
        />
        <Field
          name={`${pricing}.end_time`}
          type="text"
          component={renderTimeField}
          label="適用終了時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={greaterThanTime(`${biweekly}.start_time`)}
          className="inputTextField25"
        />
        <Field
          name={`${pricing}.price`}
          type="text"
          component={renderTextField}
          label="金額"
          rowsMax="1"
          margin="normal"
          normalize={number}
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {outChange(`${pricing}.tax_included_price`, getTaxIncludedPriceString(event.target.value))}}
        />
        <div style={{ width: '100px', marginTop: '34px', marginBottom: '8px', marginRight: '10px'}}><span>{value.tax_included_price}</span></div>
        <Button type="button" variant="outlined" color="secondary" style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

/**
 * 一時利用特定日金額生成
 * 
 * @param props 
 */
const renderDropinSpecificDayPricing = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>
      <ul>
        {fields.map(renderSpecificDayPricingFields)}
      </ul>
      <li>      
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;一時利用特定日金額 追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>      
      </li>
    </ul>
  )
}

/**
 * 定期利用特定日金額生成
 * 
 * @param props 
 */
const renderRegularSpecificDayPricing = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>
      <ul>
        {fields.map(renderSpecificDayPricingFields)}
      </ul>
      <li>      
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;定期利用特定日金額 追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>      
      </li>
    </ul>
  )
}

interface OwnProps {
  masters: {
    usage: Usage[],
    facilities: Facility[],
    terms_of_services: TermOfService[],
    taxrates: TaxRate[],
    taxratetypes: TaxRateType[] 
  },
  validationResult?: ValidationError,
  ssValidation?: (errors: ValidationError, touch: (...fields: string[]) => void) => void,
  specialError?: any,
}


export type SpaceEditFormPropsType = OwnProps &
  InjectedFormProps<{}, OwnProps>;


const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

let outMasters = null
let outChange: (field: string, value: any) => any

const Form: React.FC<SpaceEditFormPropsType> = (
  props: SpaceEditFormPropsType
) => {
  const classes = useStyles({} as StyleProps);
  const { handleSubmit, pristine, submitting, reset, masters, validationResult, ssValidation, specialError, touch, change } = props;
  const [ tabValue, setTabValue] = React.useState(0);

  outMasters = masters
  outChange = change

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  }

  if (validationResult && validationResult.status != '') {
    if (ssValidation) {      
      ssValidation(validationResult.errors, touch)
    }
  }

  /**
   * render
   */
  return (
    <>
      <Paper className={classes.root}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab value={0} label="基本情報" {...a11yProps(0)} />
          <Tab value={1} label="稼働設定" {...a11yProps(1)} />
          <Tab value={2} label="金額設定" {...a11yProps(2)} />
        </Tabs>
      </Paper>

      <form onSubmit={handleSubmit}>
        <TabPanel value={tabValue} index={0} >
          <div className={classes.formContainer}>
            <dl>
              <dt>スペース名 (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="space.name"
                    component={renderTextField}
                    label="スペース名"                    
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="1"
                    rowsMax="2"
                    required
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
            <dl style={{height: '200px'}}>
              <dt>住所 (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="space.address"
                    component={renderTextField}
                    label="スペース住所"                    
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="5"
                    rowsMax="7"
                    required
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>広さ (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="space.area"
                    component={renderTextField}
                    label="広さ (㎡ etc)"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="2"
                    rowsMax="2"
                    required
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>収容人数 (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="space.capacity"
                    component={renderTextField}
                    label="収容人数"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="2"
                    rowsMax="2"
                    required
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
            <dl style={{height: '200px'}}>
              <dt>周辺情報 (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="space.around"
                    component={renderTextField}
                    label="周辺情報"
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="5"
                    rowsMax="5"
                    required
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
            <dl style={{ height: '600px' }}>
              <dt>スペース説明 (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="space.description"
                    component={renderTextField}
                    label="説明文"
                    multiline
                    rows="30"
                    rowsMax="30"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
            <dl style={{height: '200px'}}>
              <dt>入退室方法 (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="space.enter_and_leave_method"
                    component={renderTextField}
                    label="入退室方法"
                    multiline
                    ros="4"
                    rowsMax="4"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>地図中心点 (任意)</dt>
              <dd>
                <div>
                  <Field
                    name="space.map_lat"
                    component={renderTextField}
                    label="緯度座標"
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    className={classes.inputTextField25}
                  />
                  <Field
                    name="space.map_lng"
                    component={renderTextField}
                    label="経度座標"
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    className={classes.inputTextField25}
                  />
                </div>
              </dd>
            </dl>

            <Typography variant="h3" className={classes.subtitle}>
              利用用途 (必須)
            </Typography>

            <div>
              <FieldArrayCustom
                name="space_usage"
                component={renderUsageList}
                required
              />
              {specialError && specialError['space_usage'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_usage']}</span>}
            </div>
              
            
            <Typography variant="h3" className={classes.subtitle}>
              設備 (必須)
            </Typography>

            <div>
              <FieldArrayCustom
                name="space_facilities"
                component={renderFacilityList}
                required
              />
              {specialError && specialError['space_facilities'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_facilities']}</span>}
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              禁止事項 (任意)
            </Typography>
              
            <div>
              <FieldArrayCustom
                name="space_prohibited_matter"
                component={renderProhibitedMatter}
              />
              {specialError && specialError['space_prohibited_matter'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_prohibited_matter']}</span>}
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              アクセス方法 (必須)
            </Typography>
                            
            <div>
              <FieldArrayCustom
                name="space_access"
                component={renderAccessInfo}
                required
              />
              {specialError && specialError['space_access'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_access']}</span>}
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              よくある質問 (必須)
            </Typography>

            <div>
              <FieldArrayCustom
                name="space_faq"
                component={renderQA}
                required
              />
              {specialError && specialError['space_faq'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_faq']}</span>}
            </div>
              
            <Typography variant="h3" className={classes.subtitle}>
              スペース画像 (任意)
            </Typography>
              
            <div>
              <FieldArrayCustom
                name="space_images"
                component={renderImages}
              />
              {specialError && specialError['space_images'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_images']}</span>}
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              利用規約 (必須)
            </Typography>

            <div>
              <Field
                name="space_term_of_service"
                component={renderTermsOfService}
              />              
            </div>


              
          </div>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <div className={classes.formContainer}>
            <Typography variant="h3" className={classes.centensetitle}>
              レンタル条件/営業スケジュール
            </Typography>

            <Typography variant="h3" className={classes.subtitle}>
              レンタル条件 (必須)
            </Typography>

            <dl>
              <dt>レンタル枠 時間単位(分)</dt>
              <dd>
                <div>
                  <Field
                    name="space_rental_plan.period_unit"
                    component={renderTextField}
                    label="利用時間単位を分単位で設定"
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    normalize={number}
                    className={classes.inputTextField25}
                  />
                </div>
              </dd>
            </dl>

            <dl>
              <dt>最低利用枠数</dt>
              <dd>
                <div>
                  <Field
                    name="space_rental_plan.at_least_reserve_unit"
                    component={renderTextField}
                    label="最低利用枠数を数値で設定"
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    normalize={number}
                    className={classes.inputTextField25}
                  />
                </div>
              </dd>
            </dl>

            <Typography variant="h3" className={classes.subtitle}>
              営業日時設定 (必須)
            </Typography>
              
            <div>
              <FieldArrayCustom
                name="space_business_dayofweek"
                component={renderBusinessSchedule}
                required
              />
              {specialError && specialError['space_business_dayofweek'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_business_dayofweek']}</span>}
            </div>
            
            
          </div>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          <div className={classes.formContainer}>
            <Typography variant="h3" className={classes.centensetitle}>
              金額設定
            </Typography>

            <Typography variant="h3" className={classes.subtitle}>
              一時利用金額 (必須)
            </Typography>

            <div>
              <FieldArrayCustom
                name="space_dropin_pricing"
                component={renderDropinPricing}
                required
              />
              {specialError && specialError['space_dropin_pricing'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_dropin_pricing']}</span>}
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              一時利用 特定日金額 (任意)
            </Typography>

            <div>
              <FieldArrayCustom
                name="space_dropin_specific_day_pricing"
                component={renderDropinSpecificDayPricing}
              />
              {specialError && specialError['space_dropin_specific_day_pricing'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_dropin_specific_day_pricing']}</span>}
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              定期利用金額 (任意)
            </Typography>

            <div>
              <FieldArrayCustom
                name="space_regular_pricing"
                component={renderRegularPricing}
              />
              {specialError && specialError['space_regular_pricing'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_regular_pricing']}</span>}
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              定期利用 特定日金額 (任意)
            </Typography>

            <div>
              <FieldArrayCustom
                name="space_regular_specific_day_pricing"
                component={renderRegularSpecificDayPricing}
              />
              {specialError && specialError['space_regular_specific_day_pricing'] && <span style={{color: '#f44336', fontSize: '1.4rem' }}>{specialError['space_regular_specific_day_pricing']}</span>}
            </div>

          </div>
        </TabPanel>

        <Grid container spacing={2} justify="center" style={{ marginTop: '30px'}}>
          <Grid item xs={4}>
            <div style={{width: '100%', display: 'flex', justifyContent: "center"}}>
              <Field
                name="space.reservation_stop"
                component={renderSwitch}
                label="予約停止 ON/OFF"              
                margin="normal"              
                required
              />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} justify="center" style={{ marginTop: '100px'}}>
          <Grid item xs={4}>
            <Button
                type="submit"
                disabled={pristine || submitting}
                fullWidth
                className={classes.submitButton}
              >
              登録する
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button
                type="button"
                disabled={pristine || submitting}
                fullWidth 
                variant="outlined" 
                color="secondary" 
                style={{height: '50px'}}
                onClick={reset}
              >
              クリア
            </Button>
          </Grid>
        </Grid>

      </form>
    </>
  );
};

const SpaceEditForm = reduxForm({
  form: "SpaceEditForm",
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(Form);

export default SpaceEditForm;
