import { ActionTypes } from '../actions/actionTypes'
import { NewsSearchCondition } from '../dataObjects/news';
import {
  STORE_NEWS_CONDITION
} from '../actions/newsAction'


export interface NewsSearchConditionState extends NewsSearchCondition {

}

const initialNewsSearchConditionState: NewsSearchConditionState = {
  publish_date_from: null,
  publish_date_to: null,
  title: ''
}


/* newsListCondition Reducer */
const newsListCondition = (
  state: NewsSearchConditionState = initialNewsSearchConditionState,
  action: ActionTypes,
): NewsSearchConditionState => {
  switch (action.type) {
    // おしらせ 検索条件
    case STORE_NEWS_CONDITION:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default newsListCondition
