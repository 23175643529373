import React from 'react'
import {
  Field,
  FieldArray,
  reduxForm,
  WrappedFieldProps,
  WrappedFieldArrayProps,
  InjectedFormProps,
  GenericFieldArray,
  FieldArrayFieldsProps
} from "redux-form";
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import moment from 'moment'

import { renderTextField, renderSelect, renderFormHelper, renderSwitch, renderDateCalendarField, renderTimeField, renderFile } from '../renderFormControl'
import { Values, Errors } from '../../types/formTypes'
import { required, maxLength, digit, lessThanDateTime, greaterThanDateTime, isDate, lessThanTime, greaterThanTime, number, datetime, requiredArray, validateLessThanDateTime } from "../../utils/validation";
import { fontWeight } from '@material-ui/system';
import { SpaceUsage, TermOfService, SpaceListHeader, VacancyCheckedPeriod } from 'dataObjects/space';
import { Tabs, Paper, Tab, Divider, MenuItem, Input, Icon } from '@material-ui/core';
import { a11yProps, TabPanel } from 'components/Tab/TabPanel';
import { ValidationError } from 'errors/RequestValidationError';
import { timeString, dateToString, dayOfWeeksJp } from 'utils/convertor';


interface StyleProps {
  root: BaseCSSProperties,
  formContainer: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  detail: BaseCSSProperties,
  submitButton: BaseCSSProperties,
  bottomNav: BaseCSSProperties,
  bottomNavContent: BaseCSSProperties,
  bottomNavli: BaseCSSProperties,
  notice: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      width: '100%'      
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),      
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#ffffff',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fff',      
      borderRadius: 5
    },
    centensetitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
      lineHeight: 1.4,
      marginTop: theme.spacing(2)
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
    deleteButton: {
      color: '#FFF',
      backgroundColor: '#fa2120',
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
    notice: {
      marginBottom: '180px',
    },
    addressSearchButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      fontSize: '1.4rem',
      height: '52px',
      marginTop: '16px'
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }, table: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
      fontSize: '1.5rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }
      },
      '& tbody': {
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }

      }
    },
  })
)

/**
 * 日次スケジュールフィールド生成
 * 
 * @param tax 
 * @param index 
 * @param fields 
 */
const renderEverydayScheduleFields = (everyday, index, fields) => {
  
  return (
    <li key={index} className={'list-group-item'}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <span style={{ marginTop: '16px', marginBottom: '8px', marginRight: '10px'  }} >日次定時 {index + 1}</span>
        <Field
          component={Input}
          name={`${everyday}.id`}
          type="hidden"
          style={{ height: 0 }}
        />
        <Field
          name={`${everyday}.start_time`}
          type="text"
          component={renderTimeField}
          label="開始時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={lessThanTime(`${everyday}.end_time`)}
          className="inputTextField25"
        />
        <Field
          name={`${everyday}.end_time`}
          type="text"
          component={renderTimeField}
          label="終了時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={greaterThanTime(`${everyday}.start_time`)}
          className="inputTextField25"
        />
        <Button type="button" variant="outlined" color="secondary" style={{ marginTop: '16px', marginLeft: '10px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

/**
 * 日次スケジュール生成
 * 
 * @param props 
 */
const renderEverydaySchedule = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>
      <ul>
        {fields.map(renderEverydayScheduleFields)}
      </ul>
      <li>
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{height: '50px', fontSize: '1.4rem', marginTop: '8px'}} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>
      </li>
      <li>
        {error && <span>{error}</span>}
      </li>
    </ul>
  )
}


/**
 * 週次スケジュールフィールド生成
 * 
 * @param tax 
 * @param index 
 * @param fields 
 */
const renderBiweeklyScheduleFields = (biweekly, index, fields) => {
  
  return (
    <li key={index} className={'list-group-item'}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <span style={{ marginTop: '16px', marginBottom: '8px', marginRight: '10px'  }} >週次定時 {index + 1}</span>
        <Field
          component={Input}
          name={`${biweekly}.id`}
          type="hidden"
          style={{ height: 0 }}
        />
        <Field name={`${biweekly}.day_of_week`} label='曜日' component={renderSelect} required style={{minWidth: '80px'}}>
          {
            [
              { name: '日', id: '0' }, 
              { name: '月', id: '1' }, 
              { name: '火', id: '2' },
              { name: '水', id: '3' },
              { name: '木', id: '4' },
              { name: '金', id: '5' },
              { name: '土', id: '6' }
            ].map((dayOfWeek) => {
              return (
                <MenuItem value={dayOfWeek.id}>{dayOfWeek.name}</MenuItem>
              )
            })
          }
        </Field>

        <Field name={`${biweekly}.interval`} label='隔週設定' component={renderSelect} required style={{minWidth: '80px'}}>
          {
            [
              { name: '毎週', id: '0' }, 
              { name: '1週おき', id: '1' }, 
              { name: '2週おき', id: '2' },
              { name: '3週おき', id: '3' },
              { name: '4週おき', id: '4' }
            ].map((interval) => {
              return (
                <MenuItem value={interval.id}>{interval.name}</MenuItem>
              )
            })
          }
        </Field>

        <Field
          name={`${biweekly}.start_time`}
          type="text"
          component={renderTimeField}
          label="開始時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={lessThanTime(`${biweekly}.end_time`)}
          className="inputTextField25"
        />
        <Field
          name={`${biweekly}.end_time`}
          type="text"
          component={renderTimeField}
          label="終了時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={greaterThanTime(`${biweekly}.start_time`)}
          className="inputTextField25"
        />
        <Button type="button" variant="outlined" color="secondary" style={{ marginTop: '16px', marginLeft: '10px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

/**
 * 週次スケジュール生成
 * 
 * @param props 
 */
const renderBiweeklySchedule = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>
      <ul>
        {fields.map(renderBiweeklyScheduleFields)}
      </ul>
      <li>
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{height: '50px', fontSize: '1.4rem', marginTop: '8px'}} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>
      </li>
      <li>
        {error && <span>{error}</span>}
      </li>
    </ul>
  )
}

/**
 * 月次スケジュールフィールド生成
 * 
 * @param tax 
 * @param index 
 * @param fields 
 */
const renderMonthScheduleFields = (month, index, fields) => {
  let days = [];
  
  for (let index = 1; index <= 31; index++) {
    days.push({ name: index, id: index })
  }

  return (
    <li key={index} className={'list-group-item'}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <span style={{ marginTop: '16px', marginBottom: '8px', marginRight: '10px'  }} >月次定時 {index + 1}</span>
        <Field
          component={Input}
          name={`${month}.id`}
          type="hidden"
          style={{ height: 0 }}
        />
        <Field name={`${month}.day`} label='日' component={renderSelect} required >
          {
            
            days.map((day) => {
              return (
                <MenuItem value={day.id}>{day.name}</MenuItem>
              )
            })
          }
        </Field>

        <Field
          name={`${month}.start_time`}
          type="text"
          component={renderTimeField}
          label="開始時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={lessThanTime(`${month}.end_time`)}
          className="inputTextField25"
        />
        <Field
          name={`${month}.end_time`}
          type="text"
          component={renderTimeField}
          label="終了時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={greaterThanTime(`${month}.start_time`)}
          className="inputTextField25"
        />
        <Button type="button" variant="outlined" color="secondary" style={{ marginTop: '16px', marginLeft: '10px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

/**
 * 月次スケジュール生成
 * 
 * @param props 
 */
const renderMonthSchedule = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>
      <ul>
        {fields.map(renderMonthScheduleFields)}
      </ul>
      <li>
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{height: '50px', fontSize: '1.4rem', marginTop: '8px'}} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>
      </li>
      <li>
        {error && <span>{error}</span>}
      </li>
    </ul>
  )
}


/**
 * 指定日スケジュールフィールド生成
 * 
 * @param tax 
 * @param index 
 * @param fields 
 */
const renderSpecificScheduleFields = (specific, index, fields) => {
  
  return (
    <li key={index} className={'list-group-item'}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <span style={{ marginTop: '16px', marginBottom: '8px', marginRight: '10px'  }} >指定日 {index + 1}</span>
        <Field
          component={Input}
          name={`${specific}.id`}
          type="hidden"
          style={{ height: 0 }}
        />
        <Field
          name={`${specific}.day`}
          type="text"
          component={renderDateCalendarField}
          label="指定日"
          rowsMax="1"
          margin="normal"
          normalize={datetime}
          className="inputTextField25"
        />

        <Field
          name={`${specific}.start_time`}
          type="text"
          component={renderTimeField}
          label="開始時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={lessThanTime(`${specific}.end_time`)}
          className="inputTextField25"
        />
        <Field
          name={`${specific}.end_time`}
          type="text"
          component={renderTimeField}
          label="終了時間"
          rowsMax="1"
          margin="normal"
          variant="outlined"
          minutesStep={60}
          //normalize={greaterThanTime(`${specific}.start_time`)}
          className="inputTextField25"
        />
        
        <Button type="button" variant="outlined" color="secondary" style={{ marginTop: '16px', marginLeft: '10px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

/**
 * 指定日スケジュール生成
 * 
 * @param props 
 */
const renderSpecificSchedule = (props: WrappedFieldArrayProps) => {
  const { error } = props.meta
  const { fields } = props

  return (
    <ul>
      <ul>
        {fields.map(renderSpecificScheduleFields)}
      </ul>
      <li>
        <Grid container spacing={2} justify="center">
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" color="secondary" type="button" style={{height: '50px', fontSize: '1.4rem', marginTop: '8px'}} onClick={() => fields.push({})}>
              <Icon color="primary">add_circle</Icon>&nbsp;追加
            </Button>
            {error && <span>{error}</span>}
          </Grid>
        </Grid>
      </li>
      <li>
        {error && <span>{error}</span>}
      </li>
    </ul>
  )
}

interface VacancyCheckedPeriodsProps {
  periods: VacancyCheckedPeriod[]
}
/**
 * 予約状況表示
 * 
 * @param periods 
 */
const VacancyCheckedPeriods: React.FC<VacancyCheckedPeriodsProps> = (props: VacancyCheckedPeriodsProps) => {
  const classes = useStyles({})
  const { periods } = props

  return (
    <table className={classes.table} 
      >
      <thead >
        <tr>
          <th >日付</th>
          <th >曜日</th>
          <th >開始時間</th>
          <th >終了時間</th>
        </tr>
      </thead>
      <tbody >
        {periods && periods.map((p) => {
          const day = moment(p.day).toDate()
          const start_time = moment(p.start_time).format("HH:mm")
          const end_time = moment(p.end_time).format("HH:mm")
          /*
          const padStartTime = start_time.getHours() + 9
          const padEndTime = end_time.getHours() + 9
          start_time.setHours(padStartTime)
          end_time.setHours(padEndTime)
          */
         console.log(p.start_time)
console.log(start_time)
          return (
          <tr>
            <td>{dateToString(day)}</td>
            <td>{dayOfWeeksJp(day)}</td>
            <td>{start_time}</td>
            <td>{end_time}</td>
          </tr>
          )
        })}
      </tbody>
    </table>
  )
}


const renderImagesField = (image, index, fields) => {
  const value = fields.get(index)
  const image_source = `${process.env.REACT_APP_API_SERVER_HOST}storage/images/regular/${value.reserve_id}/${value.file}`
  return (
    <li key={index}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <h4>画像 #{index + 1}</h4>

        <div>
          <img src={image_source} style={{width: '200px', margin: '8px'}} />
          <Field
              component={Input}
              name={`${image}.id`}
              type="hidden"
              style={{ height: 0, width: 0 }}
            />
          <Field
              component={Input}
              name={`${image}.reserve_id`}
              type="hidden"
              style={{ height: 0, width: 0 }}
            />
        </div>
        <div>
          <Field
            name={`${image}.image_file`}
            label='スペース画像'
            accept='image/*'
            component={renderFile}
          />
        </div>
        <Button type="button" variant="outlined" color="secondary" style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

/**
 * 画像
 * @param param0 
 */
const renderImages = (props: WrappedFieldArrayProps & OwnProps) => {
const { error } = props.meta
const { fields } = props

return (
  <ul>
    {fields.map(renderImagesField)}    
  </ul>
)
}



/**
 * バリデータ
 * 
 * @param values 
 */
export const validate = (values: Values) => {
  const errors: Errors = {}
  const requiredFields_info = [
    'space_id',
    'start_date',
    'end_date',
    'usage',
    'show_event_calendar_flg'
  ]
  const requiredFields_contractor = [
    'family_name',
    'given_name',
    'family_name_kana',
    'given_name_kana',
    'email',
    'email_confirmation',
    'postal_code',
    'address_pref',
    'address_city',
    'address_street',    
    'tel'
  ]
  const requiredFields_everyday = [
    'start_time',
    'end_time'
  ]
  const requiredFields_biweekly = [
    'day_of_week',
    'interval',
    'start_time',
    'end_time'
  ]
  const requiredFields_month = [
    'day',
    'start_time',
    'end_time'
  ]
  const requiredFields_specific = [
    'day',
    'start_time',
    'end_time'
  ]

  // 必須チェック
  requiredFields_info.forEach(field => {
    if (!required(values[field])) {
      errors[field] = "必須項目です";
    }
  });
  
  requiredFields_contractor.forEach(field => {
    if (values.contractor && !required(values.contractor[field])) {
      if(!errors['contractor']) {
        errors['contractor'] = {}
      }
      errors['contractor'][field] = "必須項目です";
    }
  });

  const everyday_errors = requiredArray(values.everyday, requiredFields_everyday)
  if (everyday_errors.length > 0) {
    errors['everyday'] = everyday_errors
  }
  const biweekly_errors = requiredArray(values.biweekly, requiredFields_biweekly)
  if (biweekly_errors.length > 0) {
    errors['biweekly'] = biweekly_errors
  }
  const month_errors = requiredArray(values.month, requiredFields_month)
  if (month_errors.length > 0) {
    errors['month'] = month_errors
  }
  const specific_errors = requiredArray(values.specific, requiredFields_specific)
  if (specific_errors.length > 0) {
    errors['specific'] = specific_errors
  }

  if(values.everyday == undefined
    && values.biweekly == undefined
    && values.month == undefined
    && values.specific == undefined) {
      const error = []
      error['start_time'] = "スケジュールのいずれか一つを必ず指定してください"
      errors['everyday'] = []
      errors['everyday'].push(error)
  }
  
  // 論理チェック
  if (validateLessThanDateTime(values.start_date, values.end_date)) {
    errors['start_date'] = '終了日より前にしてください'
  }

  return errors
}

interface OwnProps {
  spaces: SpaceListHeader[],
  reserve_id?: number,
  vacancyCheckedPeriods: VacancyCheckedPeriod[],
  validationResult?: ValidationError,
  ssValidation?: (errors: ValidationError, touch: (...fields: string[]) => void) => void,
  autoAddressLoad: (change) => void,
  onDelete: () => void
}

export type RegularReservationFormPropsType = OwnProps & InjectedFormProps<{}, OwnProps>

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

const Form: React.FC<RegularReservationFormPropsType> = (props: RegularReservationFormPropsType) => {
  const classes = useStyles({} as StyleProps)
  const { handleSubmit, pristine, submitting, reset, spaces, reserve_id, validationResult, ssValidation, touch, autoAddressLoad, change, onDelete } = props
  
  const [ tabValue, setTabValue] = React.useState(0);  

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  if (validationResult && validationResult.status != '') {
    if (ssValidation) {
      ssValidation(validationResult.errors, touch)
    }
  }

  return (
    <>
    
      <Paper className={classes.root} >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab value={0} label="基本情報" {...a11yProps(0)} />
          <Tab value={1} label="利用スケジュール" {...a11yProps(1)} />
          <Tab value={2} label="予約確定スケジュール" {...a11yProps(2)} />
        </Tabs>
      </Paper>

      <form onSubmit={handleSubmit}>
        <div className={classes.formContainer}>
          <TabPanel value={tabValue} index={0}>

            <Typography variant="h3" className={classes.centensetitle}>
              基本情報
            </Typography>

            <Field
              component={Input}
              name="reserve_id"
              type="hidden"
              style={{ height: 0 }}
            />

            <dl>
              <dt>予約スペース (必須)</dt>
              <dd>
                <div>        
                <Field name="space_id" label='スペース' component={renderSelect} required style={{minWidth: '300px'}}>
                  {
                    Object.keys(spaces).map((key) => {
                      return (
                        <MenuItem value={spaces[key].space_id}>{spaces[key].space_name}</MenuItem>
                      )
                    })
                  }
                </Field>
                </div>
              </dd>
            </dl>

            <dl>
              <dt>契約開始日〜契約終了日 (必須)</dt>
              <dd>
                <div>        
                  <Field
                    name="start_date"
                    type="text"
                    component={renderDateCalendarField}
                    label="契約開始日"
                    margin="normal"
                    rowsMax="1"
                    //normalize={lessThanDateTime(`end_date`)}
                    className={classes.inputTextField25}
                  />
                  <Field
                    name="end_date"
                    type="text"
                    component={renderDateCalendarField}
                    label="契約終了日"
                    margin="normal"
                    //normalize={greaterThanDateTime(`start_date`)}
                    className={classes.inputTextField25}
                  />
                </div>
              </dd>
            </dl>
            <dl style={{height: '200px'}}>
              <dt>利用用途 (必須)(512文字まで)</dt>
              <dd>
                <div>
                  <Field
                    name="usage"
                    component={renderTextField}
                    label="定期利用スケジュール表に記載される利用用途文です"
                    multiline
                    rows="4"
                    rowsMax="4"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>      
            </dl>
            <dl style={{height: '600px'}}>
              <dt>定期利用紹介文 (任意)(文字制限なし)</dt>
              <dd>
                <div>
                  <Field
                    name="description"
                    component={renderTextField}
                    label="利用者様が閲覧する紹介文です。"
                    multiline
                    rows="30"
                    rowsMax="30"
                    margin="normal"
                    variant="outlined"
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>リンク (任意)(http(s):// から始まるURL)</dt>
              <dd>
                <div>
                  <Field
                    name="link"
                    component={renderTextField}
                    label="ホームページをお持ちでしたら http(s):// から始まるURLを1つご記入ください"
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>氏名(カナ) (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="contractor.family_name_kana"
                    component={renderTextField}
                    label="姓(カナ)"
                    multiline
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField25}
                  />
                  <Field
                    name="contractor.given_name_kana"
                    component={renderTextField}
                    label="名(カナ)"
                    multiline
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField25}
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>氏名 (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="contractor.family_name"
                    autoComplete="family_name"
                    component={renderTextField}
                    label="姓"
                    multiline
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField25}
                  />
                  <Field
                    name="contractor.given_name"
                    autoComplete="given_name"
                    component={renderTextField}
                    label="名"
                    multiline
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField25}
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>法人・組織名 (任意)</dt>
              <dd>
                <div>
                  <Field
                    name="contractor.company_name"
                    autoComplete="company_name"
                    component={renderTextField}
                    label="法人・組織名"
                    multiline
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    className={classes.inputTextField50}
                  />
                </div>
              </dd>
            </dl>
            <dl style={{ height: '220px' }}>
              <dt>メールアドレス (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="contractor.email"
                    autoComplete="email"
                    component={renderTextField}
                    label="メールアドレス"
                    multiline
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField50}
                  />
                  <Field
                    name="contractor.email_confirmation"
                    autoComplete="email_confirmation"
                    component={renderTextField}
                    label="メールアドレス(確認)"
                    multiline
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField50}
                  />
                </div>
              </dd>
            </dl>
            <dl style={{ height: '500px' }}>
              <dt>住所 (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="contractor.postal_code"
                    autoComplete="postal-cod"
                    component={renderTextField}
                    label="郵便番号"
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField25}
                  />
                  <Button onClick={(event) => autoAddressLoad(change)} className={classes.addressSearchButton} >
                    住所自動入力
                  </Button>
                </div>
                <div>
                  <Field
                    name="contractor.address_pref"
                    autoComplete="address-level1"
                    component={renderTextField}
                    label="都道府県"
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField25}
                  />
                </div>
                <div>
                  <Field
                    name="contractor.address_city"
                    autoComplete="address-level2"
                    component={renderTextField}
                    label="市区町村"
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField50}
                  />
                </div>
                <div>
                  <Field
                    name="contractor.address_street"
                    autoComplete="street-address"
                    component={renderTextField}
                    label="番地"
                    multiline
                    rowsMax="2"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField80}
                  />
                </div>
                <div>
                  <Field
                    name="contractor.address_room"
                    autoComplete="address-level4"
                    component={renderTextField}
                    label="建物・部屋番号"
                    multiline
                    rowsMax="2"
                    margin="normal"
                    variant="outlined"
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>電話番号 (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="contractor.tel"
                    component={renderTextField}
                    label="電話番号"
                    rowsMax="1"
                    margin="normal"
                    variant="outlined"
                    required
                    className={classes.inputTextField50}
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>イベント紹介 ON/OFF設定 (必須)</dt>
              <dd>
                <div>
                  <Field
                    name="show_event_calendar_flg"
                    component={renderSwitch}
                    label=""              
                    margin="normal"              
                    required
                  />
                </div>
              </dd>
            </dl>
            

            <Typography variant="h3" className={classes.subtitle}>
              紹介画像 (任意)
            </Typography>

            <div>
              <FieldArrayCustom
                name="images"
                component={renderImages}
              />
            </div>
           
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Typography variant="h3" className={classes.centensetitle}>
              利用スケジュール
            </Typography>

            <Typography variant="h3" className={classes.subtitle}>
              毎日定時利用スケジュール (いずれか1種類必須)
            </Typography>

            <div>
              <FieldArrayCustom
                name="everyday"
                component={renderEverydaySchedule}
              />
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              週次定時利用スケジュール (いずれか1種類必須)
            </Typography>

            <div>
              <FieldArrayCustom
                name="biweekly"
                component={renderBiweeklySchedule}
              />
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              月次定時利用スケジュール (いずれか1種類必須)
            </Typography>

            <div>
              <FieldArrayCustom
                name="month"
                component={renderMonthSchedule}
              />
            </div>

            <Typography variant="h3" className={classes.subtitle}>
              指定日利用スケジュール (いずれか1種類必須)
            </Typography>

            <div>
              <FieldArrayCustom
                name="specific"
                component={renderSpecificSchedule}
              />
            </div>

          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Typography variant="h3" className={classes.centensetitle}>
              予約確定スケジュール
            </Typography>

            <VacancyCheckedPeriods {...{ periods: props.vacancyCheckedPeriods }} />

          </TabPanel>

        </div>

        <Grid container justify="center" spacing={2} style={{marginTop: '30px', marginBottom: '20px'}}>
          <Grid item xs={6}>
            <Button type="submit" disabled={pristine || submitting} fullWidth className={classes.submitButton}>
              定期利用登録をする
            </Button>
          </Grid>
        </Grid>

        {reserve_id && (
          <Grid container justify="center" spacing={2} style={{ marginTop: '30px', marginBottom: '20px' }}>
            <Grid item xs={6}>
              <Button type="button" fullWidth className={classes.deleteButton} onClick={onDelete}>
                解約する
            </Button>
            </Grid>
          </Grid>
        )
        }

      </form>

      
    </>
  )
}

const RegularReservationForm = reduxForm({
  form: 'RegularReservationForm', 
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true 
})(Form)

export default RegularReservationForm