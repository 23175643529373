import * as React from 'react';
import { Theme, createStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Container, makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { CalendarEvent } from 'dataObjects/space';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SyncIcon from '@material-ui/icons/Sync';
import LockIcon from '@material-ui/icons/Lock';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      width: '600px'
    },
    reserveInfoContainer: {
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        padding: '2% 0'
      },
      '& dl:nth-child(odd)': {
        backgroundColor: '#fbfbfb'
      },
      '& dt': {
        display: 'inline-block',
        width: '20%',
        paddingLeft: '10px',
        verticalAlign: 'middle',
      },
      '& dd': {
        marginInlineStart: '40px',
        display: 'inlineBlock',
        width: '64%',
        verticalAlign: 'middle',
        letterSpacing: '.3px'
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          minHeight: '80px',
          backgroundColor: '#fff',
          marginBlockStart: '0px',
          marginBlockEnd: '0px',
        },
        '& dl:nth-child(odd)': {
          backgroundColor: '#fff'
        },
        '& dt': {
          backgroundColor: '#fbfbfb',
          paddingTop: '10px',
          paddingLeft: '10px',
          width: '100%',
          minHeight: '40px'
        },
        '& dd': {
          backgroundColor: '#fff',
          width: '100%',
          paddingTop: '10px',
          paddingLeft: '10px',
          marginInlineStart: '0px',
          minHeight: '40px'
        },
      }
    },
  }),
);

interface OwnProps {
  event: CalendarEvent,  
  eventDialogOpen: boolean,
  handleClose: () => void
}

type EventDialogProps = OwnProps


const EventDialog: React.FC<EventDialogProps> = (props: EventDialogProps) => {
  const classes = useStyles({})
  const { event } = props

  const m_start = moment(event.start)
  const m_end = moment(event.end)

  const renderUseType = (use_type) => {
    if (use_type == '1') {
      return (
        <>
          <AccessTimeIcon />&nbsp;
          <span>一時利用</span>
        </>
      )
    } else if (use_type == '2') {
      return (
        <>
          <SyncIcon />&nbsp;
          <span>定期利用</span>
        </>
      ) 
    } else {      
      return (
        <>
          <LockIcon />&nbsp;
          <span>枠確保/休業</span>
        </>
      )
    }
  }
  
  return (
    <Dialog
      open={props.eventDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">予約情報</DialogTitle>
      <DialogContent dividers style={{display: 'flex'}} className={classes.dialogContainer}>
        <div className={classes.reserveInfoContainer}>
          <dl>
            <dt>予約ID</dt>
            <dd>{event.reserve_id}</dd>
          </dl>
          <dl>
            <dt>利用種別</dt>
            <dd>{renderUseType(event.use_type)}</dd>
          </dl>
          <dl>
            <dt>日時</dt>
            <dd>{m_start.format("YYYY/MM/DD")} {m_start.format("HH:mm")} 〜 {m_end.format("HH:mm")}</dd>
          </dl>
          <dl>
            <dt>利用者</dt>
            <dd>{event.name}</dd>
          </dl>
          <dl>
            <dt>目的</dt>
            <dd>{event.title}</dd>
          </dl>
          <dl>
            <dt>詳細/連絡事項</dt>
            <dd>{event.description}</dd>
          </dl>
                  
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default EventDialog