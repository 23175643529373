import AppClient from "../httpClients/appClient";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { AppState } from "../types/stateTypes";
import { ErrorActions } from './errorAction';
import { DashboardValuePanel, DashboardUseChart, DashboardGrassValue } from "dataObjects/dashboard";
import { ajaxErrorHandler } from "actionHelper/ajaxErrorHandler";


/**
 * 本日利用数 取得アクション
 */
export const FETCH_DAILY_USE_COUNT_START = 'FETCH_DAILY_USE_COUNT_START';
export const FETCH_DAILY_USE_COUNT_SUCCESS = 'FETCH_DAILY_USE_COUNT_SUCCESS';
export const FETCH_DAILY_USE_COUNT_FAIL = 'FETCH_DAILY_USE_COUNT_FAIL';

export type FetchDailyUseCountActions =
  | FetchDailyUseCountStartAction
  | FetchDailyUseCountSuccessAction
  | FetchDailyUseCountFailAction
  | ErrorActions;

type FetchDailyUseCountThunkResult<R> = ThunkAction<R, AppState, undefined, FetchDailyUseCountActions>;


export interface FetchDailyUseCountStartAction
  extends Action<typeof FETCH_DAILY_USE_COUNT_START> { }

const fetchDailyUseCountStartAction = (): FetchDailyUseCountStartAction => ({
  type: 'FETCH_DAILY_USE_COUNT_START',
});

export interface FetchDailyUseCountSuccessAction
  extends Action<typeof FETCH_DAILY_USE_COUNT_SUCCESS> {
  payload: DashboardValuePanel
}

const fetchDailyUseCountSuccessAction = (
  values: DashboardValuePanel
): FetchDailyUseCountSuccessAction => ({
  type: "FETCH_DAILY_USE_COUNT_SUCCESS",
  payload: values
});

export interface FetchDailyUseCountFailAction
  extends Action<typeof FETCH_DAILY_USE_COUNT_FAIL> {
  message: string;
}

const fetchDailyUseCountFailAction = (message: string): FetchDailyUseCountFailAction => ({
  type: 'FETCH_DAILY_USE_COUNT_FAIL',
  message,
})


/* 本日利用数 取得 ThunkAction */
export function fetchDailyUseCount(): FetchDailyUseCountThunkResult<void> {
  return async (
    dispatch: ThunkDispatch<AppState, any, FetchDailyUseCountActions>,
    getState: () => AppState
  ) => {
    try {
      const state = getState();

      dispatch(fetchDailyUseCountStartAction());

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/reserve/countbyday`;
      const response = await AppClient.get(url, header);
      const body: DashboardValuePanel = await response.data;
      
      dispatch(fetchDailyUseCountSuccessAction(body));
    } catch (err) {
      dispatch<FetchDailyUseCountActions>(
        await ajaxErrorHandler(err, fetchDailyUseCountFailAction)
      );
    }
  };
}


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 明日の利用数 取得アクション
 */
export const FETCH_TOMMROW_USE_COUNT_START = 'FETCH_TOMMROW_USE_COUNT_START';
export const FETCH_TOMMROW_USE_COUNT_SUCCESS = 'FETCH_TOMMROW_USE_COUNT_SUCCESS';
export const FETCH_TOMMROW_USE_COUNT_FAIL = 'FETCH_TOMMROW_USE_COUNT_FAIL';


export type FetchTommorowUseCountActions =
  | FetchTommorowUseCountStartAction
  | FetchTommorowUseCountSuccessAction
  | FetchTommorowUseCountFailAction
  | ErrorActions;

type FetchTommorowUseCountThunkResult<R> = ThunkAction<R, AppState, undefined, FetchTommorowUseCountActions>;


export interface FetchTommorowUseCountStartAction
  extends Action<typeof FETCH_TOMMROW_USE_COUNT_START> { }

const fetchTommorowUseCountStartAction = (): FetchTommorowUseCountStartAction => ({
  type: 'FETCH_TOMMROW_USE_COUNT_START',
});

export interface FetchTommorowUseCountSuccessAction
  extends Action<typeof FETCH_TOMMROW_USE_COUNT_SUCCESS> {
  payload: DashboardValuePanel
}

const fetchTommorowUseCountSuccessAction = (
  values: DashboardValuePanel
): FetchTommorowUseCountSuccessAction => ({
  type: "FETCH_TOMMROW_USE_COUNT_SUCCESS",
  payload: values
});

export interface FetchTommorowUseCountFailAction
  extends Action<typeof FETCH_TOMMROW_USE_COUNT_FAIL> {
  message: string;
}

const fetchTommorowUseCountFailAction = (message: string): FetchTommorowUseCountFailAction => ({
  type: 'FETCH_TOMMROW_USE_COUNT_FAIL',
  message,
})

/* 1週間利用数 取得 ThunkAction */
export function fetchTommorowUseCount(): FetchTommorowUseCountThunkResult<void> {
  return async (
    dispatch: ThunkDispatch<AppState, any, FetchTommorowUseCountActions>,
    getState: () => AppState
  ) => {
    try {
      const state = getState();

      dispatch(fetchTommorowUseCountStartAction());

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/reserve/countbytommorow`;
      const response = await AppClient.get(url, header);
      const body: DashboardValuePanel = await response.data;
      
      dispatch(fetchTommorowUseCountSuccessAction(body));
    } catch (err) {
      dispatch<FetchTommorowUseCountActions>(
        await ajaxErrorHandler(err, fetchTommorowUseCountFailAction)
      );
    }
  };
}



/* ---------------------------------------------------------------------------------------------------- */

/**
 * 1週間利用数 取得アクション
 */
export const FETCH_WEEK_USE_COUNT_START = 'FETCH_WEEK_USE_COUNT_START';
export const FETCH_WEEK_USE_COUNT_SUCCESS = 'FETCH_WEEK_USE_COUNT_SUCCESS';
export const FETCH_WEEK_USE_COUNT_FAIL = 'FETCH_WEEK_USE_COUNT_FAIL';

export type FetchWeekUseCountActions =
  | FetchWeekUseCountStartAction
  | FetchWeekUseCountSuccessAction
  | FetchWeekUseCountFailAction
  | ErrorActions;

type FetchWeekUseCountThunkResult<R> = ThunkAction<R, AppState, undefined, FetchWeekUseCountActions>;

export interface FetchWeekUseCountStartAction
  extends Action<typeof FETCH_WEEK_USE_COUNT_START> { }

const fetchWeekUseCountStartAction = (): FetchWeekUseCountStartAction => ({
  type: 'FETCH_WEEK_USE_COUNT_START',
});

export interface FetchWeekUseCountSuccessAction
  extends Action<typeof FETCH_WEEK_USE_COUNT_SUCCESS> {
  payload: DashboardValuePanel
}

const fetchWeekUseCountSuccessAction = (
  values: DashboardValuePanel
): FetchWeekUseCountSuccessAction => ({
  type: "FETCH_WEEK_USE_COUNT_SUCCESS",
  payload: values
});

export interface FetchWeekUseCountFailAction
  extends Action<typeof FETCH_WEEK_USE_COUNT_FAIL> {
  message: string;
}

const fetchWeekUseCountFailAction = (message: string): FetchWeekUseCountFailAction => ({
  type: 'FETCH_WEEK_USE_COUNT_FAIL',
  message,
})


/* 1週間利用数 取得 ThunkAction */
export function fetchWeekUseCount(): FetchWeekUseCountThunkResult<void> {
  return async (
    dispatch: ThunkDispatch<AppState, any, FetchWeekUseCountActions>,
    getState: () => AppState
  ) => {
    try {
      const state = getState();

      dispatch(fetchWeekUseCountStartAction());

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/reserve/countbyweek`;
      const response = await AppClient.get(url, header);
      const body: DashboardValuePanel = await response.data;
      
      dispatch(fetchWeekUseCountSuccessAction(body));
    } catch (err) {
      dispatch<FetchWeekUseCountActions>(
        await ajaxErrorHandler(err, fetchWeekUseCountFailAction)
      );
    }
  };
}


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 年間草 取得アクション
 */
export const FETCH_YEAR_USE_COUNT_START = 'FETCH_YEAR_USE_COUNT_START';
export const FETCH_YEAR_USE_COUNT_SUCCESS = 'FETCH_YEAR_USE_COUNT_SUCCESS';
export const FETCH_YEAR_USE_COUNT_FAIL = 'FETCH_YEAR_USE_COUNT_FAIL';

export type FetchYearUseCountActions =
  | FetchYearUseCountStartAction
  | FetchYearUseCountSuccessAction
  | FetchYearUseCountFailAction
  | ErrorActions;

type FetchYearUseCountThunkResult<R> = ThunkAction<R, AppState, undefined, FetchYearUseCountActions>;

export interface FetchYearUseCountStartAction
  extends Action<typeof FETCH_YEAR_USE_COUNT_START> { }

const fetchYearUseCountStartAction = (): FetchYearUseCountStartAction => ({
  type: 'FETCH_YEAR_USE_COUNT_START',
});

export interface FetchYearUseCountSuccessAction
  extends Action<typeof FETCH_YEAR_USE_COUNT_SUCCESS> {
  payload: DashboardGrassValue[]
}

const fetchYearUseCountSuccessAction = (
  values: DashboardGrassValue[]
): FetchYearUseCountSuccessAction => ({
  type: "FETCH_YEAR_USE_COUNT_SUCCESS",
  payload: values
});

export interface FetchYearUseCountFailAction
  extends Action<typeof FETCH_YEAR_USE_COUNT_FAIL> {
  message: string;
}

const fetchYearUseCountFailAction = (message: string): FetchYearUseCountFailAction => ({
  type: 'FETCH_YEAR_USE_COUNT_FAIL',
  message,
})


/* 年間利用数 取得 ThunkAction */
export function fetchYearUseCount(): FetchYearUseCountThunkResult<void> {
  return async (
    dispatch: ThunkDispatch<AppState, any, FetchYearUseCountActions>,
    getState: () => AppState
  ) => {
    try {
      const state = getState();

      dispatch(fetchYearUseCountStartAction());

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/reserve/countbyyear`;
      const response = await AppClient.get(url, header);
      const body: any = await response.data;
      const convEntity: DashboardGrassValue[] = Object.keys(body).map(function (key) {return body[key]})
      dispatch(fetchYearUseCountSuccessAction(convEntity));
    } catch (err) {
      dispatch<FetchYearUseCountActions>(
        await ajaxErrorHandler(err, fetchYearUseCountFailAction)
      );
    }
  };
}

/* ---------------------------------------------------------------------------------------------------- */


/**
 * 週チャート 取得アクション
 */
export const FETCH_WEEK_CHART_START = 'FETCH_WEEK_CHART_START';
export const FETCH_WEEK_CHART_SUCCESS = 'FETCH_WEEK_CHART_SUCCESS';
export const FETCH_WEEK_CHART_FAIL = 'FETCH_WEEK_CHART_FAIL';

export type FetchWeekChartActions =
  | FetchWeekChartStartAction
  | FetchWeekChartSuccessAction
  | FetchWeekChartFailAction
  | ErrorActions;

type FetchWeekChartThunkResult<R> = ThunkAction<R, AppState, undefined, FetchWeekChartActions>;


export interface FetchWeekChartStartAction
  extends Action<typeof FETCH_WEEK_CHART_START> { }

const fetchWeekChartStartAction = (): FetchWeekChartStartAction => ({
  type: 'FETCH_WEEK_CHART_START',
});

export interface FetchWeekChartSuccessAction
  extends Action<typeof FETCH_WEEK_CHART_SUCCESS> {
  payload: DashboardUseChart[]
}

const fetchWeekChartSuccessAction = (
  data: DashboardUseChart[],
): FetchWeekChartSuccessAction => ({
  type: 'FETCH_WEEK_CHART_SUCCESS',
  payload: data
})

export interface FetchWeekChartFailAction
  extends Action<typeof FETCH_WEEK_CHART_FAIL> {
  message: string;
}

const fetchWeekChartFailAction = (message: string): FetchWeekChartFailAction => ({
  type: 'FETCH_WEEK_CHART_FAIL',
  message,
})


/* 週間チャート 取得 ThunkAction */
export function fetchWeekChart(): FetchWeekChartThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchWeekChartActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchWeekChartStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/reserve/chartbyWeek`;
      const response = await AppClient.get(url, header)
      const body: any = await response.data;
      const convEntity: DashboardUseChart[] = Object.keys(body).map(function (key) {return body[key]})
      dispatch(
        fetchWeekChartSuccessAction(convEntity)
      )      
    } catch (err) {
      dispatch<FetchWeekChartActions>(
        await ajaxErrorHandler(err, fetchWeekChartFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */

/**
 * 月次一時利用未成売上 取得アクション
 */
export const FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_START = 'FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_START';
export const FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_SUCCESS = 'FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_SUCCESS';
export const FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_FAIL = 'FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_FAIL';

export type FetchMonthlySalesDropInUnCompletedActions =
  | FetchMonthlySalesDropInUnCompletedStartAction
  | FetchMonthlySalesDropInUnCompletedSuccessAction
  | FetchMonthlySalesDropInUnCompletedFailAction
  | ErrorActions;

type FetchMonthlySalesDropInUnCompletedThunkResult<R> = ThunkAction<R, AppState, undefined, FetchMonthlySalesDropInUnCompletedActions>;

export interface FetchMonthlySalesDropInUnCompletedStartAction
  extends Action<typeof FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_START> { }

const fetchMonthlySalesDropInUnCompletedStartAction = (): FetchMonthlySalesDropInUnCompletedStartAction => ({
  type: 'FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_START',
});

export interface FetchMonthlySalesDropInUnCompletedSuccessAction
  extends Action<typeof FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_SUCCESS> {
  payload: DashboardValuePanel
}

const fetchMonthlySalesDropInUnCompletedSuccessAction = (
  data: DashboardValuePanel,
): FetchMonthlySalesDropInUnCompletedSuccessAction => ({
  type: 'FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_SUCCESS',
  payload: data
})

export interface FetchMonthlySalesDropInUnCompletedFailAction
  extends Action<typeof FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_FAIL> {
  message: string;
}

const fetchMonthlySalesDropInUnCompletedFailAction = (message: string): FetchMonthlySalesDropInUnCompletedFailAction => ({
  type: 'FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_FAIL',
  message,
})


/* 月次一時利用未成売上 取得 ThunkAction */
export function fetchMonthlySalesDropInUnCompleted(): FetchMonthlySalesDropInUnCompletedThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchMonthlySalesDropInUnCompletedActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchMonthlySalesDropInUnCompletedStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/sales/dropinuncompleted`;
      const response = await AppClient.get(url, header)
      const body: DashboardValuePanel = await response.data;
      dispatch(
        fetchMonthlySalesDropInUnCompletedSuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchMonthlySalesDropInUnCompletedActions>(
        await ajaxErrorHandler(err, fetchMonthlySalesDropInUnCompletedFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 月次一時利用確定売上 取得アクション
 */
export const FETCH_MONTHLY_SALES_DROPIN_COMPLETED_START = 'FETCH_MONTHLY_SALES_DROPIN_COMPLETED_START';
export const FETCH_MONTHLY_SALES_DROPIN_COMPLETED_SUCCESS = 'FETCH_MONTHLY_SALES_DROPIN_COMPLETED_SUCCESS';
export const FETCH_MONTHLY_SALES_DROPIN_COMPLETED_FAIL = 'FETCH_MONTHLY_SALES_DROPIN_COMPLETED_FAIL';

export type FetchMonthlySalesDropInCompletedActions =
  | FetchMonthlySalesDropInCompletedStartAction
  | FetchMonthlySalesDropInCompletedSuccessAction
  | FetchMonthlySalesDropInCompletedFailAction
  | ErrorActions;

type FetchMonthlySalesDropInCompletedThunkResult<R> = ThunkAction<R, AppState, undefined, FetchMonthlySalesDropInCompletedActions>;

export interface FetchMonthlySalesDropInCompletedStartAction
  extends Action<typeof FETCH_MONTHLY_SALES_DROPIN_COMPLETED_START> { }

const fetchMonthlySalesDropInCompletedStartAction = (): FetchMonthlySalesDropInCompletedStartAction => ({
  type: 'FETCH_MONTHLY_SALES_DROPIN_COMPLETED_START',
});

export interface FetchMonthlySalesDropInCompletedSuccessAction
  extends Action<typeof FETCH_MONTHLY_SALES_DROPIN_COMPLETED_SUCCESS> {
  payload: DashboardValuePanel
}

const fetchMonthlySalesDropInCompletedSuccessAction = (
  data: DashboardValuePanel,
): FetchMonthlySalesDropInCompletedSuccessAction => ({
  type: 'FETCH_MONTHLY_SALES_DROPIN_COMPLETED_SUCCESS',
  payload: data
})

export interface FetchMonthlySalesDropInCompletedFailAction
  extends Action<typeof FETCH_MONTHLY_SALES_DROPIN_COMPLETED_FAIL> {
  message: string;
}

const fetchMonthlySalesDropInCompletedFailAction = (message: string): FetchMonthlySalesDropInCompletedFailAction => ({
  type: 'FETCH_MONTHLY_SALES_DROPIN_COMPLETED_FAIL',
  message,
})


/* 月次一時利用確定売上 取得 ThunkAction */
export function fetchMonthlySalesDropInCompleted(): FetchMonthlySalesDropInCompletedThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchMonthlySalesDropInCompletedActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchMonthlySalesDropInCompletedStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/sales/dropincompleted`;
      const response = await AppClient.get(url, header)
      const body: DashboardValuePanel = await response.data;
      dispatch(
        fetchMonthlySalesDropInCompletedSuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchMonthlySalesDropInCompletedActions>(
        await ajaxErrorHandler(err, fetchMonthlySalesDropInCompletedFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 月次定期利用確定売上 取得アクション
 */
export const FETCH_MONTHLY_SALES_REGULAR_COMPLETED_START = 'FETCH_MONTHLY_SALES_REGULAR_COMPLETED_START';
export const FETCH_MONTHLY_SALES_REGULAR_COMPLETED_SUCCESS = 'FETCH_MONTHLY_SALES_REGULAR_COMPLETED_SUCCESS';
export const FETCH_MONTHLY_SALES_REGULAR_COMPLETED_FAIL = 'FETCH_MONTHLY_SALES_REGULAR_COMPLETED_FAIL';

export type FetchMonthlySalesRegularCompletedActions =
  | FetchMonthlySalesRegularCompletedStartAction
  | FetchMonthlySalesRegularCompletedSuccessAction
  | FetchMonthlySalesRegularCompletedFailAction
  | ErrorActions;

type FetchMonthlySalesRegularCompletedThunkResult<R> = ThunkAction<R, AppState, undefined, FetchMonthlySalesRegularCompletedActions>;

export interface FetchMonthlySalesRegularCompletedStartAction
  extends Action<typeof FETCH_MONTHLY_SALES_REGULAR_COMPLETED_START> { }

const fetchMonthlySalesRegularCompletedStartAction = (): FetchMonthlySalesRegularCompletedStartAction => ({
  type: 'FETCH_MONTHLY_SALES_REGULAR_COMPLETED_START',
});

export interface FetchMonthlySalesRegularCompletedSuccessAction
  extends Action<typeof FETCH_MONTHLY_SALES_REGULAR_COMPLETED_SUCCESS> {
  payload: DashboardValuePanel
}

const fetchMonthlySalesRegularCompletedSuccessAction = (
  data: DashboardValuePanel,
): FetchMonthlySalesRegularCompletedSuccessAction => ({
  type: 'FETCH_MONTHLY_SALES_REGULAR_COMPLETED_SUCCESS',
  payload: data
})

export interface FetchMonthlySalesRegularCompletedFailAction
  extends Action<typeof FETCH_MONTHLY_SALES_REGULAR_COMPLETED_FAIL> {
  message: string;
}

const fetchMonthlySalesRegularCompletedFailAction = (message: string): FetchMonthlySalesRegularCompletedFailAction => ({
  type: 'FETCH_MONTHLY_SALES_REGULAR_COMPLETED_FAIL',
  message,
})

/* 月次定期利用確定売上 取得 ThunkAction */
export function fetchMonthlySalesRegularCompleted(): FetchMonthlySalesRegularCompletedThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchMonthlySalesRegularCompletedActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchMonthlySalesRegularCompletedStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/sales/regularcompleted`;
      const response = await AppClient.get(url, header)
      const body: DashboardValuePanel = await response.data;
      dispatch(
        fetchMonthlySalesRegularCompletedSuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchMonthlySalesRegularCompletedActions>(
        await ajaxErrorHandler(err, fetchMonthlySalesRegularCompletedFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */

/**
 * 未読 お問い合わせ数 取得アクション
 */
export const FETCH_UNREAD_COMMON_INQUIRY_START = 'FETCH_UNREAD_COMMON_INQUIRY_START';
export const FETCH_UNREAD_COMMON_INQUIRY_SUCCESS = 'FETCH_UNREAD_COMMON_INQUIRY_SUCCESS';
export const FETCH_UNREAD_COMMON_INQUIRY_FAIL = 'FETCH_UNREAD_COMMON_INQUIRY_FAIL';

export type FetchUnReadCommonInquiryActions =
  | FetchUnReadCommonInquiryStartAction
  | FetchUnReadCommonInquirySuccessAction
  | FetchUnReadCommonInquiryFailAction
  | ErrorActions;

type FetchUnReadCommonInquiryThunkResult<R> = ThunkAction<R, AppState, undefined, FetchUnReadCommonInquiryActions>;


export interface FetchUnReadCommonInquiryStartAction
  extends Action<typeof FETCH_UNREAD_COMMON_INQUIRY_START> { }

const fetchUnReadCommonInquiryStartAction = (): FetchUnReadCommonInquiryStartAction => ({
  type: 'FETCH_UNREAD_COMMON_INQUIRY_START',
});

export interface FetchUnReadCommonInquirySuccessAction
  extends Action<typeof FETCH_UNREAD_COMMON_INQUIRY_SUCCESS> {
  payload: DashboardValuePanel
}

const fetchUnReadCommonInquirySuccessAction = (
  data: DashboardValuePanel,
): FetchUnReadCommonInquirySuccessAction => ({
  type: 'FETCH_UNREAD_COMMON_INQUIRY_SUCCESS',
  payload: data
})

export interface FetchUnReadCommonInquiryFailAction
  extends Action<typeof FETCH_UNREAD_COMMON_INQUIRY_FAIL> {
  message: string;
}

const fetchUnReadCommonInquiryFailAction = (message: string): FetchUnReadCommonInquiryFailAction => ({
  type: 'FETCH_UNREAD_COMMON_INQUIRY_FAIL',
  message,
})


/* 未読 お問い合わせ数 ThunkAction */
export function fetchUnReadCommonInquiry(): FetchUnReadCommonInquiryThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchUnReadCommonInquiryActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchUnReadCommonInquiryStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/inquiry/countcommon`;
      const response = await AppClient.get(url, header)
      const body: DashboardValuePanel = await response.data;
      dispatch(
        fetchUnReadCommonInquirySuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchUnReadCommonInquiryActions>(
        await ajaxErrorHandler(err, fetchUnReadCommonInquiryFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */


/**
 * 未読 定期利用お問い合わせ数 取得アクション
 */
export const FETCH_UNREAD_REGULAR_INQUIRY_START = 'FETCH_UNREAD_REGULAR_INQUIRY_START';
export const FETCH_UNREAD_REGULAR_INQUIRY_SUCCESS = 'FETCH_UNREAD_REGULAR_INQUIRY_SUCCESS';
export const FETCH_UNREAD_REGULAR_INQUIRY_FAIL = 'FETCH_UNREAD_REGULAR_INQUIRY_FAIL';

export type FetchUnReadRegularInquiryActions =
  | FetchUnReadRegularInquiryStartAction
  | FetchUnReadRegularInquirySuccessAction
  | FetchUnReadRegularInquiryFailAction
  | ErrorActions;

type FetchUnReadRegularInquiryThunkResult<R> = ThunkAction<R, AppState, undefined, FetchUnReadRegularInquiryActions>;


export interface FetchUnReadRegularInquiryStartAction
  extends Action<typeof FETCH_UNREAD_REGULAR_INQUIRY_START> { }

const fetchUnReadRegularInquiryStartAction = (): FetchUnReadRegularInquiryStartAction => ({
  type: 'FETCH_UNREAD_REGULAR_INQUIRY_START',
});

export interface FetchUnReadRegularInquirySuccessAction
  extends Action<typeof FETCH_UNREAD_REGULAR_INQUIRY_SUCCESS> {
  payload: DashboardValuePanel
}

const fetchUnReadRegularInquirySuccessAction = (
  data: DashboardValuePanel,
): FetchUnReadRegularInquirySuccessAction => ({
  type: 'FETCH_UNREAD_REGULAR_INQUIRY_SUCCESS',
  payload: data
})

export interface FetchUnReadRegularInquiryFailAction
  extends Action<typeof FETCH_UNREAD_REGULAR_INQUIRY_FAIL> {
  message: string;
}

const fetchUnReadRegularInquiryFailAction = (message: string): FetchUnReadRegularInquiryFailAction => ({
  type: 'FETCH_UNREAD_REGULAR_INQUIRY_FAIL',
  message,
})


/* 未読 定期利用お問い合わせ数 ThunkAction */
export function fetchUnReadRegularInquiry(): FetchUnReadRegularInquiryThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchUnReadRegularInquiryActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchUnReadRegularInquiryStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/inquiry/countregular`;
      const response = await AppClient.get(url, header)
      const body: DashboardValuePanel = await response.data;
      dispatch(
        fetchUnReadRegularInquirySuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchUnReadRegularInquiryActions>(
        await ajaxErrorHandler(err, fetchUnReadRegularInquiryFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */


/**
 * 未処理再オーソリエラー数 取得アクション
 */
 export const FETCH_AUTHORIZATION_ERROR_START = 'FETCH_AUTHORIZATION_ERROR_START';
 export const FETCH_AUTHORIZATION_ERROR_SUCCESS = 'FETCH_AUTHORIZATION_ERROR_SUCCESS';
 export const FETCH_AUTHORIZATION_ERROR_FAIL = 'FETCH_AUTHORIZATION_ERROR_FAIL';
 
 export type FetchAuthorizationErrorActions =
   | FetchAuthorizationErrorStartAction
   | FetchAuthorizationErrorSuccessAction
   | FetchAuthorizationErrorFailAction
   | ErrorActions;
 
 type FetchAuthorizationErrorThunkResult<R> = ThunkAction<R, AppState, undefined, FetchAuthorizationErrorActions>;
 
 
 export interface FetchAuthorizationErrorStartAction
   extends Action<typeof FETCH_AUTHORIZATION_ERROR_START> { }
 
 const fetchAuthorizationErrorStartAction = (): FetchAuthorizationErrorStartAction => ({
   type: FETCH_AUTHORIZATION_ERROR_START,
 });
 
 export interface FetchAuthorizationErrorSuccessAction
   extends Action<typeof FETCH_AUTHORIZATION_ERROR_SUCCESS> {
   payload: DashboardValuePanel
 }
 
 const fetchAuthorizationErrorSuccessAction = (
   data: DashboardValuePanel,
 ): FetchAuthorizationErrorSuccessAction => ({
   type: FETCH_AUTHORIZATION_ERROR_SUCCESS,
   payload: data
 })
 
 export interface FetchAuthorizationErrorFailAction
   extends Action<typeof FETCH_AUTHORIZATION_ERROR_FAIL> {
   message: string;
 }
 
 const fetchAuthorizationErrorFailAction = (message: string): FetchAuthorizationErrorFailAction => ({
   type: FETCH_AUTHORIZATION_ERROR_FAIL,
   message,
 })
 
 
 /* 未処理再オーソリエラー数 ThunkAction */
 export function fetchAuthorizationError(): FetchAuthorizationErrorThunkResult<void> {
   return async (dispatch: ThunkDispatch<AppState, any, FetchAuthorizationErrorActions>,
     getState: () => AppState) => {
     try {
       const state = getState();
 
       dispatch(fetchAuthorizationErrorStartAction());
 
       const header: Record<string, string> = {};
       header.Authorization = 'Bearer ' + state.authStatus.access_token
 
       const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/payment/authorizationerror`;
       const response = await AppClient.get(url, header)
       const body: DashboardValuePanel = await response.data;
       dispatch(
        fetchAuthorizationErrorSuccessAction(body)
       )
     } catch (err) {
       dispatch<FetchAuthorizationErrorActions>(
         await ajaxErrorHandler(err, fetchAuthorizationErrorFailAction),
       )
     }
   }
 }


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 明日強制キャンセルとなる未決済手続き数 取得アクション
 */
 export const FETCH_PAYMENT_LIMIT_START = 'FETCH_PAYMENT_LIMIT_START';
 export const FETCH_PAYMENT_LIMIT_SUCCESS = 'FETCH_PAYMENT_LIMIT_SUCCESS';
 export const FETCH_PAYMENT_LIMIT_FAIL = 'FETCH_PAYMENT_LIMIT_FAIL';
 
 export type FetchPaymentLimitActions =
   | FetchPaymentLimitStartAction
   | FetchPaymentLimitSuccessAction
   | FetchPaymentLimitFailAction
   | ErrorActions;
 
 type FetchPaymentLimitThunkResult<R> = ThunkAction<R, AppState, undefined, FetchPaymentLimitActions>;
 
 
 export interface FetchPaymentLimitStartAction
   extends Action<typeof FETCH_PAYMENT_LIMIT_START> { }
 
 const fetchPaymentLimitStartAction = (): FetchPaymentLimitStartAction => ({
   type: FETCH_PAYMENT_LIMIT_START,
 });
 
 export interface FetchPaymentLimitSuccessAction
   extends Action<typeof FETCH_PAYMENT_LIMIT_SUCCESS> {
   payload: DashboardValuePanel
 }
 
 const fetchPaymentLimitSuccessAction = (
   data: DashboardValuePanel,
 ): FetchPaymentLimitSuccessAction => ({
   type: FETCH_PAYMENT_LIMIT_SUCCESS,
   payload: data
 })
 
 export interface FetchPaymentLimitFailAction
   extends Action<typeof FETCH_PAYMENT_LIMIT_FAIL> {
   message: string;
 }
 
 const fetchPaymentLimitFailAction = (message: string): FetchPaymentLimitFailAction => ({
   type: FETCH_PAYMENT_LIMIT_FAIL,
   message,
 })
 
 
 /* 明日強制キャンセルとなる未決済手続き数 ThunkAction */
 export function fetchPaymentLimit(): FetchPaymentLimitThunkResult<void> {
   return async (dispatch: ThunkDispatch<AppState, any, FetchPaymentLimitActions>,
     getState: () => AppState) => {
     try {
       const state = getState();
 
       dispatch(fetchPaymentLimitStartAction());
 
       const header: Record<string, string> = {};
       header.Authorization = 'Bearer ' + state.authStatus.access_token
 
       const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/payment/limit`;
       const response = await AppClient.get(url, header)
       const body: DashboardValuePanel = await response.data;
       dispatch(
        fetchPaymentLimitSuccessAction(body)
       )
     } catch (err) {
       dispatch<FetchPaymentLimitActions>(
         await ajaxErrorHandler(err, fetchPaymentLimitFailAction),
       )
     }
   }
 }

/* ---------------------------------------------------------------------------------------------------- */

/**
 * 明日強制キャンセルとなる未決済手続き数 取得アクション
 */
 export const FETCH_FORCE_CANCEL_START = 'FETCH_FORCE_CANCEL_START';
 export const FETCH_FORCE_CANCEL_SUCCESS = 'FETCH_FORCE_CANCEL_SUCCESS';
 export const FETCH_FORCE_CANCEL_FAIL = 'FETCH_FORCE_CANCEL_FAIL';
 
 export type FetchForceCancelActions =
   | FetchForceCancelStartAction
   | FetchForceCancelSuccessAction
   | FetchForceCancelFailAction
   | ErrorActions;
 
 type FetchForceCancelThunkResult<R> = ThunkAction<R, AppState, undefined, FetchForceCancelActions>;
 
 
 export interface FetchForceCancelStartAction
   extends Action<typeof FETCH_FORCE_CANCEL_START> { }
 
 const fetchForceCancelStartAction = (): FetchForceCancelStartAction => ({
   type: FETCH_FORCE_CANCEL_START,
 });
 
 export interface FetchForceCancelSuccessAction
   extends Action<typeof FETCH_FORCE_CANCEL_SUCCESS> {
   payload: DashboardValuePanel
 }
 
 const fetchForceCancelSuccessAction = (
   data: DashboardValuePanel,
 ): FetchForceCancelSuccessAction => ({
   type: FETCH_FORCE_CANCEL_SUCCESS,
   payload: data
 })
 
 export interface FetchForceCancelFailAction
   extends Action<typeof FETCH_FORCE_CANCEL_FAIL> {
   message: string;
 }
 
 const fetchForceCancelFailAction = (message: string): FetchForceCancelFailAction => ({
   type: FETCH_FORCE_CANCEL_FAIL,
   message,
 })
 
 
 /* 明日強制キャンセルとなる未決済手続き数 ThunkAction */
 export function fetchForceCancel(): FetchForceCancelThunkResult<void> {
   return async (dispatch: ThunkDispatch<AppState, any, FetchForceCancelActions>,
     getState: () => AppState) => {
     try {
       const state = getState();
 
       dispatch(fetchForceCancelStartAction());
 
       const header: Record<string, string> = {};
       header.Authorization = 'Bearer ' + state.authStatus.access_token
 
       const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/dashboard/payment/forcecancel`;
       const response = await AppClient.get(url, header)
       const body: DashboardValuePanel = await response.data;
       dispatch(
        fetchForceCancelSuccessAction(body)
       )
     } catch (err) {
       dispatch<FetchForceCancelActions>(
         await ajaxErrorHandler(err, fetchForceCancelFailAction),
       )
     }
   }
 }