import { ActionTypes } from "../actions/actionTypes";
import {
  FETCH_NEWS_START,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_FAIL,
  STORE_NEWS_LOCAL,
  REMOVE_NEWS_SUCCESS
} from 'actions/newsAction'
import { LOGOUT_SUCCESS } from "actions/authenticationActions";
import { News } from "dataObjects/news";


export interface NewsState
  extends News { }

const initialNewsState: NewsState = {
  id: undefined,
  title: '',
  contents: '',
  publish_date: undefined
}


/* News Reducer */
const news = (
  state: NewsState = initialNewsState,
  action: ActionTypes
): NewsState => {
  switch (action.type) {
    case FETCH_NEWS_START:
      return { ...state };
    case FETCH_NEWS_SUCCESS:
      return { ...state, ...action.payload };
    case STORE_NEWS_LOCAL:
      return { ...state, ...action.payload };
    case FETCH_NEWS_FAIL:
      return { ...state };
    case REMOVE_NEWS_SUCCESS:
      return { ...state, ...initialNewsState };
    case LOGOUT_SUCCESS:
      return { ...state, ...initialNewsState };
    default:
      return { ...state };
  }
};

export default news;