import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { renderTextField } from '../renderFormControl'
import { Values, Errors } from '../../types/formTypes'
import { required, maxLength } from '../../utils/validation';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },  
    paper: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      '& a': {
        fontSize: '1.2rem',
        color: '#27A78E'
      }
    },
    hr_text: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      lineHeight: '1em',
      position: 'relative',
      outline: '0',
      border: '0',
      color: 'black',
      textAlign: 'center',
      height: '1.5em',
      opacity: '.5',
      overflow: 'visible',
      "&::before": {
        content: '',        
        background: 'linear-gradient(to right, transparent, #818078, transparent)',
        position: 'absolute',
        left: 0,
        top: '50%',
        width: '100%',
        height: '1px',
      },
      "&::after": {
        content: 'attr(data-content)',
        position: 'relative',
        display: 'inline-block',
        color: 'black',
        padding: '0 .5em',
        lineHeight: '1.5em',
        backgroundColor: '#fcfcfa'
      }
    }
  }),
);


export const validate = (values: Values) => {
  const errors: Errors = {}
  const requiredFields = [
    'email',
    'password'
  ]
  requiredFields.forEach(field => {
    console.log(`${field}: ${values[field]}`)
    if (!required(values[field])) {
      errors[field] = '必須項目です'
    }
  })

  return errors
}

interface OwnProps {
}


export type SignInProps = OwnProps & InjectedFormProps<{}, OwnProps>


const Form: React.FC<SignInProps> = (props: SignInProps) => {
  const classes = useStyles({});
  
  const { handleSubmit, pristine, submitting } = props;

  return (
    <Container component="main" maxWidth="xs">      
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Shareal 管理画面 へログイン
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Field
            component={renderTextField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="メールアドレス"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Field
            component={renderTextField}
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="パスワード"
            name="password"
            autoComplete="current-password"
          />          

          <Button
            type="submit"
            fullWidth
            disabled={pristine || submitting}
            variant="contained"
            color="primary"
            className={classes.submitButton}
          >
            ログイン
          </Button>
          
        </form>
      </div>
    </Container>    

  )
}

const SignIn = reduxForm({
  form: 'SignIn', 
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Form)

export default SignIn