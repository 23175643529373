import { ActionTypes } from '../actions/actionTypes'
import {
  FETCH_USER_LIST_START,
  FETCH_USER_LIST_SUCCESS,
  FETCH_USER_LIST_FAIL
} from "../actions/userAction";
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';
import { UserListHeaderPagenation } from 'dataObjects/user';



export interface UserListHeaderState
  extends UserListHeaderPagenation { }

const initialUserListHeaderState: UserListHeaderState = {
  data: [],
  current_page: 1,
  first_page_url:
    process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/user/list/20?page=1",
  from: null,
  last_page: 1,
  last_page_url:
    process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/user/list//20?page=1",
  next_page_url: null,
  path: process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/user/list/20",
  per_page: 20,
  prev_page_url: null,
  to: null,
  total: 0
};


/* UserList Reducer */
const userList = (
  state: UserListHeaderState = initialUserListHeaderState,
  action: ActionTypes
): UserListHeaderState => {
  switch (action.type) {
    // ユーザーリスト読み込み
    case FETCH_USER_LIST_START:
      return { ...state };
    case FETCH_USER_LIST_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_USER_LIST_FAIL:
      return { ...state };
    case LOGOUT_SUCCESS:
      return { ...state, ...initialUserListHeaderState };
    default:
      return { ...state };
  }
};

export default userList;
