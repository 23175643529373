import React from 'react'
import {
  Field,
  FieldArray,
  reduxForm,
  WrappedFieldProps,
  WrappedFieldArrayProps,
  InjectedFormProps,
  GenericFieldArray,
  FieldArrayFieldsProps
} from "redux-form";
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DescriptionIcon from '@material-ui/icons/Description';

import { renderTextField, renderSelect, renderFormHelper, renderSwitch, renderDateCalendarField, renderTimeField, renderFile } from '../renderFormControl'
import { Values, Errors } from '../../types/formTypes'
import { required, maxLength, digit, lessThanDateTime, greaterThanDateTime, lessThanTime, greaterThanTime, number, datetime, requiredArray, validateLessThanDateTime } from "../../utils/validation";
import { fontWeight } from '@material-ui/system';
import { SpaceUsage, TermOfService, SpaceListHeader, VacancyCheckedPeriod } from 'dataObjects/space';
import { Tabs, Paper, Tab, Divider, MenuItem, Input, Icon } from '@material-ui/core';
import { a11yProps, TabPanel } from 'components/Tab/TabPanel';
import { ValidationError } from 'errors/RequestValidationError';
import { timeString, dateToString, dayOfWeeksJp } from 'utils/convertor';


interface StyleProps {
  root: BaseCSSProperties,
  formContainer: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  detail: BaseCSSProperties,
  submitButton: BaseCSSProperties,
  bottomNav: BaseCSSProperties,
  bottomNavContent: BaseCSSProperties,
  bottomNavli: BaseCSSProperties,
  notice: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#ffffff',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fbfbfb'
    },
    centensetitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
      lineHeight: 1.4,
      marginTop: theme.spacing(2)
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
    deleteButton: {
      color: '#FFF',
      backgroundColor: '#fa2120',
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
    notice: {
      marginBottom: '180px',
    },
    addressSearchButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      fontSize: '1.4rem',
      height: '52px',
      marginTop: '16px'
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }
  })
)


const renderAttachmentFileField = (file, index, fields) => {
  const value = fields.get(index)
  const href = "/news/file/" + value.id
  return (
    <li key={index}>
      <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        <h4>ファイル #{index + 1}</h4>

        <div>
          {value.id && 
          (<a href={href} target="_blank">
              <DescriptionIcon style={{width: '50px', height: '50px'}} /> 
          </a>)
          }
          <Field
              component={Input}
              name={`${file}.id`}
              type="hidden"
              style={{ height: 0, width: 0 }}
            />
          <Field
              component={Input}
              name={`${file}.news_id`}
              type="hidden"
              style={{ height: 0, width: 0 }}
            />
        </div>
        <div>
          <Field
            name={`${file}.file_name`}
            label='ファイル表示名'
            component={renderTextField}
            rows="1"
            rowsMax="1"
            margin="normal"
            variant="outlined"
            required
            style={{width: '200px'}}
          />
        </div>
        <div>
          <Field
            name={`${file}.file`}
            label='添付ファイル'
            component={renderFile}
          />
        </div>
        <Button type="button" variant="outlined" color="secondary" style={{ marginLeft: '10px', marginTop: '16px', height: '90%' }} onClick={() => fields.remove(index)}>削除</Button>
      </div>
      <Divider></Divider>
    </li>
  )
}

/**
 * 添付ファイル
 * @param param0 
 */
const renderAttachmentFile = (props: WrappedFieldArrayProps & OwnProps) => {
const { error } = props.meta
const { fields } = props

return (
  <ul>
    {fields.map(renderAttachmentFileField)}    

    <li>
      <Grid container spacing={2} justify="center">
        <Grid item xs={4}>
          <Button fullWidth variant="outlined" color="secondary" type="button" style={{ height: '50px', fontSize: '1.4rem', marginTop: '8px' }} onClick={() => fields.push({})}>
            <Icon color="primary">add_circle</Icon>&nbsp;ファイル 追加
          </Button>
          {error && <span>{error}</span>}
        </Grid>
      </Grid>
    </li>
  </ul>
)
}



/**
 * バリデータ
 * 
 * @param values 
 */
export const validate = (values: Values) => {
  const errors: Errors = {}
  const requiredFields = [
    'publish_date',
    'title',
    'contents'
  ]

  // 必須チェック
  requiredFields.forEach(field => {
    if (!required(values[field])) {
      errors[field] = "必須項目です";
    }
  });

  return errors
}

interface OwnProps {
  id?: number,  
  validationResult?: ValidationError,
  ssValidation?: (errors: ValidationError, touch: (...fields: string[]) => void) => void,
  onDelete: () => void
}

export type NewsFormPropsType = OwnProps & InjectedFormProps<{}, OwnProps>

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

const Form: React.FC<NewsFormPropsType> = (props: NewsFormPropsType) => {
  const classes = useStyles({} as StyleProps)
  const { handleSubmit, pristine, submitting, reset, id, validationResult, ssValidation, change, onDelete, touch } = props
  
  if (validationResult && validationResult.status != '') {
    if (ssValidation) {
      ssValidation(validationResult.errors, touch)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.formContainer}>

          <Field
            component={Input}
            name="id"
            type="hidden"
            style={{ height: 0 }}
          />

          <dl>
            <dt>公開日</dt>
            <dd>
              <div>
                <Field
                  name="publish_date"
                  type="text"
                  component={renderDateCalendarField}
                  label="公開日"
                  margin="normal"
                  rowsMax="1"
                  className={classes.inputTextField50}
                />
              </div>
            </dd>
          </dl>
          <dl>
            <dt>タイトル</dt>
            <dd>
              <div>
                <Field
                  name="title"
                  component={renderTextField}
                  label="おしらせタイトル"
                  rows="1"
                  rowsMax="1"
                  margin="normal"
                  variant="outlined"
                  className={classes.inputTextField80}
                />
              </div>
            </dd>
          </dl>
          <dl style={{height: '600px'}}>
            <dt>本文</dt>
            <dd>
              <div>
                <Field
                  name="contents"
                  component={renderTextField}
                  label="おしらせ本文"
                  multiline
                  rows="30"
                  rowsMax="30"
                  margin="normal"
                  variant="outlined"
                  className={classes.inputTextField80}
                />
              </div>
            </dd>
          </dl>

          <Typography variant="h3" className={classes.subtitle}>
            添付ファイル (任意)
          </Typography>
            
          <div>
            <FieldArrayCustom
              name="files"
              component={renderAttachmentFile}
            />
          </div>          
          

        </div>

        <Grid container justify="center" spacing={2} style={{ marginTop: '30px', marginBottom: '20px' }}>
          <Grid item xs={6}>
            <Button type="submit" disabled={pristine || submitting} fullWidth className={classes.submitButton}>
              登録をする
            </Button>
          </Grid>
        </Grid>

        { id && (
        <Grid container justify="center" spacing={2} style={{ marginTop: '30px', marginBottom: '20px' }}>
          <Grid item xs={6}>
            <Button type="button" fullWidth className={classes.deleteButton} onClick={onDelete}>
              削除する
            </Button>
          </Grid>
        </Grid>
        )
        }
      </form>


    </>
  )
}

const NewsForm = reduxForm({
  form: 'NewsForm',
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(Form)

export default NewsForm