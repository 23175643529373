import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes';
import NewsDetailPage, { NewsDetailPageProps } from '../pages/NewsDetailPage';
import { observeShownAction } from 'actions/pagingAction';
import { push } from 'connected-react-router';
import { routes } from 'routes/Route';
import { closeDialog } from 'actions/dialogAction';

interface OwnProps {
}

export type NewsDetailPageContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function NewsDetailPageContainer(props: NewsDetailPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    dispatch(observeShownAction());
  }, []);

  const _props: NewsDetailPageProps = {
    updateDialogOpen: state.page.updateDialogOpen,
    rejectDialogOpen: state.page.rejectDialogOpen,
    onDialogClose: () => {
      dispatch(closeDialog())
    },
    onBack: () => {
      dispatch(push(routes.newsList.path))
    }
  }

  return <NewsDetailPage {..._props} />

}
