import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { ListTableColumn } from '../components/ListTable/ListTable'
import DropInReserveListPage, { DropInReserveListPageProps } from 'pages/DropInReserveListPage';
import { SpaceReservation } from 'dataObjects/space';
import { fetchDropinReservationList, fetchDropinReservation } from 'actions/dropinReservationAction';
import { observeShownAction } from 'actions/pagingAction';


const columns: ListTableColumn[] = [
  { id: 'reserve_id', label: 'ID', minWidth: 50 },
  {
    id: 'space_name',
    label: 'スペース',
    minWidth: 100,
    format: (value: string) => value && value.length > 30 ? value.substring(0, 30) : value
  },
  {
    id: 'user_name',
    label: '利用者',
    minWidth: 100,
  },
  {
    id: 'email',
    label: 'email',
    minWidth: 100,
  }
  ,{
    id: 'day',
    label: '利用日',
    minWidth: 100,
  },
  {
    id: 'time_range',
    label: '利用時間',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'updated_at',
    label: '予約受付日時',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'status',
    label: '予約状況',
    minWidth: 100,
    align: 'center',
    format: (value: string) => {
      switch (value) {
        case '0':
          return '未処理'
        case '1':
          return '仮予約'
        case '2':
          return 'お支払い前'
        case '3':
          return '予約完了'
        case '4':
          return '決済エラー'
        case '9':
          return 'キャンセル済み'
        default:
          return ''
      }
    }
  }
];

interface OwnProps {
}

export type DropInReserveListPageContainerProps = OwnProps & RouteComponentProps<{}>

const appStateSelector = (state: AppState) => state

export default function DropInReserveListPageContainer(props: DropInReserveListPageContainerProps) {
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  const _props: DropInReserveListPageProps = {
    columns: columns,
    data: state.dropinReservationList.data,
    total: state.dropinReservationList.total,
    page: state.dropinReservationList.current_page - 1,
    rowsPerPage: Object.keys(state.dropinReservationList.data).length,
    rowsPerPageOptions: [
      20,
      50,
      100,
      Object.keys(state.dropinReservationList.data).length
    ],
    setPage: (page: number) => {
      ownDispatch(
        fetchDropinReservationList(
          state.dropinReservationListCondition,
          state.dropinReservationList.per_page,
          page
        )
      );
    },
    setRowsPerPage: (perPage: number) => {
      ownDispatch(
        fetchDropinReservationList(
          state.dropinReservationListCondition,
          perPage,
          0
        )
      );
    },
    selectedRow: (data: any) => {
      const reserveData = data as SpaceReservation;
      ownDispatch(fetchDropinReservation(reserveData.reserve_id));
    }
  };  

  useEffect(() => {
    console.log('DropInReserveListPageContainer::useEffect')
    ownDispatch(observeShownAction());
  }, [])

  return <DropInReserveListPage {..._props} />

}