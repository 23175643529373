import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { routes } from 'routes/Route';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { RegularReservationResult, RegularReservation, RegularImageForUpdate } from 'dataObjects/space'
import { RegularReservationHeaderPagenation, RegularReservationSearchCondition } from 'dataObjects/space';
import { ValidationErrorListState } from 'reducers/validationErrorReducer';
import { SnackBarAction } from './actionTypes';
import { getBase64 } from 'utils/convertor';
import moment from 'moment'


/**
 * 定期利用一覧取得アクション
 */
export const FETCH_REGULAR_RESERVATION_LIST_START = 'FETCH_REGULAR_RESERVATION_LIST_START';
export const FETCH_REGULAR_RESERVATION_LIST_SUCCESS = 'FETCH_REGULAR_RESERVATION_LIST_SUCCESS';
export const FETCH_REGULAR_RESERVATION_LIST_FAIL = 'FETCH_REGULAR_RESERVATION_LIST_FAIL';
/**
 * 定期利用予約 検索条件 保存
 */
export const STORE_REGULAR_RESERVATION_LIST_CONDITION = 'STORE_REGULAR_RESERVATION_LIST_CONDITION'

export type FetchRegularReservationListActions =
  | FetchRegularReservationListStartAction
  | FetchRegularReservationListSuccessAction
  | FetchRegularReservationListFailAction
  | StoreRegularReservationListConditionAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchRegularReservationListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchRegularReservationListActions>;


/**
 * 定期利用予約 取得アクション
 */
export const FETCH_REGULAR_RESERVATION_START = 'FETCH_REGULAR_RESERVATION_START';
export const FETCH_REGULAR_RESERVATION_SUCCESS = 'FETCH_REGULAR_RESERVATION_SUCCESS';
export const FETCH_REGULAR_RESERVATION_FAIL = 'FETCH_REGULAR_RESERVATION_FAIL';

export type FetchRegularReservationActions =
  | FetchRegularReservationStartAction
  | FetchRegularReservationSuccessAction
  | FetchRegularReservationFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchRegularReservationThunkResult<R> = ThunkAction<R, AppState, undefined, FetchRegularReservationActions>;


/**
 * 定期利用予約 入力内容検証アクション
 */
export const VALIDATION_REGULAR_RESERVATION_START = 'VALIDATION_REGULAR_RESERVATION_START';
export const VALIDATION_REGULAR_RESERVATION_SUCCESS = 'VALIDATION_REGULAR_RESERVATION_SUCCESS';
export const VALIDATION_REGULAR_RESERVATION_INVALID = 'VALIDATION_REGULAR_RESERVATION_INVALID';
export const VALIDATION_REGULAR_RESERVATION_FAIL = 'VALIDATION_REGULAR_RESERVATION_FAIL';

export type ValidationRegularReservationActions =
  | ValidationRegularReservationStartAction
  | ValidationRegularReservationSuccessAction
  | ValidationRegularReservationInvalidAction
  | ValidationRegularReservationFailAction
  | FetchRegularReservationSuccessAction
  | CallHistoryMethodAction
  | ErrorActions;

type ValidationRegularReservationThunkResult<R> = ThunkAction<R, AppState, undefined, ValidationRegularReservationActions>;


/**
 * 定期利用予約 更新アクション
 */
export const STORE_REGULAR_RESERVATION_START = 'STORE_REGULAR_RESERVATION_START';
export const STORE_REGULAR_RESERVATION_LOCAL = 'STORE_REGULAR_RESERVATION_LOCAL';
export const STORE_REGULAR_RESERVATION_SUCCESS = 'STORE_REGULAR_RESERVATION_SUCCESS';
export const STORE_REGULAR_RESERVATION_INVALID = 'STORE_REGULAR_RESERVATION_INVALID';
export const STORE_REGULAR_RESERVATION_FAIL = 'STORE_REGULAR_RESERVATION_FAIL';

export type StoreRegularReservationActions =
  | StoreRegularReservationStartAction
  | StoreRegularReservationSuccessAction
  | StoreRegularReservationInvalidAction
  | StoreRegularReservationFailAction
  | StoreRegularReservationLocalAction
  | CallHistoryMethodAction
  | ErrorActions;

type StoreRegularReservationThunkResult<R> = ThunkAction<R, AppState, undefined, StoreRegularReservationActions>;


/**
 * 定期利用予約 キャンセルアクション
 */
export const CANCEL_REGULAR_RESERVATION_START = 'CANCEL_REGULAR_RESERVATION_START';
export const CANCEL_REGULAR_RESERVATION_SUCCESS = 'CANCEL_REGULAR_RESERVATION_SUCCESS';
export const CANCEL_REGULAR_RESERVATION_INVALID = 'CANCEL_REGULAR_RESERVATION_INVALID';
export const CANCEL_REGULAR_RESERVATION_FAIL = 'CANCEL_REGULAR_RESERVATION_FAIL';

export type CancelRegularReservationActions =
  | CancelRegularReservationStartAction
  | CancelRegularReservationSuccessAction
  | CancelRegularReservationInvalidAction
  | CancelRegularReservationFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type CancelRegularReservationThunkResult<R> = ThunkAction<R, AppState, undefined, CancelRegularReservationActions>;

/**
 * ローカルデータクリア
 */
export const CLEAR_REGULAR_RESERVATION_LOCAL = 'CLEAR_REGULAR_RESERVATION_LOCAL';

/* ---------------------------------------------------------------------------------------------------- */


export interface FetchRegularReservationListStartAction
  extends Action<typeof FETCH_REGULAR_RESERVATION_LIST_START> { }

const fetchRegularReservationListStartAction = (): FetchRegularReservationListStartAction => ({
  type: 'FETCH_REGULAR_RESERVATION_LIST_START',
});

export interface FetchRegularReservationListSuccessAction
  extends Action<typeof FETCH_REGULAR_RESERVATION_LIST_SUCCESS> {
  payload: RegularReservationHeaderPagenation
}

const fetchRegularReservationListSuccessAction = (
  headerList: RegularReservationHeaderPagenation,
): FetchRegularReservationListSuccessAction => ({
  type: 'FETCH_REGULAR_RESERVATION_LIST_SUCCESS',
  payload: headerList
})

export interface FetchRegularReservationListFailAction
  extends SnackBarAction<typeof FETCH_REGULAR_RESERVATION_LIST_FAIL> {
  
}

const fetchRegularReservationListFailAction = (message: string): FetchRegularReservationListFailAction => ({
  type: 'FETCH_REGULAR_RESERVATION_LIST_FAIL',
  snackBarMessage: message,
  variant: "error"
})

/**
 * 定期利用予約 検索条件 保存
 */
export interface StoreRegularReservationListConditionAction 
  extends Action<typeof STORE_REGULAR_RESERVATION_LIST_CONDITION> {
    payload: RegularReservationSearchCondition
  }

const storeRegularReservationListCondition = (
  condition: RegularReservationSearchCondition
): StoreRegularReservationListConditionAction => ({
    type: 'STORE_REGULAR_RESERVATION_LIST_CONDITION',
    payload: condition
})

/* 定期利用予約一覧 取得 ThunkAction */
export function fetchRegularReservationList(condition: RegularReservationSearchCondition,
  perpage: number = 20, page: number = 0): FetchRegularReservationListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchRegularReservationListActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchRegularReservationListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/regular/list/${perpage}?page=${page + 1}`
      const response = await AppClient.get(url, header, condition)
      const body: RegularReservationHeaderPagenation = await response.data;
      dispatch(
        storeRegularReservationListCondition(condition)
      )
      dispatch(
        fetchRegularReservationListSuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchRegularReservationListActions>(
        await ajaxErrorHandler(err, fetchRegularReservationListFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */


export interface FetchRegularReservationStartAction
  extends Action<typeof FETCH_REGULAR_RESERVATION_START> { }

const fetchRegularReservationStartAction = (): FetchRegularReservationStartAction => ({
  type: 'FETCH_REGULAR_RESERVATION_START',
});

export interface FetchRegularReservationSuccessAction
  extends Action<typeof FETCH_REGULAR_RESERVATION_SUCCESS> {
  payload: RegularReservation
}

const fetchRegularReservationSuccessAction = (
  data: RegularReservation,
): FetchRegularReservationSuccessAction => ({
  type: 'FETCH_REGULAR_RESERVATION_SUCCESS',
  payload: data
})

export interface FetchRegularReservationFailAction
  extends SnackBarAction<typeof FETCH_REGULAR_RESERVATION_FAIL> {
  
}

const fetchRegularReservationFailAction = (message: string): FetchRegularReservationFailAction => ({
  type: 'FETCH_REGULAR_RESERVATION_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* 定期利用予約 取得 ThunkAction */
export function fetchRegularReservation(reserve_id: number): FetchRegularReservationThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchRegularReservationActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchRegularReservationStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/regular/find/${reserve_id}`;
      const response = await AppClient.get(url, header)
      const body: RegularReservation = await response.data;

      // 日付
      if(body.specific) {
        body.specific.forEach(value => {
          value.day = moment(value.day).toDate();
        })
      }
      dispatch(
        fetchRegularReservationSuccessAction(body)
      )
      dispatch(push(routes.regularReserveUpdate.getPath(body.reserve_id)))
    } catch (err) {
      dispatch<FetchRegularReservationActions>(
        await ajaxErrorHandler(err, fetchRegularReservationFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */

export interface ValidationRegularReservationStartAction
  extends Action<typeof VALIDATION_REGULAR_RESERVATION_START> { }

const validationRegularReservationStartAction = (): ValidationRegularReservationStartAction => ({
  type: 'VALIDATION_REGULAR_RESERVATION_START',
});

export interface ValidationRegularReservationSuccessAction
  extends Action<typeof VALIDATION_REGULAR_RESERVATION_SUCCESS> {
  payload: RegularReservationResult
}

const validationRegularReservationSuccessAction = (
  data: RegularReservationResult,
): ValidationRegularReservationSuccessAction => ({
  type: 'VALIDATION_REGULAR_RESERVATION_SUCCESS',
  payload: data
})

export interface ValidationRegularReservationInvalidAction
  extends Action<typeof VALIDATION_REGULAR_RESERVATION_INVALID> {
  payload: ValidationErrorListState
}

const validationRegularReservationInvalidAction = (
  payload: ValidationErrorListState
)
  : ValidationRegularReservationInvalidAction => {
  return {
    type: VALIDATION_REGULAR_RESERVATION_INVALID,
    payload: payload
  }
}

export interface ValidationRegularReservationFailAction
  extends Action<typeof VALIDATION_REGULAR_RESERVATION_FAIL> {
  message: string;
}

const validationRegularReservationFailAction = (message: string)
  : ValidationRegularReservationFailAction => ({
    type: 'VALIDATION_REGULAR_RESERVATION_FAIL',
    message,
  })


/* 定期利用予約 検証 ThunkAction */
export function validationRegularReservation(payload: RegularReservation): ValidationRegularReservationThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, ValidationRegularReservationActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
console.log('StartAction')
      dispatch(validationRegularReservationStartAction());
console.log('CreateHeader')
      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token
console.log('CreateURL')
      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/regular/validation`;
console.log('Before POST')
      const response = await AppClient.post(url, header, payload)
console.log('After POST')
      const data: RegularReservationResult = await response.data;
console.log('await response.data')
      if (data.validationResult && data.validationResult.length > 0) {
console.log('SSValidation失敗')
        /* SSValidation失敗 */
        dispatch(validationRegularReservationInvalidAction({
          message: '定期利用の内容チェック処理でNG項目が存在しました。',
          errors: data.validationResult
        }))
      } else {
        console.log('SSValidation成功')
      }

    } catch (err) {
      dispatch<ValidationRegularReservationActions>(
        await ajaxErrorHandler(err, validationRegularReservationFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */

export interface StoreRegularReservationStartAction
  extends Action<typeof STORE_REGULAR_RESERVATION_START> { }

const storeRegularReservationStartAction = (): StoreRegularReservationStartAction => ({
  type: 'STORE_REGULAR_RESERVATION_START',
});

export interface StoreRegularReservationLocalAction
  extends Action<typeof STORE_REGULAR_RESERVATION_LOCAL> {
  payload: RegularReservation
}

export const storeRegularReservationLocalAction = (
  data: RegularReservation,
): StoreRegularReservationLocalAction => ({
  type: 'STORE_REGULAR_RESERVATION_LOCAL',
  payload: data
})

export interface StoreRegularReservationSuccessAction
  extends Action<typeof STORE_REGULAR_RESERVATION_SUCCESS> {
  payload: RegularReservationResult
}

const storeRegularReservationSuccessAction = (
  data: RegularReservationResult,
): StoreRegularReservationSuccessAction => ({
  type: 'STORE_REGULAR_RESERVATION_SUCCESS',
  payload: data
  })

export interface StoreRegularReservationInvalidAction
  extends Action<typeof STORE_REGULAR_RESERVATION_INVALID> {
  payload: ValidationErrorListState
}

const storeRegularReservationInvalidAction = (
  payload: ValidationErrorListState
)
  : StoreRegularReservationInvalidAction => {
  return {
    type: STORE_REGULAR_RESERVATION_INVALID,
    payload: payload
  }
}

export interface StoreRegularReservationFailAction
  extends SnackBarAction<typeof STORE_REGULAR_RESERVATION_FAIL> {
  
}

const storeRegularReservationFailAction = (message: string)
  : StoreRegularReservationFailAction => ({
  type: 'STORE_REGULAR_RESERVATION_FAIL',
    snackBarMessage: message,
    variant: "error"
})


/* 定期利用予約 更新 ThunkAction */
export function storeRegularReservation(payload: RegularReservation): StoreRegularReservationThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreRegularReservationActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(storeRegularReservationStartAction());
      
      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token      

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/regular/store`;
      const response = await AppClient.post(url, header, payload)
      const data: RegularReservationResult = await response.data;

      const images : RegularImageForUpdate[] = payload.images
      payload.images = null
      let imgdata: RegularReservationResult = null
      // 画像送信
      if(images && images.length > 0) {
        
        await Promise.all(images.map(async (value, index, array)  => {
          value.reserve_id = data.payload.reserve_id          
          if(value.image_file) {
            value.image_file_name = value.image_file.name
            value.image_file_base64 = await getBase64(value.image_file) as string 
          }
          value.image_file = null    
          return
        }))        
        console.log(images)
        const payload = {
          images: images
        }
        const imageUrl = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/regular/image/upload`;
        const imageResponse = await AppClient.post(imageUrl, header, payload)
        imgdata = await imageResponse.data
      }

      if(imgdata && imgdata.validationResult && imgdata.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeRegularReservationInvalidAction({
          message: '定期利用画像 登録処理',
          errors: imgdata.validationResult
        }))
      } else if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeRegularReservationInvalidAction({
          message: '定期利用の登録処理が中断しました。',
          errors: data.validationResult
        }))
      } else {
        // 日付
        if(data.payload.specific) {
          data.payload.specific.forEach(value => {
            value.day = moment(value.day).toDate();
          })
        }

        dispatch(
          storeRegularReservationSuccessAction(data)
        )
      }
      
    } catch (err) {
      dispatch<StoreRegularReservationActions>(
        await ajaxErrorHandler(err, storeRegularReservationFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */

export interface CancelRegularReservationStartAction
  extends Action<typeof CANCEL_REGULAR_RESERVATION_START> { }

const cancelRegularReservationStartAction = (): CancelRegularReservationStartAction => ({
  type: 'CANCEL_REGULAR_RESERVATION_START',
});

export interface CancelRegularReservationSuccessAction
  extends Action<typeof CANCEL_REGULAR_RESERVATION_SUCCESS> {
  payload: RegularReservationResult
}

const cancelRegularReservationSuccessAction = (
  data: RegularReservationResult,
): CancelRegularReservationSuccessAction => ({
    type: 'CANCEL_REGULAR_RESERVATION_SUCCESS',
  payload: data
})

export interface CancelRegularReservationInvalidAction
  extends Action<typeof CANCEL_REGULAR_RESERVATION_INVALID> {
  payload: ValidationErrorListState
}

const cancelRegularReservationInvalidAction = (
  payload: ValidationErrorListState
)
  : CancelRegularReservationInvalidAction => {
  return {
    type: CANCEL_REGULAR_RESERVATION_INVALID,
    payload: payload
  }
}

export interface CancelRegularReservationFailAction
  extends SnackBarAction<typeof CANCEL_REGULAR_RESERVATION_FAIL> {

}

const cancelRegularReservationFailAction = (message: string)
  : CancelRegularReservationFailAction => ({
    type: 'CANCEL_REGULAR_RESERVATION_FAIL',
    snackBarMessage: message,
    variant: "error"
  })


/* 定期利用予約 更新 ThunkAction */
export function cancelRegularReservation(reserve_id: number, space_id: number): CancelRegularReservationThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, CancelRegularReservationActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(cancelRegularReservationStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const payload = {
        reserve_id: reserve_id,
        space_id: space_id
      }
      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/regular/cancel`;
      const response = await AppClient.post(url, header, payload)
      const data: RegularReservationResult = await response.data;
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(cancelRegularReservationInvalidAction({
          message: '定期利用の契約解除処理が中断しました。',
          errors: data.validationResult
        }))
      } else {
        dispatch(
          cancelRegularReservationSuccessAction(data)
        )
      }

    } catch (err) {
      dispatch<CancelRegularReservationActions>(
        await ajaxErrorHandler(err, cancelRegularReservationFailAction),
      )
    }
  }
}



/* ---------------------------------------------------------------------------------------------------- */

export interface ClearRegularReservationLocalAction
  extends Action<typeof CLEAR_REGULAR_RESERVATION_LOCAL> { }

export const clearRegularReservationLocalAction = (): ClearRegularReservationLocalAction => ({
  type: 'CLEAR_REGULAR_RESERVATION_LOCAL',
});
