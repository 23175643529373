import React, { useEffect } from 'react'
import { routes } from '../routes/Route';
import { RouteComponentProps } from 'react-router-dom'
import { push } from "connected-react-router"
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import KeyBoxPassPage, { KeyBoxPassPageProps } from '../pages/KeyBoxPassPage'
import { observeShownAction } from 'actions/pagingAction';
import moment from 'moment';
import { fetchKeyBoxPassList } from 'actions/keyBoxPassAction';
import { closeDialog } from 'actions/dialogAction';


interface OwnProps {
}

export type KeyBoxPassPageContainerProps = OwnProps & RouteComponentProps<{ space_id?: string }>

const appStateSelector = (state: AppState) => state

export default function KeyBoxPassPageContainer(props: KeyBoxPassPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const _props: KeyBoxPassPageProps = {
    updateDialogOpen: state.page.updateDialogOpen,
    onDialogClose: () => {
      dispatch(closeDialog())
    }
  }

  useEffect(() => {
    dispatch(fetchKeyBoxPassList())
  }, [])

  return <KeyBoxPassPage {..._props} />

}