import { ActionTypes } from '../actions/actionTypes'
import { DropinReservationSearchCondition } from '../dataObjects/space';
import {
  STORE_DROPIN_RESERVATION_LIST_CONDITION
} from '../actions/dropinReservationAction'


export interface DropinReservationSearchConditionState extends DropinReservationSearchCondition {

}

const initialDropinReservationSearchConditionState: DropinReservationSearchConditionState = {
  date_from: new Date(),
  date_to: null,
  family_name: '',
  given_name: '',
  email: ''
}


/* dropinReservationListCondition Reducer */
const dropinReservationListCondition = (
  state: DropinReservationSearchConditionState = initialDropinReservationSearchConditionState,
  action: ActionTypes,
): DropinReservationSearchConditionState => {
  switch (action.type) {
    // 一時利用予約 検索条件
    case STORE_DROPIN_RESERVATION_LIST_CONDITION:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default dropinReservationListCondition
