import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import BasePageComponent from '../components/BasePageComponent';
import { Dispatch } from 'redux';
import { routes } from 'routes/Route';
import { push } from "connected-react-router"

interface Props {

}

const appStateSelector = (state: AppState) => state

export default function BasePageContainer(props: Props) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch()

  const _props = {
    ...props,
    loading: state.page.loading,
    moveRoute: (routeKey: string) => {
      if (routes.hasOwnProperty(routeKey)) {
        dispatch(push(routes[routeKey].path))
      }
    }
  }
  
  return <BasePageComponent {..._props} />

}
