import * as React from 'react';
import useReactRouter from 'use-react-router';
import { routes } from '../routes/Route';
import { Theme, createStyles, Button, Container, MuiThemeProvider } from '@material-ui/core';
import { theme } from "materialui/theme";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';
import SignInContainer from '../containers/SignInContainer'

import { Link, LinkProps } from 'react-router-dom';
import SnackBarContainer from 'containers/SnackBarContainer';

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  description: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(10),
      height: '100%'
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fff'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center'
    }
  })
);

interface OwnProps {

}

export type SignInPageProps = OwnProps;

const SignInPage: React.FC<SignInPageProps> = (props: SignInPageProps) => {
  const classes = useStyles({} as StyleProps);  
  
  return (

    <MuiThemeProvider theme={theme}>
      <div>
        <Container fixed className={classes.topcontainer}>
      
        <SignInContainer />

        </Container>
      </div>
      <SnackBarContainer />
    </MuiThemeProvider>

  );

}

export default SignInPage