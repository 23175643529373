import { ActionTypes } from '../actions/actionTypes'
import {
  FETCH_SETTINGS_START,
  FETCH_SETTINGS_FAIL,
  FETCH_SETTINGS_SUCCESS,
} from '../actions/settingsAction'
import { Settings, OAuth } from '../dataObjects/settings'


export interface SettingsState extends Settings {
  hasLoad: boolean,
  fetchErrorCount: number
}

const initialSettingsState: SettingsState = {
  hasLoad: false,
  // ソーシャル認証情報
  oauth: undefined,
  // 決済情報
  square: undefined,
  // GoogleMapAPI
  google_map: undefined,
  fetchErrorCount: 0
};

/* Settings Reducer */
const settings = (
  state: SettingsState = initialSettingsState,
  action: ActionTypes,
): SettingsState => {
  switch (action.type) {
    // システム設定読み込み
    case FETCH_SETTINGS_START: 
      return { ...state, hasLoad: false };
    case FETCH_SETTINGS_SUCCESS:
      return { ...state, hasLoad: true, fetchErrorCount: 0, ...action.payload };
    case FETCH_SETTINGS_FAIL:
      return { ...state, hasLoad: false, fetchErrorCount: ++state.fetchErrorCount };    
    default:
      return { ...state };
  }
};

export default settings