import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { ListTableColumn } from '../components/ListTable/ListTable'
import NewsListPage, { NewsListPageProps } from 'pages/NewsListPage';
import { fetchNews, fetchNewsList } from 'actions/newsAction';
import { observeShownAction } from 'actions/pagingAction';
import { routes } from 'routes/Route';
import { push } from 'connected-react-router';
import { News } from 'dataObjects/news';


const columns: ListTableColumn[] = [
  { id: 'id', label: 'ID', minWidth: 50 },
  {
    id: 'title',
    label: 'タイトル',
    minWidth: 300,
    format: (value: string) => value && value.length > 150 ? value.substring(0, 150) + '...' : value
  },
  {
    id: 'publish_date',
    label: '公開日',
    minWidth: 100,
  }    
];

interface OwnProps {
}

export type NewsListPageContainerProps = OwnProps & RouteComponentProps<{}>

const appStateSelector = (state: AppState) => state

export default function NewsListPageContainer(props: NewsListPageContainerProps) {
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  const _props: NewsListPageProps = {
    columns: columns,
    data: state.newsList.data,
    total: state.newsList.total,
    page: state.newsList.current_page - 1,
    rowsPerPage: Object.keys(state.newsList.data).length,
    rowsPerPageOptions: [
      20,
      50,
      100,
      Object.keys(state.newsList.data).length
    ],
    setPage: (page: number) => {
      ownDispatch(
        fetchNewsList(
          state.newsListCondition,
          state.newsList.per_page,
          page
        )
      );
    },
    setRowsPerPage: (perPage: number) => {
      ownDispatch(
        fetchNewsList(
          state.newsListCondition,
          perPage,
          0
        )
      );
    },
    selectedRow: (data: any) => {
      const newsData = data as News;
      ownDispatch(fetchNews(newsData.id));
    },
    onCreate: () => {
      // 新規おしらせ作成
      ownDispatch(push(routes.newsCreate.path))
    }
  };  

  useEffect(() => {
    ownDispatch(observeShownAction());
  }, [])

  return <NewsListPage {..._props} />

}
