import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { ListTableColumn } from '../components/ListTable/ListTable'
import RegularReserveListPage, { RegularReserveListPageProps } from 'pages/RegularReserveListPage';
import { SpaceReservation } from 'dataObjects/space';
import { fetchRegularReservationList, fetchRegularReservation, clearRegularReservationLocalAction } from 'actions/regularReservationAction';
import { observeShownAction } from 'actions/pagingAction';
import { routes } from 'routes/Route';
import { push } from 'connected-react-router';


const columns: ListTableColumn[] = [
  { id: 'reserve_id', label: 'ID', minWidth: 50 },
  {
    id: 'space_name',
    label: 'スペース',
    minWidth: 100,
    format: (value: string) => value && value.length > 30 ? value.substring(0, 30) : value
  },
  {
    id: 'contractor_name',
    label: '利用者',
    minWidth: 80,
  },
  {
    id: 'email',
    label: 'email',
    minWidth: 100,
  },
  {
    id: 'start_date',
    label: '契約開始日',
    minWidth: 100,
  },
  {
    id: 'end_date',
    label: '契約終了日',
    minWidth: 100,
  },
  {
    id: 'updated_at',
    label: '予約受付日時',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'status',
    label: '予約状況',
    minWidth: 100,
    align: 'center',
    format: (value: string) => {
      switch (value) {
        case '0':
          return '未処理'
        case '1':
          return '仮予約'
        case '2':
          return 'お支払い前'
        case '3':
          return '契約完了'
        case '4':
          return '決済エラー'
        case '9':
          return '契約打ち切り'
        default:
          return ''
      }
    }
  }
];

interface OwnProps {
}

export type RegularReserveListPageContainerProps = OwnProps & RouteComponentProps<{}>

const appStateSelector = (state: AppState) => state

export default function RegularReserveListPageContainer(props: RegularReserveListPageContainerProps) {
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  const _props: RegularReserveListPageProps = {
    columns: columns,
    data: state.regularReservationList.data,
    total: state.regularReservationList.total,
    page: state.regularReservationList.current_page - 1,
    rowsPerPage: Object.keys(state.regularReservationList.data).length,
    rowsPerPageOptions: [
      20,
      50,
      100,
      Object.keys(state.regularReservationList.data).length
    ],
    setPage: (page: number) => {
      ownDispatch(
        fetchRegularReservationList(
          state.regularReservationListCondition,
          state.regularReservationList.per_page,
          page
        )
      );
    },
    setRowsPerPage: (perPage: number) => {
      ownDispatch(
        fetchRegularReservationList(
          state.regularReservationListCondition,
          perPage,
          0
        )
      );
    },
    selectedRow: (data: any) => {
      const reserveData = data as SpaceReservation;
      ownDispatch(fetchRegularReservation(reserveData.reserve_id));
    },
    onCreate: () => {
      // 新規定期利用作成
      ownDispatch(clearRegularReservationLocalAction())
      ownDispatch(push(routes.regularReserveCreate.path))
    }
  };  

  useEffect(() => {
    ownDispatch(observeShownAction());
  }, [])

  return <RegularReserveListPage {..._props} />

}
