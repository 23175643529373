import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, formValueSelector, stopAsyncValidation } from 'redux-form'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import RegularReservationForm, { RegularReservationFormPropsType, validate } from '../components/Reserve/RegularReservationForm'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'
import { ValidationError } from 'errors/RequestValidationError';
import { addressSearchByPostalCode, PostalCodeSearchResult } from 'utils/postalCodeSearch';
import { Contractor, RegularReservationEverydaySchedule, RegularReservationBiWeeklySchedule, RegularReservationMonthSchedule, RegularReservationSpecificSchedule, RegularReservation, RegularImageForUpdate } from 'dataObjects/space';
import { routes } from 'routes/Route';
import { push } from 'connected-react-router';
import { storeRegularReservationLocalAction, storeRegularReservation, cancelRegularReservation } from 'actions/regularReservationAction';
import { fetchSpaceAll } from 'actions/spaceAction';


interface OwnProps {
}

type Props = OwnProps

const CN_FORM_NAME = 'RegularReservationForm'

const appStateSelector = (state: AppState) => state

const fvSelector = formValueSelector(CN_FORM_NAME)

export default function RegularReservationFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    ownDispatch(fetchSpaceAll())
  }, [])

  const autoAddressLoad = (fnChange) => {
    const postal_code = fvSelector(state, 'contractor.postal_code')
    // 住所検索
    addressSearchByPostalCode(postal_code, (result) => {
      let addressType: PostalCodeSearchResult = null
      if (Array.isArray(result.results)) {
        addressType = result.results[0]
      } else {
        addressType = result.results
      }
      fnChange("contractor.address_pref", addressType.address1);
      fnChange("contractor.address_city", addressType.address2);
      fnChange("contractor.address_street", addressType.address3);
    })
  }
  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (Object.keys(errors).length > 0) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else { 
      setIsDisplay(false)
      
      const contractor: Contractor = {
        reserve_id: state.regularReservation.reserve_id,
        family_name: values['contractor']['family_name'],
        family_name_kana: values['contractor']['family_name_kana'],
        given_name: values['contractor']['given_name'],
        given_name_kana: values['contractor']['given_name_kana'],
        email: values['contractor']['email'],
        email_confirmation: values['contractor']['email_confirmation'],
        postal_code: values['contractor']['postal_code'],
        address_pref: values['contractor']['address_pref'],
        address_city: values['contractor']['address_city'],
        address_street: values['contractor']['address_street'],
        address_room: values['contractor']['address_room'],
        tel: values['contractor']['tel'],
        company_name: values['contractor']['company_name'],
        promotion_code: ''
      }
      const everyday = [];
      const everydayFromValues = values['everyday'];
      if(everydayFromValues) {
        Object.keys(everydayFromValues).forEach((key) => {
          const everydayItem: RegularReservationEverydaySchedule = {
            id: everydayFromValues[key]['id'],
            start_time: everydayFromValues[key]['start_time'],
            end_time: everydayFromValues[key]['end_time'],
          }
          everyday.push(everydayItem)
        })
      }
      const biweekly = [];
      const biweeklyFromValues = values['biweekly'];
      if(biweeklyFromValues) {
        Object.keys(biweeklyFromValues).forEach((key) => {
          const biweeklyItem: RegularReservationBiWeeklySchedule = {
            id: biweeklyFromValues[key]['id'],
            day_of_week: biweeklyFromValues[key]['day_of_week'],
            interval: biweeklyFromValues[key]['interval'],
            start_time: biweeklyFromValues[key]['start_time'],
            end_time: biweeklyFromValues[key]['end_time'],
          }
          biweekly.push(biweeklyItem)
        })
      }
      const month = [];
      const monthFromValues = values['month'];
      if(monthFromValues) {
        Object.keys(monthFromValues).forEach((key) => {
          const monthItem: RegularReservationMonthSchedule = {
            id: monthFromValues[key]['id'],
            day: monthFromValues[key]['day'],
            start_time: monthFromValues[key]['start_time'],
            end_time: monthFromValues[key]['end_time'],
          }
          month.push(monthItem)
        })
      }
      const specific = [];
      const specificFromValues = values['specific'];
      if(specificFromValues) {
        Object.keys(specificFromValues).forEach((key) => {
          const specificItem: RegularReservationSpecificSchedule = {
            id: specificFromValues[key]['id'],
            day: specificFromValues[key]['day'],
            start_time: specificFromValues[key]['start_time'],
            end_time: specificFromValues[key]['end_time'],
          }
          specific.push(specificItem)
        })
      }

      if(everyday.length == 0 && biweekly.length == 0 && month.length == 0 && specific.length == 0) {
        const emap = new Map()
        emap.set('everyday', 'スケジュールのいずれか一つを必ず指定してください')
        const submissionErrors = Object.fromEntries(emap.entries());
        throw new SubmissionError(submissionErrors)
      }

      let images: RegularImageForUpdate[] = null
      if(values['images'] && values['images'][0] && values['images'][0]['image_file']) {
        images = [{
          id: values['images'][0]['id'],
          image_file: values['images'][0]['image_file']
        }]
      }

console.log('values[show_event_calendar_flg]' + values['show_event_calendar_flg'])

      const payload: RegularReservation = {
        space_id: values['space_id'],
        reserve_id: state.regularReservation.reserve_id,
        start_date: values['start_date'],
        end_date: values['end_date'],
        usage: values['usage'],
        description: values['description'],
        link: values['link'],
        show_event_calendar_flg: Boolean(values['show_event_calendar_flg']),
        contractor: contractor,
        images: images,
        everyday: everyday,
        biweekly: biweekly,
        month: month,
        specific: specific
      }

      ownDispatch(storeRegularReservationLocalAction(payload))
      ownDispatch(storeRegularReservation(payload))
    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if (isDisplay === false && errors) {
      console.log({ errors })

      Object.keys(errors).map((key) => {
        if(errors.hasOwnProperty(key)
          && errors[key].hasOwnProperty(key)) {
            if(typeof(errors[key][key]) == 'string') {
              errors[key] = errors[key][key]
            }
          }
      })      

      const regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
      const toTouch = []

      const touchContractor = []
      const touchEveryday = []
      const touchBiweekly = []
      const touchMonth = []
      const touchSpecific = []

      toTouch['contractor'] = touchContractor
      toTouch['everyday'] = touchEveryday
      toTouch['biweekly'] = touchBiweekly
      toTouch['month'] = touchMonth
      toTouch['specific'] = touchSpecific
      const keyReducer = (err, result) => {
        if(err) {
          Object.keys(err).map((key) => {
            if(regexp.test(key)) {
              result[key] = []
              keyReducer(err[key], result[key])
            } else {
              if(typeof err[key] == 'string') {
                result.push(key)
              } else {
                keyReducer(err[key], result)
              }
            }
          })  
        }
      }
      
      keyReducer(errors.contractor, toTouch['contractor'])
      keyReducer(errors.everyday, toTouch['everyday'])
      keyReducer(errors.biweekly, toTouch['biweekly'])
      keyReducer(errors.month, toTouch['month'])
      keyReducer(errors.specific, toTouch['specific'])      
      keyReducer(errors, toTouch)      

      touch(...toTouch)

      ownDispatch(stopAsyncValidation('RegularReservationForm', errors))
      setIsDisplay(true)
    }
  }  

  const onDelete = () => {
    ownDispatch(cancelRegularReservation(state.regularReservation.reserve_id, state.regularReservation.space_id))
  }

  const _props = {
    onSubmit: submit,
    reserve_id: state.regularReservation.reserve_id,
    validationResult: state.ss422ValidationErrorResult,
    ssValidation: showValidationError,
    autoAddressLoad: autoAddressLoad,
    spaces: state.spaceAll.list,
    vacancyCheckedPeriods: state.regularReservation.reservePeriods,
    initialValues: convertFormInitialValues<RegularReservation>(
      state.regularReservation
    ),
    onDelete: onDelete
  }

  return <RegularReservationForm {..._props} />

}
