import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { checkAppResponse } from '../actionHelper/responseChecker';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { Masters, TaxRate, TaxRateUpdateResult, Facility, FacilitiesUpdateResult, UsageUpdateResult, Usage, TermsOfServiceUpdateResult, TermsOfService } from 'dataObjects/masters';
import { AxiosResponse } from 'axios';
import { Errors } from 'types/formTypes';
import { SnackBarAction } from './actionTypes';
import { ValidationErrorListState } from 'reducers/validationErrorReducer';

/* MasterアクションType */
export const FETCH_MASTER_START = 'FETCH_MASTER_START'
export const FETCH_MASTER_SUCCESS = 'FETCH_MASTER_SUCCESS'
export const FETCH_MASTER_FAIL = 'FETCH_MASTER_FAIL'

/* 税率マスタアクションType */
export const FETCH_TAX_RATE_MASTER_START = 'FETCH_TAX_RATE_MASTER_START'
export const FETCH_TAX_RATE_MASTER_SUCCESS = 'FETCH_TAX_RATE_MASTER_SUCCESS'
export const FETCH_TAX_RATE_MASTER_FAIL = 'FETCH_TAX_RATE_MASTER_FAIL'
export const STORE_TAX_RATE_MASTER_START = 'STORE_TAX_RATE_MASTER_START'
export const STORE_TAX_RATE_MASTER_LOCAL = 'STORE_TAX_RATE_MASTER_LOCAL'
export const STORE_TAX_RATE_MASTER_SUCCESS = 'STORE_TAX_RATE_MASTER_SUCCESS'
export const STORE_TAX_RATE_MASTER_INVALID = 'STORE_TAX_RATE_MASTER_INVALID'
export const STORE_TAX_RATE_MASTER_FAIL = 'STORE_TAX_RATE_MASTER_FAIL'

/* 設備マスタアクションType */
export const FETCH_FACILITIES_MASTER_START = 'FETCH_FACILITIES_MASTER_START'
export const FETCH_FACILITIES_MASTER_SUCCESS = 'FETCH_FACILITIES_MASTER_SUCCESS'
export const FETCH_FACILITIES_MASTER_FAIL = 'FETCH_FACILITIES_MASTER_FAIL'
export const STORE_FACILITIES_MASTER_START = 'STORE_FACILITIES_MASTER_START'
export const STORE_FACILITIES_MASTER_SUCCESS = 'STORE_FACILITIES_MASTER_SUCCESS'
export const STORE_FACILITIES_MASTER_LOCAL = 'STORE_FACILITIES_MASTER_LOCAL'
export const STORE_FACILITIES_MASTER_INVALID = 'STORE_FACILITIES_MASTER_INVALID'
export const STORE_FACILITIES_MASTER_FAIL = 'STORE_FACILITIES_MASTER_FAIL'

/* 利用用途マスタアクションType */
export const FETCH_USAGE_MASTER_START = 'FETCH_USAGE_MASTER_START'
export const FETCH_USAGE_MASTER_SUCCESS = 'FETCH_USAGE_MASTER_SUCCESS'
export const FETCH_USAGE_MASTER_FAIL = 'FETCH_USAGE_MASTER_FAIL'
export const STORE_USAGE_MASTER_START = 'STORE_USAGE_MASTER_START'
export const STORE_USAGE_MASTER_SUCCESS = 'STORE_USAGE_MASTER_SUCCESS'
export const STORE_USAGE_MASTER_LOCAL = 'STORE_USAGE_MASTER_LOCAL'
export const STORE_USAGE_MASTER_INVALID = 'STORE_USAGE_MASTER_INVALID'
export const STORE_USAGE_MASTER_FAIL = 'STORE_USAGE_MASTER_FAIL'

/* 利用規約マスタアクションType */
export const FETCH_TERMS_OF_SERVICE_MASTER_START = 'FETCH_TERMS_OF_SERVICE_MASTER_START'
export const FETCH_TERMS_OF_SERVICE_MASTER_SUCCESS = 'FETCH_TERMS_OF_SERVICE_MASTER_SUCCESS'
export const FETCH_TERMS_OF_SERVICE_MASTER_FAIL = 'FETCH_TERMS_OF_SERVICE_MASTER_FAIL'
export const STORE_TERMS_OF_SERVICE_MASTER_START = 'STORE_TERMS_OF_SERVICE_MASTER_START'
export const STORE_TERMS_OF_SERVICE_MASTER_SUCCESS = 'STORE_TERMS_OF_SERVICE_MASTER_SUCCESS'
export const STORE_TERMS_OF_SERVICE_MASTER_LOCAL = 'STORE_TERMS_OF_SERVICE_MASTER_LOCAL'
export const STORE_TERMS_OF_SERVICE_MASTER_INVALID = 'STORE_TERMS_OF_SERVICE_MASTER_INVALID'
export const STORE_TERMS_OF_SERVICE_MASTER_FAIL = 'STORE_TERMS_OF_SERVICE_MASTER_FAIL'

/* --------------------------------------------------------------------------------- */

/* 全マスタ取得アクション */
export type FetchMasterActions =
  | FetchMasterStartAction
  | FetchMasterSuccessAction
  | FetchMasterFailAction
  | ErrorActions

type MasterThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  FetchMasterActions
>

/* MASTER取得START Action */
export interface FetchMasterStartAction
  extends Action<typeof FETCH_MASTER_START> { }

const fetchMasterStartAction = (): FetchMasterStartAction => {
  return {
    type: FETCH_MASTER_START,
  }
}

/* MASTER取得成功 Action */
export interface FetchMasterSuccessAction
  extends Action<typeof FETCH_MASTER_SUCCESS> {
  payload: Masters
}

const fetchMasterSuccessAction = (payload: Masters)
  : FetchMasterSuccessAction => {
  return {
    type: FETCH_MASTER_SUCCESS,
    payload,
  }
}

/* MASTER取得失敗 Action */
export interface FetchMasterFailAction
  extends SnackBarAction<typeof FETCH_MASTER_FAIL> {
  
}

const fetchMasterFailAction = (
  message: string
): FetchMasterFailAction => {
  return {
    type: FETCH_MASTER_FAIL, 
    snackBarMessage: message,
    variant: "error"
  };
};


/* サーバーからマスタを取得 */
async function fetchMasterData(): Promise<Masters> {  
  const periodDataRes: AxiosResponse<any> = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/masters`);
  const masterData: Masters = await periodDataRes.data;

  return masterData;
}

/* SiteMasterを取得する */
export function fetchMasterAction(): MasterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchMasterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      if (state.master.fetchErrorCount > 5) {
        console.error('state.master.fetchErrorCount over limit');
        dispatch(
          fetchMasterSuccessAction({
            usage: [],
            facilities: [],
            terms_of_service: [],
            items: [],
            taxratetypes: [],
            taxrates: []
          }),
        )
      } else {
        dispatch(fetchMasterStartAction())

        const data: Masters = await fetchMasterData()

        dispatch(
          fetchMasterSuccessAction(data),
        )
      }
    } catch (err) {
      dispatch<FetchMasterActions>(
        await ajaxErrorHandler(err, fetchMasterFailAction)
      )
    }
  }
}


/* --------------------------------------------------------------------------------- */

/* 税率マスタ取得アクション */
export type FetchTaxRateMasterActions =
  | FetchTaxRateMasterStartAction
  | FetchTaxRateMasterSuccessAction
  | FetchTaxRateMasterFailAction
  | ErrorActions

type TaxRateMasterThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  FetchTaxRateMasterActions
>

/* 税率マスタ取得START Action */
export interface FetchTaxRateMasterStartAction
  extends Action<typeof FETCH_TAX_RATE_MASTER_START> { }

const fetchTaxRateMasterStartAction = (): FetchTaxRateMasterStartAction => {
  return {
    type: FETCH_TAX_RATE_MASTER_START,
  }
}

/* 税率マスタ取得成功 Action */
export interface FetchTaxRateMasterSuccessAction
  extends Action<typeof FETCH_TAX_RATE_MASTER_SUCCESS> {
  payload: TaxRate[]
}

const fetchTaxRateMasterSuccessAction = (payload: TaxRate[])
  : FetchTaxRateMasterSuccessAction => {
  return {
    type: FETCH_TAX_RATE_MASTER_SUCCESS,
    payload,
  }
}

/* 税率マスタ取得失敗 Action */
export interface FetchTaxRateMasterFailAction
  extends SnackBarAction<typeof FETCH_TAX_RATE_MASTER_FAIL> {
  
}

const fetchTaxRateMasterFailAction = (
  message: string
): FetchTaxRateMasterFailAction => {
  return {
    type: FETCH_TAX_RATE_MASTER_FAIL,
    snackBarMessage: message,
    variant: "error"
  };
};


/* 税率マスタを取得する */
export function fetchTaxRateMasterAction(): TaxRateMasterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchTaxRateMasterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      
      dispatch(fetchTaxRateMasterStartAction())

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;
      const mastaerDataRes: AxiosResponse<any> = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/taxrate`, header);
      const masterData: TaxRate[] = await mastaerDataRes.data;

      dispatch(
        fetchTaxRateMasterSuccessAction(masterData),
      )
    } catch (err) {
      dispatch<FetchTaxRateMasterActions>(
        await ajaxErrorHandler(err, fetchTaxRateMasterFailAction)
      )
    }
  }
}

/* --------------------------------------------------------------------------------- */

/* 税率マスタ更新アクション */
export type StoreTaxRateMasterActions =
  | StoreTaxRateMasterStartAction
  | StoreTaxRateMasterSuccessAction
  | StoreTaxRateMasterLocalAction
  | StoreTaxRateInvalidAction
  | StoreTaxRateMasterFailAction
  | ErrorActions

type StoreTaxRateMasterThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  StoreTaxRateMasterActions
>

/* 税率マスタ更新START Action */
export interface StoreTaxRateMasterStartAction
  extends Action<typeof STORE_TAX_RATE_MASTER_START> { }

const storeTaxRateMasterStartAction = (): StoreTaxRateMasterStartAction => {
  return {
    type: STORE_TAX_RATE_MASTER_START,
  }
}

/* 税率マスタ更新成功 Action */
export interface StoreTaxRateMasterSuccessAction
  extends Action<typeof STORE_TAX_RATE_MASTER_SUCCESS> {
  payload: TaxRate[]
}

const storeTaxRateMasterSuccessAction = (payload: TaxRate[])
  : StoreTaxRateMasterSuccessAction => {
  return {
    type: STORE_TAX_RATE_MASTER_SUCCESS,
    payload,
  }
}

/* 税率マスタローカル保存 Action */
export interface StoreTaxRateMasterLocalAction
  extends Action<typeof STORE_TAX_RATE_MASTER_LOCAL> {
  payload: TaxRate[]
}

export const storeTaxRateMasterLocalAction = (payload: TaxRate[])
  : StoreTaxRateMasterLocalAction => {
  return {
    type: STORE_TAX_RATE_MASTER_LOCAL,
    payload,
  }
}

export interface StoreTaxRateInvalidAction
  extends Action<typeof STORE_TAX_RATE_MASTER_INVALID> {
  payload: ValidationErrorListState
}

const storeTaxRateInvalidAction = (
  payload: ValidationErrorListState
)
  : StoreTaxRateInvalidAction => {
  return {
    type: STORE_TAX_RATE_MASTER_INVALID,
    payload: payload
  }
}

/* 税率マスタ更新失敗 Action */
export interface StoreTaxRateMasterFailAction
  extends SnackBarAction<typeof STORE_TAX_RATE_MASTER_FAIL> {
  
}

const storeTaxRateMasterFailAction = (
  message: string
): StoreTaxRateMasterFailAction => {
  return {
    type: STORE_TAX_RATE_MASTER_FAIL,
    snackBarMessage: message,
    variant: "error"
  };
};


/* 税率マスタを更新する */
export function storeTaxRateMasterAction(
  values: TaxRate[])
  : StoreTaxRateMasterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreTaxRateMasterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      
      dispatch(storeTaxRateMasterStartAction())

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const payload = {
        taxrate: values
      }

      const mastaerDataRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/taxrate`, header, payload);      
      const body: TaxRateUpdateResult = await mastaerDataRes.data;
      
      if (body.validationResult && body.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeTaxRateInvalidAction({
          message: '税率マスタ更新処理が中断しました。',
          errors: body.validationResult
        }))
      } else {
        dispatch(
          storeTaxRateMasterSuccessAction(body.payload),
        )
      }

      

    } catch (err) {
      dispatch<StoreTaxRateMasterActions>(
        await ajaxErrorHandler(err, storeTaxRateMasterFailAction)
      )
    }
  }
}


/* --------------------------------------------------------------------------------- */

/* 設備マスタ取得アクション */
export type FetchFacilitiesMasterActions =
  | FetchFacilitiesMasterStartAction
  | FetchFacilitiesMasterSuccessAction
  | FetchFacilitiesMasterFailAction
  | ErrorActions

type FacilitiesMasterThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  FetchFacilitiesMasterActions
>

/* 設備マスタ取得START Action */
export interface FetchFacilitiesMasterStartAction
  extends Action<typeof FETCH_FACILITIES_MASTER_START> { }

const fetchFacilitiesMasterStartAction = (): FetchFacilitiesMasterStartAction => {
  return {
    type: FETCH_FACILITIES_MASTER_START,
  }
}

/* 設備マスタ取得成功 Action */
export interface FetchFacilitiesMasterSuccessAction
  extends Action<typeof FETCH_FACILITIES_MASTER_SUCCESS> {
  payload: Facility[]
}

const fetchFacilitiesMasterSuccessAction = (payload: Facility[])
  : FetchFacilitiesMasterSuccessAction => {
  return {
    type: FETCH_FACILITIES_MASTER_SUCCESS,
    payload,
  }
}

/* 設備マスタ取得失敗 Action */
export interface FetchFacilitiesMasterFailAction
  extends SnackBarAction<typeof FETCH_FACILITIES_MASTER_FAIL> {
  
}

const fetchFacilitiesMasterFailAction = (
  message: string
): FetchFacilitiesMasterFailAction => {
  return {
    type: FETCH_FACILITIES_MASTER_FAIL,
    snackBarMessage: message,
    variant: "error"
  };
};


/* 設備マスタを取得する */
export function fetchFacilitiesMasterAction(): FacilitiesMasterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchFacilitiesMasterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      
      dispatch(fetchFacilitiesMasterStartAction())

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;
      const mastaerDataRes: AxiosResponse<any> = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/facilities`, header);
      const masterData: Facility[] = await mastaerDataRes.data;

      dispatch(
        fetchFacilitiesMasterSuccessAction(masterData),
      )
    } catch (err) {
      dispatch<FetchFacilitiesMasterActions>(
        await ajaxErrorHandler(err, fetchFacilitiesMasterFailAction)
      )
    }
  }
}

/* --------------------------------------------------------------------------------- */

/* 設備マスタ更新アクション */
export type StoreFacilitiesMasterActions =
  | StoreFacilitiesMasterStartAction
  | StoreFacilitiesMasterSuccessAction
  | StoreFacilitiesMasterLocalAction
  | StoreFacilitiesInvalidAction
  | StoreFacilitiesMasterFailAction
  | ErrorActions

type StoreFacilitiesMasterThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  StoreFacilitiesMasterActions
>

/* 設備マスタ更新START Action */
export interface StoreFacilitiesMasterStartAction
  extends Action<typeof STORE_FACILITIES_MASTER_START> { }

const storeFacilitiesMasterStartAction = (): StoreFacilitiesMasterStartAction => {
  return {
    type: STORE_FACILITIES_MASTER_START,
  }
}

/* 設備マスタ更新成功 Action */
export interface StoreFacilitiesMasterSuccessAction
  extends Action<typeof STORE_FACILITIES_MASTER_SUCCESS> {
  payload: Facility[]
}

const storeFacilitiesMasterSuccessAction = (payload: Facility[])
  : StoreFacilitiesMasterSuccessAction => {
  return {
    type: STORE_FACILITIES_MASTER_SUCCESS,
    payload,
  }
}

/* 設備マスタローカル保存 Action */
export interface StoreFacilitiesMasterLocalAction
  extends Action<typeof STORE_FACILITIES_MASTER_LOCAL> {
  payload: Facility[]
}

export const storeFacilitiesMasterLocalAction = (payload: Facility[])
  : StoreFacilitiesMasterLocalAction => {
  return {
    type: STORE_FACILITIES_MASTER_LOCAL,
    payload,
  }
}

export interface StoreFacilitiesInvalidAction
  extends Action<typeof STORE_FACILITIES_MASTER_INVALID> {
  payload: ValidationErrorListState
}

const storeFacilitiesInvalidAction = (
  payload: ValidationErrorListState
)
  : StoreFacilitiesInvalidAction => {
  return {
    type: STORE_FACILITIES_MASTER_INVALID,
    payload: payload
  }
}

/* 設備マスタ更新失敗 Action */
export interface StoreFacilitiesMasterFailAction
  extends SnackBarAction<typeof STORE_FACILITIES_MASTER_FAIL> {
  
}

const storeFacilitiesMasterFailAction = (
  message: string
): StoreFacilitiesMasterFailAction => {
  return {
    type: STORE_FACILITIES_MASTER_FAIL,
    snackBarMessage: message,
    variant: "error"
  };
};


/* 設備マスタを更新する */
export function storeFacilitiesMasterAction(
  values: Facility[])
  : StoreFacilitiesMasterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreFacilitiesMasterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      
      dispatch(storeFacilitiesMasterStartAction())

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const payload = {
        facilities: values
      }

      const mastaerDataRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/facilities`, header, payload);      
      const body: FacilitiesUpdateResult = await mastaerDataRes.data;
      
      if (body.validationResult && body.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeFacilitiesInvalidAction({
          message: '設備マスタ更新処理が中断しました。',
          errors: body.validationResult
        }))
      } else {
        dispatch(
          storeFacilitiesMasterSuccessAction(body.payload),
        )
      }

      

    } catch (err) {
      dispatch<StoreFacilitiesMasterActions>(
        await ajaxErrorHandler(err, storeFacilitiesMasterFailAction)
      )
    }
  }
}


/* --------------------------------------------------------------------------------- */

/* 利用用途マスタ取得アクション */
export type FetchUsageMasterActions =
  | FetchUsageMasterStartAction
  | FetchUsageMasterSuccessAction
  | FetchUsageMasterFailAction
  | ErrorActions

type UsageMasterThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  FetchUsageMasterActions
>

/* 利用用途マスタ取得START Action */
export interface FetchUsageMasterStartAction
  extends Action<typeof FETCH_USAGE_MASTER_START> { }

const fetchUsageMasterStartAction = (): FetchUsageMasterStartAction => {
  return {
    type: FETCH_USAGE_MASTER_START,
  }
}

/* 利用用途マスタ取得成功 Action */
export interface FetchUsageMasterSuccessAction
  extends Action<typeof FETCH_USAGE_MASTER_SUCCESS> {
  payload: Usage[]
}

const fetchUsageMasterSuccessAction = (payload: Usage[])
  : FetchUsageMasterSuccessAction => {
  return {
    type: FETCH_USAGE_MASTER_SUCCESS,
    payload,
  }
}

/* 利用用途マスタ取得失敗 Action */
export interface FetchUsageMasterFailAction
  extends SnackBarAction<typeof FETCH_USAGE_MASTER_FAIL> {
  
}

const fetchUsageMasterFailAction = (
  message: string
): FetchUsageMasterFailAction => {
  return {
    type: FETCH_USAGE_MASTER_FAIL,
    snackBarMessage: message,
    variant: "error"
  };
};


/* 利用用途マスタを取得する */
export function fetchUsageMasterAction(): UsageMasterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchUsageMasterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      
      dispatch(fetchUsageMasterStartAction())

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;
      const mastaerDataRes: AxiosResponse<any> = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/usage`, header);
      const masterData: Usage[] = await mastaerDataRes.data;

      dispatch(
        fetchUsageMasterSuccessAction(masterData),
      )
    } catch (err) {
      dispatch<FetchUsageMasterActions>(
        await ajaxErrorHandler(err, fetchUsageMasterFailAction)
      )
    }
  }
}

/* --------------------------------------------------------------------------------- */

/* 利用用途マスタ更新アクション */
export type StoreUsageMasterActions =
  | StoreUsageMasterStartAction
  | StoreUsageMasterSuccessAction
  | StoreUsageMasterLocalAction
  | StoreUsageInvalidAction
  | StoreUsageMasterFailAction
  | ErrorActions

type StoreUsageMasterThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  StoreUsageMasterActions
>

/* 利用用途マスタ更新START Action */
export interface StoreUsageMasterStartAction
  extends Action<typeof STORE_USAGE_MASTER_START> { }

const storeUsageMasterStartAction = (): StoreUsageMasterStartAction => {
  return {
    type: STORE_USAGE_MASTER_START,
  }
}

/* 利用用途マスタ更新成功 Action */
export interface StoreUsageMasterSuccessAction
  extends Action<typeof STORE_USAGE_MASTER_SUCCESS> {
  payload: Usage[]
}

const storeUsageMasterSuccessAction = (payload: Usage[])
  : StoreUsageMasterSuccessAction => {
  return {
    type: STORE_USAGE_MASTER_SUCCESS,
    payload,
  }
}

/* 利用用途マスタローカル保存 Action */
export interface StoreUsageMasterLocalAction
  extends Action<typeof STORE_USAGE_MASTER_LOCAL> {
  payload: Usage[]
}

export const storeUsageMasterLocalAction = (payload: Usage[])
  : StoreUsageMasterLocalAction => {
  return {
    type: STORE_USAGE_MASTER_LOCAL,
    payload,
  }
}

export interface StoreUsageInvalidAction
  extends Action<typeof STORE_USAGE_MASTER_INVALID> {
  payload: ValidationErrorListState
}

const storeUsageInvalidAction = (
  payload: ValidationErrorListState
)
  : StoreUsageInvalidAction => {
  return {
    type: STORE_USAGE_MASTER_INVALID,
    payload: payload
  }
}

/* 利用用途マスタ更新失敗 Action */
export interface StoreUsageMasterFailAction
  extends SnackBarAction<typeof STORE_USAGE_MASTER_FAIL> {
  
}

const storeUsageMasterFailAction = (
  message: string
): StoreUsageMasterFailAction => {
  return {
    type: STORE_USAGE_MASTER_FAIL,
    snackBarMessage: message,
    variant: "error"
  };
};


/* 利用用途マスタを更新する */
export function storeUsageMasterAction(
  values: Facility[])
  : StoreUsageMasterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreUsageMasterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      
      dispatch(storeUsageMasterStartAction())

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const payload = {
        usage: values
      }

      const mastaerDataRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/usage`, header, payload);      
      const body: UsageUpdateResult = await mastaerDataRes.data;
      
      if (body.validationResult && body.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeUsageInvalidAction({
          message: '利用用途マスタ更新処理が中断しました。',
          errors: body.validationResult
        }))
      } else {
        dispatch(
          storeUsageMasterSuccessAction(body.payload),
        )
      }      

    } catch (err) {
      dispatch<StoreUsageMasterActions>(
        await ajaxErrorHandler(err, storeUsageMasterFailAction)
      )
    }
  }
}


/* --------------------------------------------------------------------------------- */

/* 規約マスタ取得アクション */
export type FetchTermsOfServiceMasterActions =
  | FetchTermsOfServiceMasterStartAction
  | FetchTermsOfServiceMasterSuccessAction
  | FetchTermsOfServiceMasterFailAction
  | ErrorActions

type TermsOfServiceMasterThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  FetchTermsOfServiceMasterActions
>

/* 規約マスタ取得START Action */
export interface FetchTermsOfServiceMasterStartAction
  extends Action<typeof FETCH_TERMS_OF_SERVICE_MASTER_START> { }

const fetchTermsOfServiceMasterStartAction = (): FetchTermsOfServiceMasterStartAction => {
  return {
    type: FETCH_TERMS_OF_SERVICE_MASTER_START,
  }
}

/* 規約マスタ取得成功 Action */
export interface FetchTermsOfServiceMasterSuccessAction
  extends Action<typeof FETCH_TERMS_OF_SERVICE_MASTER_SUCCESS> {
  payload: TermsOfService[]
}

const fetchTermsOfServiceMasterSuccessAction = (payload: TermsOfService[])
  : FetchTermsOfServiceMasterSuccessAction => {
  return {
    type: FETCH_TERMS_OF_SERVICE_MASTER_SUCCESS,
    payload,
  }
}

/* 規約マスタ取得失敗 Action */
export interface FetchTermsOfServiceMasterFailAction
  extends SnackBarAction<typeof FETCH_TERMS_OF_SERVICE_MASTER_FAIL> {
  
}

const fetchTermsOfServiceMasterFailAction = (
  message: string
): FetchTermsOfServiceMasterFailAction => {
  return {
    type: FETCH_TERMS_OF_SERVICE_MASTER_FAIL,
    snackBarMessage: message,
    variant: "error"
  };
};


/* 規約マスタを取得する */
export function fetchTermsOfServiceMasterAction(): TermsOfServiceMasterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchTermsOfServiceMasterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      
      dispatch(fetchTermsOfServiceMasterStartAction())

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;
      const mastaerDataRes: AxiosResponse<any> = await AppClient.get(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/termsofservice`, header);
      const masterData: TermsOfService[] = await mastaerDataRes.data;

      dispatch(
        fetchTermsOfServiceMasterSuccessAction(masterData),
      )
    } catch (err) {
      dispatch<FetchTermsOfServiceMasterActions>(
        await ajaxErrorHandler(err, fetchTermsOfServiceMasterFailAction)
      )
    }
  }
}

/* --------------------------------------------------------------------------------- */

/* 規約マスタ更新アクション */
export type StoreTermsOfServiceMasterActions =
  | StoreTermsOfServiceMasterStartAction
  | StoreTermsOfServiceMasterSuccessAction
  | StoreTermsOfServiceMasterLocalAction
  | StoreTermsOfServiceInvalidAction
  | StoreTermsOfServiceMasterFailAction
  | ErrorActions

type StoreTermsOfServiceMasterThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  StoreTermsOfServiceMasterActions
>

/* 規約マスタ更新START Action */
export interface StoreTermsOfServiceMasterStartAction
  extends Action<typeof STORE_TERMS_OF_SERVICE_MASTER_START> { }

const storeTermsOfServiceMasterStartAction = (): StoreTermsOfServiceMasterStartAction => {
  return {
    type: STORE_TERMS_OF_SERVICE_MASTER_START,
  }
}

/* 規約マスタ更新成功 Action */
export interface StoreTermsOfServiceMasterSuccessAction
  extends Action<typeof STORE_TERMS_OF_SERVICE_MASTER_SUCCESS> {
  payload: TermsOfService[]
}

const storeTermsOfServiceMasterSuccessAction = (payload: TermsOfService[])
  : StoreTermsOfServiceMasterSuccessAction => {
  return {
    type: STORE_TERMS_OF_SERVICE_MASTER_SUCCESS,
    payload,
  }
}

/* 規約マスタローカル保存 Action */
export interface StoreTermsOfServiceMasterLocalAction
  extends Action<typeof STORE_TERMS_OF_SERVICE_MASTER_LOCAL> {
  payload: TermsOfService[]
}

export const storeTermsOfServiceMasterLocalAction = (payload: TermsOfService[])
  : StoreTermsOfServiceMasterLocalAction => {
  return {
    type: STORE_TERMS_OF_SERVICE_MASTER_LOCAL,
    payload,
  }
}

export interface StoreTermsOfServiceInvalidAction
  extends Action<typeof STORE_TERMS_OF_SERVICE_MASTER_INVALID> {
  payload: ValidationErrorListState
}

const storeTermsOfServiceInvalidAction = (
  payload: ValidationErrorListState
)
  : StoreTermsOfServiceInvalidAction => {
  return {
    type: STORE_TERMS_OF_SERVICE_MASTER_INVALID,
    payload: payload
  }
}

/* 規約マスタ更新失敗 Action */
export interface StoreTermsOfServiceMasterFailAction
  extends SnackBarAction<typeof STORE_TERMS_OF_SERVICE_MASTER_FAIL> {
  
}

const storeTermsOfServiceMasterFailAction = (
  message: string
): StoreTermsOfServiceMasterFailAction => {
  return {
    type: STORE_TERMS_OF_SERVICE_MASTER_FAIL,
    snackBarMessage: message,
    variant: "error"
  };
};


/* 規約マスタを更新する */
export function storeTermsOfServiceMasterAction(
  values: TermsOfService[])
  : StoreTermsOfServiceMasterThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreTermsOfServiceMasterActions>,
    getState: () => AppState) => {
    try {
      const state = getState();
      
      dispatch(storeTermsOfServiceMasterStartAction())

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const payload = {
        termsofservice: values
      }

      const mastaerDataRes: AxiosResponse<any> = await AppClient.post(`${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/termsofservice`, header, payload);      
      const body: TermsOfServiceUpdateResult = await mastaerDataRes.data;
      
      if (body.validationResult && body.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeTermsOfServiceInvalidAction({
          message: '規約マスタ更新処理が中断しました。',
          errors: body.validationResult
        }))
      } else {
        dispatch(
          storeTermsOfServiceMasterSuccessAction(body.payload),
        )
      }

      

    } catch (err) {
      dispatch<StoreTermsOfServiceMasterActions>(
        await ajaxErrorHandler(err, storeTermsOfServiceMasterFailAction)
      )
    }
  }
}