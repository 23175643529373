import * as React from 'react';
import { Theme, createStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import UserInfoFormContainer from 'containers/UserInfoFormContainer';

interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  description: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#f4f4f4'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    },
    submitButton: {
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
    dialogSubmitButton: {     
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '30px',
      marginRight: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',      
    },
    dialogCancelButton: {     
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      height: '30px',
      fontWeight: 'bold',
      fontSize: '1.4rem',      
    },
    buttonGreen: {
      color: '#FFF',
      backgroundColor: '#26A78E',
    },
    buttonRed: {
      color: '#FFF',
      backgroundColor: '#fa2120',
    },
  })
);

interface OwnProps {
  status: string,
  dialogOpen: boolean,
  accountProcResultDialogOpen: boolean,
  forceDeleteProcResultDialogOpen: boolean,
  handleClose: () => void,
  handleForceDeleteClose: () => void,
  onBack: () => void,
  onSubmit: () => void,
  ban: () => void,
  restore: () => void
}

export type UserDetailPageProps = OwnProps;

const UserDetailPage: React.FC<UserDetailPageProps> = (props: UserDetailPageProps) => {
  const [isShowConfirmDialog, setConfirmDialogState] = React.useState(false)
  const classes = useStyles({} as StyleProps);  
  const { status, onBack, onSubmit, ban, restore } = props

  const showConfirmDialog = () => {
    setConfirmDialogState(true)
  }

  const onSubmitInDialog = () => {
    setConfirmDialogState(false)
    if(onSubmit) {
      onSubmit()
    }
  }

  const onCancelInDialog = () => {
    setConfirmDialogState(false)
  }

  const createBanButton = (status) => {
    const today = new Date()
    let buttonLabel = ''
    let className = classes.submitButton
    let process: () => void

    switch (status) {
      case '0':
        buttonLabel = 'アカウント停止'
        className += ' ' + classes.buttonRed
        process = ban
        break;
      case '1':
        buttonLabel = 'アカウント復旧'
        className += ' ' + classes.buttonGreen
        process = restore
        break;      
      default:
        buttonLabel = ''
    }

    
    return buttonLabel === '' ? null : (

      <Grid container spacing={2} alignItems="center" justify="center" style={{ marginTop: '100px', marginBottom: '20px' }}>
        <Grid item xs={6}>
          <Button fullWidth className={className} onClick={process}>
            {buttonLabel}
          </Button>
        </Grid>
      </Grid>

    )

  }

  return (

    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >

        <Typography variant="h3" className={classes.subtitle}>
          ユーザーデータの更新
        </Typography>

        <UserInfoFormContainer />

        {createBanButton(status)}

        <Grid container spacing={2} alignItems="center" justify="center" style={{ marginTop: '30px', marginBottom: '20px' }}>
          <Grid item xs={6}>
            <Button fullWidth className={classes.submitButton + ' ' + classes.buttonRed} onClick={showConfirmDialog}>
              強制削除
            </Button>
          </Grid>
        </Grid>

        <Dialog
          open={props.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"更新処理結果"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ユーザーアカウント情報が更新されました。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={props.accountProcResultDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"アカウント停止/復旧 処理結果"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ユーザーアカウント状態が切り替えられました。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={props.forceDeleteProcResultDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"アカウント削除 処理結果"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ユーザーアカウントが削除されました。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleForceDeleteClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isShowConfirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">ユーザー削除 実行確認</DialogTitle>
          <DialogContent dividers style={{display: 'flex'}}>
            <div>
              <NewReleasesIcon style={{ width: '30px', height: '30px', color: '#AA0000'}} />
            </div>
            <div>
              <ul>
                  <li>本当に実行してもよろしいですか？ 元に戻すことはできません。</li>
              </ul>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onSubmitInDialog} className={classes.dialogSubmitButton}>
              実行
            </Button>
            <Button onClick={onCancelInDialog} className={classes.dialogCancelButton} autoFocus>
              キャンセル
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container justify="center" spacing={2} style={{marginTop: '30px', marginBottom: '200px'}}>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" color="secondary" style={{height: '50px'}} onClick={onBack}>
              戻る
            </Button>
          </Grid>
        </Grid>

      </Container>
    </BasePageContainer>

  );

}

export default UserDetailPage