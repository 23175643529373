import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';


interface StackedAreaChartData {
  name: string
}


interface OwnProps {
  width: number | string,
  height: number | string,
  data: StackedAreaChartData | any[],
  areaInfo: {
    key: string,
    stroke: string,
    fill: string
  }[]
}

type StackedAreaChartProps = OwnProps

const StackedAreaChart: React.FC<StackedAreaChartProps> = (props: StackedAreaChartProps) => {
  
  
  
  return (
    <AreaChart
      width={props.width}
      height={props.height}
      data={props.data}
      margin={{
        top: 10, right: 30, left: 0, bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      {props.areaInfo.map((a) => <Area type="monotone" dataKey={a.key} stackId="1" stroke={a.stroke} fill={a.fill} />)}
    </AreaChart>
  )
  
}

export default StackedAreaChart