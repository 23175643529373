import { ActionTypes } from '../actions/actionTypes'
import { SpaceListHeaderPagenation } from '../dataObjects/space';
import {
  FETCH_SPACE_LIST_START,
  FETCH_SPACE_LIST_SUCCESS,
  FETCH_SPACE_LIST_FAIL
} from "../actions/spaceAction";
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';



export interface SpaceListHeaderState
  extends SpaceListHeaderPagenation { }

const initialSpaceListHeaderState: SpaceListHeaderState = {
  data: [],
  current_page: 1,
  first_page_url:
    process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/space/list/20?page=1",
  from: null,
  last_page: 1,
  last_page_url:
    process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/space/list//20?page=1",
  next_page_url: null,
  path: process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/space/list/20",
  per_page: 20,
  prev_page_url: null,
  to: null,
  total: 0
};


/* SpaceList Reducer */
const spaceList = (
  state: SpaceListHeaderState = initialSpaceListHeaderState,
  action: ActionTypes
): SpaceListHeaderState => {
  switch (action.type) {
    // ユーザー問い合わせ読み込み
    case FETCH_SPACE_LIST_START:
      return { ...state };
    case FETCH_SPACE_LIST_SUCCESS:
      console.log(action.payload);
      return { ...state, ...action.payload };
    case FETCH_SPACE_LIST_FAIL:
      return { ...state };
    case LOGOUT_SUCCESS:
      return { ...state, ...initialSpaceListHeaderState };
    default:
      return { ...state };
  }
};

export default spaceList;
