import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes';
import { observeShownAction } from 'actions/pagingAction';
import { push } from 'connected-react-router';
import { routes } from 'routes/Route';
import DashboardPage, { DashboardPageProps } from 'pages/DashboardPage';
import { fetchDailyUseCount, fetchTommorowUseCount, fetchWeekUseCount, fetchMonthlySalesDropInUnCompleted, fetchMonthlySalesDropInCompleted, fetchUnReadCommonInquiry, fetchUnReadRegularInquiry, fetchWeekChart, fetchYearUseCount, fetchMonthlySalesRegularCompleted, fetchAuthorizationError, fetchForceCancel, fetchPaymentLimit } from 'actions/dashboardAction';

interface OwnProps {
}

export type DashboardPageContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function DashboardPageContainer(props: DashboardPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  // ユーザーデータ読み込み
  useEffect(() => {
    dispatch(fetchDailyUseCount())
    dispatch(fetchTommorowUseCount())
    dispatch(fetchWeekUseCount())
    dispatch(fetchMonthlySalesDropInUnCompleted())
    dispatch(fetchMonthlySalesDropInCompleted())
    dispatch(fetchMonthlySalesRegularCompleted())
    dispatch(fetchUnReadCommonInquiry())
    dispatch(fetchUnReadRegularInquiry())
    dispatch(fetchAuthorizationError())
    dispatch(fetchPaymentLimit())
    dispatch(fetchForceCancel())
    dispatch(fetchWeekChart())
    dispatch(fetchYearUseCount())

    dispatch(observeShownAction())
  }, []);

  const _props: DashboardPageProps = {
    todayUseCount: state.dailyUseCount,
    tommorowUseCount: state.tommorowUseCount,
    weekUseCount: state.weekUseCount,
    unCompleteSales: state.salesOfDropInUnCompleted,
    completeSales: state.salesOfDropInCompleted,
    regularCompleteSales: state.salesOfRegularCompleted,
    authorizationError: state.authorizationError,
    paymentLimit: state.paymentLimit,
    forceCancel: state.forceCancel,
    commonInquiryCount: state.unReadCommonInquiry,
    regularInquiryCount: state.unReadRegularInquiry,
    weeklyUseChartData: state.weekChart.values,
    weeklyUserChardLoading: state.weekUseCount.isLoading,
    yearUseGrassData: state.yearUseCountGrass.values,
    yearUseGrassDataLoading: state.yearUseCountGrass.isLoading,
  }

  return <DashboardPage {..._props} />

}