import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, stopAsyncValidation } from 'redux-form'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import PromotionCodeForm, { validate } from '../components/PromotionCode/PromotionCodeForm'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'

import { storePromotionCode, storePromotionCodeLocalAction } from 'actions/promotionCodeAction';
import { ValidationError } from 'errors/RequestValidationError';
import { PromotionCode } from 'dataObjects/promotioncode';
import { PromotionCodeState } from 'reducers/promotionCodeReducer';
import { fetchSpaceAll } from 'actions/spaceAction';


interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

export default function PromotionCodeFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    ownDispatch(fetchSpaceAll())
  }, [])

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {
      setIsDisplay(false)
      const list = values.list.map(v => {
        const record: PromotionCode = {
          id: v.id,
          space_id: v.space_id,
          code: v.code,
          enable_from: v.enable_from,
          enable_to: v.enable_to,
          discount: v.discount,
          description: v.description
        }
        return record
      })
      const payload = {
        list: list
      }
      
      ownDispatch(
        storePromotionCodeLocalAction(
          list
        )
      )

      ownDispatch(
        storePromotionCode(
          payload
        )
      )

    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if(isDisplay === false) {
      console.log({errors})     
           
      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)
 
      ownDispatch(stopAsyncValidation('PromotionCodeForm', errors))
      setIsDisplay(true)
    }
  }  

  const _props = {
    onSubmit: submit,
    spaces: state.spaceAll.list,
    validationResult: state.ss422ValidationErrorResult,
    ss422Validation: showValidationError,
    initialValues: convertFormInitialValues<PromotionCodeState>(state.promotionCode)
  }

  return <PromotionCodeForm {..._props} />

}