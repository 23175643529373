import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, stopAsyncValidation, startAsyncValidation } from 'redux-form'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import SpaceEditForm, { SpaceEditFormPropsType, validate } from '../components/Space/SpaceEditForm'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'
import { ValidationError } from 'errors/RequestValidationError';
import { storeSpace, storeSpaceLocalAction } from '../actions/spaceAction'
import { SpaceForUpdate, SpaceUsageForUpdate, SpaceFacilityForUpdate, SpaceFaqForUpdate, SpaceAccessForUpdate, SpaceBusinessDayOfWeekForUpdate, SpaceDropinPricingForUpdate, SpaceDropinSpecificDayPricingForUpdate, SpaceRegularPricingForUpdate, SpaceRegularSpecificDayPricingForUpdate, SpaceTermsOfServiceForUpdate, SpaceImageForUpdate, SpaceProhibitedMatter } from 'dataObjects/space';

interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

export default function SpaceEditFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const [specialError, setSpecialError] = useState({})
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()  

  useEffect(() => {    
    return () => {      
      setSpecialError({})
    }
  }, [])

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)

    } else { 
      setIsDisplay(false)
      setSpecialError([])

      const formValue_space = values['space']
      const space = {
        id: state.spaceForUpdate.space.id,
        name: formValue_space['name'],
        address: formValue_space['address'],
        area: formValue_space['area'],
        capacity: formValue_space['capacity'],
        around: formValue_space['around'],
        enter_and_leave_method: formValue_space['enter_and_leave_method'],
        description: formValue_space['description'],
        map_lat: formValue_space['map_lat'],
        map_lng: formValue_space['map_lng'],
        reservation_stop: formValue_space['reservation_stop']
      } 

      const space_usage = [];
      const formValue_space_usage = values['space_usage'];
      if(formValue_space_usage) {
        Object.keys(formValue_space_usage).forEach((key) => {
          const usg: SpaceUsageForUpdate = {
            id: formValue_space_usage[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            usage: formValue_space_usage[key]['usage'],
          }
          space_usage.push(usg)
        })
      }      

      const space_facilities = [];
      const formValue_space_facilities = values['space_facilities'];
      if(formValue_space_facilities) {
        Object.keys(formValue_space_facilities).forEach((key) => {
          const fac: SpaceFacilityForUpdate = {
            id: formValue_space_facilities[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            facility_id: formValue_space_facilities[key]['facility_id'],
          }
          space_facilities.push(fac)
        })
      }

      const space_access = [];
      const formValue_space_access = values['space_access'];
      if(formValue_space_access) {
        Object.keys(formValue_space_access).forEach((key) => {
          const acc: SpaceAccessForUpdate = {
            id: formValue_space_access[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            type: formValue_space_access[key]['type'],
            description: formValue_space_access[key]['description'],
          }
          space_access.push(acc)
        })
      }

      const space_prohibited_matter = []
      const formValue_space_prohibited_matter = values['space_prohibited_matter'];
      if(formValue_space_prohibited_matter) {
        Object.keys(formValue_space_prohibited_matter).forEach((key) => {
          const phm: SpaceProhibitedMatter = {
            id: formValue_space_prohibited_matter[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            prohibited_matter: formValue_space_prohibited_matter[key]['prohibited_matter']
          }
          space_prohibited_matter.push(phm)
        })
      }

      const space_faq = [];
      const formValue_space_faq = values['space_faq'];
      if(formValue_space_faq) {
        Object.keys(formValue_space_faq).forEach((key) => {
          const faq: SpaceFaqForUpdate = {
            id: formValue_space_faq[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            question: formValue_space_faq[key]['question'],
            answer: formValue_space_faq[key]['answer'],
          }
          space_faq.push(faq)
        })
      }

      const formValue_rental_plan = values['space_rental_plan']
      const space_rental_plan = {
        id: formValue_rental_plan['id'],
        space_id: state.spaceForUpdate.space.id,
        period_unit: formValue_rental_plan['period_unit'],
        at_least_reserve_unit: formValue_rental_plan['at_least_reserve_unit']
      } 

      const space_business_dayofweek = [];
      const formValue_space_business_dayofweek = values['space_business_dayofweek'];
      if(formValue_space_business_dayofweek) {
        Object.keys(formValue_space_business_dayofweek).forEach((key) => {
          const biz: SpaceBusinessDayOfWeekForUpdate = {
            id: formValue_space_business_dayofweek[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            day_of_week: formValue_space_business_dayofweek[key]['day_of_week'],
            opening_time: formValue_space_business_dayofweek[key]['opening_time'],
            closing_time: formValue_space_business_dayofweek[key]['closing_time']
          }
          space_business_dayofweek.push(biz)
        })
      }

      const space_dropin_pricing = [];
      const formValue_space_dropin_pricing = values['space_dropin_pricing'];
      if(formValue_space_dropin_pricing) {
        Object.keys(formValue_space_dropin_pricing).forEach((key) => {
          const dp: SpaceDropinPricingForUpdate = {
            id: formValue_space_dropin_pricing[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            day_of_week: formValue_space_dropin_pricing[key]['day_of_week'],
            start_time: formValue_space_dropin_pricing[key]['start_time'],
            end_time: formValue_space_dropin_pricing[key]['end_time'],
            price: formValue_space_dropin_pricing[key]['price']
          }
          space_dropin_pricing.push(dp)
        })
      }
      
      const space_dropin_specific_day_pricing = [];
      const formValue_space_dropin_specific_day_pricing = values['space_dropin_specific_day_pricing'];
      if(formValue_space_dropin_specific_day_pricing) {
        Object.keys(formValue_space_dropin_specific_day_pricing).forEach((key) => {
          const dsp: SpaceDropinSpecificDayPricingForUpdate = {
            id: formValue_space_dropin_specific_day_pricing[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            day: formValue_space_dropin_specific_day_pricing[key]['day'],
            start_time: formValue_space_dropin_specific_day_pricing[key]['start_time'],
            end_time: formValue_space_dropin_specific_day_pricing[key]['end_time'],
            price: formValue_space_dropin_specific_day_pricing[key]['price']
          }
          space_dropin_specific_day_pricing.push(dsp)
        })
      }

      const space_regular_pricing = [];
      const formValue_space_regular_pricing = values['space_regular_pricing'];
      if(formValue_space_regular_pricing) {
        Object.keys(formValue_space_regular_pricing).forEach((key) => {
          const dp: SpaceRegularPricingForUpdate = {
            id: formValue_space_regular_pricing[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            day_of_week: formValue_space_regular_pricing[key]['day_of_week'],
            start_time: formValue_space_regular_pricing[key]['start_time'],
            end_time: formValue_space_regular_pricing[key]['end_time'],
            price: formValue_space_regular_pricing[key]['price']
          }
          space_regular_pricing.push(dp)
        })
      }
      
      const space_regular_specific_day_pricing = [];
      const formValue_space_regular_specific_day_pricing = values['space_regular_specific_day_pricing'];
      if(formValue_space_regular_specific_day_pricing) {
        Object.keys(formValue_space_regular_specific_day_pricing).forEach((key) => {
          const dsp: SpaceRegularSpecificDayPricingForUpdate = {
            id: formValue_space_regular_specific_day_pricing[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            day: formValue_space_regular_specific_day_pricing[key]['day'],
            start_time: formValue_space_regular_specific_day_pricing[key]['start_time'],
            end_time: formValue_space_regular_specific_day_pricing[key]['end_time'],
            price: formValue_space_regular_specific_day_pricing[key]['price']
          }
          space_regular_specific_day_pricing.push(dsp)
        })
      }
      
      const formValue_space_term_of_service = values['space_term_of_service']
      const space_term_of_service = {
        id: formValue_space_term_of_service['id'],
        space_id: state.spaceForUpdate.space.id,
        terms_of_service_id: formValue_space_term_of_service['terms_of_service_id']
      }      


      const space_images = []
      const formValue_space_images = values['space_images'];
      if(formValue_space_images) {
        Object.keys(formValue_space_images).forEach((key) => {
          const img: SpaceImageForUpdate = {
            id: formValue_space_images[key]['id'],
            space_id: state.spaceForUpdate.space.id,
            parts_type: formValue_space_images[key]['parts_type'],
            title: formValue_space_images[key]['title'],
            file: formValue_space_images[key]['file'],
            order_number: formValue_space_images[key]['order_number'],
            image_file: formValue_space_images[key]['image_file']
          }
          space_images.push(img)
        })
      }


      const payload: SpaceForUpdate = {
        space: space,
  
        space_usage: space_usage,
  
        space_facilities: space_facilities,
  
        space_access: space_access,
  
        space_prohibited_matter: space_prohibited_matter,

        space_faq: space_faq,
  
        space_rental_plan: space_rental_plan,
  
        space_business_dayofweek: space_business_dayofweek,
  
        space_dropin_pricing: space_dropin_pricing,
  
        space_dropin_specific_day_pricing: space_dropin_specific_day_pricing,
  
        space_regular_pricing: space_regular_pricing,
  
        space_regular_specific_day_pricing: space_regular_specific_day_pricing,
  
        space_term_of_service: space_term_of_service,

        space_images: space_images
      }

      ownDispatch(storeSpaceLocalAction(payload))
      ownDispatch(storeSpace(payload))

    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if (isDisplay === false) {
      console.log({ errors })

      ownDispatch(startAsyncValidation('SpaceEditForm'))

      if(errors.hasOwnProperty('space')) {
        Object.keys(errors['space']).forEach((key) => {
          errors['space.' + key] = errors['space'][key]
        })          
      }      

      if(errors.hasOwnProperty('space_rental_plan')) {
        Object.keys(errors['space_rental_plan']).forEach((key) => {
          errors['space_rental_plan.' + key] = errors['space_rental_plan'][key]
        })          
      }


      if(errors.hasOwnProperty('space_usage')
        && errors['space_usage'].hasOwnProperty('space_usage')) {                
          specialError['space_usage'] = errors['space_usage']['space_usage']          
      }
      if(errors.hasOwnProperty('space_facilities')
        && errors['space_facilities'].hasOwnProperty('space_facilities')) {                
          specialError['space_facilities'] = errors['space_facilities']['space_facilities']          
      }
      if(errors.hasOwnProperty('space_prohibited_matter')
        && errors['space_prohibited_matter'].hasOwnProperty('space_prohibited_matter')) {                          
          specialError['space_prohibited_matter'] = errors['space_prohibited_matter']['space_prohibited_matter']          
      }
      if(errors.hasOwnProperty('space_access')
        && errors['space_access'].hasOwnProperty('space_access')) {                
          specialError['space_access'] = errors['space_access']['space_access']          
      }
      if(errors.hasOwnProperty('space_faq')
        && errors['space_faq'].hasOwnProperty('space_faq')) {                          
          specialError['space_faq'] = errors['space_faq']['space_faq']          
      }

      if(errors.hasOwnProperty('space_business_dayofweek')
        && errors['space_business_dayofweek'].hasOwnProperty('space_business_dayofweek')) {                
          specialError['space_business_dayofweek'] = errors['space_business_dayofweek']['space_business_dayofweek']          
      }
      if(errors.hasOwnProperty('space_dropin_pricing')
        && errors['space_dropin_pricing'].hasOwnProperty('space_dropin_pricing')) {                                  
          specialError['space_dropin_pricing'] = errors['space_dropin_pricing']['space_dropin_pricing']          
      }
      if(errors.hasOwnProperty('space_dropin_specific_day_pricing')
        && errors['space_dropin_specific_day_pricing'].hasOwnProperty('space_dropin_specific_day_pricing')) {              
          specialError['space_dropin_specific_day_pricing'] = errors['space_dropin_specific_day_pricing']['space_dropin_specific_day_pricing']          
      }
      if(errors.hasOwnProperty('space_regular_pricing')
        && errors['space_regular_pricing'].hasOwnProperty('space_regular_pricing')) {      
          specialError['space_regular_pricing'] = errors['space_regular_pricing']['space_regular_pricing']          
      }
      if(errors.hasOwnProperty('space_regular_specific_day_pricing')
        && errors['space_regular_specific_day_pricing'].hasOwnProperty('space_regular_specific_day_pricing')) {      
          specialError['space_regular_specific_day_pricing'] = errors['space_regular_specific_day_pricing']['space_regular_specific_day_pricing']          
      }

      setSpecialError(specialError)
      
      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)

      setIsDisplay(true)      
      ownDispatch(stopAsyncValidation('SpaceEditForm', errors))
      
    }
  }  

  const _props = {
    onSubmit: submit,
    validationResult: state.ss422ValidationErrorResult,
    ssValidation: showValidationError,
    initialValues: convertFormInitialValues<SpaceForUpdate>(state.spaceForUpdate),
    specialError: specialError,
    masters: {
      usage: state.master.usage,
      facilities: state.master.facilities,
      terms_of_services: state.master.terms_of_service,
      taxrates: state.master.taxrates,
      taxratetypes: state.master.taxratetypes
    }
  }

  return <SpaceEditForm {..._props} />

}