import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { routes } from 'routes/Route';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { ValidationErrorListState } from 'reducers/validationErrorReducer';
import { PromotionCode, PromotionCodeUpdateResult, PromotionCodeStoreRequest } from '../dataObjects/promotioncode'
import { SnackBarAction } from './actionTypes';
import { dateToString } from 'utils/convertor'


/**
 * PROMOTIONCODE一覧取得アクション
 */
export const FETCH_PROMOTIONCODE_LIST_START = 'FETCH_PROMOTIONCODE_LIST_START';
export const FETCH_PROMOTIONCODE_LIST_SUCCESS = 'FETCH_PROMOTIONCODE_LIST_SUCCESS';
export const FETCH_PROMOTIONCODE_LIST_FAIL = 'FETCH_PROMOTIONCODE_LIST_FAIL';


export type FetchPromotionCodeListActions =
  | FetchPromotionCodeListStartAction
  | FetchPromotionCodeListSuccessAction
  | FetchPromotionCodeListFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchPromotionCodeListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchPromotionCodeListActions>;


/**
 * PROMOTIONCODE 更新アクション
 */
export const STORE_PROMOTIONCODE_START = 'STORE_PROMOTIONCODE_START';
export const STORE_PROMOTIONCODE_LOCAL = 'STORE_PROMOTIONCODE_LOCAL';
export const STORE_PROMOTIONCODE_SUCCESS = 'STORE_PROMOTIONCODE_SUCCESS';
export const STORE_PROMOTIONCODE_INVALID = 'STORE_PROMOTIONCODE_INVALID';
export const STORE_PROMOTIONCODE_FAIL = 'STORE_PROMOTIONCODE_FAIL';

export type StorePromotionCodeActions =
  | StorePromotionCodeStartAction
  | StorePromotionCodeSuccessAction
  | StorePromotionCodeInvalidAction
  | StorePromotionCodeFailAction
  | StorePromotionCodeLocalAction
  | CallHistoryMethodAction
  | ErrorActions;

type StorePromotionCodeThunkResult<R> = ThunkAction<R, AppState, undefined, StorePromotionCodeActions>;


/* ---------------------------------------------------------------------------------------------------- */


export interface FetchPromotionCodeListStartAction
  extends Action<typeof FETCH_PROMOTIONCODE_LIST_START> { }

const fetchPromotionCodeListStartAction = (): FetchPromotionCodeListStartAction => ({
  type: 'FETCH_PROMOTIONCODE_LIST_START',
});

export interface FetchPromotionCodeListSuccessAction
  extends Action<typeof FETCH_PROMOTIONCODE_LIST_SUCCESS> {
  payload: PromotionCode[]
}

const fetchPromotionCodeListSuccessAction = (
  list: PromotionCode[],
): FetchPromotionCodeListSuccessAction => ({
  type: 'FETCH_PROMOTIONCODE_LIST_SUCCESS',
  payload: list
})

export interface FetchPromotionCodeListFailAction
  extends SnackBarAction<typeof FETCH_PROMOTIONCODE_LIST_FAIL> {

}

const fetchPromotionCodeListFailAction = (message: string): FetchPromotionCodeListFailAction => ({
  type: 'FETCH_PROMOTIONCODE_LIST_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* PROMOTIONCODE一覧 取得 ThunkAction */
export function fetchPromotionCodeList(): FetchPromotionCodeListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchPromotionCodeListActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchPromotionCodeListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/promotioncode`
      const response = await AppClient.get(url, header)
      const body: PromotionCode[] = await response.data;

      const toString = Object.prototype.toString
      body.forEach((value) => {
        value.enable_from = toString.call(value.enable_from) == '[object Date]' ? value.enable_from : new Date(Date.parse(dateToString(value.enable_from).replace(/-/g,"/")))
        value.enable_to = toString.call(value.enable_to) == '[object Date]' ? value.enable_to : new Date(Date.parse(dateToString(value.enable_to).replace(/-/g,"/")))
      })

      dispatch(
        fetchPromotionCodeListSuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchPromotionCodeListActions>(
        await ajaxErrorHandler(err, fetchPromotionCodeListFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */

export interface StorePromotionCodeStartAction
  extends Action<typeof STORE_PROMOTIONCODE_START> { }

const storePromotionCodeStartAction = (): StorePromotionCodeStartAction => ({
  type: 'STORE_PROMOTIONCODE_START',
});

export interface StorePromotionCodeLocalAction
  extends Action<typeof STORE_PROMOTIONCODE_LOCAL> {
  payload: PromotionCode[]
}

export const storePromotionCodeLocalAction = (
  data: PromotionCode[]
): StorePromotionCodeLocalAction => ({
  type: 'STORE_PROMOTIONCODE_LOCAL',
  payload: data
})

export interface StorePromotionCodeSuccessAction
  extends Action<typeof STORE_PROMOTIONCODE_SUCCESS> {
  payload: PromotionCodeUpdateResult
}

const storePromotionCodeSuccessAction = (
  data: PromotionCodeUpdateResult,
): StorePromotionCodeSuccessAction => ({
  type: 'STORE_PROMOTIONCODE_SUCCESS',
  payload: data
})

export interface StorePromotionCodeInvalidAction
  extends Action<typeof STORE_PROMOTIONCODE_INVALID> {
  payload: ValidationErrorListState
}

const storePromotionCodeInvalidAction = (
  payload: ValidationErrorListState
)
  : StorePromotionCodeInvalidAction => {
  return {
    type: STORE_PROMOTIONCODE_INVALID,
    payload: payload
  }
}

export interface StorePromotionCodeFailAction
  extends SnackBarAction<typeof STORE_PROMOTIONCODE_FAIL> {

}

const storePromotionCodeFailAction = (message: string)
  : StorePromotionCodeFailAction => ({
    type: 'STORE_PROMOTIONCODE_FAIL',
    snackBarMessage: message,
    variant: "error"
  })


/* PROMOTIONCODE 更新 ThunkAction */
export function storePromotionCode(payload: PromotionCodeStoreRequest): StorePromotionCodeThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StorePromotionCodeActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(storePromotionCodeStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/promotioncode/store`;
      const response = await AppClient.post(url, header, payload)
      const data: PromotionCodeUpdateResult = await response.data;
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storePromotionCodeInvalidAction({
          message: 'プロモーションコードの登録処理が中断しました。',
          errors: data.validationResult
        }))
      } else {
        dispatch(
          storePromotionCodeSuccessAction(data)
        )
      }

    } catch (err) {
      dispatch<StorePromotionCodeActions>(
        await ajaxErrorHandler(err, storePromotionCodeFailAction),
      )
    }
  }
}