import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { checkAppResponse } from '../actionHelper/responseChecker';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { routes } from 'routes/Route';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import { InquiryListHeaderPagenation, InquirySearchCondition, AcceptInquiry } from 'dataObjects/inquiry';
import { SnackBarAction } from './actionTypes';



/**
 * 問い合わせ一覧取得アクション
 */
export const FETCH_COMMON_INQUIRY_LIST_START = 'FETCH_COMMON_INQUIRY_LIST_START';
export const FETCH_COMMON_INQUIRY_LIST_SUCCESS = 'FETCH_COMMON_INQUIRY_LIST_SUCCESS';
export const FETCH_COMMON_INQUIRY_LIST_FAIL = 'FETCH_COMMON_INQUIRY_LIST_FAIL';
/**
 * 問い合わせ 検索条件 保存
 */
export const STORE_COMMON_INQUIRY_LIST_CONDITION = 'STORE_COMMON_INQUIRY_LIST_CONDITION'

export type FetchCommonInquiryListActions =
  | FetchCommonInquiryListStartAction
  | FetchCommonInquiryListSuccessAction
  | FetchCommonInquiryListFailAction
  | StoreCommonInquiryListConditionAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchCommonInquiryListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchCommonInquiryListActions>;


/**
 * 問い合わせ 取得アクション
 */
export const FETCH_COMMON_INQUIRY_START = 'FETCH_COMMON_INQUIRY_START';
export const FETCH_COMMON_INQUIRY_SUCCESS = 'FETCH_COMMON_INQUIRY_SUCCESS';
export const FETCH_COMMON_INQUIRY_FAIL = 'FETCH_COMMON_INQUIRY_FAIL';

export type FetchCommonInquiryActions =
  | FetchCommonInquiryStartAction
  | FetchCommonInquirySuccessAction
  | FetchCommonInquiryFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchCommonInquiryThunkResult<R> = ThunkAction<R, AppState, undefined, FetchCommonInquiryActions>;


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 定期利用問い合わせ一覧取得アクション
 */
export const FETCH_REGULAR_INQUIRY_LIST_START = 'FETCH_REGULAR_INQUIRY_LIST_START';
export const FETCH_REGULAR_INQUIRY_LIST_SUCCESS = 'FETCH_REGULAR_INQUIRY_LIST_SUCCESS';
export const FETCH_REGULAR_INQUIRY_LIST_FAIL = 'FETCH_REGULAR_INQUIRY_LIST_FAIL';
/**
 * 定期利用問い合わせ 検索条件 保存
 */
export const STORE_REGULAR_INQUIRY_LIST_CONDITION = 'STORE_REGULAR_INQUIRY_LIST_CONDITION'

export type FetchRegularInquiryListActions =
  | FetchRegularInquiryListStartAction
  | FetchRegularInquiryListSuccessAction
  | FetchRegularInquiryListFailAction
  | StoreRegularInquiryListConditionAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchRegularInquiryListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchRegularInquiryListActions>;



/**
 * 定期利用問い合わせ 取得アクション
 */
export const FETCH_REGULAR_INQUIRY_START = 'FETCH_REGULAR_INQUIRY_START';
export const FETCH_REGULAR_INQUIRY_SUCCESS = 'FETCH_REGULAR_INQUIRY_SUCCESS';
export const FETCH_REGULAR_INQUIRY_FAIL = 'FETCH_REGULAR_INQUIRY_FAIL';

export type FetchRegularInquiryActions =
  | FetchRegularInquiryStartAction
  | FetchRegularInquirySuccessAction
  | FetchCommonInquirySuccessAction
  | FetchRegularInquiryFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchRegularInquiryThunkResult<R> = ThunkAction<R, AppState, undefined, FetchRegularInquiryActions>;


/* ---------------------------------------------------------------------------------------------------- */


export interface FetchCommonInquiryListStartAction
  extends Action<typeof FETCH_COMMON_INQUIRY_LIST_START> { }

const fetchCommonInquiryListStartAction = (): FetchCommonInquiryListStartAction => ({
  type: 'FETCH_COMMON_INQUIRY_LIST_START',
});

export interface FetchCommonInquiryListSuccessAction
  extends Action<typeof FETCH_COMMON_INQUIRY_LIST_SUCCESS> {
  payload: InquiryListHeaderPagenation
}

const fetchCommonInquiryListSuccessAction = (
  headerList: InquiryListHeaderPagenation
): FetchCommonInquiryListSuccessAction => ({
  type: "FETCH_COMMON_INQUIRY_LIST_SUCCESS",
  payload: headerList
});

export interface FetchCommonInquiryListFailAction
  extends SnackBarAction<typeof FETCH_COMMON_INQUIRY_LIST_FAIL> {  
}

const fetchCommonInquiryListFailAction = (message: string): FetchCommonInquiryListFailAction => ({
  type: 'FETCH_COMMON_INQUIRY_LIST_FAIL',
  snackBarMessage: message,
  variant: "error"
})

/**
 * 問い合わせ 検索条件 保存
 */
export interface StoreCommonInquiryListConditionAction
  extends Action<typeof STORE_COMMON_INQUIRY_LIST_CONDITION> {
  payload: InquirySearchCondition;
}

const storeUserListCondition = (
  condition: InquirySearchCondition
): StoreCommonInquiryListConditionAction => ({
  type: "STORE_COMMON_INQUIRY_LIST_CONDITION",
  payload: condition
});

/* 問い合わせ一覧 取得 ThunkAction */
export function fetchCommonInquiryList(
  condition: InquirySearchCondition,
  perpage: number = 20,
  page: number = 0
): FetchCommonInquiryListThunkResult<void> {
  return async (
    dispatch: ThunkDispatch<AppState, any, FetchCommonInquiryListActions>,
    getState: () => AppState
  ) => {
    try {
      const state = getState();

      dispatch(fetchCommonInquiryListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const url = `${
        process.env.REACT_APP_API_SERVER_HOST
        }api/backoffice/inquiry/list/${perpage}?page=${page + 1}`;
      const response = await AppClient.get(url, header, condition);
      const body: InquiryListHeaderPagenation = await response.data;
      dispatch(storeUserListCondition(condition));
      dispatch(fetchCommonInquiryListSuccessAction(body));
    } catch (err) {
      dispatch<FetchCommonInquiryListActions>(
        await ajaxErrorHandler(err, fetchCommonInquiryListFailAction)
      );
    }
  };
}

/* ---------------------------------------------------------------------------------------------------- */

export interface FetchCommonInquiryStartAction
  extends Action<typeof FETCH_COMMON_INQUIRY_START> { }

const fetchCommonInquiryStartAction = (): FetchCommonInquiryStartAction => ({
  type: 'FETCH_COMMON_INQUIRY_START',
});

export interface FetchCommonInquirySuccessAction
  extends Action<typeof FETCH_COMMON_INQUIRY_SUCCESS> {
  payload: AcceptInquiry
}

const fetchCommonInquirySuccessAction = (
  data: AcceptInquiry,
): FetchCommonInquirySuccessAction => ({
  type: 'FETCH_COMMON_INQUIRY_SUCCESS',
  payload: data
})

export interface FetchCommonInquiryFailAction
  extends Action<typeof FETCH_COMMON_INQUIRY_FAIL> {
  message: string;
}

const fetchCommonInquiryFailAction = (message: string): FetchCommonInquiryFailAction => ({
  type: 'FETCH_COMMON_INQUIRY_FAIL',
  message,
})


/* 問い合わせ 取得 ThunkAction */
export function fetchCommonInquiry(inquiry_id: number): FetchCommonInquiryThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchCommonInquiryActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchCommonInquiryStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/inquiry/find/${inquiry_id}`;
      const response = await AppClient.get(url, header)
      const body: AcceptInquiry = await response.data;

      const openedUrl = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/inquiry/opened/${inquiry_id}`;
      const opResponse = await AppClient.post(openedUrl, header);

      dispatch(
        fetchCommonInquirySuccessAction(body)
      )
      dispatch(push(routes.inquiryDetail.path))
    } catch (err) {
      dispatch<FetchCommonInquiryActions>(
        await ajaxErrorHandler(err, fetchCommonInquiryFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */

export interface FetchRegularInquiryListStartAction
  extends Action<typeof FETCH_REGULAR_INQUIRY_LIST_START> { }

const fetchRegularInquiryListStartAction = (): FetchRegularInquiryListStartAction => ({
  type: 'FETCH_REGULAR_INQUIRY_LIST_START',
});

export interface FetchRegularInquiryListSuccessAction
  extends Action<typeof FETCH_REGULAR_INQUIRY_LIST_SUCCESS> {
  payload: InquiryListHeaderPagenation
}

const fetchRegularInquiryListSuccessAction = (
  headerList: InquiryListHeaderPagenation
): FetchRegularInquiryListSuccessAction => ({
  type: "FETCH_REGULAR_INQUIRY_LIST_SUCCESS",
  payload: headerList
});

export interface FetchRegularInquiryListFailAction
  extends Action<typeof FETCH_REGULAR_INQUIRY_LIST_FAIL> {
  message: string;
}

const fetchRegularInquiryListFailAction = (message: string): FetchRegularInquiryListFailAction => ({
  type: 'FETCH_REGULAR_INQUIRY_LIST_FAIL',
  message,
})

/**
 * 問い合わせ 検索条件 保存
 */
export interface StoreRegularInquiryListConditionAction
  extends Action<typeof STORE_REGULAR_INQUIRY_LIST_CONDITION> {
  payload: InquirySearchCondition;
}

const storeRegularInquiryListCondition = (
  condition: InquirySearchCondition
): StoreRegularInquiryListConditionAction => ({
    type: "STORE_REGULAR_INQUIRY_LIST_CONDITION",
  payload: condition
});

/* 問い合わせ一覧 取得 ThunkAction */
export function fetchRegularInquiryList(
  condition: InquirySearchCondition,
  perpage: number = 20,
  page: number = 0
): FetchRegularInquiryListThunkResult<void> {
  return async (
    dispatch: ThunkDispatch<AppState, any, FetchRegularInquiryListActions>,
    getState: () => AppState
  ) => {
    try {
      const state = getState();

      dispatch(fetchRegularInquiryListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const url = `${
        process.env.REACT_APP_API_SERVER_HOST
        }api/backoffice/regularinquiry/list/${perpage}?page=${page + 1}`;
      const response = await AppClient.get(url, header, condition);

      const body: InquiryListHeaderPagenation = await response.data;
      dispatch(storeRegularInquiryListCondition(condition));
      dispatch(fetchRegularInquiryListSuccessAction(body));
    } catch (err) {
      dispatch<FetchRegularInquiryListActions>(
        await ajaxErrorHandler(err, fetchRegularInquiryListFailAction)
      );
    }
  };
}

/* ---------------------------------------------------------------------------------------------------- */


export interface FetchRegularInquiryStartAction
  extends Action<typeof FETCH_REGULAR_INQUIRY_START> { }

const fetchRegularInquiryStartAction = (): FetchRegularInquiryStartAction => ({
  type: 'FETCH_REGULAR_INQUIRY_START',
});

export interface FetchRegularInquirySuccessAction
  extends Action<typeof FETCH_REGULAR_INQUIRY_SUCCESS> {
  payload: AcceptInquiry
}

const fetchRegularInquirySuccessAction = (
  data: AcceptInquiry,
): FetchRegularInquirySuccessAction => ({
  type: 'FETCH_REGULAR_INQUIRY_SUCCESS',
  payload: data
})

export interface FetchRegularInquiryFailAction
  extends SnackBarAction<typeof FETCH_REGULAR_INQUIRY_FAIL> {
  
}

const fetchRegularInquiryFailAction = (message: string): FetchRegularInquiryFailAction => ({
  type: 'FETCH_REGULAR_INQUIRY_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* 問い合わせ 取得 ThunkAction */
export function fetchRegularInquiry(inquiry_id: number): FetchRegularInquiryThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchRegularInquiryActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchRegularInquiryStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/regularinquiry/find/${inquiry_id}`;
      const response = await AppClient.get(url, header)
      const body: AcceptInquiry = await response.data;

      const openedUrl = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/regularinquiry/opened/${inquiry_id}`;
      const opResponse = await AppClient.post(openedUrl, header);

      dispatch(
        fetchRegularInquirySuccessAction(body)
      )
      dispatch(push(routes.regularInquiryDetail.path))
    } catch (err) {
      dispatch<FetchRegularInquiryActions>(
        await ajaxErrorHandler(err, fetchRegularInquiryFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */
