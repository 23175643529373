import { ActionTypes } from "../actions/actionTypes";
import { RegularReservation } from "dataObjects/space";
import {
  FETCH_REGULAR_RESERVATION_START,
  FETCH_REGULAR_RESERVATION_SUCCESS ,
  FETCH_REGULAR_RESERVATION_FAIL,
  STORE_REGULAR_RESERVATION_LOCAL,
  CLEAR_REGULAR_RESERVATION_LOCAL,
  STORE_REGULAR_RESERVATION_SUCCESS
} from 'actions/regularReservationAction'


export interface RegularReservationState
  extends RegularReservation {}

const initialRegularReservationState: RegularReservationState = {
  reserve_id: undefined,
  space_id: undefined,
  space_name: "",
  status: "0",
  start_date: undefined,
  end_date: undefined,
  usage: '',
  description: '',
  link: '',
  show_event_calendar_flg: true,
  images: [{
    id: undefined,
    file: undefined
  }],
  contractor: {
    reserve_id: 0,
    family_name: "",
    family_name_kana: "",
    given_name: "",
    given_name_kana: "",
    postal_code: "",
    address_pref: "",
    address_city: "",
    address_street: "",
    address_room: "",
    email: "",
    email_confirmation: "",
    tel: "",
    promotion_code: "",
    company_name: ""
  },
  everyday: [],
  biweekly: [],
  month: [],
  specific: []
}


/* regularReservation Reducer */
const regularReservation = (
  state: RegularReservationState = initialRegularReservationState,
  action: ActionTypes
): RegularReservationState => {
  switch (action.type) {
    case FETCH_REGULAR_RESERVATION_START:
      return { ...state };
    case FETCH_REGULAR_RESERVATION_SUCCESS: 
      return { ...state, ...action.payload };
    case FETCH_REGULAR_RESERVATION_FAIL:
      return { ...state };
    case STORE_REGULAR_RESERVATION_LOCAL:
      return { ...state, ...action.payload };
    case STORE_REGULAR_RESERVATION_SUCCESS:
      return { ...state, ...action.payload };
    case CLEAR_REGULAR_RESERVATION_LOCAL:
      return { ...initialRegularReservationState }
    default:
      return { ...state };
  }
};

export default regularReservation;