import {
  FETCH_TAX_RATE_MASTER_START,
  FETCH_TAX_RATE_MASTER_SUCCESS,
  FETCH_TAX_RATE_MASTER_FAIL,
  STORE_TAX_RATE_MASTER_SUCCESS,
} from '../actions/masterAction';

import { ActionTypes } from '../actions/actionTypes';
import { TaxRateList } from '../dataObjects/masters'

export interface TaxRateState extends TaxRateList {
}

const initialMasterState: TaxRateState = {
  taxrate: []
}


const taxRateMaster = (
  state: TaxRateState = initialMasterState,
  action: ActionTypes,
): TaxRateState => {
  switch (action.type) {
    // スペース選択
    case FETCH_TAX_RATE_MASTER_START:
      return { ...state };
    case FETCH_TAX_RATE_MASTER_SUCCESS:
      return {
        ...state,
        taxrate: [
          ...action.payload
        ],
      }
    case STORE_TAX_RATE_MASTER_SUCCESS:
      return {
        ...state,
        taxrate: [
          ...action.payload
        ],
      }
    case FETCH_TAX_RATE_MASTER_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default taxRateMaster;
