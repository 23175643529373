import { ActionTypes } from '../actions/actionTypes'
import {
  FETCH_COMMON_INQUIRY_LIST_START,
  FETCH_COMMON_INQUIRY_LIST_SUCCESS,
  FETCH_COMMON_INQUIRY_LIST_FAIL,
  FETCH_REGULAR_INQUIRY_LIST_START,
  FETCH_REGULAR_INQUIRY_LIST_SUCCESS,
  FETCH_REGULAR_INQUIRY_LIST_FAIL
} from "../actions/inquiryAction";
import { LOGOUT_SUCCESS } from 'actions/authenticationActions';
import { InquiryListHeaderPagenation } from 'dataObjects/inquiry';



export interface RegularInquiryListHeaderState
  extends InquiryListHeaderPagenation { }

const initialRegularInquiryListHeaderState: RegularInquiryListHeaderState = {
  data: [],
  current_page: 1,
  first_page_url:
    process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/regularinquiry/list/20?page=1",
  from: null,
  last_page: 1,
  last_page_url:
    process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/regularinquiry/list//20?page=1",
  next_page_url: null,
  path: process.env.REACT_APP_API_SERVER_HOST + "api/backoffice/regularinquiry/list/20",
  per_page: 20,
  prev_page_url: null,
  to: null,
  total: 0
};


/* regularInquiryList Reducer */
const commonInquiryList = (
  state: RegularInquiryListHeaderState = initialRegularInquiryListHeaderState,
  action: ActionTypes
): RegularInquiryListHeaderState => {
  switch (action.type) {
    // ユーザーリスト読み込み
    case FETCH_REGULAR_INQUIRY_LIST_START:
      return { ...state };
    case FETCH_REGULAR_INQUIRY_LIST_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_REGULAR_INQUIRY_LIST_FAIL:
      return { ...state };
    case LOGOUT_SUCCESS:
      return { ...state, ...initialRegularInquiryListHeaderState };
    default:
      return { ...state };
  }
};

export default commonInquiryList;
