import {
  FETCH_FACILITIES_MASTER_START,
  FETCH_FACILITIES_MASTER_SUCCESS,
  FETCH_FACILITIES_MASTER_FAIL,
  STORE_FACILITIES_MASTER_SUCCESS,
} from '../actions/masterAction';

import { ActionTypes } from '../actions/actionTypes';
import { FacilityList } from '../dataObjects/masters'

export interface FacilitiesState extends FacilityList {
}

const initialMasterState: FacilitiesState = {
  facilities: []
}


const facilitiesMaster = (
  state: FacilitiesState = initialMasterState,
  action: ActionTypes,
): FacilitiesState => {
  switch (action.type) {    
    case FETCH_FACILITIES_MASTER_START:
      return { ...state };
    case FETCH_FACILITIES_MASTER_SUCCESS:
      return {
        ...state,
        facilities: [
          ...action.payload
        ],
      }
    case STORE_FACILITIES_MASTER_SUCCESS:
      return {
        ...state,
        facilities: [
          ...action.payload
        ],
      }
    case FETCH_FACILITIES_MASTER_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default facilitiesMaster;
