import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, stopAsyncValidation } from 'redux-form'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import TaxRateMasterForm, { validate } from '../components/Master/TaxRateMasterForm'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'

import { storeTaxRateMasterLocalAction, storeTaxRateMasterAction } from 'actions/masterAction';
import { TaxRate } from 'dataObjects/masters';
import { TaxRateState } from 'reducers/taxRateMasterReducer';
import { ValidationError } from 'errors/RequestValidationError';


interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

export default function TaxRateMasterFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {
      setIsDisplay(false)
      
      const payload = values.taxrate.map(v => {
        const record: TaxRate = {
          id: v.id,
          tax_rate_type_id: v.tax_rate_type_id,
          enable_from: v.enable_from,
          enable_to: v.enable_to,
          rate: v.rate
        }
        return record
      })
          
      ownDispatch(
        storeTaxRateMasterLocalAction(
          payload
        )
      )

      ownDispatch(
        storeTaxRateMasterAction(
          payload
        )
      )

    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if(isDisplay === false) {
      console.log({errors})     

      const taxrateErrors = []
      Object.keys(errors.taxrate).map((key) => {
        taxrateErrors[key] = errors.taxrate[key]
      })      
      errors.taxrate = taxrateErrors    
           
      const regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
      const toTouch = []
      const touchtaxrate = []
      toTouch['taxrate'] = touchtaxrate
      const keyReducer = (err, result) => {
        Object.keys(err).map((key) => {
          if(regexp.test(key)) {
            result[key] = []
            keyReducer(err[key], result[key])
          } else {
            if(typeof err[key] == 'string') {
              result.push(key)
            } else {
              keyReducer(err[key], result)
            }
          }
        })  
      }
      keyReducer(errors.taxrate, toTouch['taxrate'])
      touch(...toTouch)
 
 
      ownDispatch(stopAsyncValidation('TaxRateMasterForm', errors))
      setIsDisplay(true)
    }
  }  

  const _props = {
    onSubmit: submit,
    masters: {
      taxratetypes: [
        {
          id: 1,
          tax_type: '通常税率'
        },
        {
          id: 2,
          tax_type: '軽減税率'
        }
      ]
      
    },
    validationResult: state.ss422ValidationErrorResult,
    ssValidation: showValidationError,
    initialValues: convertFormInitialValues<TaxRateState>(state.taxRateMaster)
  }

  return <TaxRateMasterForm {..._props} />

}