import {
  FETCH_USAGE_MASTER_START,
  FETCH_USAGE_MASTER_SUCCESS,
  FETCH_USAGE_MASTER_FAIL,
  STORE_USAGE_MASTER_SUCCESS,
} from '../actions/masterAction';

import { ActionTypes } from '../actions/actionTypes';
import { UsageList } from '../dataObjects/masters'

export interface UsageState extends UsageList {
}

const initialMasterState: UsageState = {
  usage: []
}


const usageMaster = (
  state: UsageState = initialMasterState,
  action: ActionTypes,
): UsageState => {
  switch (action.type) {    
    case FETCH_USAGE_MASTER_START:
      return { ...state };
    case FETCH_USAGE_MASTER_SUCCESS:
      return {
        ...state,
        usage: [
          ...action.payload
        ],
      }
    case STORE_USAGE_MASTER_SUCCESS:
      return {
        ...state,
        usage: [
          ...action.payload
        ],
      }
    case FETCH_USAGE_MASTER_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default usageMaster;
