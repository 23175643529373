import React from 'react'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import moment from "moment";
import "moment/locale/ja";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  TimePicker
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

moment.locale("ja"); 

export const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  style,
  ...custom
}) => (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      style={style}
      {...input}
      {...custom}
    />
  )

export const renderDateCalendarField = ({
         id,
         label,
         input: { value, onChange },
         meta: { touched, invalid, error },
         onFieldChange,
         ...custom
       }) => (
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
           <KeyboardDatePicker
             disableToolbar
             format="yyyy/MM/dd"
             margin="normal"
             id={id}
             label={label}
             clearable
             autoOk
             variant="inline"
             inputVariant="outlined"
             InputAdornmentProps={{ position: "start" }}
             value={value}
             error={touched && invalid}
             helperText={touched && error}
             onChange={date => {
               onChange(date);
               onFieldChange && onFieldChange(date);
             }}
             {...custom}
           />
         </MuiPickersUtilsProvider>
       );

export const renderTimeField = ({
  id,
  label,
  input: { value, onChange },
  meta: { touched, invalid, error },
  onFieldChange,
  ...custom
}) => (    
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        margin="normal"        
        id={id}
        label={label}
        clearable
        autoOk
        ampm={false}
        value={value}
        error={touched && invalid}
        helperText={touched && error}
        onChange={(date) => {
          onChange(date)
          onFieldChange && onFieldChange(date)
        }}
        {...custom}
      />
    </MuiPickersUtilsProvider>
  )


export const renderRadio = ({
  input: { value, onChange },
  label,
  children,
  meta: { touched, error },
  onFieldChange,
  row = true,
  required = false,
  rootClass = '',
}) => {
  try{
    value = value.toString()
  } catch($err) {

  }
  return (
    <FormControl classes={{ root: rootClass }} required={required} component='fieldset' error={!!(touched && error)}>
      <FormLabel component='legend'>{label}</FormLabel>
      <RadioGroup
        row={row}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
          onFieldChange && onFieldChange(e.target.value)
        }}
      >
        {children}
      </RadioGroup>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

// CheckBoxGroup
export const renderCheckBox = ({
  input: { value, onChange },
  label,
  children,
  meta: { touched, error },
  onFieldChange,
  row = true,
  required = false,
  rootClass = '',
}) => (
  <FormControl classes={{root: rootClass}} required={required} component='fieldset' error={!!(touched && error)}>
    <FormLabel component='legend'>{label}</FormLabel>
    <FormGroup
      row={row}
      onChange={(e) => {
        onChange((e.target as HTMLInputElement).value)
        onFieldChange && onFieldChange((e.target as HTMLInputElement).value)
      }}
    >
      {children}
    </FormGroup>
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
  )

// Switch
export const renderSwitch = ({
  input: { value, onChange },
  label,
  meta: { touched, invalid, error },
  onFieldChange,
  rootClass = '',
}) => {
  if(value === 0 || value === '0') {    
    value = false
  } else if (value === 1 || value === '1') {
    value = true
  }
  if(value == 'true') {
    value = true
  } else if (value == 'false') {
    value = false
  }

  return (
    <>
      <FormControlLabel
        classes={{root: rootClass}}
        control={
          <Switch
            checked={value}
            onChange={(e, bool) => {
              onChange(bool)
              onFieldChange && onFieldChange(bool)
            }}
          />
        }      
        label={label}
      />
      {touched && error && <FormHelperText style={{color: '#f44336'}}>{error}</FormHelperText>}
    </>
  )
  }

// File
export const renderFile = ({
  input: { value, name, onChange },
  label,
  meta: { touched, error },
  classes,
  onFieldChange,
  buttonLabel,
  accept = '*',
  required = false,
  rootClass = '',
}) => (
  <FormControl classes={{root: rootClass}} required={required} component='fieldset' error={!!(touched && error)}>
    <FormLabel component='legend'>{label}</FormLabel>
    <input
      accept={accept}
      id={name}
      type='file'
      onChange={e => {
        e.preventDefault()
        onChange(e.target.files[0])
        onFieldChange && onFieldChange(e.target.files[0])
      }}
      onBlur={() => {}}
    />
    {/*
    <label htmlFor={name}>
      <Button variant='outlined' component='span'>
        {buttonLabel || 'ファイルを選択'}
      </Button>
    </label>
    */}
    <label>{value && value.name}</label>
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)

export const renderSelect = ({
  input: { value, onChange },
  label,
  children,
  meta: { touched, invalid, error },
  onFieldChange,
  required = false,
  rootClass = '',
  ...custom
}) => {   
  return (
      <TextField
        required={required}
        classes={{ root: rootClass }}
        select
        label={label}
        variant='outlined'
        value={value}
        onChange={e => {
          onChange(e.target.value)
          onFieldChange && onFieldChange(e.target.value)
        }}
        error={touched && invalid}
        helperText={touched && error}
        style={{ marginTop: '16px', marginBottom: '8px', minWidth: '100px' }}
        {...custom}
      >
        {children}
      </TextField>
    )
  }

export const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return <></>
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}
