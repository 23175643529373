import { ActionTypes } from "../actions/actionTypes";
import { AcceptInquiry } from "dataObjects/inquiry";
import {
  FETCH_COMMON_INQUIRY_START,
  FETCH_COMMON_INQUIRY_SUCCESS,
  FETCH_COMMON_INQUIRY_FAIL,
  FETCH_REGULAR_INQUIRY_START,
  FETCH_REGULAR_INQUIRY_SUCCESS,
  FETCH_REGULAR_INQUIRY_FAIL
} from 'actions/inquiryAction'
import { LOGOUT_SUCCESS } from "actions/authenticationActions";


export interface RegularInquiryState
  extends AcceptInquiry { }

const initialRegularInquiryState: RegularInquiryState = {
  id: undefined,
  category: '',
  name: '',
  email: '',
  content: '',
  already_read: '',
  inquiry_datetime: undefined,
  str_inquiry_at: '',
  user_id: undefined,
  user_name: '',
  user_email: ''
}


/* regularInquiry Reducer */
const regularInquiry = (
  state: RegularInquiryState = initialRegularInquiryState,
  action: ActionTypes
): RegularInquiryState => {
  switch (action.type) {
    case FETCH_REGULAR_INQUIRY_START:
      return { ...state };
    case FETCH_REGULAR_INQUIRY_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_REGULAR_INQUIRY_FAIL:
      return { ...state };
    case LOGOUT_SUCCESS:
      return { ...state, ...initialRegularInquiryState };
    default:
      return { ...state };
  }
};

export default regularInquiry;