import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes';
import DropInReserveDetailPage, { DropInReserveDetailPageProps } from '../pages/DropInReserveDetailPage';
import { observeShownAction } from 'actions/pagingAction';
import { routes } from 'routes/Route';
import { push } from 'connected-react-router';
import { reservationCancelAction, reservationCaptureAction, reservationAuthorizationAction } from 'actions/dropinReservationAction';
import { closeDialog } from 'actions/dialogAction';

interface OwnProps {
}

export type InquiryDetailPageContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function DropInReservationDetailPageContainer(props: InquiryDetailPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  // ユーザーデータ読み込み
  useEffect(() => {
    dispatch(observeShownAction());
  }, []);

  const timeRange = state.dropinReservation.reserveTimeRange;
  const contractor = state.dropinReservation.contractor;
  const payment = state.dropinReservation.payment;
  const _props: DropInReserveDetailPageProps = {
    space_id: state.dropinReservation.space_id,
    space_name: state.dropinReservation.space_name,
    selectedDate: state.dropinReservation.selectedDate,
    timerange: state.dropinReservation.selectedDate + ' ' + timeRange.start_time + '〜' + timeRange.end_time,
    users_adult: state.dropinReservation.enquete.users_adult,
    users_children: state.dropinReservation.enquete.users_children,
    usage: state.dropinReservation.enquete.usage,
    detail: state.dropinReservation.enquete.detail,
    invoice: state.dropinReservation.invoice,
    family_name: contractor ? state.dropinReservation.contractor.family_name : '',
    given_name: contractor ? state.dropinReservation.contractor.given_name : '',
    family_name_kana: contractor ? state.dropinReservation.contractor.family_name_kana : '',
    given_name_kana: contractor ? state.dropinReservation.contractor.given_name_kana : '',
    email: contractor ? state.dropinReservation.contractor.email : '',
    postal_code: contractor ? state.dropinReservation.contractor.postal_code : '',
    address_pref: contractor ? state.dropinReservation.contractor.address_pref : '',
    address_city: contractor ? state.dropinReservation.contractor.address_city : '',
    address_street: contractor ? state.dropinReservation.contractor.address_street : '',
    address_room: contractor ? state.dropinReservation.contractor.address_room : '',
    tel: contractor ? state.dropinReservation.contractor.tel : '',
    promotion_code: contractor ? state.dropinReservation.contractor.promotion_code : '',
    reserve_status: state.dropinReservation.status,
    payemnt_status: payment ? payment.status : '',
    payemnt_amout_money: payment ? payment.amount : 0,
    payemnt_card_brand: payment ? payment.card_data ? payment.card_data.brand : '' : '',
    payemnt_last_4: payment ? payment.card_data ? payment.card_data.last4 : '' : '',
    payemnt_exp_year: payment ? payment.card_data ? payment.card_data.exp_year : 0 : 0,
    payemnt_exp_month: payment ? payment.card_data ? payment.card_data.exp_month : 0 : 0,
    payemnt_country: payment ? payment.card_data ? payment.card_data.country : '' : '',
    payemnt_three_d_secure: payment ? payment.card_data ? payment.card_data.three_d_secure : '' : '',
    payment_intents: state.dropinReservation.stripePaymentIntent,
    payment_batch_process: state.dropinReservation.stripePaymentBatchProcess,
    user_name: state.dropinReservation.user ? state.dropinReservation.user.name : '',
    user_email: state.dropinReservation.user ? state.dropinReservation.user.email : '',
    send_thunks_flg: state.dropinReservation.send_thunks_flg,
    send_cancel_flg: state.dropinReservation.send_cancel_flg,
    send_reminder_flg: state.dropinReservation.send_reminder_flg,
    send_confirm_period_ext_flg: state.dropinReservation.send_confirm_period_ext_flg,
    dialogOpen: state.page.updateDialogOpen,
    handleClose: () => {
      dispatch(closeDialog())      
    },
    onCancel: () => {      
      // キャンセル処理
      dispatch(reservationCancelAction(state.dropinReservation.space_id, state.dropinReservation.reserve_id))
    },
    onAuthorization: () => {      
      // キャンセル処理
      dispatch(reservationAuthorizationAction(state.dropinReservation.reserve_id))
    },
    onCapture: () => {      
      // キャンセル処理
      dispatch(reservationCaptureAction(state.dropinReservation.reserve_id))
    },
    onBack: () => {
      dispatch(push(routes.dropInReserveList.path))
    }
  }

  return <DropInReserveDetailPage {..._props} />

}