import * as React from 'react';
import { Theme, createStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Container, makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { ValidationErrorListState } from '../../reducers/validationErrorReducer'
import { VacancyCheckedPeriod } from 'dataObjects/space';
import { dateToString } from 'utils/convertor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%',
      marginTop: theme.spacing(1),
      overflowX: 'auto',
      fontSize: '1.3rem',
      border: '1px solid rgb(234, 234, 234)',
      borderCollapse: 'collapse',
      '& thead': {
        border: '1px solid rgb(234, 234, 234)',
        '& th': {
          textAlign: 'left',
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }
      },
      '& tbody': {
        '& td': {
          border: '1px solid rgb(234, 234, 234)',
          padding: theme.spacing(2)
        }

      }
    },
  }),
);

interface OwnProps {
  validationErrorDialogOpen: boolean,
  validationResult?: ValidationErrorListState,
  handleClose: () => void
}

type ValidationErrorDialogProps = OwnProps

/**
 * エラーメッセージ項目対比表
 */
const itemDictionary = {
  'exists auth user': 'ログインユーザー',
  'parameter': 'システム',
  'existsPeriod': '予約時間',
  'doesnotdata': '内部データ',  
  'period.continuous': '予約時間',
  'period.at_least_reserve_unit': '予約時間',
  'period.before_5minutes': '予約時間',
  'canReserve': '予約可否',
  'canEveryDayReserve': '日次スケジュール予約可否',
  'canBiWeeklyReserve': '週次スケジュール予約可否',
  'canMonthlyReserve': '月次スケジュール予約可否',
  'canSpecificReserve': '指定日スケジュール予約可否',  
  'payment.status': '決済処理状況',
  'payment.exists': '決済情報',
  'reserve.exists': '予約情報',
  'reserve.status': '予約状況',
  'reserve.promotioncode': 'プロモーションコード',
  'space.plan.onlybasic': 'スペース予約有り',
  'space.plan.allupdated': 'スペース予約有り',
  'space_images.parameter': 'スペース画像保存',
  'space_images.save': 'スペース画像保存', 
  'news_file.parameter': 'お知らせ添付ファイル',
  'news_file.save': 'お知らせ添付ファイル保存',
  'user.forcedelete': '強制削除エラー',
  'delete': '削除エラー',
  'tax_rate': '消費税率'
}

const ValidationErrorDialog: React.FC<ValidationErrorDialogProps> = (props: ValidationErrorDialogProps) => {
  const classes = useStyles({})

  const convertErrorItemName = (item: string) => {
    try {
      return itemDictionary[item]
    } catch (ex) {
      return ''
    }
  }

  const trimSeconds = (time: string) => {
    if (time) {
      return time.substring(0, time.lastIndexOf(':'))
    }
    return time
  }

  const isVCP = (arg: any): arg is VacancyCheckedPeriod => arg.day !== undefined

  const errorDecorator = (error: any) => {
    if (isVCP(error)) {
      const useType = error.use_type == '0' ? '予約可' : error.use_type == '1' ? '一般利用' : error.use_type == '2' ? '定期利用' : ''
      const status = error.status == '0' ? '予約可' : error.status == '1' ? '予約不可' : error.status == '9' ? '休業' : ''
      const timeRange = dateToString(error.day) + ' ' + trimSeconds(error.start_time) + ' ～ ' + trimSeconds(error.end_time)
        
      return (
        <table className={classes.table} aria-label="customized table">
          <thead>
            <tr>
              <th>日時</th>
              <th>予約可否</th>
              <th>理由</th>
              <th>他の予約ID</th>
            </tr>
          </thead>
          <tbody>
            <tr key={error.id}>
              <td>{timeRange}</td>
              <td>{status}</td>
              <td>{useType}</td>
              <td>{error.reserve_id}</td>
            </tr>
          </tbody>
        </table>
      )
    } else {
      return error
    }
  }

  return (
    <Dialog
      open={props.validationErrorDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">処理が中断しました</DialogTitle>
      <DialogContent dividers style={{display: 'flex'}}>
        <div>
          <ErrorIcon style={{ width: '30px', height: '30px', color: '#AA0000'}} />
        </div>
        <div>
          <ul>
            {
              props.validationResult ? props.validationResult.errors.map(err => {
                return (
                  <li>{convertErrorItemName(err.item)}<br />{errorDecorator(err.error)}</li>
                )
              }) : null
            }
          </ul>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default ValidationErrorDialog