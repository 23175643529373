import * as React from 'react';
import useReacdlouter from 'use-react-router';
import { routes } from '../routes/Route';
import { Theme, createStyles, Container, Divider, Card, CardContent, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import BasePageContainer from '../containers/BasePageContainer';
import SpaceEditFormContainer from '../containers/SpaceEditFormContainer';


interface StyleProps {
  container: BaseCSSProperties,
  title: BaseCSSProperties,
  subtitle: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fff'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center'
    },
    reserveInfoContainer: {
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        padding: '2% 0'
      },
      '& dl:nth-child(odd)': {
        backgroundColor: '#fbfbfb'
      },
      '& dt': {
        display: 'inline-block',
        width: '20%',
        paddingLeft: '10px',
        verticalAlign: 'middle',
      },
      '& dd': {
        marginInlineStart: '40px',
        display: 'inlineBlock',
        width: '64%',
        verticalAlign: 'middle',
        letterSpacing: '.3px'
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          minHeight: '80px',
          backgroundColor: '#fff',
          marginBlockStart: '0px',
          marginBlockEnd: '0px',
        },
        '& dl:nth-child(odd)': {
          backgroundColor: '#fff'
        },
        '& dt': {
          backgroundColor: '#ffffff',
          paddingTop: '10px',
          paddingLeft: '10px',
          width: '100%',
          minHeight: '40px'
        },
        '& dd': {
          backgroundColor: '#fff',
          width: '100%',
          paddingTop: '10px',
          paddingLeft: '10px',
          marginInlineStart: '0px',
          minHeight: '40px'
        },
      }
    },
    invoice: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8)
    },

    rowWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: 1,
      marginBottom: 1
    },
    colWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'start',
      width: '100%'
    },
    invoice_content: {
      fontSize: '1.7rem'
    },
    invoice_content_value: {
      minWidth: '25%',
      textAlign: 'right'
    },
    invoice_amount: {
      fontSize: '2.0rem'
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
  })
);

interface OwnProps {  
  onBack: () => void,
  updateDialogOpen: boolean,
  rejectDialogOpen: boolean,
  onDialogClose: () => void
}

export type SpaceEditPageProps = OwnProps;

const SpaceEditPage: React.FC<SpaceEditPageProps> = (props: SpaceEditPageProps) => {
  const classes = useStyles({} as StyleProps);
  

  return (
    <BasePageContainer>
      <Container fixed className={classes.topcontainer} >
        
        <Typography variant="h2" className={classes.title}>
          スペース編集
        </Typography>

        <SpaceEditFormContainer />

        <Grid container justify="center" spacing={2} style={{marginTop: '30px', marginBottom: '200px'}}>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" color="secondary" style={{height: '50px'}} onClick={props.onBack}>
              戻る
            </Button>
          </Grid>
        </Grid>

        <Dialog
          open={props.updateDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"更新処理結果"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              スペースを登録しました。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onDialogClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={props.rejectDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"削除処理結果"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              スペースを削除しました。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onDialogClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>

      </Container>
    </BasePageContainer>

  );

}

export default SpaceEditPage
