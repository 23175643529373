import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, stopAsyncValidation } from 'redux-form'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import UsageMasterForm, { validate } from '../components/Master/UsageMasterForm'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'

import { storeUsageMasterAction } from 'actions/masterAction';
import { Facility, Usage } from 'dataObjects/masters';
import { TaxRateState } from 'reducers/taxRateMasterReducer';
import { ValidationError } from 'errors/RequestValidationError';
import { UsageState } from 'reducers/usageMasterReducer';


interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

export default function UsageMasterFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {
      setIsDisplay(false)
      
      let index = 0
      const payload = values.usage.map(v => {
        const record: Usage = {
          id: v.id,
          usage: v.usage,
          order_number: index
        }
        index++
        return record
      })
      
      ownDispatch(
        storeUsageMasterAction(
          payload
        )
      )

    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if(isDisplay === false) {
      console.log({errors})     
        
      const usageErrors = []
      Object.keys(errors.usage).map((key) => {
        usageErrors[key] = errors.usage[key]
      })      
      errors.usage = usageErrors      

      const regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
      const toTouch = []
      const touchUsage = []
      toTouch['usage'] = touchUsage
      const keyReducer = (err, result) => {
        Object.keys(err).map((key) => {
          if(regexp.test(key)) {
            result[key] = []
            keyReducer(err[key], result[key])
          } else {
            if(typeof err[key] == 'string') {
              result.push(key)
            } else {
              keyReducer(err[key], result)
            }
          }
        })  
      }
      keyReducer(errors.usage, toTouch['usage'])
      touch(...toTouch)
 
      ownDispatch(stopAsyncValidation('UsageMasterForm', errors))
      setIsDisplay(true)
    }
  }  

  const _props = {
    onSubmit: submit,
    validationResult: state.ss422ValidationErrorResult,
    ssValidation: showValidationError,
    initialValues: convertFormInitialValues<UsageState>(state.usageMaster)
  }

  return <UsageMasterForm {..._props} />

}