import React, { useEffect } from 'react'
import { routes } from '../routes/Route';
import { RouteComponentProps } from 'react-router-dom'
import { push } from "connected-react-router"
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import PromotionCodePage, { PromotionCodePageProps } from '../pages/PromotionCodePage'
import moment from 'moment';
import { closeDialog } from 'actions/dialogAction';
import { fetchPromotionCodeList } from 'actions/promotionCodeAction';


interface OwnProps {
}

export type PromotionCodePageContainerProps = OwnProps & RouteComponentProps<{}>

const appStateSelector = (state: AppState) => state

export default function PromotionCodePageContainer(props: PromotionCodePageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  const _props: PromotionCodePageProps = {
    updateDialogOpen: state.page.updateDialogOpen,
    onDialogClose: () => {
      dispatch(closeDialog())
    }
  }

  useEffect(() => {
    dispatch(fetchPromotionCodeList())
  }, [])

  return <PromotionCodePage {..._props} />

}