import { ActionTypes } from '../actions/actionTypes';
import { PromotionCode } from 'dataObjects/promotioncode';
import { FETCH_PROMOTIONCODE_LIST_START, FETCH_PROMOTIONCODE_LIST_SUCCESS, FETCH_PROMOTIONCODE_LIST_FAIL, STORE_PROMOTIONCODE_LOCAL } from 'actions/promotionCodeAction';

export interface PromotionCodeState {
  list: PromotionCode[]
}

const initialPromotionCodeState: PromotionCodeState = {
  list: []
}


const promotionCode = (
  state: PromotionCodeState = initialPromotionCodeState,
  action: ActionTypes,
): PromotionCodeState => {
  switch (action.type) {
    // スペース選択
    case FETCH_PROMOTIONCODE_LIST_START:
      return { ...state };
    case FETCH_PROMOTIONCODE_LIST_SUCCESS:
      return {
        ...state,
        list: [
          ...action.payload
        ],
      }
    case STORE_PROMOTIONCODE_LOCAL:
      return {
        ...state,
        list: [
          ...action.payload
        ],
      }
    case FETCH_PROMOTIONCODE_LIST_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};

export default promotionCode;
