import { ActionTypes } from '../actions/actionTypes';
import { SNACK_BAR_CLOSE } from '../actions/snackBarAction';
import { SnackBarVariant } from '../components/SnackBar';
import { LOGIN_FAIL } from '../actions/authenticationActions';
import { PATH_NAME_CHANGE } from '../actions/locationChangeAction';
import { SS_VALIDATION_ERROR } from 'actions/errorAction';
import { FETCH_SETTINGS_FAIL } from 'actions/settingsAction';
import { FETCH_TAX_RATE_MASTER_FAIL } from 'actions/masterAction';
import { FETCH_DROPIN_RESERVATION_LIST_FAIL } from 'actions/dropinReservationAction';
import { VALIDATION_REGULAR_RESERVATION_FAIL, STORE_REGULAR_RESERVATION_FAIL } from 'actions/regularReservationAction';

export interface SnackBarState {
  reason: string;
  available: boolean;
  variant: SnackBarVariant;
}

const initialState: SnackBarState = {
  reason: '',
  available: false,
  variant: 'success',
};

const snackBar = (
  state: SnackBarState = initialState,
  action: ActionTypes,
): SnackBarState => {
  switch (action.type) {
    case SS_VALIDATION_ERROR:
      return {
        reason: action.snackBarMessage,
        available: true,
        variant: action.variant,
      };
    case LOGIN_FAIL:
      return {
        reason: action.snackBarMessage,
        available: true,
        variant: action.variant,
      };
    case FETCH_SETTINGS_FAIL:
    case FETCH_TAX_RATE_MASTER_FAIL:
    case FETCH_DROPIN_RESERVATION_LIST_FAIL:
    case STORE_REGULAR_RESERVATION_FAIL:
    case VALIDATION_REGULAR_RESERVATION_FAIL:
    case SNACK_BAR_CLOSE:
    case PATH_NAME_CHANGE:
      return initialState;
    default:
      return { ...state };
  }
};

export default snackBar;
