import moment from 'moment'
import { isArray } from 'util';

export const required = (value: any): boolean => {
  if (value == undefined) {
    return false
  } else if(value == null) {
    return false 
  } else if(typeof(value) === 'boolean') {
    return true
  } else if(typeof(value) === 'string' && value === '') {
    return false
  }
  
  return true
}

export const requiredArray = (values: [], fields: string[]): any[] => {
  const membersArrayErrors = []
  if (values && isArray(values)) {
    values.forEach((member, memberIndex) => {
      const memberErrors: { [key: string]: string } = {};
      fields.forEach(field => {
        if (!required(member[field])) {
          memberErrors[field] = "必須項目です";
        }
      });
      if (Object.keys(memberErrors).length > 0) {
        membersArrayErrors[memberIndex] = memberErrors;
      }
    })
  }
  return membersArrayErrors
}

export const isEmail = (value: string): boolean => (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? true : false);

export const maxLength = (value: string, max: number) => (value.length > max ? false : true);

/* ------------------------ ノーマライザー(入力制御) ------------------------ */
export const number = (value, previousValue, allValues) => value && isFinite(value) ? value : previousValue

export const digit = (value, previousValue, allValues) => value && value.search(new RegExp('/^([1-9]\d*|0)(\.\d+)?$/')) ? value : previousValue

export const datetime = (value, previousValue, allValues)  => value && moment(value).isValid() ? value : previousValue

export const upper = value => value && value.toUpperCase()

export const lower = value => value && value.toLowerCase()

export const lessThan = otherField => (value, previousValue, allValues) =>
  parseFloat(value) < parseFloat(allValues[otherField]) ? value : previousValue

export const greaterThan = otherField => (value, previousValue, allValues) =>
  parseFloat(value) > parseFloat(allValues[otherField]) ? value : previousValue

export const lessThanDateTime = otherField => (value, previousValue, allValues) => {
  const m1 = moment(value)
  const otherDate = moment(allValues[otherField])
  if(m1.isValid()) {
    return m1.isBefore(otherDate) ? value : previousValue
  } else {
    return previousValue
  }
}

export const greaterThanDateTime = otherField => (value, previousValue, allValues) => {
  const m1 = moment(value)
  const otherDate = moment(allValues[otherField])
  if(m1.isValid()) {
    return m1.isAfter(otherDate) ? value : previousValue
  } else {
    return previousValue
  }
}

export const lessThanTime = otherField => (value, previousValue, allValues) => {
  const m1 = moment('2020-01-01 ' + value)
  const otherDate = moment('2020-01-01 ' + allValues[otherField])
  if(m1.isValid()) {
    return m1.isBefore(otherDate, 'minute') ? value : previousValue
  } else {
    return previousValue
  }
}

export const greaterThanTime = otherField => (value, previousValue, allValues) => {
  const m1 = moment('2020-01-01 ' + value)
  const otherDate = moment('2020-01-01 ' + allValues[otherField])
  if(m1.isValid()) {
    return m1.isAfter(otherDate, 'minute') ? value : previousValue
  } else {
    return previousValue
  }
}

/* ------------------------ バリデーター ------------------------ */
export const validateLessThan = (value, targetValue): boolean =>
  parseFloat(value) < parseFloat(targetValue)

export const validateGreaterThan = (value, targetValue): boolean =>
  parseFloat(value) > parseFloat(targetValue)

export const validateLessThanDateTime = (value, targetValue): boolean => {
  const m1 = moment(value)
  const otherDate = moment(targetValue)
  if (m1.isValid() && otherDate.isValid()) {
    return otherDate.isBefore(m1)
  } else {
    return false
  }
}

export const validateGreaterThanDateTime = (value, targetValue): boolean => {
  const m1 = moment(value)
  const otherDate = moment(targetValue)
  if (m1.isValid() && otherDate.isValid()) {
    return otherDate.isAfter(m1) 
  } else {
    return false
  }
}

export const validateLessThanTime = (value, targetValue): boolean => {
  const m1 = moment('2020-01-01 ' + value)
  const otherDate = moment('2020-01-01 ' + targetValue)
  if (m1.isValid() && otherDate.isValid()) {
    return otherDate.isBefore(m1, 'minute') 
  } else {
    return false
  }
}

export const validateGreaterThanTime = (value, targetValue) : boolean => {
  const m1 = moment('2020-01-01 ' + value)
  const otherDate = moment('2020-01-01 ' + targetValue)
  if (m1.isValid() && otherDate.isValid()) {
    return otherDate.isAfter(m1, 'minute')
  } else {
    return false
  }
}


/* タイプガード */

export const isDate = (arg: any): arg is Date => (arg as Date) != null
