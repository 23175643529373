import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { ListTableColumn } from '../components/ListTable/ListTable'
import SpaceListPage, { SpaceListPageProps } from 'pages/SpaceListPage';
import { fetchUserList, fetchUser } from 'actions/userAction';
import { UserListHeader } from 'dataObjects/user';
import { observeShownAction } from 'actions/pagingAction';
import { SpaceListHeader } from 'dataObjects/space';
import { fetchSpace, fetchSpaceList } from 'actions/spaceAction';
import { routes } from 'routes/Route';
import { push } from 'connected-react-router';


const columns: ListTableColumn[] = [
  { id: 'space_id', label: 'ID', minWidth: 50 },
  {
    id: 'space_name',
    label: 'スペース名',
    minWidth: 200,
    format: (value: string) => value && value.length > 30 ? value.substring(0, 100) + "..." : value
  },
  {
    id: 'address',
    label: '住所',
    minWidth: 200,
  }  
];

interface OwnProps {
}

export type SpaceListPageContainerProps = OwnProps & RouteComponentProps<{}>

const appStateSelector = (state: AppState) => state

export default function SpaceListPageContainer(props: SpaceListPageContainerProps) {
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  const _props: SpaceListPageProps = {
    columns: columns,
    data: state.spaceList.data,
    total: state.spaceList.total,
    page: state.spaceList.current_page - 1,
    rowsPerPage: Object.keys(state.spaceList.data).length,
    rowsPerPageOptions: [
      20,
      50,
      100,
      Object.keys(state.userList.data).length
    ],
    setPage: (page: number) => {
      ownDispatch(
        fetchSpaceList(
          state.spaceListCondition,
          state.spaceList.per_page,
          page
        )
      );
    },
    setRowsPerPage: (perPage: number) => {
      ownDispatch(
        fetchSpaceList(
          state.spaceListCondition,
          perPage,
          0
        )
      );
    },
    selectedRow: (data: any) => {
      const spaceData = data as SpaceListHeader;
      ownDispatch(fetchSpace(spaceData.space_id));
    },
    onCreate: () => {
      // 新規定期利用作成
      ownDispatch(push(routes.spaceCreate.path))
    }
  };

  useEffect(() => {
    ownDispatch(observeShownAction())
  }, [])

  return <SpaceListPage {..._props} />

}