import { AppResponseError } from '../errors/AppResponseError';

import axios, { AxiosResponse } from "axios";
import { checkAppResponse } from 'actionHelper/responseChecker';
import _ from 'lodash'

/**
 * HTTP client for main app
 */
class AppClient {
  private defaultHeaders: HeadersInit = {};

  async get(path: string, header: Record<string, string> = {}, params: Record<string, any> = {}): Promise<AxiosResponse<any>> {
    const headers = Object.assign({}, this.defaultHeaders, header, {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })

    try {
      const res = await axios.get(path, { params: params, headers: headers })      
      return res
    
    } catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`AppClient Error! HTTP Status: ${status} ${statusText}`);

      await checkAppResponse(error.response)

      return error.response
    }

  }

  async post(
    path: string,
    header: Record<string, string> = {},
    params: any = {},
  ): Promise<AxiosResponse<any>> {
    const headers = Object.assign({}, this.defaultHeaders, header, {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })

    try {
      const res = await axios.post(path, params, { headers: headers })
      return res
    
    } catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`AppClient Error! HTTP Status: ${status} ${statusText}`);

      await checkAppResponse(error.response)

      return error.response
    }
  }

  async postMultipart(
    path: string,
    header: Record<string, string> = {},
    params: any = {},
  ): Promise<AxiosResponse<any>> {    
    try {
      const headers = Object.assign({}, this.defaultHeaders, header, {'content-type': 'multipart/form-data'})

      const instance = axios.create({
        headers: headers,
        transformRequest: _.concat(
          (data, header) => {
            if (data instanceof FormData) {
              return data
            }
            if (this.containFileObject(data)) {
              return this.toFormData(data)
            }
            return data
          }, 
          axios.defaults.transformRequest
        )
      })

      const res = await instance.post(path, params)
      return res
    
    } catch (error) {
      const {
        status,
        statusText
      } = error.response;
      console.log(`AppClient Error! HTTP Status: ${status} ${statusText}`);

      await checkAppResponse(error.response)

      return error.response
    }
  }

  private containFileObject(val: any) {
    console.log('containFileObject START: ' + val)
    if(val instanceof File) {
      console.log('containFileObject isFile')
      return true
    }
    if (_.isObject(val)) {
      console.log('containFileObject isObject')
      const checked = Object.keys(val).map((value, index, array) => {
        console.log('map ' + value + ':' + val[value])
        return this.containFileObject(val[value])
      })
      return _.some(checked, (val2) => {
        console.log('checked ' + val2)
        return val2 === true
      })
    }
    if (_.isArray(val)) {
      console.log('containFileObject isArray')
      return _.some(val, this.containFileObject)
    }
    console.log('containFileObject thraw')
    return false
  }

  private toFormData (original) {
    const data = new FormData()

    function setData (path, obj) {
      console.log(obj)
      _.each(obj, (val, key) => {
        console.log(key + ':' + val)
        let nextPath = path.length > 0 ? path + '[' + key + ']' : key
        if (_.isArray(obj)) {          
          nextPath = path + '[]'
        }
        if (val instanceof File) {
          console.log(nextPath)
          data.append(nextPath, val)
          return
        }
        if (_.isObject(val)) {
          setData(nextPath, val) 
          return
        }
        console.log(nextPath)
        data.append(nextPath, val)
      })
    }

    setData('', original)
    return data
  }

  
}

export default new AppClient();
