import * as React from "react";
import { Theme, createStyles, Container, Grid, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import makeStyles from "@material-ui/core/styles/makeStyles";
import BasePageContainer from "../containers/BasePageContainer";
import ListTable, { ListTableProps } from "components/ListTable/ListTable";
import SpaceSearchFormContainer from "../containers/SpaceSearchFormContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: "100%"
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },  
    title: {
      marginTop: theme.spacing(2),
      fontSize: "1.0em",
      paddingBottom: theme.spacing(2),
      borderBottom: "1px solid #cecece"
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: "1.6rem",
      paddingBottom: theme.spacing(2),
      paddingTop: "20px",
      paddingLeft: "12px",
      backgroundColor: "#fff"
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: "1.0em",
      marginBottom: theme.spacing(2),
      textAlign: "center"
    },
    list: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8)
    }
  })
);

interface OwnProps {
  onCreate: () => void
}

export type SpaceListPageProps = OwnProps & ListTableProps;

const SpaceListPage: React.FC<SpaceListPageProps> = (
  props: SpaceListPageProps
) => {
  const classes = useStyles({});
  const {
    columns,
    data,
    total,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    setPage,
    setRowsPerPage,
    selectedRow
  } = props;

  const listTableProps: ListTableProps = {
    columns: props.columns,
    data: props.data,
    total: props.total,
    page: props.page,
    rowsPerPage: props.rowsPerPage,
    rowsPerPageOptions: props.rowsPerPageOptions,
    setPage: props.setPage,
    setRowsPerPage: props.setRowsPerPage,
    selectedRow: props.selectedRow
  };

  return (
    <BasePageContainer>
      <Container fixed className={classes.topcontainer}>
        <Typography variant="h3" className={classes.subtitle}>
          スペース一覧
        </Typography>

        <div>
          <SpaceSearchFormContainer />
        </div>

        <Grid container
          spacing={2}
          justify="center"
          alignItems="center">
          <Grid item xs={4}>
            <Button fullWidth color="primary" className={classes.submitButton} onClick={props.onCreate}>
              スペース 作成
            </Button>
          </Grid>
        </Grid>

        <div className={classes.list}>
          <ListTable {...listTableProps} />
        </div>
      </Container>
    </BasePageContainer>
  );
};

export default SpaceListPage;
