import * as React from 'react';
import { Theme, createStyles, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';
import BasePageContainer from '../containers/BasePageContainer';

import { Link, LinkProps } from 'react-router-dom';
import { routes } from 'routes/Route';

import CalendarHeatmap from 'react-calendar-heatmap';
import NumericInformation from '../components/Dashboard/NumericInformation'
import StackedAreaChart from '../components/Dashboard/StackedAreaChart'

import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';
import { useDispatch } from 'react-redux';
import { observeShownAction } from 'actions/pagingAction';


import 'react-calendar-heatmap/dist/styles.css';
import { DashboardValuePanel, DashboardUseChart, DashboardGrassValue } from 'dataObjects/dashboard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2),
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(2),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '10px',
      paddingLeft: '12px',
      //backgroundColor: '#fff'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& a': {
        fontSize: '1.7rem',
        color: '#27A78E'
      }
    },
    reserve_container: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'start',
      alignContent: 'center'
    },
    sales_container: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'start',
      alignContent: 'center'
    },
    inquiry_container: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'start',
      alignContent: 'center'
    },
    glass_container: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'start',
      alignContent: 'center'
    },
    grass_usage_guide: {
      display: 'flex', 
      justifyContent: 'start',
      fontSize: '1.5rem',
      marginLeft: 200
    },
    grass_usage_guide_desc: {
      marginRight: 16
    },
    grass_usage_guide_0: {
      backgroundColor: '#eeeeee',
      width: '16px',
      height: '16px',
      margin: 2
    },
    grass_usage_guide_1: {
      backgroundColor: '#d6e685',
      width: '16px',
      height: '16px',
      margin: 2
    },
    grass_usage_guide_2: {
      backgroundColor: '#8cc665',
      width: '16px',
      height: '16px',
      margin: 2
    },
    grass_usage_guide_3: {
      backgroundColor: '#44a340',
      width: '16px',
      height: '16px',
      margin: 2
    },
    grass_usage_guide_4: {
      backgroundColor: '#1e6823',
      width: '16px',
      height: '16px',
      margin: 2
    },
    weeklychart_usage_guide: {
      display: 'flex', 
      justifyContent: 'start',
      fontSize: '1.5rem',
      marginLeft: 230
    },
    weeklychart_usage_guide_desc: {
      marginRight: 16
    },
    weeklychart_usage_guide_reserve: {
      backgroundColor: '#00AB80',
      width: '16px',
      height: '16px',
      margin: 2
    },
    weeklychart_usage_guide_vacancy: {
      backgroundColor: '#D1DFB2',
      width: '16px',
      height: '16px',
      margin: 2
    },
    weeklychart_usage_guide_close: {
      backgroundColor: '#00835E',
      width: '16px',
      height: '16px',
      margin: 2
    },
    user_container: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'start',
      alignContent: 'center'
    },
    userTransition_container: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'start',
      alignContent: 'center'
    },
    error_container: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'start',
      alignContent: 'center'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.3rem',
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    },
    spacer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    }
  })
);

interface OwnProps {
  todayUseCount: DashboardValuePanel,
  tommorowUseCount: DashboardValuePanel,
  weekUseCount: DashboardValuePanel,
  unCompleteSales: DashboardValuePanel,
  completeSales: DashboardValuePanel,
  regularCompleteSales: DashboardValuePanel,
  commonInquiryCount: DashboardValuePanel,
  regularInquiryCount: DashboardValuePanel,
  authorizationError: DashboardValuePanel,
  paymentLimit: DashboardValuePanel,
  forceCancel: DashboardValuePanel,
  weeklyUseChartData: DashboardUseChart[],
  weeklyUserChardLoading: boolean,
  yearUseGrassData: DashboardGrassValue[],
  yearUseGrassDataLoading: boolean
}

export type DashboardPageProps = OwnProps;

const DashboardPage: React.FC<DashboardPageProps> = (props: DashboardPageProps) => {
  const classes = useStyles({});  

  const _chartProps = {
    width: 1000,
    height: 250,
    data: props.weeklyUseChartData,
    areaInfo: [
      {
        key: 'close',
        stroke: '#00835E',
        fill: '#00835E'
      }, 
      {
        key: 'reserve',
        stroke: '#00AB80',
        fill: '#00AB80'
      }, 
      {
        key: 'vacancy',
        stroke: '#D1DFB2',
        fill: '#D1DFB2'
      }
    ]
  }

  const now = new Date()
  const year = now.getFullYear()

  return (

    <BasePageContainer>

      <Container fixed className={classes.topcontainer} >
        <div className={classes.container}>

          {/* 年間稼働状況 */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.subtitle}>
                <div style={{display: 'flex', justifyContent: 'start' }}>
                  <div>
                    {year}&nbsp;年間稼働状況
                  </div>
                  <div className={classes.grass_usage_guide}>
                    <div className={classes.grass_usage_guide_0}></div>
                    <div className={classes.grass_usage_guide_desc}>利用なし</div>
                    <div className={classes.grass_usage_guide_1}></div>
                    <div className={classes.grass_usage_guide_desc}>利用率 30%未満</div>
                    <div className={classes.grass_usage_guide_2}></div>
                    <div className={classes.grass_usage_guide_desc}>利用率 30〜59%</div>
                    <div className={classes.grass_usage_guide_3}></div>
                    <div className={classes.grass_usage_guide_desc}>利用率 60〜79%</div>
                    <div className={classes.grass_usage_guide_4}></div>
                    <div className={classes.grass_usage_guide_desc}>利用率 80%以上</div>
                  </div>
                </div>
              </Typography>

              <div className={classes.spacer}></div>

              <div style={{width: '90%'}}>
                { !props.yearUseGrassDataLoading &&
                props.yearUseGrassData && 
                props.yearUseGrassData.length > 0 && (
                <CalendarHeatmap
                  startDate={new Date(year, 0, 1)}
                  endDate={new Date(year, 11, 31)}
                  values={props.yearUseGrassData}
                  monthLabels={['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']}
                  weekdayLabels={['日','月','火','水','木','金','土']}
                  showWeekdayLabels={true}
                  classForValue={(value) => {
                    let index = 0
                    try{
                      if (!value) {
                        return 'color-empty';
                      }
                      const percentage = value.count / value.allPeriod
                      if(percentage >= 0.8) {
                        index = 4
                      } else if (percentage >= 0.6) {
                        index = 3
                      } else if (percentage >= 0.3) {
                        index = 2
                      } else if (percentage >= 0) {
                        index = 1
                      }
                    } catch(err) {
                      console.log(err)
                    }
                    return `color-github-${index}`;
                  }}
                />)}
              </div>
              
              
            </Grid>            
          </Grid>

          {/* 週間一時利用/定期利用/総コマ比較 */}
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Typography variant="h3" className={classes.subtitle}>
                
                <div style={{display: 'flex', justifyContent: 'start' }}>
                  <div>
                  週間稼働数 空きコマ数/休業(管理者抑え)数/利用数
                  </div>
                  <div className={classes.weeklychart_usage_guide}>
                    <div className={classes.weeklychart_usage_guide_vacancy}></div>
                    <div className={classes.weeklychart_usage_guide_desc}>空き枠数</div>
                    <div className={classes.weeklychart_usage_guide_reserve}></div>
                    <div className={classes.weeklychart_usage_guide_desc}>利用枠数</div>
                    <div className={classes.weeklychart_usage_guide_close}></div>
                    <div className={classes.weeklychart_usage_guide_desc}>休業(管理者抑え)枠数</div>
                  </div>
                </div>
              </Typography>
              { !props.weeklyUserChardLoading && 
              props.weeklyUseChartData &&
              props.weeklyUseChartData.length > 0 &&
                (<StackedAreaChart {..._chartProps} />)
              }
            </Grid>           
          </Grid>

          <div className={classes.spacer}></div>
          <div className={classes.spacer}></div>

          {/* 予約状況 */}
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <NumericInformation {...props.todayUseCount} />
            </Grid>
            <Grid item sm={4}>
              <NumericInformation {...props.tommorowUseCount} />
            </Grid>
            <Grid item sm={4}>
              <NumericInformation {...props.weekUseCount} />
            </Grid>
          </Grid>

          <div className={classes.spacer}></div>

          {/* 売上 */}
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <NumericInformation {...props.unCompleteSales} />
            </Grid>
            <Grid item sm={4}>
              <NumericInformation {...props.completeSales} />
            </Grid>
            <Grid item sm={4}>
              <NumericInformation {...props.regularCompleteSales} />
            </Grid>
          </Grid>      
        
          <div className={classes.spacer}></div>   

          {/* 決済状況 */}
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <NumericInformation {...props.authorizationError} />
            </Grid>
            <Grid item sm={4}>
              <NumericInformation {...props.paymentLimit} />
            </Grid>
            <Grid item sm={4}>
              <NumericInformation {...props.forceCancel} />
            </Grid>
          </Grid>      
        
          <div className={classes.spacer}></div>   

          {/* 問い合わせ */}
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <NumericInformation {...props.commonInquiryCount} />
            </Grid>
            <Grid item sm={4}>
              <NumericInformation {...props.regularInquiryCount} />
            </Grid>            
          </Grid>

          <div className={classes.spacer}></div>

          {/* 新規ユーザーTOP10 */}
          <div className={classes.userTransition_container}>

          </div>

          {/* システムエラー */}
          <div className={classes.error_container}>
            
          </div>

        </div>
      </Container>
    </BasePageContainer>

  );

}

export default DashboardPage