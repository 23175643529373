import React, { useEffect, useState } from "react";
import { AppState } from "../types/stateTypes";
import { useSelector, useDispatch } from "react-redux";
import { CustomThunkDispatch } from "../types/dispatchTypes";
import { Values, Errors, convertFormInitialValues } from "types/formTypes";
import SpaceSearchForm, { validate } from "../components/Space/SpaceSearchForm";
import { SubmissionError, stopAsyncValidation } from "redux-form";
import { fetchSpaceList } from "actions/spaceAction";
import { SpaceSearchCondition } from "dataObjects/space";
import { ValidationError } from "errors/RequestValidationError";

interface OwnProps {
}

export type SpaceSearchFromContainerProps = OwnProps;

const appStateSelector = (state: AppState) => state

export default function SpaceSearchFromContainer(props: SpaceSearchFromContainerProps) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)

    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {
      setIsDisplay(false)
      
      const condition = {
        space_name: values["space_name"],
        address: values["address"]
      };

      ownDispatch(
        fetchSpaceList(
          condition,
          state.spaceList.per_page,
          0
        )
      );
    }
  }

  useEffect(() => {
    ownDispatch(
      fetchSpaceList(state.spaceListCondition)
    );
  }, []);

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if (isDisplay === false) {
      console.log({ errors })
           
      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)

      ownDispatch(stopAsyncValidation('SpaceSearchForm', errors))
      setIsDisplay(true)
    }
  }  

  const _props = {
    onSubmit: submit,
    initialValues: convertFormInitialValues<SpaceSearchCondition>(
      state.spaceListCondition
    ),
    validationResult: state.ss422ValidationErrorResult,
    ssValidation: showValidationError,
  };

  return <SpaceSearchForm {..._props} />

}
