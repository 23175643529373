import React, { useEffect, useState } from "react";
import { AppState } from "../types/stateTypes";
import { useSelector, useDispatch } from "react-redux";
import { CustomThunkDispatch } from "../types/dispatchTypes";
import { Values, Errors, convertFormInitialValues } from "types/formTypes";
import { SubmissionError, stopAsyncValidation } from "redux-form";
import { InquirySearchCondition } from "dataObjects/inquiry";
import { fetchRegularInquiryList } from "actions/inquiryAction";
import InquirySearchForm, { validate } from "components/Inquiry/InquirySearchForm";
import { ValidationError } from "errors/RequestValidationError";

interface OwnProps {
}

export type RegularInquirySearchFromContainerProps = OwnProps;

const appStateSelector = (state: AppState) => state

export default function RegularInquirySearchFromContainer(props: RegularInquirySearchFromContainerProps) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {

      const condition = {
        user_name: values['user_name'],
        email: values['email'],
        content: values['content'],
        inquiry_date_from: values['inquiry_date_from'],
        inquiry_date_to: values['inquiry_date_to']
      };

      ownDispatch(
        fetchRegularInquiryList(
          condition,
          state.regularInquiryList.per_page,
          0
        )
      );
    }
  }

  useEffect(() => {
    ownDispatch(
      fetchRegularInquiryList(state.regularInquiryListCondition)
    );
  }, []);

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if(isDisplay === false) {
      console.log({errors})     
        
      const convErrors = []
      Object.keys(errors).map((key) => {
        convErrors[key] = errors[key][key]
      })      
      errors = convErrors

      const regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
      const toTouch = []            
      const keyReducer = (err, result) => {
        Object.keys(err).map((key) => {
          if(regexp.test(key)) {
            result[key] = []
            keyReducer(err[key], result[key])
          } else {
            if(typeof err[key] == 'string') {
              result.push(key)
            } else {
              keyReducer(err[key], result)
            }
          }
        })  
      }
      keyReducer(errors, toTouch)
      touch(...toTouch)
 
      ownDispatch(stopAsyncValidation('InquirySearchForm', errors))
      setIsDisplay(true)
    }
  }  
  
  const _props = {
    onSubmit: submit,
    validationResult: state.ss422ValidationErrorResult,
    ssValidation: showValidationError,
    initialValues: convertFormInitialValues<InquirySearchCondition>(
      state.regularInquiryListCondition
    )
  };

  return <InquirySearchForm {..._props} />

}
