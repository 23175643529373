import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { ListTableColumn } from '../components/ListTable/ListTable'
import InquiryListPage, { InquiryListPageProps } from 'pages/InquiryListPage';
import { fetchCommonInquiryList, fetchCommonInquiry } from 'actions/inquiryAction';
import { InquiryListHeader } from 'dataObjects/inquiry';
import { observeShownAction } from 'actions/pagingAction';


const columns: ListTableColumn[] = [
  { id: 'reserve_id', label: 'ID', minWidth: 50 },
  {
    id: 'name',
    label: 'お客様名',
    minWidth: 100,
    format: (value: string) => value && value.length > 30 ? value.substring(0, 30) : value
  },
  {
    id: 'email',
    label: 'email',
    minWidth: 100,
  }
  , {
    id: 'content',
    label: '問い合わせ内容',
    minWidth: 100,
    format: (value: string) => value && value.length > 80 ? value.substring(0, 80) + "..." : value
  },
  {
    id: 'str_inquiry_at',
    label: '受付日時',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'already_read',
    label: '開封済み',
    minWidth: 100,
    align: 'center',
    format: (value: string) => {
      switch (value) {
        case '0':
          return '未開封'
        case '1':
          return '済み'
        default:
          return ''
      }
    }
  }
];

interface OwnProps {
}

export type InquiryListPageContainerProps = OwnProps & RouteComponentProps<{}>

const appStateSelector = (state: AppState) => state

export default function InquiryListPageContainer(props: InquiryListPageContainerProps) {
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  const _props: InquiryListPageProps = {
    columns: columns,
    data: state.commonInquiryList.data,
    total: state.commonInquiryList.total,
    page: state.commonInquiryList.current_page - 1,
    rowsPerPage: Object.keys(state.commonInquiryList.data).length,
    rowsPerPageOptions: [
      20,
      50,
      100,
      Object.keys(state.commonInquiryList.data).length
    ],
    setPage: (page: number) => {
      ownDispatch(
        fetchCommonInquiryList(
          state.commonInquiryListCondition,
          state.commonInquiryList.per_page,
          page
        )
      );
    },
    setRowsPerPage: (perPage: number) => {
      ownDispatch(
        fetchCommonInquiryList(
          state.commonInquiryListCondition,
          perPage,
          0
        )
      );
    },
    selectedRow: (data: any) => {
      const inquiryData = data as InquiryListHeader;
      ownDispatch(fetchCommonInquiry(inquiryData.id));
    }
  };

  useEffect(() => {
    ownDispatch(observeShownAction());
  }, [])

  return <InquiryListPage {..._props} />

}