import { ActionTypes } from '../actions/actionTypes'
import { STORE_USER_LIST_CONDITION } from 'actions/userAction';
import { UserSearchCondition } from 'dataObjects/user';


export interface UserSearchConditionState extends UserSearchCondition {

}

const initialUserSearchConditionState: UserSearchConditionState = {
  account_name: '',
  email: '',
  family_name: '',
  given_name: ''
}


/* userListCondition Reducer */
const userListCondition = (
  state: UserSearchConditionState = initialUserSearchConditionState,
  action: ActionTypes,
): UserSearchConditionState => {
  switch (action.type) {
    // ユーザー 検索条件
    case STORE_USER_LIST_CONDITION:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export default userListCondition
