import { combineReducers } from 'redux'
import settings from './settingsReducer'
import authStatus from './authStatusReducer'
import authRedirectRoute from './authRedirectRouteReducer'
import error from './errorReducer'
import page from './pageReducuer'
import snackBar from './snackBarReducer'
import master from './masterReducer'
import taxRateMaster from './taxRateMasterReducer'
import facilitiesMaster from './facilitiesMasterReducer'
import usageMaster from './usageMasterReducer'
import termsOfServiceMaster from './termsOfServiceMasterReducer'
import dropinReservationListCondition from './dropinReservationListConditionReducer'
import dropinReservationList from "./dropinReservationListReducer";
import dropinReservation from "./dropinReservationReducer";
import regularReservationListCondition from "./regularReservationListConditionReducer";
import regularReservationList from "./regularReservationListReducer";
import regularReservation from "./regularReservationReducer";
import spaceAll from "./spaceAllReducer"
import spaceListCondition from './spaceListConditionReducer'
import spaceList from "./spaceListReducer";
import spaceForUpdate from "./spaceForUpdateReducer";
import userListCondition from "./userListConditionReducer"
import userList from "./userListReducer";
import userData from "./userDataReducer";
import commonInquiry from "./commonInquiryReducer"
import commonInquiryList from "./commonInquiryListReducer"
import commonInquiryListCondition from "./commonInquiryListConditionReducer"
import regularInquiry from "./regularInquiryReducer"
import regularInquiryList from "./regularInquiryListReducer"
import regularInquiryListCondition from "./regularInquiryListConditionReducer"
import ss422ValidationErrorResult from './ss422ValidationErrorReducer'
import validationError from './validationErrorReducer'
import newsList from './newsListReducer'
import news from './newsReducer'
import newsListCondition from './newsListConditionReducer'
import {
  dailyUseCount,
  tommorowUseCount,
  weekUseCount,
  salesOfDropInUnCompleted,
  salesOfDropInCompleted,
  salesOfRegularCompleted,
  unReadCommonInquiry,
  unReadRegularInquiry,
  yearUseCountGrass,
  weekChart,
  authorizationError,
  paymentLimit,
  forceCancel
} from './dashboardReducer'
import promotionCode from './promotionCodeReducer'
import closeDay from './spaceCloseDayReducer'
import scheduleList, { showEvent } from './scheduleReducer'
import keyBoxPass from './keyBoxPassReducer'
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { reducer as reduxFormReducer } from 'redux-form';

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    settings,
    master,
    authStatus,
    authRedirectRoute,
    scheduleList,
    showEvent,
    dailyUseCount,
    tommorowUseCount,
    weekUseCount,
    salesOfDropInUnCompleted,
    salesOfDropInCompleted,
    salesOfRegularCompleted,
    unReadCommonInquiry,
    unReadRegularInquiry,
    authorizationError,
    paymentLimit,
    forceCancel,
    yearUseCountGrass,
    weekChart,
    error,
    validationError,
    ss422ValidationErrorResult,
    page,
    snackBar,
    news,
    newsList,
    newsListCondition,
    spaceAll,
    spaceListCondition,
    spaceList,
    spaceForUpdate,
    dropinReservationListCondition,
    dropinReservationList,
    dropinReservation,
    regularReservationListCondition,
    regularReservationList,
    regularReservation,
    userListCondition,
    userList,
    userData,
    commonInquiry,
    commonInquiryList,
    commonInquiryListCondition,
    regularInquiry,
    regularInquiryList,
    regularInquiryListCondition,
    taxRateMaster,
    facilitiesMaster,
    usageMaster,
    termsOfServiceMaster,
    promotionCode,
    closeDay,
    keyBoxPass,
    form: reduxFormReducer
  });
