import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, stopAsyncValidation } from 'redux-form'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import KeyBoxPassForm, { validate } from '../components/KeyBoxPass/KeyBoxPassForm'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'

import { storeKeyBoxPass, storeKeyBoxPassLocalAction } from 'actions/keyBoxPassAction';
import { ValidationError } from 'errors/RequestValidationError';
import { KeyBoxPass } from 'dataObjects/keyboxpass';
import { KeyBoxPassState } from 'reducers/keyBoxPassReducer';
import { fetchSpaceAll } from 'actions/spaceAction';


interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

export default function KeyBoxPassFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const [specialError, setSpecialError] = useState('')
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    ownDispatch(fetchSpaceAll())
  }, [])

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {
      setIsDisplay(false)
      const list = values.list.map(v => {
        const record: KeyBoxPass = {
          id: v.id,
          space_id: v.space_id,
          enable_from: v.enable_from,
          enable_to: v.enable_to,
          pass: v.pass
        }
        return record
      })
      const payload = {
        list: list
      }
      
      ownDispatch(
        storeKeyBoxPassLocalAction(
          list
        )
      )

      ownDispatch(
        storeKeyBoxPass(
          payload
        )
      )

    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if(isDisplay === false) {
      console.log({errors})      
      if(errors['list'] && errors['list']['list']) {
        setSpecialError(errors['list']['list'])
      }
           
      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)

      ownDispatch(stopAsyncValidation('KeyBoxPassForm', errors))
      setIsDisplay(true)
    }
  }  

  const _props = {
    onSubmit: submit,
    spaces: state.spaceAll.list,
    validationResult: state.ss422ValidationErrorResult,
    specialError: specialError,
    ssValidation: showValidationError,
    initialValues: convertFormInitialValues<KeyBoxPassState>(state.keyBoxPass)
  }

  return <KeyBoxPassForm {..._props} />

}