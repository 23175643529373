import React from 'react';
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';

import { AppRoute } from './routes/Route';

const App: React.FC<{}> = () => (
  <React.Fragment>
    <AppRoute />
  </React.Fragment>
);

export default App;
