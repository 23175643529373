import React, { useEffect, useState } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { SubmissionError, stopAsyncValidation } from 'redux-form'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import CloseDayForm, { validate } from '../components/CloseDay/CloseDayForm'
import { Values, Errors, convertFormInitialValues } from '../types/formTypes'

import { storeCloseDay, storeCloseDayLocalAction } from 'actions/spaceCloseDayAction';
import { ValidationError } from 'errors/RequestValidationError';
import { CloseDay } from 'dataObjects/closeday';
import { CloseDayState } from 'reducers/spaceCloseDayReducer';
import { fetchSpaceAll } from 'actions/spaceAction';


interface OwnProps {
}

type Props = OwnProps

const appStateSelector = (state: AppState) => state

export default function CloseDayFormContainer(props: Props) {
  const [isDisplay, setIsDisplay] = useState(false)
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  useEffect(() => {
    ownDispatch(fetchSpaceAll())
  }, [])

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else {
      setIsDisplay(false)

      if(values && values.list.length) {
        const list = values.list.map(v => {
          const record: CloseDay = {
            id: v.id,
            space_id: v.space_id,
            day: v.day,
            start_time: v.start_time,
            end_time: v.end_time
          }
          return record
        })
        const payload = {
          list: list
        }

        ownDispatch(
          storeCloseDayLocalAction(
            list
          )
        )
  
        ownDispatch(
          storeCloseDay(
            payload
          )
        )
      } else {
        ownDispatch(
          storeCloseDayLocalAction(
            []
          )
        )
        ownDispatch(
          storeCloseDay(
            {
              list: []
            }
          )
        )
      } 
      

    }
  }

  // SS validationエラーがあればFormに連携
  const showValidationError = (errors: ValidationError, touch: (...fields: string[]) => void) => {
    if(isDisplay === false) {
      console.log({errors}) 
           
      const toTouch = []
      const keyReducer = (err) => {
        Object.keys(err).forEach((key) => {
          if(typeof err[key] == 'string') {
            toTouch.push(key)
          } else {
            keyReducer(err[key])
          }
        })  
      }
      keyReducer(errors)
      touch(...toTouch)

      ownDispatch(stopAsyncValidation('CloseDayForm', errors))
      setIsDisplay(true)
    }
  }  

  const _props = {
    onSubmit: submit,
    spaces: state.spaceAll.list,
    validationResult: state.ss422ValidationErrorResult,
    ss422Validation: showValidationError,
    initialValues: convertFormInitialValues<CloseDayState>(state.closeDay)
  }

  return <CloseDayForm {..._props} />

}