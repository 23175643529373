import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { checkAppResponse } from '../actionHelper/responseChecker';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { routes } from 'routes/Route';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import { UserListHeaderPagenation, UserSearchCondition, UserData, UserDataUpdateResult } from 'dataObjects/user';
import { SnackBarAction } from './actionTypes';
import { ValidationErrorListState } from 'reducers/validationErrorReducer';



/**
 * ユーザー一覧取得アクション
 */
export const FETCH_USER_LIST_START = 'FETCH_USER_LIST_START';
export const FETCH_USER_LIST_SUCCESS = 'FETCH_USER_LIST_SUCCESS';
export const FETCH_USER_LIST_FAIL = 'FETCH_USER_LIST_FAIL';
/**
 * ユーザー 検索条件 保存
 */
export const STORE_USER_LIST_CONDITION = 'STORE_USER_LIST_CONDITION'

export type FetchUserListActions =
  | FetchUserListStartAction
  | FetchUserListSuccessAction
  | FetchUserListFailAction
  | StoreUserListConditionAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchUserListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchUserListActions>;


/**
 * ユーザー 取得アクション
 */
export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export type FetchUserActions =
  | FetchUserStartAction
  | FetchUserSuccessAction
  | FetchUserFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchUserThunkResult<R> = ThunkAction<R, AppState, undefined, FetchUserActions>;


/**
 * ユーザー 保存アクション
 */
export const STORE_USER_START = 'STORE_USER_START';
export const STORE_USER_LOCAL = 'STORE_USER_LOCAL';
export const STORE_USER_SUCCESS = 'STORE_USER_SUCCESS';
export const STORE_USER_INVALID = 'STORE_USER_INVALID';
export const STORE_USER_FAIL = 'STORE_USER_FAIL';

export type StoreUserActions =
  | StoreUserStartAction
  | StoreUserLocalAction
  | StoreUserSuccessAction
  | StoreUserInvalidAction
  | StoreUserFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type StoreUserThunkResult<R> = ThunkAction<R, AppState, undefined, StoreUserActions>;


/**
 * ユーザー BANアクション
 */
export const BAN_USER_START = 'BAN_USER_START';
export const BAN_USER_SUCCESS = 'BAN_USER_SUCCESS';
export const BAN_USER_INVALID = 'BAN_USER_INVALID';
export const BAN_USER_FAIL = 'BAN_USER_FAIL';

export type BanUserActions =
  | BanUserStartAction
  | BanUserSuccessAction
  | BanUserInvalidAction
  | BanUserFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type BanUserThunkResult<R> = ThunkAction<R, AppState, undefined, BanUserActions>;

/**
 * ユーザー RESTOREアクション
 */
export const RESTORE_USER_START = 'RESTORE_USER_START';
export const RESTORE_USER_SUCCESS = 'RESTORE_USER_SUCCESS';
export const RESTORE_USER_INVALID = 'RESTORE_USER_INVALID';
export const RESTORE_USER_FAIL = 'RESTORE_USER_FAIL';

export type RestoreUserActions =
  | RestoreUserStartAction
  | RestoreUserSuccessAction
  | RestoreUserInvalidAction
  | RestoreUserFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type RestoreUserThunkResult<R> = ThunkAction<R, AppState, undefined, RestoreUserActions>;


/**
 * ユーザー FORCEDELETEアクション
 */
export const FORCEDELETE_USER_START = 'FORCEDELETE_USER_START';
export const FORCEDELETE_USER_SUCCESS = 'FORCEDELETE_USER_SUCCESS';
export const FORCEDELETE_USER_INVALID = 'FORCEDELETE_USER_INVALID';
export const FORCEDELETE_USER_FAIL = 'FORCEDELETE_USER_FAIL';

export type ForceDeleteUserActions =
  | ForceDeleteUserStartAction
  | ForceDeleteUserSuccessAction
  | ForceDeleteUserInvalidAction
  | ForceDeleteUserFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type ForceDeleteUserThunkResult<R> = ThunkAction<R, AppState, undefined, ForceDeleteUserActions>;


/* ---------------------------------------------------------------------------------------------------- */


export interface FetchUserListStartAction
  extends Action<typeof FETCH_USER_LIST_START> { }

const fetchUserListStartAction = (): FetchUserListStartAction => ({
  type: 'FETCH_USER_LIST_START',
});

export interface FetchUserListSuccessAction
  extends Action<typeof FETCH_USER_LIST_SUCCESS> {
  payload: UserListHeaderPagenation
}

const fetchUserListSuccessAction = (
  headerList: UserListHeaderPagenation
): FetchUserListSuccessAction => ({
  type: "FETCH_USER_LIST_SUCCESS",
  payload: headerList
});

export interface FetchUserListFailAction
  extends SnackBarAction<typeof FETCH_USER_LIST_FAIL> {
  
}

const fetchUserListFailAction = (message: string): FetchUserListFailAction => ({
  type: 'FETCH_USER_LIST_FAIL',
  snackBarMessage: message,
  variant: "error"
})

/**
 * ユーザー 検索条件 保存
 */
export interface StoreUserListConditionAction
  extends Action<typeof STORE_USER_LIST_CONDITION> {
  payload: UserSearchCondition;
}

const storeUserListCondition = (
  condition: UserSearchCondition
): StoreUserListConditionAction => ({
    type: "STORE_USER_LIST_CONDITION",
  payload: condition
});

/* ユーザー一覧 取得 ThunkAction */
export function fetchUserList(
  condition: UserSearchCondition,
  perpage: number = 20,
  page: number = 0
): FetchUserListThunkResult<void> {
  return async (
    dispatch: ThunkDispatch<AppState, any, FetchUserListActions>,
    getState: () => AppState
  ) => {
    try {
      const state = getState();

      dispatch(fetchUserListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = "Bearer " + state.authStatus.access_token;

      const url = `${
        process.env.REACT_APP_API_SERVER_HOST
        }api/backoffice/user/list/${perpage}?page=${page + 1}`;
      const response = await AppClient.get(url, header, condition);
      const body: UserListHeaderPagenation = await response.data;
      dispatch(storeUserListCondition(condition));
      dispatch(fetchUserListSuccessAction(body));
    } catch (err) {
      dispatch<FetchUserListActions>(
        await ajaxErrorHandler(err, fetchUserListFailAction)
      );
    }
  };
}

/* ---------------------------------------------------------------------------------------------------- */


export interface FetchUserStartAction
  extends Action<typeof FETCH_USER_START> { }

const fetchUserStartAction = (): FetchUserStartAction => ({
  type: 'FETCH_USER_START',
});

export interface FetchUserSuccessAction
  extends Action<typeof FETCH_USER_SUCCESS> {
  payload: UserData
}

const fetchUserSuccessAction = (
  data: UserData,
): FetchUserSuccessAction => ({
  type: 'FETCH_USER_SUCCESS',
  payload: data
})

export interface FetchUserFailAction
  extends SnackBarAction<typeof FETCH_USER_FAIL> {
}

const fetchUserFailAction = (message: string): FetchUserFailAction => ({
  type: 'FETCH_USER_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* ユーザー 取得 ThunkAction */
export function fetchUser(user_id: number): FetchUserThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchUserActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchUserStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/user/find/${user_id}`;
      const response = await AppClient.get(url, header)
      const body: UserData = await response.data;
      dispatch(
        fetchUserSuccessAction(body)
      )
      dispatch(push(routes.userDetail.path))
    } catch (err) {
      dispatch<FetchUserActions>(
        await ajaxErrorHandler(err, fetchUserFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */


export interface StoreUserStartAction
  extends Action<typeof STORE_USER_START> { }

const storeUserStartAction = (): StoreUserStartAction => ({
  type: 'STORE_USER_START',
});

export interface StoreUserLocalAction
  extends Action<typeof STORE_USER_LOCAL> {
  payload: UserData
}

export const storeUserLocalAction = (
  data: UserData,
): StoreUserLocalAction => ({
  type: 'STORE_USER_LOCAL',
  payload: data
})

export interface StoreUserSuccessAction
  extends Action<typeof STORE_USER_SUCCESS> {
  payload: UserData
}

const storeUserSuccessAction = (
  data: UserData,
): StoreUserSuccessAction => ({
  type: 'STORE_USER_SUCCESS',
  payload: data
  })

export interface StoreUserInvalidAction
  extends Action<typeof STORE_USER_INVALID> {
  payload: ValidationErrorListState
}

const storeUserInvalidAction = (
  payload: ValidationErrorListState
)
  : StoreUserInvalidAction => {
  return {
    type: STORE_USER_INVALID,
    payload: payload
  }
}

export interface StoreUserFailAction
  extends SnackBarAction<typeof STORE_USER_FAIL> {
}

const storeUserFailAction = (message: string): StoreUserFailAction => ({
  type: 'STORE_USER_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* ユーザー 更新 ThunkAction */
export function storeUser(payload: UserData): StoreUserThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreUserActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(storeUserStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/user/store`;
      const response = await AppClient.post(url, header, payload)
      const body: UserDataUpdateResult = await response.data;
      if (body.validationResult && body.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeUserInvalidAction({
          message: 'ユーザー情報更新処理が中断しました。',
          errors: body.validationResult
        }))
      } else {
        dispatch(
          storeUserSuccessAction(body.data)
        )
      }

    } catch (err) {
      dispatch<StoreUserActions>(
        await ajaxErrorHandler(err, storeUserFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */


export interface BanUserStartAction
  extends Action<typeof BAN_USER_START> { }

const banUserStartAction = (): BanUserStartAction => ({
  type: 'BAN_USER_START',
});

export interface BanUserSuccessAction
  extends Action<typeof BAN_USER_SUCCESS> {
  payload: UserData
}

const banUserSuccessAction = (
  data: UserData,
): BanUserSuccessAction => ({
  type: 'BAN_USER_SUCCESS',
  payload: data
  })

export interface BanUserInvalidAction
  extends Action<typeof BAN_USER_INVALID> {
  payload: ValidationErrorListState
}

const banUserInvalidAction = (
  payload: ValidationErrorListState
)
  : BanUserInvalidAction => {
  return {
    type: BAN_USER_INVALID,
    payload: payload
  }
}

export interface BanUserFailAction
  extends SnackBarAction<typeof BAN_USER_FAIL> {
}

const banUserFailAction = (message: string): BanUserFailAction => ({
  type: 'BAN_USER_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* ユーザー アカウント停止 ThunkAction */
export function banUser(user_id: number): BanUserThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, BanUserActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(banUserStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const payload = {
        user_id: user_id
      }
      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/user/ban`;
      const response = await AppClient.post(url, header, payload)
      const body: UserDataUpdateResult = await response.data;
      if (body.validationResult && body.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(banUserInvalidAction({
          message: 'アカウント停止処理が中断しました。',
          errors: body.validationResult
        }))
      } else {
        dispatch(
          banUserSuccessAction(body.data)
        )
      }

    } catch (err) {
      dispatch<BanUserActions>(
        await ajaxErrorHandler(err, banUserFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */


export interface RestoreUserStartAction
  extends Action<typeof RESTORE_USER_START> { }

const restoreUserStartAction = (): RestoreUserStartAction => ({
  type: 'RESTORE_USER_START',
});

export interface RestoreUserSuccessAction
  extends Action<typeof RESTORE_USER_SUCCESS> {
  payload: UserData
}

const restoreUserSuccessAction = (
  data: UserData,
): RestoreUserSuccessAction => ({
  type: 'RESTORE_USER_SUCCESS',
  payload: data
  })

export interface RestoreUserInvalidAction
  extends Action<typeof RESTORE_USER_INVALID> {
  payload: ValidationErrorListState
}

const restoreUserInvalidAction = (
  payload: ValidationErrorListState
)
  : RestoreUserInvalidAction => {
  return {
    type: RESTORE_USER_INVALID,
    payload: payload
  }
}

export interface RestoreUserFailAction
  extends SnackBarAction<typeof RESTORE_USER_FAIL> {
}

const restoreUserFailAction = (message: string): RestoreUserFailAction => ({
  type: 'RESTORE_USER_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* ユーザー アカウント復旧 ThunkAction */
export function restoreUser(user_id: number): RestoreUserThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, RestoreUserActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(restoreUserStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const payload = {
        user_id: user_id
      }

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/user/restore`;
      const response = await AppClient.post(url, header, payload)
      const body: UserDataUpdateResult = await response.data;
      if (body.validationResult && body.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(restoreUserInvalidAction({
          message: 'アカウント復旧処理が中断しました。',
          errors: body.validationResult
        }))
      } else {
        dispatch(
          restoreUserSuccessAction(body.data)
        )
      }

    } catch (err) {
      dispatch<RestoreUserActions>(
        await ajaxErrorHandler(err, restoreUserFailAction),
      )
    }
  }
}


/* ---------------------------------------------------------------------------------------------------- */


export interface ForceDeleteUserStartAction
  extends Action<typeof FORCEDELETE_USER_START> { }

const forceDeleteUserStartAction = (): ForceDeleteUserStartAction => ({
  type: 'FORCEDELETE_USER_START',
});

export interface ForceDeleteUserSuccessAction
  extends Action<typeof FORCEDELETE_USER_SUCCESS> {
}

const forceDeleteUserSuccessAction = (): ForceDeleteUserSuccessAction => ({
  type: 'FORCEDELETE_USER_SUCCESS'
  })

export interface ForceDeleteUserInvalidAction
  extends Action<typeof FORCEDELETE_USER_INVALID> {
  payload: ValidationErrorListState
}

const forceDeleteUserInvalidAction = (
  payload: ValidationErrorListState
)
  : ForceDeleteUserInvalidAction => {
  return {
    type: FORCEDELETE_USER_INVALID,
    payload: payload
  }
}

export interface ForceDeleteUserFailAction
  extends SnackBarAction<typeof FORCEDELETE_USER_FAIL> {
}

const forceDeleteUserFailAction = (message: string): ForceDeleteUserFailAction => ({
  type: 'FORCEDELETE_USER_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* ユーザー アカウント強制削除 ThunkAction */
export function forceDeleteUser(user_id: number): ForceDeleteUserThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, ForceDeleteUserActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(forceDeleteUserStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const payload = {
        user_id: user_id
      }

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/user/forcedelete`;
      const response = await AppClient.post(url, header, payload)
      const body: UserDataUpdateResult = await response.data;
      if (body.validationResult && body.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(forceDeleteUserInvalidAction({
          message: 'アカウント削除処理が中断しました。',
          errors: body.validationResult
        }))
      } else {
        dispatch(
          forceDeleteUserSuccessAction()
        )
      }

    } catch (err) {
      dispatch<ForceDeleteUserActions>(
        await ajaxErrorHandler(err, forceDeleteUserFailAction),
      )
    }
  }
}