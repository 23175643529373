import { ActionTypes } from '../actions/actionTypes';
import {
  FETCH_DAILY_USE_COUNT_START,
  FETCH_DAILY_USE_COUNT_SUCCESS,
  FETCH_DAILY_USE_COUNT_FAIL,
  FETCH_TOMMROW_USE_COUNT_START,
  FETCH_TOMMROW_USE_COUNT_SUCCESS,
  FETCH_TOMMROW_USE_COUNT_FAIL,
  FETCH_WEEK_USE_COUNT_START,
  FETCH_WEEK_USE_COUNT_SUCCESS,
  FETCH_WEEK_USE_COUNT_FAIL,
  FETCH_YEAR_USE_COUNT_START,
  FETCH_YEAR_USE_COUNT_SUCCESS,
  FETCH_YEAR_USE_COUNT_FAIL,
  FETCH_WEEK_CHART_START,
  FETCH_WEEK_CHART_SUCCESS,
  FETCH_WEEK_CHART_FAIL,
  FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_START,
  FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_SUCCESS,
  FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_FAIL,
  FETCH_MONTHLY_SALES_DROPIN_COMPLETED_START,
  FETCH_MONTHLY_SALES_DROPIN_COMPLETED_SUCCESS,
  FETCH_MONTHLY_SALES_DROPIN_COMPLETED_FAIL,
  FETCH_MONTHLY_SALES_REGULAR_COMPLETED_START,
  FETCH_MONTHLY_SALES_REGULAR_COMPLETED_SUCCESS,
  FETCH_MONTHLY_SALES_REGULAR_COMPLETED_FAIL,
  FETCH_UNREAD_COMMON_INQUIRY_START,
  FETCH_UNREAD_COMMON_INQUIRY_SUCCESS,
  FETCH_UNREAD_COMMON_INQUIRY_FAIL,
  FETCH_UNREAD_REGULAR_INQUIRY_START,
  FETCH_UNREAD_REGULAR_INQUIRY_SUCCESS,
  FETCH_UNREAD_REGULAR_INQUIRY_FAIL,
  FETCH_FORCE_CANCEL_START,
  FETCH_FORCE_CANCEL_SUCCESS,
  FETCH_FORCE_CANCEL_FAIL,
  FETCH_PAYMENT_LIMIT_START,
  FETCH_PAYMENT_LIMIT_SUCCESS,
  FETCH_PAYMENT_LIMIT_FAIL,
  FETCH_AUTHORIZATION_ERROR_START,
  FETCH_AUTHORIZATION_ERROR_SUCCESS,
  FETCH_AUTHORIZATION_ERROR_FAIL,
} from '../actions/dashboardAction'
import { DashboardValuePanel, DashboardUseChart, DashboardGrassValue } from 'dataObjects/dashboard';

/**
 * 当日利用数
 */
export interface DailyUseCountState extends DashboardValuePanel {
}

const initialDailyUseCountState: DailyUseCountState = {
  title: '',
  value: 0,
  isLoading: false
}


/* DailyUseCountState Reducer */
export const dailyUseCount = (
  state: DailyUseCountState = initialDailyUseCountState,
  action: ActionTypes
): DailyUseCountState => {
  switch (action.type) {
    case FETCH_DAILY_USE_COUNT_START:
      return { ...state, isLoading: true  };
    case FETCH_DAILY_USE_COUNT_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false  };
    case FETCH_DAILY_USE_COUNT_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};

/* ---------------------------------------------------------------------------------------------------- */

/**
 * 翌日利用数
 */
export interface TommorowUseCountState extends DashboardValuePanel {
}

const initialTommorowUseCountState: TommorowUseCountState = {
  title: '',
  value: 0,
  isLoading: false
}

/* TommorowUseCountState Reducer */
export const tommorowUseCount = (
  state: TommorowUseCountState = initialTommorowUseCountState,
  action: ActionTypes
): TommorowUseCountState => {
  switch (action.type) {
    case FETCH_TOMMROW_USE_COUNT_START:
      return { ...state, isLoading: true  };
    case FETCH_TOMMROW_USE_COUNT_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false  };
    case FETCH_TOMMROW_USE_COUNT_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};

/* ---------------------------------------------------------------------------------------------------- */

/**
 * 週間利用数
 */
export interface WeekUseCountState extends DashboardValuePanel {
}

const initialWeekUseCountState: WeekUseCountState = {
  title: '',
  value: 0,
  isLoading: false
}

/* WeekUseCountState Reducer */
export const weekUseCount = (
  state: WeekUseCountState = initialWeekUseCountState,
  action: ActionTypes
): WeekUseCountState => {
  switch (action.type) {
    case FETCH_WEEK_USE_COUNT_START:
      return { ...state, isLoading: true  };
    case FETCH_WEEK_USE_COUNT_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false  };
    case FETCH_WEEK_USE_COUNT_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 月次一時利用未成売上
 */
export interface SalesOfDropInUnCompletedState extends DashboardValuePanel {
}

const initialSalesOfDropInUnCompletedState: SalesOfDropInUnCompletedState = {
  title: '',
  value: 0,
  isLoading: false
}

/* SalesOfDropInUnCompletedState Reducer */
export const salesOfDropInUnCompleted = (
  state: SalesOfDropInUnCompletedState = initialSalesOfDropInUnCompletedState,
  action: ActionTypes
): SalesOfDropInUnCompletedState => {
  switch (action.type) {
    case FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_START:
      return { ...state, isLoading: true  };
    case FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false  };
    case FETCH_MONTHLY_SALES_DROPIN_UNCOMPLETED_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};


/* ---------------------------------------------------------------------------------------------------- */


/**
 * 月次一時利用確定売上
 */
export interface SalesOfDropInCompletedState extends DashboardValuePanel {
}

const initialSalesOfDropInCompletedState: SalesOfDropInCompletedState = {
  title: '',
  value: 0,
  isLoading: false
}

/* SalesOfDropInCompletedState Reducer */
export const salesOfDropInCompleted = (
  state: SalesOfDropInCompletedState = initialSalesOfDropInCompletedState,
  action: ActionTypes
): SalesOfDropInCompletedState => {
  switch (action.type) {
    case FETCH_MONTHLY_SALES_DROPIN_COMPLETED_START:
      return { ...state, isLoading: true  };
    case FETCH_MONTHLY_SALES_DROPIN_COMPLETED_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false  };
    case FETCH_MONTHLY_SALES_DROPIN_COMPLETED_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};

/* ---------------------------------------------------------------------------------------------------- */


/**
 * 月次定期利用確定売上
 */
export interface SalesOfRegularCompletedState extends DashboardValuePanel {
}

const initialSalesOfRegularCompletedState: SalesOfRegularCompletedState = {
  title: '',
  value: 0,
  isLoading: false
}

/* SalesOfDropInCompletedState Reducer */
export const salesOfRegularCompleted = (
  state: SalesOfRegularCompletedState = initialSalesOfRegularCompletedState,
  action: ActionTypes
): SalesOfRegularCompletedState => {
  switch (action.type) {
    case FETCH_MONTHLY_SALES_REGULAR_COMPLETED_START:
      return { ...state, isLoading: true  };
    case FETCH_MONTHLY_SALES_REGULAR_COMPLETED_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false  };
    case FETCH_MONTHLY_SALES_REGULAR_COMPLETED_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};

/* ---------------------------------------------------------------------------------------------------- */

/**
 * 未読 お問い合わせ数
 */
export interface UnReadCommonInquiryState extends DashboardValuePanel {
}

const initialUnReadCommonInquiryState: UnReadCommonInquiryState = {
  title: '',
  value: 0,
  isLoading: false
}

/* UnReadCommonInquiryState Reducer */
export const unReadCommonInquiry = (
  state: UnReadCommonInquiryState = initialUnReadCommonInquiryState,
  action: ActionTypes
): UnReadCommonInquiryState => {
  switch (action.type) {
    case FETCH_UNREAD_COMMON_INQUIRY_START:
      return { ...state, isLoading: true  };
    case FETCH_UNREAD_COMMON_INQUIRY_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false };
    case FETCH_UNREAD_COMMON_INQUIRY_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};

/* ---------------------------------------------------------------------------------------------------- */

/**
 * 未読 定期利用お問い合わせ数
 */
export interface UnReadRegularInquiryState extends DashboardValuePanel {
}

const initialUnReadRegularInquiryState: UnReadRegularInquiryState = {
  title: '',
  value: 0,
  isLoading: false
}

/* UnReadRegularInquiryState Reducer */
export const unReadRegularInquiry = (
  state: UnReadRegularInquiryState = initialUnReadRegularInquiryState,
  action: ActionTypes
): UnReadRegularInquiryState => {
  switch (action.type) {
    case FETCH_UNREAD_REGULAR_INQUIRY_START:
      return { ...state, isLoading: true };
    case FETCH_UNREAD_REGULAR_INQUIRY_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false  };
    case FETCH_UNREAD_REGULAR_INQUIRY_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 未処理 再オーソリエラー数
 */
export interface AuthorizationErrorState extends DashboardValuePanel {
}

const initialAuthorizationErrorStateState: AuthorizationErrorState = {
  title: '',
  value: 0,
  isLoading: false
}

/* AuthorizationErrorStateState Reducer */
export const authorizationError = (
  state: AuthorizationErrorState = initialAuthorizationErrorStateState,
  action: ActionTypes
): AuthorizationErrorState => {
  switch (action.type) {
    case FETCH_AUTHORIZATION_ERROR_START:
      return { ...state, isLoading: true };
    case FETCH_AUTHORIZATION_ERROR_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false  };
    case FETCH_AUTHORIZATION_ERROR_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 明日強制キャンセルとなる未決済手続き数
 */
export interface PaymentLimitState extends DashboardValuePanel {
}

const initialPaymentLimitStateState: PaymentLimitState = {
  title: '',
  value: 0,
  isLoading: false
}

/* PaymentLimitStateState Reducer */
export const paymentLimit = (
  state: PaymentLimitState = initialPaymentLimitStateState,
  action: ActionTypes
): PaymentLimitState => {
  switch (action.type) {
    case FETCH_PAYMENT_LIMIT_START:
      return { ...state, isLoading: true };
    case FETCH_PAYMENT_LIMIT_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false  };
    case FETCH_PAYMENT_LIMIT_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};

/* ---------------------------------------------------------------------------------------------------- */

/**
 * 本決済エラーとなった数
 */
export interface ForceCancelState extends DashboardValuePanel {
}

const initialForceCancelStateState: ForceCancelState = {
  title: '',
  value: 0,
  isLoading: false
}

/* ForceCancelStateState Reducer */
export const forceCancel = (
  state: ForceCancelState = initialForceCancelStateState,
  action: ActionTypes
): ForceCancelState => {
  switch (action.type) {
    case FETCH_FORCE_CANCEL_START:
      return { ...state, isLoading: true };
    case FETCH_FORCE_CANCEL_SUCCESS: 
      return { ...state, ...action.payload, isLoading: false  };
    case FETCH_FORCE_CANCEL_FAIL:
      return { ...state, isLoading: false  };
    default:
      return { ...state };
  }
};


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 年間利用数草
 */
export interface YearUseCountGrassState {
  values: DashboardGrassValue[],
  isLoading: boolean
}

const initialYearUseCountGrassState: YearUseCountGrassState = {
  values: [
    {
      date: new Date(2020, 1, 1),
      count: 0, 
      allPeriod: 0
    }
  ],
  isLoading: false
}

/* YearUseCountGrassState Reducer */
export const yearUseCountGrass = (
  state: YearUseCountGrassState = initialYearUseCountGrassState,
  action: ActionTypes
): YearUseCountGrassState => {
  switch (action.type) {
    case FETCH_YEAR_USE_COUNT_START:
      return { ...state, isLoading: true };
    case FETCH_YEAR_USE_COUNT_SUCCESS: 
      return { ...state,
        values: [
          ...action.payload
        ], isLoading: false,
      };
    case FETCH_YEAR_USE_COUNT_FAIL:
      return { ...state, isLoading: false };
    default:
      return { ...state };
  }
};


/* ---------------------------------------------------------------------------------------------------- */

/**
 * 週間利用数 種別相対表
 */
export interface WeekChartState {
  values: DashboardUseChart[],
  isLoading: boolean
}

const initialWeekChartState: WeekChartState = {
  values: [
    {
      name: '2020-01-01',
      reserve: 0,
      vacancy: 0,
      close: 0
    }
  ],
  isLoading: false
}

/* YearUseCountGrassState Reducer */
export const weekChart = (
  state: WeekChartState = initialWeekChartState,
  action: ActionTypes
): WeekChartState => {
  switch (action.type) {
    case FETCH_WEEK_CHART_START:
      return { ...state };
    case FETCH_WEEK_CHART_SUCCESS: 
      const newState = { ...state,
        values: [
          ...action.payload
        ],
      };      
      return newState
    case FETCH_WEEK_CHART_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};
