import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes';
import { banUser, restoreUser, forceDeleteUser } from '../actions/userAction';
import UserDetailPage, { UserDetailPageProps } from '../pages/UserDetailPage';
import { closeDialog } from '../actions/dialogAction';
import { routes } from 'routes/Route';
import { push } from 'connected-react-router';
import { observeShownAction } from 'actions/pagingAction';

interface OwnProps {
}

export type UserDetailPageContainerProps = OwnProps

const appStateSelector = (state: AppState) => state

export default function UserDetailPageContainer(props: UserDetailPageContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()

  // ユーザーデータ読み込み
  useEffect(() => {
    dispatch(observeShownAction())
  }, []);

  const _props: UserDetailPageProps = {
    dialogOpen: state.page.dialogOpen,
    accountProcResultDialogOpen: state.page.updateDialogOpen,
    forceDeleteProcResultDialogOpen: state.page.rejectDialogOpen,
    handleClose: () => {
      dispatch(closeDialog())
    },
    handleForceDeleteClose: () => {
      dispatch(closeDialog())
      dispatch(push(routes.userList.path))
    },
    onBack: () => {
      dispatch(push(routes.userList.path))
    },

    status: state.userData.user_deleted_at == undefined || state.userData.user_deleted_at == null ? '0' : '1',    
    
    onSubmit: () => {
      dispatch(forceDeleteUser(state.userData.user_id))
    },
    ban: () => {
      dispatch(banUser(state.userData.user_id))
    },
    restore: () => {
      dispatch(restoreUser(state.userData.user_id))
    }
  }

  return <UserDetailPage {..._props} />

}