import AppClient from '../httpClients/appClient';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from '../types/stateTypes';
import { ErrorActions } from './errorAction';
import { ajaxErrorHandler } from '../actionHelper/ajaxErrorHandler';
import { routes } from 'routes/Route';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { ValidationErrorListState } from 'reducers/validationErrorReducer';
import { CloseDay, CloseDayUpdateResult, CloseDayStoreRequest } from '../dataObjects/closeday'
import { SnackBarAction } from './actionTypes';
import { dateToString } from 'utils/convertor'

/**
 * CLOSEDAY一覧取得アクション
 */
export const FETCH_CLOSEDAY_LIST_START = 'FETCH_CLOSEDAY_LIST_START';
export const FETCH_CLOSEDAY_LIST_SUCCESS = 'FETCH_CLOSEDAY_LIST_SUCCESS';
export const FETCH_CLOSEDAY_LIST_FAIL = 'FETCH_CLOSEDAY_LIST_FAIL';


export type FetchCloseDayListActions =
  | FetchCloseDayListStartAction
  | FetchCloseDayListSuccessAction
  | FetchCloseDayListFailAction
  | CallHistoryMethodAction
  | ErrorActions;

type FetchCloseDayListThunkResult<R> = ThunkAction<R, AppState, undefined, FetchCloseDayListActions>;


/**
 * CLOSEDAY 更新アクション
 */
export const STORE_CLOSEDAY_START = 'STORE_CLOSEDAY_START';
export const STORE_CLOSEDAY_LOCAL = 'STORE_CLOSEDAY_LOCAL';
export const STORE_CLOSEDAY_SUCCESS = 'STORE_CLOSEDAY_SUCCESS';
export const STORE_CLOSEDAY_INVALID = 'STORE_CLOSEDAY_INVALID';
export const STORE_CLOSEDAY_FAIL = 'STORE_CLOSEDAY_FAIL';

export type StoreCloseDayActions =
  | StoreCloseDayStartAction
  | StoreCloseDaySuccessAction
  | StoreCloseDayInvalidAction
  | StoreCloseDayFailAction
  | StoreCloseDayLocalAction
  | CallHistoryMethodAction
  | ErrorActions;

type StoreCloseDayThunkResult<R> = ThunkAction<R, AppState, undefined, StoreCloseDayActions>;


/* ---------------------------------------------------------------------------------------------------- */


export interface FetchCloseDayListStartAction
  extends Action<typeof FETCH_CLOSEDAY_LIST_START> { }

const fetchCloseDayListStartAction = (): FetchCloseDayListStartAction => ({
  type: 'FETCH_CLOSEDAY_LIST_START',
});

export interface FetchCloseDayListSuccessAction
  extends Action<typeof FETCH_CLOSEDAY_LIST_SUCCESS> {
  payload: CloseDay[]
}

const fetchCloseDayListSuccessAction = (
  list: CloseDay[],
): FetchCloseDayListSuccessAction => ({
  type: 'FETCH_CLOSEDAY_LIST_SUCCESS',
  payload: list
})

export interface FetchCloseDayListFailAction
  extends SnackBarAction<typeof FETCH_CLOSEDAY_LIST_FAIL> {

}

const fetchCloseDayListFailAction = (message: string): FetchCloseDayListFailAction => ({
  type: 'FETCH_CLOSEDAY_LIST_FAIL',
  snackBarMessage: message,
  variant: "error"
})


/* CLOSEDAY一覧 取得 ThunkAction */
export function fetchCloseDayList(): FetchCloseDayListThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, FetchCloseDayListActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(fetchCloseDayListStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/closeday`
      const response = await AppClient.get(url, header)
      const body: CloseDay[] = await response.data;

      const toString = Object.prototype.toString
      body.forEach((value) => {
        value.day = toString.call(value.day) == '[object Date]' ? value.day : new Date(Date.parse(dateToString(value.day).replace(/-/g,"/")))
      })

      dispatch(
        fetchCloseDayListSuccessAction(body)
      )
    } catch (err) {
      dispatch<FetchCloseDayListActions>(
        await ajaxErrorHandler(err, fetchCloseDayListFailAction),
      )
    }
  }
}

/* ---------------------------------------------------------------------------------------------------- */

export interface StoreCloseDayStartAction
  extends Action<typeof STORE_CLOSEDAY_START> { }

const storeCloseDayStartAction = (): StoreCloseDayStartAction => ({
  type: 'STORE_CLOSEDAY_START',
});

export interface StoreCloseDayLocalAction
  extends Action<typeof STORE_CLOSEDAY_LOCAL> {
  payload: CloseDay[]
}

export const storeCloseDayLocalAction = (
  data: CloseDay[]
): StoreCloseDayLocalAction => ({
  type: 'STORE_CLOSEDAY_LOCAL',
  payload: data
})

export interface StoreCloseDaySuccessAction
  extends Action<typeof STORE_CLOSEDAY_SUCCESS> {
  payload: CloseDay[]
}

const storeCloseDaySuccessAction = (
  data: CloseDay[],
): StoreCloseDaySuccessAction => ({
  type: 'STORE_CLOSEDAY_SUCCESS',
  payload: data
})

export interface StoreCloseDayInvalidAction
  extends Action<typeof STORE_CLOSEDAY_INVALID> {
  payload: ValidationErrorListState
}

const storeCloseDayInvalidAction = (
  payload: ValidationErrorListState
)
  : StoreCloseDayInvalidAction => {
  return {
    type: STORE_CLOSEDAY_INVALID,
    payload: payload
  }
}

export interface StoreCloseDayFailAction
  extends SnackBarAction<typeof STORE_CLOSEDAY_FAIL> {

}

const storeCloseDayFailAction = (message: string)
  : StoreCloseDayFailAction => ({
    type: 'STORE_CLOSEDAY_FAIL',
    snackBarMessage: message,
    variant: "error"
  })


/* CLOSEDAY 更新 ThunkAction */
export function storeCloseDay(payload: CloseDayStoreRequest): StoreCloseDayThunkResult<void> {
  return async (dispatch: ThunkDispatch<AppState, any, StoreCloseDayActions>,
    getState: () => AppState) => {
    try {
      const state = getState();

      dispatch(storeCloseDayStartAction());

      const header: Record<string, string> = {};
      header.Authorization = 'Bearer ' + state.authStatus.access_token

      const url = `${process.env.REACT_APP_API_SERVER_HOST}api/backoffice/closeday/store`;
      const response = await AppClient.post(url, header, payload)
      const data: CloseDayUpdateResult = await response.data;
      if (data.validationResult && data.validationResult.length > 0) {
        /* SSValidation失敗 */
        dispatch(storeCloseDayInvalidAction({
          message: '休業日の登録処理が中断しました。',
          errors: data.validationResult
        }))
      } else {
        dispatch(
          storeCloseDaySuccessAction(data.payload)
        )
      }

    } catch (err) {
      dispatch<StoreCloseDayActions>(
        await ajaxErrorHandler(err, storeCloseDayFailAction),
      )
    }
  }
}