import React from 'react'
import {
  Field,
  FieldArray,
  reduxForm,
  WrappedFieldProps,
  WrappedFieldArrayProps,
  InjectedFormProps,
  GenericFieldArray,
  FieldArrayFieldsProps
} from "redux-form";
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


import { renderTextField, renderSelect, renderFormHelper, renderSwitch, renderDateCalendarField, renderTimeField, renderFile } from '../renderFormControl'
import { Values, Errors } from '../../types/formTypes'
import { Paper } from '@material-ui/core';


interface StyleProps {
  root: BaseCSSProperties,
  formContainer: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  detail: BaseCSSProperties,
  submitButton: BaseCSSProperties,
  bottomNav: BaseCSSProperties,
  bottomNavContent: BaseCSSProperties,
  bottomNavli: BaseCSSProperties,
  notice: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#ffffff',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    spacer: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8)
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.3rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fbfbfb'
    },
    centensetitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.5rem',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    description: {
      fontWeight: 'normal',
      fontSize: '1.3rem',
      lineHeight: 1.4,
      marginTop: theme.spacing(2)
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    },
    notice: {
      marginBottom: '180px',
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }
  })
)


/**
 * バリデータ
 * 
 * @param values 
 */
export const validate = (values: Values) => {
  const errors: Errors = {}


  return errors
}

interface OwnProps {
}

export type ReservationSearchFormPropsType = OwnProps & InjectedFormProps<{}, OwnProps>

const FieldArrayCustom = FieldArray as new () => GenericFieldArray<Field, any>;

const Form: React.FC<ReservationSearchFormPropsType> = (props: ReservationSearchFormPropsType) => {
  const classes = useStyles({} as StyleProps)
  const { handleSubmit, pristine, submitting, reset } = props;

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Paper className={classes.formContainer}>
          <div>
            <dl>
              <dt>公開日From〜公開日To</dt>
              <dd>
                <div>
                  <Field
                    name="publish_date_from"
                    type="text"
                    component={renderDateCalendarField}
                    label="公開日From"
                    margin="normal"
                    rowsMax="1"
                    className={classes.inputTextField25}
                  />
                  <Field
                    name="publish_date_to"
                    type="text"
                    component={renderDateCalendarField}
                    label="公開日To"
                    margin="normal"
                    className={classes.inputTextField25}
                  />
                </div>
              </dd>
            </dl>
            <dl>
              <dt>タイトル</dt>
              <dd>
                <div>
                  <Field
                    name="title"
                    component={renderTextField}
                    label="タイトル(あいまい検索)"
                    rows="2"
                    rowsMax="2"
                    margin="normal"
                    variant="outlined"
                    className={classes.inputTextField80}
                  />
                </div>
              </dd>
            </dl>
          </div>

          <div className={classes.spacer}></div>

          <Grid container spacing={2} justify="center">
            <Grid item xs={4}>
              <Button
                type="submit"
                fullWidth
                className={classes.submitButton}
              >
                検索
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
}

const NewsSearchForm = reduxForm({
  form: 'NewsSearchForm',
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(Form)

export default NewsSearchForm