import React from 'react'
import { Field, reduxForm, InjectedFormProps } from 'redux-form'

import { withStyles, Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { BaseCSSProperties } from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';


import { renderTextField, renderRadio, renderFormHelper } from '../renderFormControl'
import { Values, Errors } from '../../types/formTypes'
import { required, maxLength } from '../../utils/validation';
import { Typography } from '@material-ui/core';
import { UserData } from 'dataObjects/user';
import { ValidationError } from 'errors/RequestValidationError';

interface StyleProps {
  root: BaseCSSProperties,
  formContainer: BaseCSSProperties,
  subtitle: BaseCSSProperties,
  detail: BaseCSSProperties,
  submitButton: BaseCSSProperties,
  clearButton: BaseCSSProperties,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      width: '100%'
    },    
    formContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),      
      width: '100%',
      borderRadius: '3px',
      fontSize: '1.3rem',
      lineHeight: 1.3,
      '& dl': {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '120px',
        alignItems: 'center',
      },
      '& dt': {
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        width: '20%',
        height: '100%',
        paddingLeft: '10px',
        borderBottom: '1px solid #f0f0f0',
        '& dt:last-child': {
          borderBottom: 'none'
        }
      },
      '& dd': {
        marginLeft: '0',
        paddingLeft: '40px',
        display: 'inline-block',
        alignItems: 'center',
        width: '70%',
        height: '100%',
        borderBottom: '1px solid #f0f0f0',
        '& dd:last-child': {
          borderBottom: 'none'
        }
      },
      [theme.breakpoints.down('sm')]: {
        '& dl': {
          flexFlow: 'column nowrap',
          height: '150px',
        },
        '& dt': {
          backgroundColor: '#ffffff',
          paddingLeft: '10px',
          width: '100%',
          height: '80px',
          borderBottom: '1px solid #fefefe'
        },
        '& dd': {
          width: '100%',
          paddingLeft: '10px'
        },
      }
    },
    cardGrid: {
      textAlign: 'center'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fff'
    },
    userinfo: {
      marginTop: theme.spacing(2)
    },
    detail: {
      width: '100%'
    },
    submitButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      height: '50px',
      marginBottom: '20px',
      fontWeight: 'bold',
      fontSize: '1.4rem',
    }, 
    addressSearchButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      fontSize: '1.4rem',
      height: '52px',
      marginTop: '16px'
    },
    clearButton: {
      color: '#000000',
      backgroundColor: theme.palette.secondary.main,
      height: '50px',
      marginBottom: '50px'
    },
    inputTextField25: {
      width: '25%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '40%'
      }
    },
    inputTextField50: {
      width: '50%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '70%'
      }
    },
    inputTextField80: {
      width: '80%',
      marginRight: '15px',
      [theme.breakpoints.down('sm')]: {
        width: '90%'
      }
    }
  })
)


export const validate = (values: Values) => {
  const errors: Errors = {}
  const requiredFields = [
    'user_name'
  ]
  requiredFields.forEach(field => {
    if (!required(values[field])) {
      errors[field] = '必須項目です'
    }
  })

  if(values['email'] !== values['email_confirmation']){
    errors['email_confirmation'] = '一つ目のメールアドレスと一致しません'
  }

  return errors
}

interface OwnProps {
  validationResult?: ValidationError,
  ssValidation?: (errors: ValidationError, touch: (...fields: string[]) => void) => void,
  autoAddressLoad: (change) => void
}

export type UserInfoFormPropsType = OwnProps & InjectedFormProps<{}, OwnProps>


const Form: React.FC<UserInfoFormPropsType> = ({ handleSubmit, pristine, submitting, reset, validationResult, ssValidation, touch, autoAddressLoad, change }) => {
  const theme = useTheme()
  const classes = useStyles({} as StyleProps)

  if (validationResult && validationResult.status != '') {
    if (ssValidation) {
      ssValidation(validationResult.errors, touch)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formContainer}>
        <dl>
          <dt>ユーザーアカウント名</dt>
          <dd>
            <div>
              <Field
                name="user_name"
                component={renderTextField}
                label="ユーザーアカウント名"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
            </div>
          </dd>
        </dl>
        
        <Typography variant="h3" className={classes.subtitle} style={{marginBottom: theme.spacing(4)}}>
          以下のお客様情報はスペース予約時のお客様情報入力で参照されます
        </Typography>

        <dl className={classes.userinfo}>
          <dt>氏名(カナ)</dt>
          <dd>
            <div>
              <Field
                name="contact_family_name_kana"
                component={renderTextField}
                label="姓(カナ)"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
              <Field
                name="contact_given_name_kana"
                component={renderTextField}
                label="名(カナ)"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
            </div>
          </dd>
        </dl>
        <dl>
          <dt>氏名</dt>
          <dd>
            <div>
              <Field
                name="contact_family_name"
                autoComplete="family_name"
                component={renderTextField}
                label="姓"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
              <Field
                name="contact_given_name"
                autoComplete="given_name"
                component={renderTextField}
                label="名"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
            </div>
          </dd>
        </dl>
        <dl style={{ height: '220px'}}>
          <dt>メールアドレス</dt>
          <dd>
            <div>
              <Field
                name="contact_email"
                autoComplete="email"
                component={renderTextField}
                label="メールアドレス"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
              <Field
                name="contact_email_confirmation"
                autoComplete="email_confirmation"
                component={renderTextField}
                label="メールアドレス(確認)"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
            </div>
          </dd>
        </dl>
        <dl style={{ height: '500px'}}>
          <dt>住所</dt>
          <dd>
            <div>
              <Field
                name="contact_postal_code"
                autoComplete="postal-cod"
                component={renderTextField}
                label="郵便番号"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
              <Button onClick={(event) => autoAddressLoad(change)} className={classes.addressSearchButton} >
                住所自動入力
              </Button>
            </div>
            <div>
              <Field
                name="contact_address_pref"
                autoComplete="address-level1"
                component={renderTextField}
                label="都道府県"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField25}
              />
            </div>
            <div>
              <Field
                name="contact_address_city"
                autoComplete="address-level2"
                component={renderTextField}
                label="市区町村"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
            </div>
            <div>
              <Field
                name="contact_address_street"
                autoComplete="street-address"
                component={renderTextField}
                label="番地"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField80}
              />
            </div>
            <div>
              <Field
                name="contact_address_room"
                autoComplete="address-level4"
                component={renderTextField}
                label="建物・部屋番号"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField80}
              />
            </div>
          </dd>
        </dl>
        <dl>
          <dt>電話番号</dt>
          <dd>
            <div>
              <Field
                name="contact_tel"
                component={renderTextField}
                label="電話番号"
                multiline
                rowsMax="1"
                margin="normal"
                variant="outlined"
                className={classes.inputTextField50}
              />
            </div>
          </dd>
        </dl>
      </div>

      <Grid container spacing={2} alignItems="center" justify="center" style={{marginBottom: '100px'}}>
        <Grid item xs={6}>
          <Button type="submit" disabled={pristine || submitting} fullWidth className={classes.submitButton}>
            保存
          </Button>
        </Grid>
      </Grid>

    </form>    
  )
}

const UserInfoForm = reduxForm({
  form: 'UserInfoForm', 
  validate,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(Form)

export default UserInfoForm