import * as React from 'react';
import { Theme, createStyles, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { theme } from 'materialui/theme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BasePageContainer from '../containers/BasePageContainer';
import ListTable, { ListTableProps } from 'components/ListTable/ListTable';
import DropInReservationSearchFormContainer from 'containers/DropInReservationSearchFormContainer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topcontainer: {
      marginTop: theme.spacing(2)
    },
    container: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #cecece'
    },
    subtitle: {
      marginTop: theme.spacing(5),
      fontSize: '1.6rem',
      paddingBottom: theme.spacing(2),
      paddingTop: '20px',
      paddingLeft: '12px',
      backgroundColor: '#fff'
    },
    description: {
      marginTop: theme.spacing(2),
      fontSize: '1.0em',
      marginBottom: theme.spacing(2),
      textAlign: 'center'
    },
    list: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8)
    }
  })
);

interface OwnProps {

}

export type DropInReserveListPageProps = OwnProps & ListTableProps;


const DropInReserveListPage: React.FC<DropInReserveListPageProps> = (
  props: DropInReserveListPageProps
) => {
  const classes = useStyles({});
  const {
    columns,
    data,
    total,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    setPage,
    setRowsPerPage,
    selectedRow
  } = props;

  const listTableProps: ListTableProps = {
    columns: props.columns,
    data: props.data,
    total: props.total,
    page: props.page,
    rowsPerPage: props.rowsPerPage,
    rowsPerPageOptions: props.rowsPerPageOptions,
    setPage: props.setPage,
    setRowsPerPage: props.setRowsPerPage,
    selectedRow: props.selectedRow
  };

  return (
    <BasePageContainer>
      <Container fixed className={classes.topcontainer}>
        <Typography variant="h3" className={classes.subtitle}>
        一時予約検索
        </Typography>
          
        <div>
          <DropInReservationSearchFormContainer />
        </div>

        <div className={classes.list}>
          <ListTable {...listTableProps} />
        </div>
      </Container>
    </BasePageContainer>
  );
};

export default DropInReserveListPage