import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme, MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from 'materialui/theme';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import InsertChartIcon from '@material-ui/icons/InsertChart';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import ApartmentIcon from '@material-ui/icons/Apartment';
import GroupIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import StorageIcon from '@material-ui/icons/Storage';
import BookIcon from '@material-ui/icons/Book';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BallotIcon from '@material-ui/icons/Ballot';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyIcon from '@material-ui/icons/Money';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import DialpadIcon from '@material-ui/icons/Dialpad';

import LoadingComponent, { LoadingComponentProps, LoadingStyleProps } from './LoadingComponent';
import SnackBarContainer from '../containers/SnackBarContainer';
import { Collapse } from '@material-ui/core';
import ValidationErrorDialogContainer from 'containers/ValidationErrorDialogContainer';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: '#FFF',
      boxShadow: 'none'
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: '#222'
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      overflowY: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      maxWidth: drawerWidth,
      ...theme.mixins.toolbar,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    main: {
      width: '100%',
      overflow: 'hidden',
      minHeight: '100vh',
      flex: 1
    },
  })
);

interface BaseProps {
  loading: boolean,
  moveRoute: (routeKey: string) => void
}

export type Props = PropsWithChildren<BaseProps>;

const BasePageComponent: React.FC<Props> = (props: Props) => {
  const classes = useStyles({})
  const [open, setOpen] = React.useState(true);
  const [openReserve, setOpenReserve] = React.useState(false);
  const [openInquiry, setOpenInquiry] = React.useState(false);
  const [openMaster, setOpenMaster] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleReserveClick = () => {
    setOpenReserve(!openReserve);
  };

  const handleInquiryClick = () => {
    setOpenInquiry(!openInquiry);
  };
  
  const handleMasterClick = () => {
    setOpenMaster(!openMaster);
  };

  const handleRoute = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const routesKey = event.currentTarget.getAttribute('data-routes')
    props.moveRoute(routesKey)
  }

  const loadingProps: LoadingComponentProps = {
    size: 80,
    style: {} as LoadingStyleProps
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>

        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              <img src="/images/logo.png" style={{width: '80%'}}/>
            </Typography>            
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>

          <Divider />

          <List>
            <ListItem button key="dashBoard" data-routes="dashBoard" onClick={handleRoute}>
              <ListItemIcon><InsertChartIcon /></ListItemIcon>
              <ListItemText primary="ダッシュボード" />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem button key="reserveSchedule" data-routes="reserveSchedule" onClick={handleRoute}>
              <ListItemIcon><ViewComfyIcon /></ListItemIcon>
              <ListItemText primary="予約スケジュール表" />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem button key="newsList" data-routes="newsList" onClick={handleRoute}>
              <ListItemIcon><FiberNewIcon /></ListItemIcon>
              <ListItemText primary="お知らせ" />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem button key="spaceList" data-routes="spaceList" onClick={handleRoute}>
              <ListItemIcon><ApartmentIcon /></ListItemIcon>
              <ListItemText primary="スペース" />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem button key="userList" data-routes="userList" onClick={handleRoute}>
              <ListItemIcon><GroupIcon /></ListItemIcon>
              <ListItemText primary="ユーザー" />
            </ListItem>
          </List>

          <Divider />

          <List>
            <ListItem button onClick={handleReserveClick}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary="予約処理" />
              {openReserve ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openReserve} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button key="dropInReserveList" data-routes="dropInReserveList" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <ScheduleIcon />
                  </ListItemIcon>
                  <ListItemText primary="一時利用" />
                </ListItem>

                <ListItem button key="regularReserveList" data-routes="regularReserveList" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <ContactMailIcon />
                  </ListItemIcon>
                  <ListItemText primary="定期利用" />
                </ListItem>

                <ListItem button key="closeday" data-routes="closeday" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <LockIcon />
                  </ListItemIcon>
                  <ListItemText primary="管理者枠確保/休業設定" />
                </ListItem>

                
              </List>
            </Collapse>
          </List>

          <Divider />

          <List>
            <ListItem button onClick={handleInquiryClick}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="問い合わせ" />
              {openInquiry ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openInquiry} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button key="inquiryList" data-routes="inquiryList" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <MailIcon />
                  </ListItemIcon>
                  <ListItemText primary="一般問い合わせ" />
                </ListItem>

                <ListItem button key="regularInquiryList" data-routes="regularInquiryList" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <DateRangeIcon />
                  </ListItemIcon>
                  <ListItemText primary="定期利用申し込み" />
                </ListItem>
              </List>
            </Collapse>
          </List>

          <Divider />

          <List>
            <ListItem button onClick={handleMasterClick}>
              <ListItemIcon>
                <StorageIcon />
              </ListItemIcon>
              <ListItemText primary="マスタ" />
              {openMaster ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openMaster} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button key="usage" data-routes="usage" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <BookIcon />
                  </ListItemIcon>
                  <ListItemText primary="スペース利用用途" />
                </ListItem>

                <ListItem button key="facilities" data-routes="facilities" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <HomeWorkIcon />
                  </ListItemIcon>
                  <ListItemText primary="スペース設備" />
                </ListItem>

                <ListItem button key="termsOfService" data-routes="termsOfService" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="利用規約" />
                </ListItem>                

                <ListItem button key="taxRate" data-routes="taxRate" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="消費税率" />
                </ListItem>

                <ListItem button key="promotioncode" data-routes="promotioncode" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <MoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="プロモーションコード" />
                </ListItem>

                <ListItem button key="keyboxpass" data-routes="keyboxpass" onClick={handleRoute} className={classes.nested}>
                  <ListItemIcon>
                    <DialpadIcon />
                  </ListItemIcon>
                  <ListItemText primary="スペース暗証番号" />
                </ListItem>

              </List>
            </Collapse>
          </List>

          <Divider />

          <List>
            <ListItem button key="signOut" data-routes="signOut" onClick={handleRoute}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItem>
          </List>

        </Drawer>
  
        <main className={classes.content} style={{backgroundColor: '#fbfbfb'}}>

          <div className={classes.toolbar} />

          <div className={classes.main}>
            
            { props.loading ? (
              <LoadingComponent {...loadingProps} />
            ) : (        
              props.children || null    
            ) 
            }
            
            <SnackBarContainer />

            <ValidationErrorDialogContainer />
            
          </div>
        </main>
     

      </div>
    </MuiThemeProvider>
  );
}

export default BasePageComponent;
