import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    card: {
      minWidth: '250px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }),
);


interface OwnProps {
  title: string,
  value: number,
  isLoading: boolean
}

export type NumericInformationProps = OwnProps

const NumericInformation: React.FC<NumericInformationProps> = (props: NumericInformationProps) => {
  const classes = useStyles({});
  const formatter = new Intl.NumberFormat('ja-JP')

  return (
    <div className={classes.root}>
      
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {props.title}
            </Typography>
            
            {props.isLoading ? (
              <div style={{textAlign: 'center'}}>
                <CircularProgress color="primary" />
              </div>
            ) : (
              <Typography variant="h2" component="h2" style={{textAlign: 'right'}}>
                {formatter.format(props.value)}
              </Typography>
            )}
            
          </CardContent>
        </Card>
      
    </div>
  );
}


export default NumericInformation