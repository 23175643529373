import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import { logOutAction } from 'actions/authenticationActions';
import { routes } from 'routes/Route';
import { Redirect } from 'react-router';

interface OwnProps {  
}

export type SignOutContainerProps = OwnProps 


const appStateSelector = (state: AppState) => state

export default function SignOutContainer(props: SignOutContainerProps) {
  const state = useSelector(appStateSelector)
  const dispatch = useDispatch<CustomThunkDispatch>()
    
  dispatch(logOutAction())    

  return <Redirect to={routes.signIn.path} />

}