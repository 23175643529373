import { ActionTypes } from "../actions/actionTypes";
import { SpaceReservation } from "dataObjects/space";
import {
  FETCH_DROPIN_RESERVATION_START,
  FETCH_DROPIN_RESERVATION_SUCCESS ,
  FETCH_DROPIN_RESERVATION_FAIL,
  CANCEL_DROPIN_RESERVATION_START,
  CANCEL_DROPIN_RESERVATION_SUCCESS,
  CANCEL_DROPIN_RESERVATION_FAIL,
  AUTHORIZATION_DROPIN_RESERVATION_START,
  AUTHORIZATION_DROPIN_RESERVATION_SUCCESS,
  AUTHORIZATION_DROPIN_RESERVATION_FAIL,
  CAPTURE_DROPIN_RESERVATION_START,
  CAPTURE_DROPIN_RESERVATION_SUCCESS,
  CAPTURE_DROPIN_RESERVATION_FAIL,
  AUTHORIZATION_DROPIN_RESERVATION_REFRESH,
  CAPTURE_DROPIN_RESERVATION_REFRESH
} from 'actions/dropinReservationAction'


export interface DropinReservationState
  extends SpaceReservation {}

const initialDropinReservationState: DropinReservationState = {
  reserve_id: undefined,
  space_id: undefined,
  space_name: "",
  status: "0",
  selectedDate: undefined,
  selectedPeriods: [],
  reserveTimeRange: {
    start_time: "",
    end_time: ""
  },
  enquete: {
    users_adult: 0,
    users_children: 0,
    usage: undefined,
    detail: "",
    terms_of_service: false
  },
  invoice: {
    invoice_id: 0,
    reserve_id: 0,
    amount: 0,
    details: [],
    discount: 0
  },
  contractor: {
    reserve_id: 0,
    family_name: "",
    family_name_kana: "",
    given_name: "",
    given_name_kana: "",
    postal_code: "",
    address_pref: "",
    address_city: "",
    address_street: "",
    address_room: "",
    email: "",
    email_confirmation: "",
    tel: "",
    promotion_code: "",
    company_name: ""
  },
  payment: {    
    card_data: {
      brand: "",
      last4: "",
      exp_month: 0,
      exp_year: 0
    }
  },
  send_thunks_flg: false,
  send_cancel_flg: false,
  send_reminder_flg: false,
  send_confirm_period_ext_flg: false
}


/* dropinReservation Reducer */
const dropinReservation = (
  state: DropinReservationState = initialDropinReservationState,
  action: ActionTypes
): DropinReservationState => {
  switch (action.type) {
    case FETCH_DROPIN_RESERVATION_START:
      return { ...state };
    case FETCH_DROPIN_RESERVATION_SUCCESS: 
      return { ...state, ...action.payload };
    case FETCH_DROPIN_RESERVATION_FAIL:
      return { ...state };
    case CANCEL_DROPIN_RESERVATION_START:
      return { ...state };
    case CANCEL_DROPIN_RESERVATION_SUCCESS: 
      return { ...state, ...action.payload.payload };
    case CANCEL_DROPIN_RESERVATION_FAIL:
      return { ...state };
    case AUTHORIZATION_DROPIN_RESERVATION_START:
      return { ...state };
    case AUTHORIZATION_DROPIN_RESERVATION_SUCCESS: 
      return { ...state, ...action.payload.payload };
    case AUTHORIZATION_DROPIN_RESERVATION_REFRESH: 
      return { ...state, ...action.payload.payload };
    case AUTHORIZATION_DROPIN_RESERVATION_FAIL:
      return { ...state };      
    case CAPTURE_DROPIN_RESERVATION_START:
      return { ...state };
    case CAPTURE_DROPIN_RESERVATION_SUCCESS: 
      return { ...state, ...action.payload.payload };
    case CAPTURE_DROPIN_RESERVATION_REFRESH: 
      return { ...state, ...action.payload.payload };
    case CAPTURE_DROPIN_RESERVATION_FAIL:
      return { ...state };      
    default:
      return { ...state };
  }
};

export default dropinReservation;