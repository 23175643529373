import React, { useEffect } from 'react'
import { AppState } from '../types/stateTypes';
import { useSelector, useDispatch } from 'react-redux'
import { CustomThunkDispatch } from '../types/dispatchTypes'
import SignIn, { SignInProps, validate } from '../components/Auth/SignIn'
import { SubmissionError } from 'redux-form'
import { Values, Errors } from '../types/formTypes'

import { sharealLoginAction } from '../actions/authenticationActions'

interface OwnProps {  
}

export type SignInContainerProps = OwnProps 


const appStateSelector = (state: AppState) => state

export default function SignInContainer(props: SignInContainerProps) {
  const state = useSelector(appStateSelector)
  const ownDispatch = useDispatch<CustomThunkDispatch>()

  // Submit
  const submit = (values: Values, dispatch: any, props: any) => {
    const errors: Errors = validate(values)
    if (errors.Values) {
      const emap = new Map()
      Object.keys(errors).forEach(key => {
        emap.set(key, errors[key])
      });
      const submissionErrors = Object.fromEntries(emap.entries());
      throw new SubmissionError(submissionErrors)
    } else { 
      
      ownDispatch(sharealLoginAction(values['email'], values['password'], ))
    }
  }

  // 初期化
  useEffect(() => {
    //dispatch(fetchPeriodAtDayAction(baseDate))
  }, []);
    
  const _props = {    
    onSubmit: submit  
  }

  return <SignIn {..._props} />

}